// tslint:disable:comment-format
///<amd-dependency path="lazyload.script.1.0.5" />
// tslint:enable:comment-format

import * as $j from "jquery";
import * as Marzipano from "marzipano";
import * as tippy from "tippy.all";
import { Debug } from "./debug";
import { ArtikelInformationTyp } from "./enums/ArtikelInformationsTyp";
import { DokumentTyp } from "./enums/DokumentTyp";
import { KatalogSeitenTyp } from "./enums/KatalogSeitenTyp";
import { Historie } from "./historie";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { IOxomiAccessDaten } from "./interfaces/IOxomiAccessDaten";
import { KonstantenOxomi } from "./konstanten/konstantenOxomi";

/**
 * Stellt alle Funktionen bereit, die im Zusammenhang mit der Katalog-Integration stehen.
 */
export class Katalog {
    public static readonly INSTANCE = new Katalog();

    private constructor() {}

    /**
     * Stellt das interne Promise-Objekt bereit, über das die Methoden ausgeführt werden können, sobald Oxomi erfolgreich initialisiert worden ist.
     */
    private static $promiseInit: JQuery.Deferred<any> = $j.Deferred();

    /**
     * Initialisiert alle Events für Kataloge, die nur einmal
     * in der Anwendungslaufzeit ausgeführt werden dürfen.
     */
    public InitEvents() {
        // Oxomi Access Token lesen
        let accessDaten: IOxomiAccessDaten = this.HoleOxomiAccessDaten();

        // Event oxomi-ready wird gefeuert sobald Oxomi bereit zur Initialisierung ist
        $j(document).on("oxomi-ready", (e) => {
            // Oxomi-Initialisierung ausführen
            this.OxomiInit(accessDaten, accessDaten.PortalUrl)
                .done(Katalog.$promiseInit.resolve)
                .fail(Katalog.$promiseInit.reject);
        });

        // Für Oxomi benötigte Module global bereitstellen
        let winElem: any;

        if (typeof window !== "undefined") {
            winElem = window;
        } else if (typeof global !== "undefined") {
            winElem = global;
        } else if (typeof self !== undefined) {
            winElem = self;
        } else {
            winElem = this;
        }

        winElem.Marzipano = Marzipano;
        winElem.tippy = tippy;

        let onShow = (e) => {
            // Aktuellen Popstate duplizieren, damit später zurücknavigiert werden kann
            Historie.INSTANCE.DuplizierePopState();
        };

        let onHide = (e) => {
            // Popstate um einen Tupel zurücknavigieren
            let navigationUnterbrechen: boolean = window["ho-oxomi-nonavigate"];

            if (navigationUnterbrechen) {
                // ignorieren und nicht weiter zurücknavigieren
                window["ho-oxomi-nonavigate"] = false;
            } else {
                Historie.INSTANCE.EinenEintragZuruecknavigieren();
            }
        };

        $j(window).on("oxomi-show-overlay", onShow);
        $j(window).on("oxomi-hide-overlay", onHide);
        $j(window).on("oxomi-show-dialog", onShow);
        $j(window).on("oxomi-hide-dialog", onHide);

        // Oxomi-Script lazy-loaden
        const oxomiScriptSource =
            accessDaten.PortalUrl + "/assets/frontend/oxomi.js";

        require("lazyload.script.1.0.5")(oxomiScriptSource).catch(
            Katalog.$promiseInit.reject
        );
    }

    /**
     * Holt zu den angegebenen Suchbegriffen die zugehörigen Katalogseiten
     * und stellt diese in den Container, der dem angegebenen ZielSelector entspricht
     * @param SeitenTyp Gibt an, ob nach Holter-eigenen Katalogen oder nach Fremd-Katalogen gesucht wird
     * @param ZielSelector Selector des zu befüllenden Containers
     * @param ArtikelNummerHolter Holter-Artikelnummer
     * @param ArtikelNummerLieferant Lieferanten-Artikelnummer
     * @param LieferantNummer Lieferantennummer
     */
    public HoleKatalogSeiten(
        SeitenTyp: KatalogSeitenTyp,
        ZielSelector: string,
        ArtikelNummerHolter: string,
        ArtikelNummerLieferant: string,
        LieferantNummer: string
    ): JQuery.Deferred<any> {
        // Parameterset definieren für Methodenaufruf
        let oxomiItemPagesParams: OxomiItemFunctionsSettings | undefined;

        // Abhängig vom gewünschten Katalog-Such-Typ die Oxomi-Methode entsprechend aufrufen
        switch (SeitenTyp) {
            case KatalogSeitenTyp.Holter:
                // Hier wird die Holter-Artikelnummer als Such-String mitangegeben
                oxomiItemPagesParams = {
                    target: ZielSelector,
                    itemNumber: ArtikelNummerLieferant,
                    supplierNumber: LieferantNummer,
                    supplierItemNumber: ArtikelNummerLieferant,
                    query: ArtikelNummerHolter,
                    own: true,
                };
                break;
            case KatalogSeitenTyp.Extern:
                // Methodenaufruf ohne Holter-Artikelnummer
                oxomiItemPagesParams = {
                    target: ZielSelector,
                    itemNumber: ArtikelNummerLieferant,
                    supplierNumber: LieferantNummer,
                    supplierItemNumber: ArtikelNummerLieferant,
                };
                break;
            default:
                break;
        }

        // Promise holen, dass Methode ausgeführt wird, sobald Oxomi initialisiert worden ist
        return this.VerarbeiteDeferredCallWennOxomiInitialisiert(
            this.OxomiItemPages.bind(this),
            oxomiItemPagesParams
        );
    }

    /**
     * Holt abhängig vom angeforderten Informationstyp die entsprechenden Artikelinformationen
     * und stellt diese in den Container, der dem angegebenen ZielSelector entspricht
     * @param Informationstyp Gibt den Typ von Information an, der gesucht wird
     * @param ZielSelector Selector des zu befüllenden Containers
     * @param ArtikelNummerHolter Holter-Artikelnummer
     * @param ArtikelNummerLieferant Lieferanten-Artikelnummer
     * @param LieferantNummer Lieferantennummer
     */
    public HoleArtikelInformationen(
        Informationstyp: ArtikelInformationTyp,
        ZielSelector: string,
        ArtikelNummerHolter: string,
        ArtikelNummerLieferant: string,
        LieferantNummer: string
    ): JQuery.Deferred<any> {
        // Diese Variable wird mit der nach Timeout auszuführenden Methode befüllt
        let oxomiFunktion: (
            config: OxomiItemFunctionsSettings
        ) => JQuery.Deferred<any>;

        let oxomiFunktionParameter: OxomiItemFunctionsSettings = {
            target: ZielSelector,
            itemNumber: ArtikelNummerLieferant,
            supplierItemNumber: ArtikelNummerLieferant,
            supplierNumber: LieferantNummer,
        };

        // Abhängig vom gewünschten Informationstyp die entsprechende Methode ausführen
        switch (Informationstyp) {
            case ArtikelInformationTyp.Masterdata:
                // Artikel-Masterdaten ermitteln (bspw. Lieferanten-Artikelnummer)
                oxomiFunktion = this.OxomiItemMasterdata.bind(this);
                break;
            case ArtikelInformationTyp.Bilder:
                // Artikelbilder aus dem Oxomi-Portal laden
                oxomiFunktion = this.OxomiItemImages.bind(this);
                break;
            case ArtikelInformationTyp.Videos:
                // Artikelvideos aus dem Oxomi-Portal laden
                oxomiFunktion = this.OxomiItemVideos.bind(this);
                break;
            case ArtikelInformationTyp.Langtext:
                // Artikel-Langtext aus dem Oxomi-Portal laden
                oxomiFunktion = this.OxomiItemText.bind(this);
                break;
            case ArtikelInformationTyp.Exposees:
                // Exposees zum entsprechenden Artikel aus dem Oxomi-Portal laden
                oxomiFunktion = this.OxomiItemGalleries.bind(this);
                break;
            case ArtikelInformationTyp.Dokumente:
                // Downloadbare Dokumente zum Artikel aus dem Oxomi-Portal laden
                oxomiFunktion = this.OxomiItemAttachments.bind(this);
                // Bei den itemAttachments gibt es eine spezielle ClassMap, die angehängt werden muss
                // Implementierung für Artikeldetails
                oxomiFunktionParameter.classMap =
                    KonstantenOxomi.itemAttachmentsClassMap;
                break;
            default:
                break;
        }

        // Promise holen, dass die Funktion nach Abschluss der Initialisierung ausgeführt wird
        // In der Methode wird geprüft, ob eine Funktion übergeben worden ist
        return this.VerarbeiteDeferredCallWennOxomiInitialisiert(
            oxomiFunktion!,
            oxomiFunktionParameter
        );
    }

    /**
     * Prüft, ob im Oxomi-Portal Daten für den angegebenen Informationstyp vorhanden sind
     * @param Informationstyp Informationstyp, der ermittelt wird.
     * Es gibt keine Funktion, um Artikel-Masterdata zu prüfen.
     * @param ArtikelNummerHolter Holter-Artikelnummer
     * @param ArtikelNummerLieferant Lieferanten-Artikelnummer
     * @param LieferantNummer Lieferantennummer
     */
    public HoleArtikelInformationsVerfuegbarkeit(
        ArtikelNummerHolter: string,
        ArtikelNummerLieferant: string,
        LieferantNummer: string
    ): JQuery.Deferred<OxomiExistsJsonDaten> {
        // Promise holen, dass Methode ausgeführt wird, sobald Oxomi initialisiert worden ist
        return this.VerarbeiteDeferredCallWennOxomiInitialisiert(
            this.OxomiExists.bind(this),
            {
                item: ArtikelNummerLieferant,
                itemNumber: ArtikelNummerLieferant,
                supplierNumber: LieferantNummer,
            } as OxomiExistsSettings
        );
    }

    /**
     * Öffnet einen Katalog
     * @param KatalogName Name des Katalogs, oder Code
     * @param KatalogSeite Seitennummer, auf der der Katalog geöffnet werden soll
     * @param KatalogSuchbegriff Suchbegriff, der im Katalog hervorgehoben werden soll
     */
    public OeffneKatalog(
        KatalogName: string,
        KatalogSeite?: number,
        KatalogSuchbegriff?: string
    ): JQuery.Deferred<any> {
        // Promise holen, dass Methode ausgeführt wird, sobald Oxomi initialisiert worden ist
        return this.VerarbeiteDeferredCallWennOxomiInitialisiert(
            this.OxomiOpenCatalog.bind(this),
            {
                catalog: KatalogName,
                page: KatalogSeite,
                query: KatalogSuchbegriff,
            } as OxomiOpenCatalogSettings
        );
    }

    /**
     * Öffnet das Partner-Portal. Dort werden Dokumente unserer Hersteller angezeigt.
     */
    public OeffnePartnerPortal(): JQuery.Deferred<any> {
        // Promise holen, dass Methode ausgeführt wird, sobald Oxomi initialisiert worden ist
        return this.VerarbeiteDeferredCallWennOxomiInitialisiert(
            this.OxomiOpenPortal.bind(this),
            {
                withMenu: true,
            } as OxomiOpenPortalSettings
        );
    }

    /**
     * Lädt eine Liste von Dokumenten in den angegebenen Container.
     * @param dokumentTyp Dokumenttyp, der geladen wird
     * @param ZielSelector Selector des zu befüllenden Containers
     * @param NurEigene Bei true werden nur eigene Dokumente ausgegeben. Bei false werden nur Hersteller-Dokumente ausgegeben. Bei undefined werden sowohl Hersteller- als auch eigene Dokumente angezeigt.
     * @param BeschriftungAnzeigen Gibt an, ob ein Beschriftungstext je Katalog angezeigt wird.
     */
    public LadeDokumente(
        dokumentTyp: DokumentTyp,
        ZielSelector: string,
        NurEigene: boolean | undefined,
        BeschriftungAnzeigen: boolean | undefined
    ): JQuery.Deferred<any> {
        // Promise holen, dass Methode ausgeführt wird, sobald Oxomi initialisiert worden ist
        return this.VerarbeiteDeferredCallWennOxomiInitialisiert(
            this.OxomiCatalogs.bind(this),
            {
                target: ZielSelector,
                type: dokumentTyp,
                own: NurEigene,
                showDetails: BeschriftungAnzeigen,
            } as OxomiCatalogsSettings
        );
    }

    /**
     * Führt eine Portal-Suche aus.
     * @param ZielSelector Selector des Containers, in den die Auswahlliste gestellt wird
     * @param InputSelector Selector des Containers, in den das Such-Eingabefeld gestellt wird
     * @param FilterSelector Selector des Containers, in den der Filter-Suchbaum gestellt wird
     * @param FilterString Text, nach dem bei Initialisierung gefiltert wird
     */
    public LadePortalSuche(
        ZielSelector: string,
        InputSelector: string | undefined,
        FilterSelector: string | undefined,
        FilterString: string | undefined
    ): JQuery.Deferred<any> {
        // Promise holen, dass Methode ausgeführt wird, sobald Oxomi initialisiert worden ist
        return this.VerarbeiteDeferredCallWennOxomiInitialisiert(
            this.OxomiPortalSearch.bind(this),
            {
                target: ZielSelector,
                input: InputSelector,
                filterBox: FilterSelector,
                selection: FilterString,
                showDetails: true,
                topBrands: true,
            } as OxomiPortalSearchSettings
        );
    }

    /**
     * Initialisiert das Oxomi-Portal.
     * @param paramPortal Portal-ID, mit der das Portal initialisiert wird
     * @param paramUser Username, mit dem das Portal initialisiert wird
     * @param paramAccessToken Access Token, mit dem das Portal initialisiert wird
     */
    private OxomiInit(
        accessDaten: IOxomiAccessDaten,
        OxomiServerAdresse?: string
    ): JQuery.Deferred<any> {
        // Rückgabeparameter initialisieren
        let $d = $j.Deferred();

        try {
            oxomi.init({
                portal: accessDaten.PortalId,
                user: accessDaten.UserName,
                accessToken: accessDaten.AccessToken,
                roles: accessDaten.PortalRollen,
                filterCountry: accessDaten.Laendercode,
                debug: Debug.OxomiDebugModus,
                disableOverlayHistory: KonstantenOxomi.disableOverlayHistory,
                infoplayMenuTitle: KonstantenOxomi.infoplayMenuTitle,
                infoplayItemHandler: this.OxomiInfoplayItemHandlerFunction,
                server: OxomiServerAdresse,
            });

            // Request "auflösen"
            $d.resolve();
        } catch (err) {
            // Fehler zu einer Warnung herabstufen
            // Es ist zwar Oxomi nicht verfügbar, das soll den Kunden aber zunächst nicht stören
            console.warn(err);

            // Request "ablehnen"
            $d.reject(err);
        }

        // Rückgabe des PromiseLike-Objekts
        return $d;
    }

    /**
     * Diese Methode wird ausgeführt, wenn innerhalb des Oxomi-Portals
     * ein Text ausgewählt wird, um danach zu suchen.
     */
    // tslint:disable-next-line:ban-types
    private OxomiInfoplayItemHandlerFunction(
        infoplayObj: OxomiInfoplayDatenstruktur,
        nextStep: (infoplayObj: OxomiInfoplayDatenstruktur) => void
    ) {
        let ProgrammInfo: HoProgrammInfo = new HoProgrammInfo({
            Programm: "O4375W",
            Parameter:
                "suche=" + encodeURIComponent(infoplayObj.compactSelection),
            CallJsAnhaengen: false,
        });

        // Link in neuem Fenster öffnen (neuem Tab)
        window.open(ProgrammInfo.ProgrammAufruf);
        // Rückgabe des Call-Stacks an Oxomi, damit die Standard-Behandlung inaktiviert wird
        nextStep(infoplayObj);
    }

    /**
     * Oxomi Access-Daten ermitteln
     */
    private HoleOxomiAccessDaten(): IOxomiAccessDaten {
        let oxomiAccessDaten: IOxomiAccessDaten = {
            PortalId: ($j("#oxomi_portalId").val() as string) || "",
            UserName: ($j("#oxomi_userName").val() as string) || "",
            AccessToken: ($j("#oxomi_accessToken").val() as string) || "",
            PortalUrl: ($j("#oxomi_portalUrl").val() as string) || "",
            PortalRollen: ($j("#oxomi_portalRollen").val() as string) || "",
            Laendercode:
                (($j("#oxomi_laendercode").val() as string) || "") === "de"
                    ? "de"
                    : "at",
        };

        return oxomiAccessDaten;
    }

    /**
     * Hängt eine Funktion an das Oxomi-Initialsiert-Promise an zur Durchführung, sobald Oxomi erfolgreich initialisiert worden ist.
     * @param klassenFunktion Funktion, die nach der Initialisierung aufgerufen wird
     * @param klassenFunktionConfig Konfiguration, mit der die entsprechende Methode aufgerufen wird
     */
    private VerarbeiteDeferredCallWennOxomiInitialisiert<T>(
        klassenFunktion: (config: T) => JQuery.Deferred<any>,
        klassenFunktionConfig: T
    ): JQuery.Deferred<any> {
        // Rückgabeparameter initialisieren
        let $d = $j.Deferred();

        if (klassenFunktion && klassenFunktionConfig) {
            try {
                // Anhängen der auszuführenden Logik auf dem statischen Promise-Objekt
                Katalog.$promiseInit.fail($d.reject).done((dataPromiseInit) => {
                    // Ausführen der übergebenen Methodenlogik
                    klassenFunktion(klassenFunktionConfig)
                        .fail($d.reject)
                        .done($d.resolve);
                });
            } catch (err) {
                // Fehler zu Warnung herabstufen
                console.warn(err);
                // Promise rejecten
                $d.reject();
            }
        } else {
            // Wenn es keine Methode oder Config gibt, dann kann der Promise auch nicht eingehalten werden
            $d.reject(klassenFunktion, klassenFunktionConfig);
        }

        // Rückgabe des Promise-Objekts
        return $d;
    }

    /**
     * Zentrale Methode zur Ausführung von Deferred-Logik für Oxomi-Methoden.
     * @description
     * Der Codeausschnitt in dieser Methode kam zuvor beliebig oft in dieser Klasse vor.
     * Zur Reduzierung von Duplicate Code wurde die essenzielle Logik in diese Methode ausgelagert.
     * @param oxomiFunktion Funktion aus dem Oxomi-Framework, die ausgeführt wird
     * @param oxomiConfig Konfiguration, mit der die entsprechende Methode aufgerufen wird.
     * Muss eine Variante von [[OxomiResultHandlersInterface]] sein, damit [[emptyResultHandler]] und [[completionHandler]] verfügbar sind.
     */
    private VerarbeiteDeferredCallAnOxomiMethode(
        oxomiFunktion: (config: OxomiResultHandlersInterface) => void,
        oxomiConfig: OxomiResultHandlersInterface
    ): JQuery.Deferred<any> {
        // Rückgabeparameter initialisieren
        let $d = $j.Deferred();

        try {
            // Methoden für Promise-Ereignisbehandlung definieren
            oxomiConfig.completionHandler = $d.resolve;
            oxomiConfig.emptyResultHandler = $d.reject;

            // Angegebene Methode ausführen
            oxomiFunktion(oxomiConfig);
        } catch (err) {
            // Fehler zu einer Warnung herabstufen
            // Es ist zwar Oxomi nicht verfügbar, das soll den Kunden aber zunächst nicht stören
            console.warn(err);

            // Promise im Fehlerfall rejecten
            $d.reject(err);
        }

        // Rückgabe des Promise-Objekts
        return $d;
    }

    /**
     * Führt oxomi.itemPages mit den angegebenen Parametern aus
     * @param ItemPagesSettings Objekt von Oxomi-Parametern, die übergeben werden
     */
    private OxomiItemPages(
        ItemPagesSettings: OxomiItemFunctionsSettings
    ): JQuery.Deferred<any> {
        return this.VerarbeiteDeferredCallAnOxomiMethode(
            oxomi.itemPages,
            ItemPagesSettings
        );
    }

    /**
     * Führt oxomi.itemText mit den angegebenen Parametern aus
     */
    private OxomiItemText(
        ItemTextSettings: OxomiItemFunctionsSettings
    ): JQuery.Deferred<any> {
        // nach Artikel-Langtext suchen
        return this.VerarbeiteDeferredCallAnOxomiMethode(
            oxomi.itemText,
            ItemTextSettings
        );
    }

    /**
     * Führt oxomi.itemImages mit den angegebenen Parametern aus
     */
    private OxomiItemImages(
        ItemImagesSettings: OxomiItemFunctionsSettings
    ): JQuery.Deferred<any> {
        // nach Artikel-Bildern suchen
        return this.VerarbeiteDeferredCallAnOxomiMethode(
            oxomi.itemImages,
            ItemImagesSettings
        );
    }

    /**
     * Führt oxomi.itemVideos mit den angegebenen Parametern aus
     */
    private OxomiItemVideos(
        ItemVideosSettings: OxomiItemFunctionsSettings
    ): JQuery.Deferred<any> {
        // nach Artikel-Videos suchen
        return this.VerarbeiteDeferredCallAnOxomiMethode(
            oxomi.itemVideos,
            ItemVideosSettings
        );
    }

    /**
     * Führt oxomi.itemGalleries mit den angegebenen Parametern aus
     */
    private OxomiItemGalleries(
        ItemGalleriesSettings: OxomiItemFunctionsSettings
    ): JQuery.Deferred<any> {
        // nach Artikel-Exposees suchen
        return this.VerarbeiteDeferredCallAnOxomiMethode(
            oxomi.itemGalleries,
            ItemGalleriesSettings
        );
    }

    /**
     * Führt oxomi.itemAttachments mit den angegebenen Parametern aus
     */
    private OxomiItemAttachments(
        ItemAttachmentsSettings: OxomiItemFunctionsSettings
    ): JQuery.Deferred<any> {
        // nach Artikel-Dokumenten suchen
        return this.VerarbeiteDeferredCallAnOxomiMethode(
            oxomi.itemAttachments,
            ItemAttachmentsSettings
        );
    }

    /**
     * Führt oxomi.itemMasterdata mit den angegebenen Parametern aus
     */
    private OxomiItemMasterdata(
        ItemMasterdataSettings: OxomiItemFunctionsSettings
    ): JQuery.Deferred<any> {
        // nach Artikel-Masterdaten suchen
        return this.VerarbeiteDeferredCallAnOxomiMethode(
            oxomi.itemMasterdata,
            ItemMasterdataSettings
        );
    }

    /**
     * Öffnet einen Oxomi-Katalog.
     * @param OpenCatalogSettings Objekt von Oxomi-Parametern, die übergeben werden
     */
    private OxomiOpenCatalog(
        OpenCatalogSettings: OxomiOpenCatalogSettings
    ): JQuery.Deferred<any> {
        return this.VerarbeiteDeferredCallAnOxomiMethode(
            oxomi.openCatalog,
            OpenCatalogSettings
        );
    }

    /**
     * Es werden Kataloge in einen Container befüllt.
     * @param CatalogSettings Objekt von Oxomi-Parametern, die übergeben werden
     */
    private OxomiCatalogs(
        CatalogSettings: OxomiCatalogsSettings
    ): JQuery.Deferred<any> {
        return this.VerarbeiteDeferredCallAnOxomiMethode(
            oxomi.catalogs,
            CatalogSettings
        );
    }

    /**
     * Es wird eine Portal-Suche durchgeführt, und das Ergebnis wird in einen angegebenen Container ausgegeben.
     * @param PortalSearchSettings Objekt mit Oxomi-Parametern, die übergeben werden
     */
    private OxomiPortalSearch(
        PortalSearchSettings: OxomiPortalSearchSettings
    ): JQuery.Deferred<any> {
        return this.VerarbeiteDeferredCallAnOxomiMethode(
            oxomi.portalSearch,
            PortalSearchSettings
        );
    }

    /**
     * Prüft, ob im Oxomi-Portal die gesuchten Informationen zur Verfügung stehen
     */
    private OxomiExists(
        ExistsSettings: OxomiExistsSettings
    ): JQuery.Deferred<OxomiExistsJsonDaten> {
        // Rückgabeparameter initialisieren
        let $d: JQuery.Deferred<OxomiExistsJsonDaten> = $j.Deferred();

        try {
            // Callback-Funktion für die Prüfung, ob Daten vorhanden sind, definieren
            ExistsSettings.callback = (jsonDaten) => {
                if (jsonDaten.error === false) {
                    // Kein Fehler, Anfrage ok -> Promise mit den Oxomi-Daten resolven
                    $d.resolve(jsonDaten);
                } else {
                    // Fehler gefunden -> Promise ablehnen
                    $d.reject();
                }
            };

            // Oxomi-Methode ausführen
            oxomi.exists(ExistsSettings);
        } catch (err) {
            // Fehler zu einer Warnung herabstufen
            // Es ist zwar Oxomi nicht verfügbar, das soll den Kunden aber zunächst nicht stören
            console.warn(err);

            // Promise im Fehlerfall rejecten
            $d.reject(err);
        }

        // Rückgabe des Promise-Objekts
        return $d;
    }

    /**
     * Öffnet im Oxomi die Portal-Ansicht.
     * @param OpenPortalSettings Objekt mit der Konfiguration, die an die Oxomi-Logik übergeben wird.
     */
    private OxomiOpenPortal(OpenPortalSettings: OxomiOpenPortalSettings) {
        try {
            // Oxomi-Katalog öffnen
            oxomi.openPortal(OpenPortalSettings);
        } catch (err) {
            // Fehler zu einer Warnung herabstufen
            // Es ist zwar Oxomi nicht verfügbar, das soll den Kunden aber zunächst nicht stören
            console.warn(err);
        }
    }
}
