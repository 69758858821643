/**
 * Definiert die möglichen Typen von Dokumenten, nach denen gesucht werden kann
 */
export enum DokumentTyp {
    Aktion = "promotion",
    Katalog = "catalog",
    Preisliste = "pricelist",
    Ersatzteile = "spareparts",
    Prospekt = "flyer",
    Handbuch = "manual",
    Datenblatt = "datasheet",
    Zertifikat = "certificate",
    Werkszeugnis = "factory_certificate",
    Sicherheitsdatenblatt = "material_safety_data_sheet",
    Anleitung = "instruction",
    Leistungserklaerung = "declaration_of_performance",
    Praesentation = "presentation",
    Sonstiges = "other",
}
