import * as $j from "jquery";
import { AS400Layer } from "./as400Layer";
import { Historie } from "./historie";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { IModalSettings } from "./interfaces/IModalSettings";
import { Konstanten } from "./konstanten/konstanten";
import { Layout } from "./layout";
import { StatischeMethoden } from "./statischeMethoden";

/**
 * Stellt alle Funktionen bereit, die im Zusammenhang mit dem Modal notwendig sind.
 */
export class Modal {
    /**
     * Modal-Instanz dieser Klasseninstanz
     */
    private $modal: JQuery;

    /**
     * Gibt den Wert an, der beim Schließen des Modals an den onClose Promise handler übergeben wird.
     */
    private onCloseFlag?: boolean;

    /**
     * Definiert Eigenschaften zu Darstellung und Verhalten des Modals
     */
    private _modalSettings: IModalSettings;

    /**
     * Gibt an, mit welchen Eigenschaften diese Modal-Instanz gebaut worden ist.
     */
    public get modalSettings() {
        return this._modalSettings;
    }

    /**
     * Initialisiert die Klasse
     */
    constructor(modalSettings: IModalSettings) {
        this._modalSettings = modalSettings;
    }

    /**
     * Führt alle notwendigen Schritte zum Anzeigen des Modals aus.
     */
    public anzeigen(): JQuery.Deferred<any> {
        const $d = jQuery.Deferred();

        let params: string = "";

        // der Div Tag muss angegeben werden als Dummycontainer
        // würde das nicht gemacht werden, würde das .find kein element im Markup finden
        // da die 2 hidden input fields nicht im Container enthalten sind
        if (
            $j("<div>" + this._modalSettings.modalContent + "</div>").find(
                "#ho_fehler"
            ).length
        ) {
            params = "FEHLER=J";
        }

        // Wenn ein Footer vorhanden ist , dann mitgeben in O8411W
        if (
            $j("<div>" + this._modalSettings.modalContent + "</div>").find(
                "#footersection"
            ).length
        ) {
            let footersection: string = $j(
                "<div>" + this._modalSettings.modalContent + "</div>"
            )
                .find("#footersection")
                .val() as string;
            params += "&FOOTER=" + footersection.trim();
        }

        let ProgrammInfo: HoProgrammInfo = new HoProgrammInfo({
            Programm: "O4811W",
            Parameter: params.trim(),
            CallJsAnhaengen: true,
        });

        AS400Layer.INSTANCE.SendeGetRequest(ProgrammInfo, {}).done(
            (AntwortDaten) => {
                // Entkopplung über anonyme Methode ist notwendig, da sonst "this" in VerarbeitePostAntwort undefined ist
                this.VerarbeiteGetAntwort(AntwortDaten);
                $d.resolve();
            }
        );

        return $d;
    }

    /**
     * Schließt das Modal.
     */
    public verstecken(params: { ModalDismissVerhindern: boolean }) {
        if (params.ModalDismissVerhindern) {
            this.$modal.attr("data-ho-modal-nocallback", "true");
        }

        this.$modal.attr("data-ho-modal-nonavigate", "true");
        this.$modal.modal("hide");
    }

    /**
     * Hängt alle notwendigen Events an
     */
    private InitEvents(ProgrammName: string) {
        $j("[data-ho-modal-onclose-setflag]").on(
            "click.holteronline-modal",
            (event) => {
                this.onCloseFlag =
                    $j(event.currentTarget).attr(
                        "data-ho-modal-onclose-setflag"
                    ) === "true";

                this.verstecken({ ModalDismissVerhindern: false });
            }
        );

        this.$modal.on("shown.bs.modal", (ereignis) => {
            const $layoutRoot = $j(`#${this.modalSettings.modalId}`);

            Layout.INSTANCE.InitLayout($layoutRoot, {
                ProgrammName,
                ModalAnzeige: true,
            });

            // Tooltip auch dann schließen, wenn aus btn mit tt ein Modal geöffnet wird
            StatischeMethoden.VersteckeTooltips($layoutRoot);
            StatischeMethoden.EntferneAlleToasts();

            // Aktuellen Popstate duplizieren, damit später zurücknavigiert werden kann
            Historie.INSTANCE.DuplizierePopState();
        });

        this.$modal.on("hide.bs.modal", (ereignis) => {
            const $layoutRoot = $j(`#${this.modalSettings.modalId}`);

            Layout.INSTANCE.Zuruecksetzen($layoutRoot);

            // Methode zum Verhindern der Callback-Funktion
            // bspw. wenn gerade eine andere Ajax Request aktiv ist
            const callbackUnterbrechen: boolean =
                this.$modal.attr("data-ho-modal-nocallback") === "true";

            // Popstate um einen Tupel zurücknavigieren
            const navigationUnterbrechen: boolean =
                $j(ereignis.target).attr("data-ho-modal-nonavigate") === "true";

            if (!navigationUnterbrechen) {
                Historie.INSTANCE.EinenEintragZuruecknavigieren();
            }

            // Erst nach dem Auslesen des benötigten data-Attributs das Modal aus dem Dom entfernen
            $j(ereignis.target).remove();
            StatischeMethoden.aktivesModal = undefined;

            if (!callbackUnterbrechen && this._modalSettings.modalCallback) {
                const ProgrammInfo: HoProgrammInfo = new HoProgrammInfo({
                    ...this._modalSettings.modalCallback,
                    CallJsAnhaengen: true,
                });

                AS400Layer.INSTANCE.LadeSeite(ProgrammInfo, {
                    DialogAnzeige: this._modalSettings.modalCallbackAlsDialog,
                });
            }

            if (this.onCloseFlag !== undefined) {
                this._modalSettings.onClose.resolve(this.onCloseFlag);
            } else {
                this._modalSettings.onClose.reject();
            }
        });
    }

    /**
     * Setzt den Modaltitel
     */
    private SetzeHeader(params: {
        /**
         * Titel des Modals
         */
        titel: string;
        /**
         * Inhalt, der in den Programmnamen-Platzhalter im Modal gestellt wird
         */
        programmName: string;
        /**
         * Inhalt, der in den Datum-Platzhalter im Modal gestellt wird
         */
        datum: string;
    }) {
        $j("#modal-title", this.$modal).html(params.titel);
        $j("#modal-pgmn", this.$modal).html(params.programmName);
        $j("#modal-datum", this.$modal).html(params.datum);
    }

    /**
     * Setzt den Modalcontent (Markup)
     * @param html - Content der im Modal angezeigt werden soll
     */
    private SetzeInhalt(html: string) {
        $j("#dialog-content", this.$modal).html(html);

        $j(Konstanten.InfoBoxModalSelector, this.$modal).html(
            $j(
                "#dialog-content " + Konstanten.InfoBoxSelector,
                this.$modal
            ).html()
        );

        if (
            !$j("#dialog-content " + Konstanten.InfoButtonSelector, this.$modal)
                .length
        ) {
            $j(Konstanten.InfoButtonModalSelector, this.$modal).remove();
        } else if (
            $j(
                "#dialog-content " +
                    Konstanten.InfoButtonSelector +
                    '[data-ho-offen="true"]',
                this.$modal
            )
        ) {
            $j(Konstanten.InfoButtonModalSelector, this.$modal).attr(
                "data-ho-offen",
                "true"
            );
        }

        $j("#dialog-content", this.$modal).find("#O4BSTHead").remove();
        let $DOMfooter: JQuery = $j("#dialog-content", this.$modal).find(
            "#modal-footer"
        );
        if ($DOMfooter.length) {
            $j("#modal-footer-placeholder").html($DOMfooter.html());
            $DOMfooter.remove();
        } else {
            $j("#modal-footer-placeholder").remove();
        }

        // Wenn Seite in Modal breit angezeigt werden muss, dann die Klasse hinzufügen
        if ($j("#dialog-content [data-ho-modal-breit]", this.$modal).length) {
            $j("#modal-dialog", this.$modal).addClass("modal-lg");
        }

        // Wenn Seite in Modal extra breit angezeigt werden muss, dann die Klasse hinzufügen
        if ($j("#dialog-content [data-ho-modal-xlarge]", this.$modal).length) {
            $j("#modal-dialog", this.$modal).addClass("modal-xl");
        }
    }

    /**
     * Anzeige des Modals
     */
    private ZeigeModal() {
        this.$modal.modal("show");
    }

    /**
     * Verarbeitet die Antwort eines AJAX POST.
     * @param AntwortDaten Daten, die als Antwort auf den AJAX POST gekommen sind.
     */
    private VerarbeiteGetAntwort(AntwortDaten: string) {
        this.$modal = $j(AntwortDaten);
        this.$modal.attr("id", this._modalSettings.modalId);
        $j(Konstanten.ProgrammDatenRahmenSelector).append(this.$modal);

        this.SetzeHeader({
            titel: $j(this._modalSettings.modalContent)
                .find(Konstanten.FensterNameTitelId)
                .html(),
            programmName: $j(this._modalSettings.modalContent)
                .find(Konstanten.FensterPgmnId)
                .html(),
            datum: $j(this._modalSettings.modalContent)
                .find(Konstanten.FensterDatumId)
                .html(),
        });
        this.SetzeInhalt(this._modalSettings.modalContent);
        this.ZeigeModal();
        this.InitEvents(this._modalSettings.programmInfo.Programm);
    }
}
