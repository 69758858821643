/**
 * Definiert die möglichen Typen von DOMs für die DataTable-Initialisierung.
 */
export enum DataTableFunktionenDomTyp {
    /**
     * Standard-DOM
     */
    Default,
    /**
     * Spezial-DOM mit Filter und div die wie ein Footer aussiehnt
     */
    FilterUndFooterAlike,
    /**
     * Spezial-DOM mit dass den Standardfooter verwirft
     */
    WithoutPagination,
}
