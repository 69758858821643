/**
 * Definiert die möglichen Spaltennamen für die Artikelsuche
 */
export enum ArtikelsucheSpaltenNamen {
    Toggle = "TOGGLE",
    Bild = "BILD",
    Artikel = "ARTIKEL",
    Lagerware = "CDLW",
    ArtikelnummerLieferant = "ARTL",
    Menge = "MENGE",
    Preis = "PREIS",
}
