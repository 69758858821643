// Code generiert mit <https://editor.swagger.io/>
// Es wurde die Option
//  "Generate Client -> TypeScript Fetch"
//  gewählt und diese Interfaces aus dem generierten Code rauskopiert
// Anschließend die folgenden Änderungen händisch nachpflegen:
// - `isRangeType: boolean`
//   - bei `DistinctFilter` auf `false` stellen
//   - bei `RangeFilter` auf `true` stellen
// - Alle Vorkommen von `<any>` entfernen

export namespace HoItemSearchApi {
    /**
     * Klasse für Error mit HTTP Statuscode bei TSOA-Anwendungen
     * @export
     * @interface ApiError
     */
    export interface ApiError {
        /**
         *
         * @type {string}
         * @memberof ApiError
         */
        traceCode: string;
        /**
         *
         * @type {number}
         * @memberof ApiError
         */
        statusCode: number;
        /**
         *
         * @type {string}
         * @memberof ApiError
         */
        stack: string;
        /**
         *
         * @type {string}
         * @memberof ApiError
         */
        name: string;
        /**
         *
         * @type {string}
         * @memberof ApiError
         */
        message: string;
        /**
         *
         * @type {number}
         * @memberof ApiError
         */
        errorCode: number;
    }
    /**
     * Wird zum Ausführen von Autocomplete-Anforderungen verwendet.
     * @export
     * @interface AutoCompleteRequest
     */
    export interface AutoCompleteRequest {
        /**
         * Die Suchanfrage zum Ermitteln der Suchvorschläge.
         * @type {string}
         * @memberof AutoCompleteRequest
         */
        query: string;
        /**
         *
         * @type {QueryCollectionParameters}
         * @memberof AutoCompleteRequest
         */
        queriesOptions?: QueryCollectionParameters;
    }
    /**
     * Das Ergebnis einer Auto-Vervollständigungsanfrage.
     * @export
     * @interface AutoCompleteResponse
     */
    export interface AutoCompleteResponse {
        /**
         * Die Abfrage, welche die Engine als am relevantesten erachtet, erhält ein Ergebnis mit Gültigkeitsbereichen.
         * @type {UnScopedQueryResult | ScopedQueryResult}
         * @memberof AutoCompleteResponse
         */
        scopedQuery?: UnScopedQueryResult | ScopedQueryResult;
        /**
         *
         * @type {QueryCollection}
         * @memberof AutoCompleteResponse
         */
        queries?: QueryCollection;
    }
    /**
     * Wird zum Übermitteln einer Benutzeraktion verwendet.
     * @export
     * @interface CreateEventsRequest
     */
    export interface CreateEventsRequest {
        /**
         * Sammlung von Ereignissen.
         * @type {Array<Event>}
         * @memberof CreateEventsRequest
         */
        events: Array<Event>;
    }
    /**
     * Eine Liste eindeutiger Filterwerte und die Anzahl der Treffer, die diese Werte enthalten.
     * @export
     * @interface DistinctFilter
     */
    export interface DistinctFilter {
        /**
         * Der eindeutige Attribut-Name des Filters.
         * @type {string}
         * @memberof DistinctFilter
         */
        attributeName: string;
        /**
         * Der für den Benutzer lesbare Name des Filters.
         * @type {string}
         * @memberof DistinctFilter
         */
        displayName: string;
        /**
         * Gibt an, ob die Elemente oder Werte ungefähre oder genaue Werte sind.
         * @type {string}
         * @memberof DistinctFilter
         */
        approximated?: string;
        /**
         * Gibt die Einheit an, in welcher die Filter-Werte angegeben sind.
         * @type {string}
         * @memberof DistinctFilter
         */
        unit?: string;
        /**
         * Gibt an, dass der zugrundeliegende Filterparameter ein Wertefilter ist.
         * @type {boolean}
         * @memberof DistinctFilter
         */
        isRangeType?: false;
        /**
         * Die Filter.
         * @type {Array<DistinctFilterItem>}
         * @memberof DistinctFilter
         */
        items?: Array<DistinctFilterItem>;
    }
    /**
     * Ein Objekt, das Filterinformationen zu einem einzelnen Attributwert enthält.
     * @export
     * @interface DistinctFilterItem
     */
    export interface DistinctFilterItem {
        /**
         * Der in den Ergebnissen gefundene Attributwert.
         * @type {string | number | boolean}
         * @memberof DistinctFilterItem
         */
        item?: string | number | boolean;
        /**
         * Die Gesamt-Anzahl der Entitäten in den Ergebnissen, die diesen Attributwert haben. Sie entspricht nicht der aktuell zurückgelieferten Anzahl an Elementen in der `items`-Sammlung.
         * @type {number}
         * @memberof DistinctFilterItem
         */
        totalCount?: number;
        /**
         * Gibt an, ob dieser Attributswert in der Anfrage ausgewählt wurde.
         * @type {boolean}
         * @memberof DistinctFilterItem
         */
        selected?: boolean;
    }
    /**
     * Beschreibt, wie die im Filter zurückgegebenen Elemente sortiert werden sollen.
     * @export
     * @interface DistinctFilterItemSortingParameter
     */
    export interface DistinctFilterItemSortingParameter {
        /**
         *
         * @type {DistinctFilterItemSortingParameterTypeEnum}
         * @memberof DistinctFilterItemSortingParameter
         */
        type?: DistinctFilterItemSortingParameterTypeEnum;
        /**
         *
         * @type {DistinctFilterItemSortingParameterOrderEnum}
         * @memberof DistinctFilterItemSortingParameter
         */
        order?: DistinctFilterItemSortingParameterOrderEnum;
    }
    /**
     *
     * @export
     * @enum {string}
     */
    export enum DistinctFilterItemSortingParameterOrderEnum {
        Asc = "asc",
        Desc = "desc",
    }
    /**
     *
     * @export
     * @enum {string}
     */
    export enum DistinctFilterItemSortingParameterTypeEnum {
        Item = "item",
        Count = "count",
        Selected = "selected",
    }
    /**
     * Wird zum Filtern einer Entitätssammlung mit einer Sammlung von Attributwerten verwendet.
     * @export
     * @interface DistinctFilterParameter
     */
    export interface DistinctFilterParameter {
        /**
         * Der Name des Attributs für die Entitäten, nach denen das Ergebnis gefiltert werden soll. Wenn das Attribut für keine Entität vorhanden ist, werden alle Entitäten übergeben und ein leerer Filter zurückgegeben.
         * @type {string}
         * @memberof DistinctFilterParameter
         */
        attributeName: string;
        /**
         * Definiert, ob der zugrunde liegende Filterparameter ein Bereichsfilter ist.
         * @type {boolean}
         * @memberof DistinctFilterParameter
         */
        isRangeType?: boolean;
        /**
         * Ein Array mit den ausgewählten Werten. Es werden nur dazugehörige Entitäten zurückgegeben. Wenn nicht eingestellt, wird keine Filterung durchgeführt.
         * @type {Array<string | number | boolean>}
         * @memberof DistinctFilterParameter
         */
        selected?: Array<string | number | boolean>;
        /**
         * Array von Sortierparametern, die angeben, wie die Filterelemente sortiert werden sollen.
         * @type {Array<DistinctFilterItemSortingParameter>}
         * @memberof DistinctFilterParameter
         */
        sortBy?: Array<DistinctFilterItemSortingParameter>;
    }
    /**
     * Das Objekt, welches zum Modellieren von Produkten, Kategorien, Marken, etc. verwendet wird.
     * @export
     * @interface Entity
     */
    export interface Entity {
        /**
         * Der Typ der Entität.
         * @type {string}
         * @memberof Entity
         */
        type: string;
        /**
         * Die ID der Entität für diesen Entitätstyp.
         * @type {string}
         * @memberof Entity
         */
        id: string;
    }
    /**
     * Eine Sammlung von Entitäten.
     * @export
     * @interface EntityCollection
     */
    export interface EntityCollection {
        /**
         * Die Gesamt-Anzahl der Entitäten in dieser Sammlung. Sie entspricht nicht der Anzahl an Elementen in der `items`-Sammlung.
         * @type {number}
         * @memberof EntityCollection
         */
        totalCount?: number;
        /**
         * Die Filter, welche für diese Entitätssammlung gefunden wurden.
         * @type {Array<DistinctFilter | RangeFilter>}
         * @memberof EntityCollection
         */
        filters?: Array<DistinctFilter | RangeFilter>;
        /**
         * Die Entitäten.
         * @type {Array<Entity | Product>}
         * @memberof EntityCollection
         */
        items?: Array<Entity | Product>;
    }
    /**
     * Gibt an, wie eine Sammlung von Entitäten aufgeteilt, sortiert und gefiltert werden soll.
     * @export
     * @interface EntityCollectionParameters
     */
    export interface EntityCollectionParameters {
        /**
         * Wie viele Entitäten beim Blättern (paging) des Ergebnisses übersprungen werden sollen.
         * @type {number}
         * @memberof EntityCollectionParameters
         */
        skip?: number;
        /**
         * Wie viele Entitäten beim Blättern (paging) des Ergebnisses zurückgegeben werden sollen.
         * @type {number}
         * @memberof EntityCollectionParameters
         */
        take?: number;
        /**
         * Array von Sortierparametern, die angeben, wie das Ergebnis sortiert werden soll.
         * @type {Array<EntitySortingParameter>}
         * @memberof EntityCollectionParameters
         */
        sortBy?: Array<EntitySortingParameter>;
        /**
         * Array von Filtern, um das Ergebnis zu berechnen und möglicherweise zu filtern. Wenn nicht angegeben, wird keine Filterung durchgeführt.
         * @type {Array<DistinctFilterParameter | RangeFilterParameter>}
         * @memberof EntityCollectionParameters
         */
        filter?: Array<DistinctFilterParameter | RangeFilterParameter>;
        /**
         *
         * @type {ProductSearchParameter}
         * @memberof EntityCollectionParameters
         */
        productSearchOptions?: ProductSearchParameter;
    }
    /**
     * Objekt, welches bestimmt, wie ein Ergebnis sortiert werden soll.
     * @export
     * @interface EntitySortingParameter
     */
    export interface EntitySortingParameter {
        /**
         *
         * @type {EntitySortingParameterTypeEnum}
         * @memberof EntitySortingParameter
         */
        type?: EntitySortingParameterTypeEnum;
        /**
         * Wenn `type` auf `attribute` festgelegt wurde, entscheidet diese Eigenschaft, welches Attribut auf der Entität zum Sortieren verwendet wird.
         * @type {string}
         * @memberof EntitySortingParameter
         */
        attributeName?: string;
        /**
         *
         * @type {EntitySortingParameterOrderEnum}
         * @memberof EntitySortingParameter
         */
        order?: EntitySortingParameterOrderEnum;
    }
    /**
     *
     * @export
     * @enum {string}
     */
    export enum EntitySortingParameterOrderEnum {
        Asc = "asc",
        Desc = "desc",
    }
    /**
     *
     * @export
     * @enum {string}
     */
    export enum EntitySortingParameterTypeEnum {
        Attribute = "attribute",
        Id = "id",
        Type = "type",
        Relevance = "relevance",
        Popularity = "popularity",
    }
    /**
     * Wird verwendet, um Benutzerinteraktionen wie \"Klicks\", \"zum Warenkorb hinzugefügt\" und Käufe nachverfolgen zu können.
     * @export
     * @interface Event
     */
    export interface Event {
        /**
         *
         * @type {EventTypes}
         * @memberof Event
         */
        type: EventTypes;
        /**
         *
         * @type {Entity}
         * @memberof Event
         */
        entity: Entity;
        /**
         * Die Einnahmen aus dieser Interaktion. Wird normalerweise nur für Kaufereignisse verwendet.
         * @type {number}
         * @memberof Event
         */
        revenue?: number;
        /**
         * Wenn an der Interaktion eine Transaktion beteiligt war, wird diese Eigenschaft zum Angeben der ID dieser Transaktion verwendet. Wird normalerweise nur für Kaufereignisse verwendet.
         * @type {string}
         * @memberof Event
         */
        orderId?: string;
        /**
         * Wenn mit mehreren Elementen derselben Entität interagiert wurde, wird diese Eigenschaft zum Festlegen der Anzahl der Elemente verwendet. Wird normalerweise nur für Kaufereignisse verwendet.
         * @type {number}
         * @memberof Event
         */
        quantity?: number;
    }
    /**
     *
     * @export
     * @enum {string}
     */
    export enum EventTypes {
        Click = "click",
        Addtocart = "addtocart",
        Purchase = "purchase",
    }
    /**
     * Ein Holter-Artikel.
     * @export
     * @interface Product
     */
    export interface Product {
        /**
         * Der Typ der Entität.
         * @type {string}
         * @memberof Product
         */
        type: string;
        /**
         * Die ID der Entität für diesen Entitätstyp.
         * @type {string}
         * @memberof Product
         */
        id: string;
        /**
         * Artikelnummer für diesen Artikel, welche für den Benutzer sichtbar ist.
         * @type {string}
         * @memberof Product
         */
        itemId: string;
        /**
         * Artikelnummer des Herstellers, welche für den Benutzer sichtbar ist.
         * @type {string}
         * @memberof Product
         */
        itemIdBrand: string;
        /**
         * Link zum Artikelbild.
         * @type {string}
         * @memberof Product
         */
        imageURL: string;
        /**
         * Beschreibungstext für den Artikel.
         * @type {string}
         * @memberof Product
         */
        description: string;
        /**
         * Gibt an, ob der Artikel als Lagerartikel geführt wird.
         * @type {boolean}
         * @memberof Product
         */
        inStock: boolean;
        /**
         * Brutto-Verkaufspreis des Artikels
         * @type {number}
         * @memberof Product
         */
        grossPrice?: number;
        /**
         * Verpackungseinheit
         * @type {number}
         * @memberof Product
         */
        packageSize: number;
        /**
         * Einheitsbezeichnung
         * @type {string}
         * @memberof Product
         */
        unit: string;
        /**
         *
         * @type {Stock}
         * @memberof Product
         */
        stock?: Stock;
        /**
         * Nettopreis des Artikels für den angegebenen Kunden
         * @type {number}
         * @memberof Product
         */
        netPrice?: number;
        /**
         * Kundenindiv.VP des Artikels für den angegebenen Kunden
         * @type {number}
         * @memberof Product
         */
        individualNetPrice?: number;
    }
    /**
     * Enthält Werte für die grundlegende Filterung der Artikel.
     * @export
     * @interface ProductSearchParameter
     */
    export interface ProductSearchParameter {
        /**
         * Gibt an, ob nur Lagerartikel gesucht werden sollen.
         * @type {boolean}
         * @memberof ProductSearchParameter
         */
        inStock?: boolean;
        /**
         * Gibt die Lager an, in welchem die Artikel lagernd sein sollen. Die Elemente der Auflistung sind Lagernummern.
         * @type {Array<number>}
         * @memberof ProductSearchParameter
         */
        inStockIn?: Array<number>;

        /**
         * Gibt an, ob nur bereits gekaufte Artikel gesucht werden sollen.
         * @type {boolean}
         * @memberof ProductSearchParameter
         */
        onlyUserPurchased?: boolean;
    }
    /**
     * Eine Sammlung von Abfragen.
     * @export
     * @interface QueryCollection
     */
    export interface QueryCollection {
        /**
         * Die Gesamt-Anzahl der Abfragen in dieser Sammlung. Sie entspricht nicht der Anzahl an Elementen in der `items`-Sammlung.
         * @type {number}
         * @memberof QueryCollection
         */
        totalCount?: number;
        /**
         * Die Abfragen.
         * @type {Array<UnScopedQueryResult | ScopedQueryResult>}
         * @memberof QueryCollection
         */
        items?: Array<UnScopedQueryResult | ScopedQueryResult>;
    }
    /**
     * Wird zum Festlegen von Parametern verwendet, für die Abfragen in einer Sammlung zurückgegeben werden sollen.
     * @export
     * @interface QueryCollectionParameters
     */
    export interface QueryCollectionParameters {
        /**
         * Wie viele Zeichenfolgen beim Blättern (paging) des Ergebnisses übersprungen werden sollen.
         * @type {number}
         * @memberof QueryCollectionParameters
         */
        skip?: number;
        /**
         * Wie viele Zeichenfolgen beim Blättern (paging) des Ergebnisses zurückgegeben werden sollen.
         * @type {number}
         * @memberof QueryCollectionParameters
         */
        take?: number;
        /**
         * Array von Sortierparametern, die angeben, wie die resultierenden Abfragen sortiert werden sollen. Der erste Sortierparameter gibt die primäre Reihenfolge an. Wenn Elemente gleich sind, werden sie mit dem zweiten Parameter usw. sortiert. Standard: Nach absteigender Relevanz sortieren.
         * @type {Array<QuerySortingParameter>}
         * @memberof QueryCollectionParameters
         */
        sortBy?: Array<QuerySortingParameter>;
    }
    /**
     * Wird zum Sortieren einer Sammlung von Abfragen verwendet.
     * @export
     * @interface QuerySortingParameter
     */
    export interface QuerySortingParameter {
        /**
         *
         * @type {QuerySortingParameterTypeEnum}
         * @memberof QuerySortingParameter
         */
        type: QuerySortingParameterTypeEnum;
        /**
         *
         * @type {QuerySortingParameterOrderEnum}
         * @memberof QuerySortingParameter
         */
        order?: QuerySortingParameterOrderEnum;
    }
    /**
     *
     * @export
     * @enum {string}
     */
    export enum QuerySortingParameterOrderEnum {
        Asc = "asc",
        Desc = "desc",
    }
    /**
     *
     * @export
     * @enum {string}
     */
    export enum QuerySortingParameterTypeEnum {
        Relevance = "relevance",
        Popularity = "popularity",
        Alphabetic = "alphabetic",
    }
    /**
     * Das Maximum und das Minimum aller Werte für dieses Attribut in der Ergebnissammlung.
     * @export
     * @interface RangeFilter
     */
    export interface RangeFilter {
        /**
         * Der eindeutige Attribut-Name des Filters.
         * @type {string}
         * @memberof RangeFilter
         */
        attributeName: string;
        /**
         * Der für den Benutzer lesbare Name des Filters.
         * @type {string}
         * @memberof RangeFilter
         */
        displayName: string;
        /**
         * Gibt an, ob die Elemente oder Werte ungefähre oder genaue Werte sind.
         * @type {string}
         * @memberof RangeFilter
         */
        approximated?: string;
        /**
         * Gibt die Einheit an, in welcher die Filter-Werte angegeben sind.
         * @type {string}
         * @memberof RangeFilter
         */
        unit?: string;
        /**
         * Gibt an, dass der zugrundeliegende Filterparameter ein Bereichsfilter ist.
         * @type {boolean}
         * @memberof RangeFilter
         */
        isRangeType: true;
        /**
         * Der gefundene Mindestwert.
         * @type {number}
         * @memberof RangeFilter
         */
        min?: number;
        /**
         * Der gefundene Maximalwert.
         * @type {number}
         * @memberof RangeFilter
         */
        max?: number;
        /**
         * Der Wert, welcher als Mindestwert in der Anfrage angegeben wurde.
         * @type {number}
         * @memberof RangeFilter
         */
        selectedMin?: number;
        /**
         * Der Wert, welcher als Maximalwert in der Anfrage angegeben wurde.
         * @type {number}
         * @memberof RangeFilter
         */
        selectedMax?: number;
    }
    /**
     * Wird zum Filtern einer Entitätssammlung mit einem Bereich (Maximalwert und Minimalwert) verwendet.
     * @export
     * @interface RangeFilterParameter
     */
    export interface RangeFilterParameter {
        /**
         * Der Name des Attributs für die Entitäten, nach denen das Ergebnis gefiltert werden soll. Wenn das Attribut für keine Entität vorhanden ist, werden alle Entitäten übergeben und ein leerer Filter zurückgegeben.
         * @type {string}
         * @memberof RangeFilterParameter
         */
        attributeName: string;
        /**
         * Definiert, ob der zugrunde liegende Filterparameter ein Bereichsfilter ist.
         * @type {boolean}
         * @memberof RangeFilterParameter
         */
        isRangeType?: boolean;
        /**
         *
         * @type {RangeFilterSelectedParameter}
         * @memberof RangeFilterParameter
         */
        selected?: RangeFilterSelectedParameter;
    }
    /**
     * Dient zur Auswahl des Maximal- und Minimalwerts zum Filtern der Ergebnisse basierend auf diesem Filter.
     * @export
     * @interface RangeFilterSelectedParameter
     */
    export interface RangeFilterSelectedParameter {
        /**
         * Der niedrigere Wert des Bereichs, Vergleich ist inklusive.
         * @type {number}
         * @memberof RangeFilterSelectedParameter
         */
        min?: number;
        /**
         * Der höhere Wert des Bereichs, Vergleich ist inklusive
         * @type {number}
         * @memberof RangeFilterSelectedParameter
         */
        max?: number;
    }
    /**
     * Eine Anfrage mit Gültigkeitsbereichen.
     * @export
     * @interface ScopedQueryResult
     */
    export interface ScopedQueryResult {
        /**
         * Die Abfrage.
         * @type {string}
         * @memberof ScopedQueryResult
         */
        query: string;
        /**
         * Bereiche, in denen diese Abfrage relevant ist, basierend darauf, welche Entitätsattributwerte in den Suchergebnissen vorhanden sind. Dies kann verwendet werden, um den Benutzer zu vorgefilterten Suchergebnissen zu leiten.
         * @type {Array<string | number | boolean>}
         * @memberof ScopedQueryResult
         */
        scopes?: Array<string | number | boolean>;
        /**
         * Mit welchem Attribut die Gültigkeitsbereiche erstellt wurden.
         * @type {string}
         * @memberof ScopedQueryResult
         */
        scopeAttributeName?: string;
    }
    /**
     * Wird zum Ausführen von Suchanfragen verwendet.
     * @export
     * @interface SearchRequest
     */
    export interface SearchRequest {
        /**
         * Die Anfrage, nach welcher Gesucht werden soll.
         * @type {string}
         * @memberof SearchRequest
         */
        query: string;
        /**
         *
         * @type {EntityCollectionParameters}
         * @memberof SearchRequest
         */
        resultsOptions?: EntityCollectionParameters;
        /**
         * Gibt an, ob Nettopreise kalkuliert werden für die Suchergebnisse.
         * @type {boolean}
         * @memberof SearchRequest
         */
        calculateNetPrices?: boolean;
    }
    /**
     * Das Ergebnis einer Suchanfrage.
     * @export
     * @interface SearchResponse
     */
    export interface SearchResponse {
        /**
         * Gibt an, ob die Engine die Suchanfrage verstanden hat. Siehe auch https://docs.loop54.com/latest/guide/search.html.
         * @type {boolean}
         * @memberof SearchResponse
         */
        makesSense: boolean;
        /**
         *
         * @type {QueryCollection}
         * @memberof SearchResponse
         */
        spellingSuggestions?: QueryCollection;
        /**
         *
         * @type {QueryCollection}
         * @memberof SearchResponse
         */
        relatedQueries?: QueryCollection;
        /**
         *
         * @type {EntityCollection}
         * @memberof SearchResponse
         */
        results?: EntityCollection;
        /**
         *
         * @type {EntityCollection}
         * @memberof SearchResponse
         */
        relatedResults?: EntityCollection;
    }
    /**
     * Lagerstands-Informationen zu einem Artikel
     * @export
     * @interface Stock
     */
    export interface Stock {
        /**
         *
         * @type {StockAvailability}
         * @memberof Stock
         */
        availability: StockAvailability;
        /**
         * Lagerstand
         * @type {number}
         * @memberof Stock
         */
        stockLevel: number;
        /**
         * Verbleibende Reststücke im Lager
         * @type {number}
         * @memberof Stock
         */
        remnants: number;
        /**
         * Gibt an, ob der Artikel Beschaffungsware ist
         * @type {boolean}
         * @memberof Stock
         */
        isProcurementItem: boolean;
    }
    /**
     * Verfügbarkeits-Informations-Text
     * @export
     * @interface StockAvailability
     */
    export interface StockAvailability {
        /**
         *
         * @type {string}
         * @memberof StockAvailability
         */
        stockInformation: string;
        /**
         *
         * @type {string}
         * @memberof StockAvailability
         */
        indicator: StockAvailability.IndicatorEnum;
    }

    /**
     * @export
     * @namespace StockAvailability
     */
    export namespace StockAvailability {
        /**
         * @export
         * @enum {string}
         */
        export enum IndicatorEnum {
            InStock = "inStock",
            OutOfStock = "outOfStock",
            InMainStock = "inMainStock",
            PartiallyInStock = "partiallyInStock",
            AvailableWithinTwoDays = "availableWithinTwoDays",
        }
    }
    /**
     * Eine Anfrage ohne Gültigkeitsbereiche.
     * @export
     * @interface UnScopedQueryResult
     */
    export interface UnScopedQueryResult {
        /**
         * Die Abfrage.
         * @type {string}
         * @memberof UnScopedQueryResult
         */
        query: string;
    }
}
