import * as $j from "jquery";
import { HoMobileApi } from "./api/hoMobileApi";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { IAS400LayerSettings } from "./interfaces/IAS400LayerSettings";
import { IAS400LayerDataObject } from "./interfaces/IAs400LayerDataObject";
import { IAs400LayerStatusInformation } from "./interfaces/IAs400LayerStatusInformation";
import { IHoProgrammInfo } from "./interfaces/IHoProgrammInfo";
import { IModalSettings } from "./interfaces/IModalSettings";
import { Konstanten } from "./konstanten/konstanten";
import { KonstantenBenutzerdefinierteEvents } from "./konstanten/konstantenBenutzerdefinierteEvents";
import { Layout } from "./layout";
import { Message } from "./message";
import { Modal } from "./modal";
import { StatischeMethoden } from "./statischeMethoden";
import { Warenkorb } from "./warenkorb";

/**
 * Stellt Funktionen zur Kommunikation mit der Businesslogik bereit.
 */
export class AS400Layer {
    public static readonly INSTANCE = new AS400Layer();

    private constructor() {}

    /**
     * Initialisiert alle Events, die für diese Klasse notwendig sind.
     */
    public InitEvents() {
        // Bei AJAX Error immer dieselbe Routine ausführen
        $j(document).ajaxError(
            (
                Evt: JQuery.Event,
                Req: JQueryXHR,
                Settings: JQueryAjaxSettings,
                Err: any
            ) => {
                StatischeMethoden.LadeAnimationAusblenden();

                if ((Settings.url || "").indexOf("oxomi.com") >= 0) {
                    return;
                }

                if (
                    Req.status !== 0 &&
                    (Settings.url || "").indexOf("HTTPSTATUS=") < 0
                ) {
                    // Mit Statusnummer weiterleiten an Standard-Fehlerprogramm
                    // als Dialog anzeigen
                    // Es wird die Info mitgegeben, welche Seite nicht erreichbar war
                    this.LadeSeite(
                        new HoProgrammInfo({
                            Programm: Konstanten.FehlerProgramm,
                            Parameter: `HTTPSTATUS=${
                                Req.status
                            }&TEXTHT=${encodeURI(Settings.url ?? "")}`,
                        }),
                        {
                            DialogAnzeige: true,
                        }
                    );
                } else {
                    // Wenn Status = 0 dann ist Server nicht erreichbar
                    // Wenn vorher in der URL schon "HTTPSTATUS" drin war, dann war ein vorheriger Fehlerseiten-Aufruf nicht erfolgreich
                    // -> Seite aktualisieren, damit die Standard-Browser-Fehlermeldung erscheint
                    window.location.reload();
                }
            }
        );

        $j(document).ajaxStart(() => {
            // Toggle dass Request gestartet worden ist
            StatischeMethoden.LadeAnimationAnzeigen();
        });

        $j(document).ajaxStop(() => {
            // Toggle ob Request noch aktiv ist abschalten
            StatischeMethoden.LadeAnimationAusblenden();
        });
    }

    /**
     * Erstellt die notwendige Request zum Laden des Seiteninhalts und führt diese anschließend aus.
     * @description
     * Es werden zuerst alle notwendigen AJAX Vorbereitungen vorgenommen ([[StarteAjaxVorbereitungen]]).
     * Anschließend wird der Programmlink für den Schnittstellenaufruf erstellt ([[ErmittleProgrammaufruf]]).
     * Dann wird der Link per GET-Request aufgerufen ([[SendeGetRequest]]).
     * Bei erfolgreichem Schnittstellenaufruf wird [[VerarbeiteAjaxDone]] ausgeführt.
     * @param ProgrammInfo Gibt an, welches Programm mit welchen Parametern aufgerufen wird.
     * @param Settings Gibt die zusätzlichen optionalen Einstellungen für die Verarbeitung der Schnittstellenantwort an.
     * @returns Wenn Programm "O4001" aufgerufen wird, dann nichts, ansonsten ein Promise, das den Status des JQueryXHR zurückgibt
     */
    public LadeSeite(
        ProgrammInfo: HoProgrammInfo,
        Settings: IAS400LayerSettings
    ): JQuery.Deferred<IAs400LayerStatusInformation> {
        // Routine ausführen, die vor dem Schnittstellenaufruf ausgeführt werden muss
        this.StarteAjaxVorbereitungen(Settings.ModalSchliessen);

        let $d = $j.Deferred();
        let request: JQueryXHR;

        if (ProgrammInfo.Programm.startsWith("O4001")) {
            // Fallback-Strategie: auf Startseite weiterleiten
            ProgrammInfo.CallJsAnhaengen = false;
            ProgrammInfo.Parameter =
                "PROGRAMM=" + Konstanten.FallbackStrategieProgramm;
            window.location.href = ProgrammInfo.ProgrammAufruf;
        } else {
            if (Settings.ForDownload) {
                // Window-Element bereitstellen für späteres Download-Fenster-Öffnen
                Settings.ForDownloadWindowElement = window.open();
            }

            request = this.SendeGetRequest(ProgrammInfo, Settings);

            request
                .done((ResponseData: string) => {
                    // Antwort der (erfolgreichen) Anfrage verarbeiten
                    let dataObject: IAS400LayerDataObject = {
                        Data: ResponseData,
                        ProgrammInfo,
                        Settings,
                    };
                    this.VerarbeiteAjaxDone(dataObject)
                        .done($d.resolve)
                        .fail($d.reject);
                })
                .fail($d.reject);
        }

        return $d;
    }

    /**
     * Diese Methode mach einen AJAX Call an das angegebene Programm
     *  und überträgt als data den Seiteninhaltsbereich.
     * @description
     * Es werden zuerst alle notwendigen AJAX Vorbereitungen vorgenommen ([[StarteAjaxVorbereitungen]]).
     * Anschließend wird der Programmlink für den Schnittstellenaufruf erstellt ([[ErmittleProgrammaufruf]]).
     * Falls keine AJAX Daten für den Submit übergeben worden sind, werden diese mittels
     * [[Konstanten.ProgrammDatenRahmenSelector]] unter Verwendung von [[Konstanten.ProgrammDatenSerialisierungTypen]]
     * ermittelt.
     * Die AJAX Parameter werden mittels [[ErmittleAjaxParameter]] für die Anfrage vorbereitet.
     * Dann wird der Link per POST-Request aufgerufen ([[SendePostRequest]]).
     * Bei erfolgreichem Schnittstellenaufruf wird [[VerarbeiteAjaxDone]] ausgeführt;
     * @param ProgrammInfo Gibt an, welches Programm mit welchen AJAX-Daten aufgerufen wird.
     * @param Settings Gibt die zusätzlichen optionalen Einstellungen für die Verarbeitung der Schnittstellenantwort an.
     */
    public InhaltSubmit(
        ProgrammInfo: HoProgrammInfo,
        Settings: IAS400LayerSettings
    ): JQuery.Deferred<IAs400LayerStatusInformation> {
        // Routine ausführen, die vor dem Schnittstellenaufruf ausgeführt werden muss
        this.StarteAjaxVorbereitungen();

        let $d = $j.Deferred();

        // Wenn keine Ajax Daten übergeben worden sind, diese hier ermitteln
        if (!ProgrammInfo.AjaxDaten) {
            ProgrammInfo.AjaxDaten = $j(Konstanten.ProgrammDatenRahmenSelector)
                .find(Konstanten.ProgrammDatenSerialisierungTypen)
                .serialize();
        }

        // R-Pgm dürfen nur per POST aufgerufen werden
        this.SendePostRequest(ProgrammInfo, Settings)
            .done((AntwortDaten) => {
                // Antwort der (erfolgreichen) Anfrage verarbeiten
                let dataObject: IAS400LayerDataObject = {
                    Data: AntwortDaten,
                    ProgrammInfo,
                    Settings,
                };
                this.VerarbeiteAjaxDone(dataObject)
                    .done($d.resolve)
                    .fail($d.reject);
            })
            .fail($d.reject);

        return $d;
    }

    /**
     * Sendet eine AJAX GET Request
     * @param ProgrammInfo Gibt an, welches Programm mit welchen Parametern aufgerufen wird.
     * @param Settings Gibt die zusätzlichen optionalen Einstellungen für die Verarbeitung der Schnittstellenantwort an.
     * @returns JQueryXHR-Anfragen-Objekt, mit dem weitergearbeitet werden kann
     */
    public SendeGetRequest(
        ProgrammInfo: HoProgrammInfo,
        Settings: IAS400LayerSettings
    ): JQueryXHR {
        return this.SendeAjaxRequest({
            url: ProgrammInfo.ProgrammAufruf,
            global: !Settings.LadeanimationDeaktivieren,
            cache: false,
        });
    }

    /**
     * Sendet eine AJAX POST Request.
     * @param ProgrammInfo Gibt an, welches Programm mit welchen AJAX-Daten aufgerufen wird.
     * @param Settings Gibt die zusätzlichen optionalen Einstellungen für die Verarbeitung der Schnittstellenantwort an.
     * @returns JQueryXHR-Anfragen-Objekt, mit dem weitergearbeitet werden kann
     */
    public SendePostRequest(
        ProgrammInfo: HoProgrammInfo,
        Settings: IAS400LayerSettings
    ): JQueryXHR {
        return this.SendeAjaxRequest({
            type: "POST",
            global: !Settings.LadeanimationDeaktivieren,
            url: ProgrammInfo.ProgrammAufruf,
            data: ProgrammInfo.AjaxParameter,
        });
    }

    /**
     * Sendet eine jQuery AJAX Request
     * @param Settings JQuery.AjaxSettings-Objekt
     */
    public SendeAjaxRequest(Settings: JQuery.AjaxSettings): JQueryXHR {
        return $j.ajax(Settings);
    }

    /**
     * Verarbeitet einen erfolgreichen AJAX Call
     * @param Data AJAX Daten
     * @param ProgrammInfo Gibt an, welches Programm mit welchen Parametern aufgerufen wird.
     * @param Settings Gibt die zusätzlichen optionalen Einstellungen für die Verarbeitung der Schnittstellenantwort an.
     */
    public VerarbeiteAjaxDone(
        options: IAS400LayerDataObject
    ): JQuery.Deferred<IAs400LayerStatusInformation> {
        let $d = $j.Deferred();
        let data = options.Data ?? "";
        let settings: IAS400LayerSettings = options.Settings || {
            DialogAnzeige: false,
        };

        let statusInformation: IAs400LayerStatusInformation = {
            FrontendTextboxenLeeren: false,
        };

        if (data.startsWith("Location:")) {
            HoMobileApi.Logout();

            // An entsprechende Seite weiterleiten
            window.location.href = data.substring("Location:".length).trim();

            // Rücksprung damit restliche Logik nicht mehr ausgeführt wird
            $d.resolve(statusInformation);
            return $d;
        }

        if (data.indexOf("::noop::") >= 0) {
            // nichts tun in diesem Fall
            $d.resolve(statusInformation);
            return $d;
        }

        if (data.indexOf("&PGMN=") >= 0) {
            // Loop-Aufruf: Es wurde an ein anderes Programm weitergeleitet
            // Programmnamen aus Daten laden

            let ProgrammInfoNeu: HoProgrammInfo;
            let SettingsNeu: IAS400LayerSettings = {};

            // Hilfsvariable für Parameter-Länge
            let paramIndex: number = 0;

            // Hilfsvariablen für das Finden von ProgrammInfoNeu
            let ProgrammNeu: string;
            let ParameterNeu: string | undefined;

            // Programmname finden
            paramIndex = data.indexOf("&PGMN=") + 6;
            ProgrammNeu = data.substring(
                paramIndex,
                data.indexOf("\n", paramIndex)
            );

            // Gucken ob auch Parameter vorhanden sind
            if (data.indexOf("&PARAMS=") >= 0) {
                paramIndex = data.indexOf("&PARAMS=") + 8;
                // Zeilenende suchen
                let posZe: number = data.indexOf("\n", paramIndex);

                // Prüfen ob Zeilenumbruch nach Params kommt
                if (posZe <= 0) {
                    posZe = data.length;
                }

                // Parameter auslesen
                ParameterNeu = data.substring(paramIndex, posZe);
            }

            // Klasse für Programminfos instanziieren
            ProgrammInfoNeu = new HoProgrammInfo({
                Programm: ProgrammNeu,
                Parameter: ParameterNeu,
            });

            // Gucken ob in Dialog zu öffnen ist
            if (data.indexOf("&DIALOG=") >= 0) {
                // Parameter auslesen
                SettingsNeu.DialogAnzeige = true;

                // Prüfen ob ein Callback-Programm-Name übergeben wurde
                if (data.indexOf("&DIALOGCB=") >= 0) {
                    let modalCallback: IHoProgrammInfo | undefined;

                    paramIndex = data.indexOf("&DIALOGCB=") + 10;
                    // Zeilenende suchen
                    let posZe: number = data.indexOf("\n", paramIndex);

                    // Prüfen ob Zeilenumbruch nach Params kommt
                    if (posZe <= 0) {
                        posZe = data.length;
                    }

                    modalCallback = {
                        Programm: data.substring(paramIndex, posZe),
                    };

                    // Prüfen ob Parameter für das Callback-Programm übergeben wurden
                    if (data.indexOf("&DIALOGCBPARAMS=") >= 0) {
                        paramIndex = data.indexOf("&DIALOGCBPARAMS=") + 16;
                        // Zeilenende suchen
                        let posZeI: number = data.indexOf("\n", paramIndex);

                        // Prüfen ob Zeilenumbruch nach Params kommt
                        if (posZeI <= 0) {
                            posZeI = data.length;
                        }

                        // Parameter auslesen
                        modalCallback.Parameter = data.substring(
                            paramIndex,
                            posZeI
                        );
                    }

                    SettingsNeu.modalCallback = modalCallback;

                    // Prüfen ob das Callback-Programm als Dialog geöffnet werden soll
                    if (data.indexOf("&DIALOGCBDIALOG=") >= 0) {
                        // Parameter auslesen
                        SettingsNeu.modalCallbackAlsDialog = true;
                    }
                }
            }

            // Prüfen ob Fenster für Download geöffnet werden soll
            if (data.indexOf("&FORDOWNLOAD=") >= 0) {
                SettingsNeu.ForDownload = true;
            }

            if (data.indexOf("&MESSAGE=") >= 0) {
                // Parameter auslesen
                SettingsNeu.ToastAnzeige = true;
            }

            // Neue Seite laden
            this.LadeSeite(ProgrammInfoNeu, SettingsNeu)
                .done((hons) => {
                    // Prüfen ob Warenkorb zu aktualisieren ist
                    if (data.indexOf("&WKUPDATE=") >= 0) {
                        Warenkorb.INSTANCE.Aktualisieren();
                    }
                    $d.resolve(hons);
                })
                .fail($d.reject);

            // Wenn die aktuellen Settings ein Fenster geöffnet haben, aber das aufgerufene Programm keinen Link für das Fenster zurückgegeben hat, dann das geöffnete Fenster schließen
            if (settings.ForDownload && settings.ForDownloadWindowElement) {
                // Fenster schließen, wird nicht mehr benötigt
                settings.ForDownloadWindowElement.close();
            }

            return $d;
        }

        if (data.indexOf("&DOC=") >= 0) {
            // Dokument herunterladen
            let downloadUrl: string = data.substring(
                data.indexOf("&DOC=") + 5,
                data.length
            );

            // Wenn das Fenster For-Download geöffnet worden ist, dann gibt es in den Settings ein Window-Element
            if (settings.ForDownload && settings.ForDownloadWindowElement) {
                // Zuvor geöffnetem Fenster die Location übergeben, die es anzeigen soll
                settings.ForDownloadWindowElement.location.href = downloadUrl;
            } else {
                // Fehler: Wenn DOC zurückkommt, dann müsste immer ForDownload true sein
                console.warn("VerarbeiteAjaxDone &DOC Fehler");
                console.warn("Settings.ForDownload: " + settings.ForDownload);
                console.warn(
                    "Settings.ForDownloadWindowElement: " +
                        settings.ForDownloadWindowElement
                );

                // Über herkömmlichen Weg versuchen, ein Fenster zu öffnen
                window.open(downloadUrl);
            }

            $d.resolve(statusInformation);
            return $d;
        }

        if (settings.DialogAnzeige) {
            statusInformation.onModalClose = jQuery.Deferred<boolean>();

            // Ergebnis des Programmaufrufs als Dialog anzeigen
            this.DialogUpdate({
                ...options,
                onModalClose: statusInformation.onModalClose,
            }).done(() => {
                $d.resolve(statusInformation);
            });

            return $d;
        }

        if (settings.ToastAnzeige) {
            // Ergebnis des Programmaufrufs als Toast anzeigen
            this.ToastUpdate(options);
            statusInformation.FrontendTextboxenLeeren = true;

            $d.resolve(statusInformation);
            return $d;
        }

        if (settings.BlockContainerSelector?.length) {
            // Es wird nur ein Teil des Rahmenlayout-Containers ersetzt
            this.BlockUpdate(options);

            $d.resolve(statusInformation);
            return $d;
        }

        if (!options.Settings?.Silent) {
            // Ergebnis des Programmaufruf ausgeben
            this.FormUpdate(options);
            // Ladeanimation, die beim Aufrufen einer Seite angezeigt wird, beenden
            StatischeMethoden.LadeAnimationAusblenden();
        }

        $d.resolve(statusInformation);
        return $d;
    }

    /**
     * Verarbeitet ein AJAX Start Event
     */
    private StarteAjaxVorbereitungen(ModalSchliessen?: boolean): void {
        // Modals schließen, wenn ModalSchließen true ist
        if (ModalSchliessen === true || ModalSchliessen === undefined) {
            StatischeMethoden.SchliesseModal(true);
        }

        // Profil schließen, falls dieses geöffnet ist
        Layout.INSTANCE.SchliesseProfil();

        // Restlicher Logik mitteilen, dass Ajax-Vorbereitungen gestartet worden sind
        $j(document).trigger(
            KonstantenBenutzerdefinierteEvents.AjaxVorbereitungenGestartet
        );
    }

    /**
     * Gibt die Antwort eines Schnittstellenaufrufs als Seiteninhalt aus.
     * Aktualisiert die Browserhistorie.
     * @param Data HTML-Text, der als Seiteninhalt ausgegeben wird
     * @param ProgrammInfo Gibt an, welches Programm mit welchen Parametern aufgerufen wird.
     */
    private FormUpdate(options: IAS400LayerDataObject) {
        Layout.INSTANCE.Aktualisieren($j("body"), options);
    }

    /**
     * Zeigt HTML als Dialog an
     * @param Data HTML-Text, der als Dialoginhalt angezeigt wird
     * @param ProgrammInfo Gibt an, welches Programm mit welchen Parametern aufgerufen wird.
     * @param Settings Gibt die zusätzlichen optionalen Einstellungen für die Verarbeitung der Schnittstellenantwort an.
     */
    private DialogUpdate(
        options: IAS400LayerDataObject & {
            onModalClose: JQuery.Deferred<boolean>;
        }
    ): JQuery.Deferred<any> {
        let settings: IAS400LayerSettings = options.Settings || {
            DialogAnzeige: false,
        };

        // Modal initialisieren
        let modalSettings: IModalSettings = {
            programmInfo: options.ProgrammInfo,
            modalId: Konstanten.DialogFensterId,
            modalContent: options.Data ?? "",
            onClose: options.onModalClose,
        };

        if (settings.modalCallback) {
            modalSettings.modalCallback = settings.modalCallback;
            modalSettings.modalCallbackAlsDialog =
                settings.modalCallbackAlsDialog;
        }

        StatischeMethoden.aktivesModal = new Modal(modalSettings);
        return StatischeMethoden.aktivesModal.anzeigen();
    }

    /**
     * Zeigt HTML als Toast an
     * @param Data HTML-Text, der als Dialoginhalt angezeigt wird
     * @param ProgrammInfo Gibt an, welches Programm mit welchen Parametern aufgerufen wird.
     * @param Settings Gibt die zusätzlichen optionalen Einstellungen für die Verarbeitung der Schnittstellenantwort an.
     */
    private ToastUpdate(options: IAS400LayerDataObject) {
        // Toast initialisieren
        let toast: Message = new Message(options.Data ?? "");
        toast.InitMarkup(options.ProgrammInfo.Programm);
    }

    /**
     * Aktualisiert einen Teil des Seiteninhalts
     * @param Data Inhalt, der im Container, der aktualisiert wird, angezeigt wird
     * @param ProgrammInfo Gibt an, welches Programm mit welchen Parametern aufgerufen wird.
     * @param Settings Gibt die zusätzlichen optionalen Einstellungen für die Verarbeitung der Schnittstellenantwort an.
     */
    private BlockUpdate(options: IAS400LayerDataObject) {
        const settings = options.Settings || {
            DialogAnzeige: false,
        };

        const $layoutRoot = $j(settings.BlockContainerSelector ?? "body");

        // Layout-Container bereinigen
        Layout.INSTANCE.Zuruecksetzen($layoutRoot);

        if (settings.BlockContainerSelector) {
            // Prüfen ob der angegebene Block geleert werden soll
            if (settings.BlockContainerLeeren !== false) {
                $layoutRoot.children().off();
                $layoutRoot.off().empty();
            }

            // Layoutcontainer aktualisieren
            $layoutRoot.append(options.Data ?? "");
        }

        // Layout initialisieren
        Layout.INSTANCE.InitLayout($layoutRoot, {
            ProgrammName: options.ProgrammInfo.Programm,
            ModalAnzeige: false,
            TableStateWiederherstellen: settings.TableStateWiederherstellen,
        });
    }
}
