/**
 * Beinhaltet konstante Werte für den Warenkorb
 */
export class KonstantenWarenkorb {
    /**
     * Gibt den Namen der Warenkorb-Seite an
     */
    public static readonly ProgrammWarenkorb = "O4003W";

    /**
     * Gibt den Namen des Programms an,
     * das zum Speichern von Änderungen im Warenkorb verwendet wird.
     */
    public static readonly ProgrammWarenkorbSpeichern = "O4003R";

    /**
     * Gibt an, welche Programme aufgerufen werden dürfen,
     * während es noch ungespeicherte Änderungen im Warenkorb gibt.
     * @description
     * Diese Programme werden als Modale geöffnet.
     * Deshalb kommen diese sich nicht in die Quere mit den ungespeicherten Änderungen.
     */
    public static readonly ErlaubteWechselProgramme = [
        "O4CLWW",
        "O4002W",
        "O4048W",
        "O4251W",
        "O4828W",
    ];
}
