import * as $j from "jquery";
import { AS400Layer } from "./as400Layer";
import { KeyEvent } from "./events/keyEvent";
import { Historie } from "./historie";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { Konstanten } from "./konstanten/konstanten";

/**
 * Klasse für die Aktions-Funktionen.
 * @description
 * Stellt die Funktionslogik rund um Aktionen bereit.
 */
export class Aktionen {
    /**
     * Definiert den Layout-Root-Bereich, in dem die Initialisierung der Artikelsuche durchgeführt wird.
     */
    private $layoutRoot: JQuery;

    /**
     * Ermittelt die aktuell verwendete bzw. zu verwendende UKAKTSF.
     */
    private get UkAktsf(): string {
        return ($j("#O4620_ukaktsf", this.$layoutRoot).val() || "")
            .toString()
            .trim();
    }

    /**
     * Legt eine neue UKAKTSF fest.
     */
    private set UkAktsf(value: string) {
        $j("#O4620_ukaktsf", this.$layoutRoot).val(value);
    }

    /**
     * Ermittelt die aktuell ausgewählte Sortierung aus der Oberfläche.
     */
    private get Sortierung(): string {
        return ($j("#O4620_sortierung", this.$layoutRoot).val() || "")
            .toString()
            .trim();
    }

    constructor($layoutRoot: JQuery) {
        this.$layoutRoot = $layoutRoot;
    }

    /**
     * Initialisiert alle notwendigen Funktionen rund um Aktionen.
     */
    public InitAktionsUebersicht() {
        this.InitKopfLayout();
        this.LadeErgebnisliste();
        this.LadeFilterBereich();
    }

    /**
     * Lädt die Ergebnisliste von Aktionen.
     */
    private LadeErgebnisliste(naechsteSeite: number = 0) {
        if (naechsteSeite <= 1) {
            // Beispiele, wann dieser Codepfad eintritt:
            // - Seite wurde neu geladen
            // - Es wurde die Sortierung der Ergebnisliste geändert
            // - Neue Such-ID wurde vergeben (bspw. durch Ändern des Suchbegriffs)
            this.updateHistory();

            this.ersetzeDurchLadeAnimation(
                $j("#O4620_ergebnisliste", this.$layoutRoot)
            );
        }

        return AS400Layer.INSTANCE.LadeSeite(
            new HoProgrammInfo({
                Programm: "O4621W",
                Parameter: `ukaktsf=${this.UkAktsf}&seitht=${naechsteSeite}&sortht=${this.Sortierung}`,
            }),
            {
                BlockContainerSelector: "#O4620_ergebnisliste",
                BlockContainerLeeren: naechsteSeite <= 1,
                LadeanimationDeaktivieren: true,
            }
        )
            .done(this.InitErgebnislisteLayout.bind(this))
            .fail(() => {
                $j("#O4620_ergebnisliste", this.$layoutRoot)
                    .empty()
                    .html("<p>Fehler beim Laden der Aktionen</p>");
            });
    }

    /**
     * Lädt den Filterbereich von Aktionen.
     */
    private LadeFilterBereich() {
        // Prüfen ob das Filter-Panel als Overlay angezeigt wird
        // Wenn ja -> schließen, damit es die Sicht nicht stört
        if (
            $j("[data-ho-artikelsuche-table-wrapper]", this.$layoutRoot).css(
                "display"
            ) === "block"
        ) {
            this.FilterBereichSchliessen();
        }

        this.ersetzeDurchLadeAnimation($j("#O4620_filter", this.$layoutRoot));

        return AS400Layer.INSTANCE.LadeSeite(
            new HoProgrammInfo({
                Programm: "O4622W",
                Parameter: `ukaktsf=${this.UkAktsf}`,
            }),
            {
                BlockContainerSelector: "#O4620_filter",
                BlockContainerLeeren: true,
                LadeanimationDeaktivieren: true,
            }
        )
            .done(this.InitFilterBereichLayout.bind(this))
            .fail(() => {
                $j("#O4620_filter", this.$layoutRoot)
                    .empty()
                    .html("<p>Fehler beim Laden der Filter</p>");
            });
    }

    /**
     * Initialisiert das Layout für den Kopfbereich.
     */
    private InitKopfLayout() {
        $j("[data-ho-O4620-filter-anwenden]", this.$layoutRoot)
            .off("click.holteronline-aktionen")
            .on(
                "click.holteronline-aktionen",
                this.AnsichtAktualisieren.bind(this)
            );

        $j("#O4620_sortierung", this.$layoutRoot)
            .off("change.holteronline-aktionen")
            .on("change.holteronline-aktionen", () => this.LadeErgebnisliste());

        $j("#O4620_Suchbegriff")
            .off("keypress.holteronline-aktionen")
            .on("keypress.holteronline-aktionen", (ereignis) => {
                if (KeyEvent.isEnter(ereignis)) {
                    this.AnsichtAktualisieren();
                }
            });

        $j("[data-ho-aktionen-filter-anzeigen]", this.$layoutRoot)
            .off("click.holteronline-aktionen")
            .on(
                "click.holteronline-aktionen",
                this.FilterBereichOeffnen.bind(this)
            );

        $j("[data-ho-aktionen-filter-ausblenden]", this.$layoutRoot)
            .off("click.holteronline-aktionen")
            .on(
                "click.holteronline-aktionen",
                this.FilterBereichSchliessen.bind(this)
            );
    }

    /**
     * Wird zur Layoutinitialisierung nach dem Neu-Laden der Seite ausgeführt.
     */
    private InitErgebnislisteLayout() {
        this.InitWeitereLadenButton();
    }

    /**
     * Wird zur Layoutinitialisierung des Filter-Bereichs nach Neu-Laden der Seite ausgeführt.
     */
    private InitFilterBereichLayout() {
        $j("[data-ho-O4622-filter-anwenden]", this.$layoutRoot)
            .off("click.holteronline-aktionen")
            .on(
                "click.holteronline-aktionen",
                this.AnsichtAktualisieren.bind(this)
            );

        $j("[data-ho-O4622-filter-reset-alle]", this.$layoutRoot)
            .off("click.holteronline-aktionen")
            .on("click.holteronline-aktionen", () => {
                $j("[data-ho-O4622-filter-reset]", this.$layoutRoot).each(
                    (index, elem) => {
                        let filterName =
                            $j(elem).attr("data-ho-O4622-filter-reset") ?? "";

                        if (this.FilterIstSichtbar(filterName)) {
                            this.FilterSchliessen(filterName);
                        }
                    }
                );
                this.AnsichtAktualisieren();
            });

        $j("[data-ho-O4622-filter-header]", this.$layoutRoot)
            .off("click.holteronline-aktionen")
            .on(
                "click.holteronline-aktionen",
                (ereignis: JQuery.TriggeredEvent) => {
                    this.FilterDetailsSichtbarkeitUmschalten(
                        $j(ereignis.currentTarget).attr(
                            "data-ho-O4622-filter-header"
                        ) ?? ""
                    );
                }
            );

        $j("[data-ho-O4622-filter-reset]", this.$layoutRoot)
            .off("click.holteronline-aktionen")
            .on("click.holteronline-aktionen", (ereignis) => {
                this.FilterZuruecksetzen(
                    $j(ereignis.currentTarget).attr(
                        "data-ho-O4622-filter-reset"
                    ) ?? ""
                );
                this.AnsichtAktualisieren();
            });

        $j("[data-ho-O4622-filter-textbox-for]", this.$layoutRoot)
            .off("keyup.holteronline-aktionen")
            .on("keyup.holteronline-aktionen", (ereignis) => {
                let $textbox = $j(ereignis.currentTarget);
                let filterName = $textbox.attr(
                    "data-ho-O4622-filter-textbox-for"
                );
                let $listOfChildren = $j(
                    `[data-ho-O4622-filter-values-list="${filterName}"]`
                ).children("li");

                let $matchingChildren = $listOfChildren.filter(
                    (index, elem) =>
                        $j(elem)
                            .text()
                            .toUpperCase()
                            .indexOf(
                                ($textbox.val() || "").toString().toUpperCase()
                            ) >= 0
                );

                $listOfChildren.hide();
                $matchingChildren.show();
            });
    }

    /**
     * Initialisiert die notwendige Funktionalität für den "Weitere laden" Button.
     */
    private InitWeitereLadenButton() {
        $j("[data-ho-aktion-weitere-laden]", this.$layoutRoot)
            .off(".holteronline-aktionen")
            .on(
                "click.holteronline-aktionen",
                (ereignis: JQuery.TriggeredEvent) => {
                    let $weitereLadenButton = $j(ereignis.currentTarget);

                    let naechsteSeite: number =
                        $weitereLadenButton.data("ho-aktion-seite") || 0;

                    let $weitereLadenWrapper = $j(
                        "[data-ho-aktion-footer-container]",
                        this.$layoutRoot
                    );

                    this.ersetzeDurchLadeAnimation($weitereLadenWrapper);

                    this.LadeErgebnisliste(naechsteSeite).done(() => {
                        $weitereLadenWrapper.remove();
                    });
                }
            );
    }

    /**
     * Öffnet den Filterbereich.
     */
    private FilterBereichOeffnen() {
        $j("[data-ho-aktionen-wrapper]", this.$layoutRoot).removeClass(
            "filter-hidden"
        );
        $j("[data-ho-aktionen-filter-anzeigen]", this.$layoutRoot).addClass(
            "invisible"
        );
    }

    /**
     * Schließt den Filterbereich.
     */
    private FilterBereichSchliessen() {
        $j("[data-ho-aktionen-wrapper]", this.$layoutRoot).addClass(
            "filter-hidden"
        );
        $j("[data-ho-aktionen-filter-anzeigen]", this.$layoutRoot).removeClass(
            "invisible"
        );
    }

    /**
     * Ändert die Sichtbarkeit einer Filterdetailansicht.
     */
    private FilterDetailsSichtbarkeitUmschalten(FilterName: string) {
        if (this.FilterIstSichtbar(FilterName)) {
            this.FilterSchliessen(FilterName);
        } else {
            this.FilterOeffnen(FilterName);
        }
    }

    /**
     * Schließt einen Filter.
     */
    private FilterSchliessen(FilterName: string) {
        $j(`[data-ho-O4622-filter-header="${FilterName}"]`, this.$layoutRoot)
            .children("img")
            .addClass("closed");
        $j(
            `[data-ho-O4622-filter-container="${FilterName}"]`,
            this.$layoutRoot
        ).css("height", 0);
    }

    /**
     * Öffnet einen Filter.
     */
    private FilterOeffnen(FilterName: string) {
        $j(`[data-ho-O4622-filter-header="${FilterName}"]`, this.$layoutRoot)
            .children("img")
            .removeClass("closed");
        $j(
            `[data-ho-O4622-filter-container="${FilterName}"]`,
            this.$layoutRoot
        ).css("height", "auto");
    }

    /**
     * Prüft, ob der angegebenen Filter sichtbar / eingeblendet ist.
     */
    private FilterIstSichtbar(FilterName: string): boolean {
        return (
            $j(
                `[data-ho-O4622-filter-container="${FilterName}"]`,
                this.$layoutRoot
            ).height() !== 0
        );
    }

    /**
     * Setzt den angegebene Filter zurück (entfernt ihn von der Serialisierung der Daten).
     */
    private FilterZuruecksetzen(FilterName: string) {
        this.FilterSchliessen(FilterName);
    }

    /**
     * Ermittelt eine neue Such-ID.
     */
    private AnsichtAktualisieren() {
        let submitProgrammInfo = new HoProgrammInfo({
            Programm: "O4620R",
            AjaxDaten: $j("[data-ho-aktionen-kopfdaten]", this.$layoutRoot)
                .find(Konstanten.ProgrammDatenSerialisierungTypen)
                .serialize(),
        });

        let filterString = this.getFilterSearchString();
        if (filterString) {
            submitProgrammInfo.AjaxDatenHinzufuegen(
                "filterht",
                encodeURIComponent(filterString)
            );
        }

        AS400Layer.INSTANCE.SendePostRequest(submitProgrammInfo, {}).done(
            (AntwortDaten: string) => {
                this.UkAktsf = AntwortDaten.substring(
                    AntwortDaten.indexOf("&ukaktsf=") + "&ukaktsf=".length,
                    AntwortDaten.indexOf(
                        "&",
                        AntwortDaten.indexOf("&ukaktsf=") + "&ukaktsf=".length
                    )
                );
                this.LadeErgebnisliste();
                this.LadeFilterBereich();
            }
        );
    }

    /**
     * Ermittelt den Filter-String, mit dem die Suche nach einer neuen UKAKTSF erfolgen soll.
     */
    private getFilterSearchString(): string {
        let filterString = "";

        $j("[data-ho-O4622-filter-type='selection']", this.$layoutRoot).each(
            (filterIndex, filterElem) => {
                let $container = $j(filterElem);
                let filterName =
                    $container.attr("data-ho-O4622-filter-container") ?? "";

                if (this.FilterIstSichtbar(filterName)) {
                    let selectedFiltersArray: string[] = [];
                    $container
                        .find("input[type=checkbox]")
                        .each(
                            (
                                checkboxIndex: number,
                                checkboxElem: HTMLInputElement
                            ) => {
                                if (
                                    checkboxElem.checked &&
                                    $j(checkboxElem)
                                        .parents("li")
                                        .is(":visible")
                                ) {
                                    selectedFiltersArray.push(
                                        ($j(checkboxElem).val() || "")
                                            .toString()
                                            .trim()
                                    );
                                }
                            }
                        );

                    filterString +=
                        "&" + filterName + "=" + selectedFiltersArray.join(";");
                }
            }
        );

        let prepareNumericFilter = (filterIndex, filterElem) => {
            let $container = $j(filterElem);
            let filterName =
                $container.attr("data-ho-O4622-filter-container") ?? "";

            if (this.FilterIstSichtbar(filterName)) {
                let wertMin =
                    $j("#" + filterName + "_min", $container).val() || 0;

                let wertMax =
                    $j("#" + filterName + "_max", $container).val() || 0;

                filterString +=
                    "&" +
                    filterName +
                    "=" +
                    wertMin.toString() +
                    "-" +
                    wertMax.toString();
            }
        };

        $j("[data-ho-O4622-filter-type='slider']", this.$layoutRoot).each(
            prepareNumericFilter.bind(this)
        );

        $j("[data-ho-O4622-filter-type='datepicker']", this.$layoutRoot).each(
            prepareNumericFilter.bind(this)
        );

        return filterString;
    }

    /**
     * Ersetzt den Inhalt aller Container, die dem JQuery-Selector entsprechen, sauber durch eine Ladeanimation.
     * @param $obj JQuery-Selector-Objekt für die zu ersetzenden Container
     */
    private ersetzeDurchLadeAnimation($obj: JQuery) {
        $obj.children().off();
        $obj.off()
            .empty()
            .append(
                $j("#O4621_muster_ladeanimation", this.$layoutRoot).html() || ""
            );
    }

    /**
     * Aktualisiert die Historie.
     */
    private updateHistory() {
        Historie.INSTANCE.NeuerEintrag(
            new HoProgrammInfo({
                Programm: "O4620W",
                Parameter: `&ukaktsf=${this.UkAktsf}&sortht=${this.Sortierung}`,
            }),
            document.title
        );
    }
}
