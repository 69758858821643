// tslint:disable:comment-format
///<amd-dependency path="bloodhound" />
///<amd-dependency path="typeahead.js" />
// tslint:enable:comment-format

import * as $j from "jquery";
import { HoItemSearchApi } from "./api/interfaces/hoItemSearchApi";
import { ItemSearchApi } from "./api/itemSearchApi";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { KonstantenAutocomplete } from "./konstanten/konstantenAutocomplete";

/**
 * Stellt wiederverwendbare Funktionen für Autocompletes bereit.
 */
export class Autocomplete {
    public static readonly INSTANCE = new Autocomplete();

    /**
     * Decodiert Autocomplete-Daten für die Anzeige.
     * Es werden bei JSON Datenobjekten alle Child-Elemente decodiert.
     * @param Datenobjekt Daten, die decodiert werden sollen
     */
    public static DecodiereJsonDaten(Datenobjekt: any) {
        // Alle Elemente in den JSON Daten decoden, damit die Umlaute passen
        Datenobjekt.forEach((element, index, array) => {
            for (let key in element) {
                if (element.hasOwnProperty(key)) {
                    element[key] = decodeURIComponent(element[key]);
                }
            }
        });
        return Datenobjekt;
    }

    /**
     * Decodiert Autocomplete-Daten für die Anzeige.
     * @param Datenobjekt Daten, die decodiert werden sollen
     */
    public static DecodiereStringDaten(Datenobjekt: any) {
        // Alle Elemente im String-Datenobjekt durchgehen
        Datenobjekt.forEach((element, index, array) => {
            array[index] = decodeURIComponent(element);
        });
        return Datenobjekt;
    }

    /**
     * Bereitet das AJAX-Settings-Objekt für eine Bloodhound Request vor
     */
    public static PrepareAjaxSettings(
        query: string,
        settings: JQueryAjaxSettings
    ) {
        if (settings) {
            // Für die Settings das "global"-Attribut deaktivieren
            settings.global = false;

            if (settings.url) {
                // Weil die Standard-Logik von Bloodhound.prepare mit dieser Methode ausgehebelt wird,
                //  muss auch der Parameter in der URL mit dem tatsächlichen Request-Wert ersetzt werden.
                settings.url = (settings.url || "").replace(
                    KonstantenAutocomplete.StandardUrlWildcard,
                    encodeURIComponent(query)
                );
            }
        }

        return settings;
    }

    private constructor() {}

    /**
     * Initialisiert alle Autocomplete-Funktionalitäten
     */
    public InitAutocomplete($layoutRoot: JQuery) {
        // Autocompletes initialisieren
        $j("[data-ho-autocomplete]", $layoutRoot).each((index, elem) => {
            // jQuery-Objekt aus dem Element erzeugen, an das typeahead angehängt wird
            let $elem = $j(elem);

            let typeaheadOptions: Twitter.Typeahead.Options;
            let typeaheadDataset: Twitter.Typeahead.Dataset<any>;

            let autocompleteTyp = $elem.data("ho-autocomplete");

            let limit: number = $elem.data("ho-autocomplete-limit");
            if (!limit || limit < 1) {
                limit = 10;
            }

            if (autocompleteTyp === "itemsearchapi") {
                let bloodhoundOptions: Bloodhound.BloodhoundOptions<any> = {
                    datumTokenizer: Bloodhound.tokenizers.whitespace,
                    queryTokenizer: Bloodhound.tokenizers.whitespace,
                    remote: {
                        url: ItemSearchApi.AUTOCOMPLETE_URL,
                        prepare: (query, settings) => {
                            return ItemSearchApi.getAutocompleteAjaxSettings(
                                query,
                                limit,
                                settings
                            );
                        },
                        transform: (
                            data: HoItemSearchApi.AutoCompleteResponse
                        ) => {
                            return data.queries?.items ?? [];
                        },
                    },
                };

                typeaheadDataset = {
                    name: "dsTypeaheadItemSearchApiHints",
                    source: new Bloodhound(bloodhoundOptions),
                    limit,
                    templates: {
                        suggestion: (
                            data:
                                | HoItemSearchApi.UnScopedQueryResult
                                | HoItemSearchApi.ScopedQueryResult
                        ) => {
                            return "<div>" + data.query + "</div>";
                        },
                    },
                };
            } else {
                // Programminfo-Objekt vorbereiten
                let ProgrammInfo: HoProgrammInfo = new HoProgrammInfo({
                    Programm: $elem.data("ho-autocomplete-pgmn"),
                    Parameter: $elem.data("ho-autocomplete-params"),
                });

                let fnTransform: (x: any) => any =
                    Autocomplete.DecodiereStringDaten;

                if (autocompleteTyp === "remotejson") {
                    // Antwort kommt als JSON Daten
                    fnTransform = Autocomplete.DecodiereJsonDaten;
                }

                // Grundsätzliche Bloodhound-Options vorbereiten
                let bloodhoundOptions: Bloodhound.BloodhoundOptions<any> = {
                    datumTokenizer: Bloodhound.tokenizers.whitespace,
                    queryTokenizer: Bloodhound.tokenizers.whitespace,
                    remote: {
                        url:
                            ProgrammInfo.ProgrammAufruf +
                            KonstantenAutocomplete.StandardUrlErweiterung,
                        wildcard: KonstantenAutocomplete.StandardUrlWildcard,
                        prepare: Autocomplete.PrepareAjaxSettings,
                        transform: fnTransform,
                    },
                };

                // Typeahead-Dataset vorbereiten
                typeaheadDataset = {
                    name: "dsTypeahead" + ProgrammInfo.Programm,
                    source: new Bloodhound(bloodhoundOptions),
                    limit,
                };

                switch ($elem.data("ho-autocomplete-template-suggestion")) {
                    case "powersuche":
                        typeaheadDataset.templates = {
                            suggestion: (data: any) => {
                                return "<div>" + data.suchbegr + "</div>";
                            },
                        };
                        break;
                    case "plzort":
                        // Sonderfall: Alternative Suggestion-Template verwenden
                        typeaheadDataset.templates = {
                            suggestion: (data: any) => {
                                return (
                                    "<div>" +
                                    data.plz +
                                    " " +
                                    data.ort +
                                    "</div>"
                                );
                            },
                        };
                        break;
                    default:
                        break;
                }
            }

            let minLength: number = $elem.data("ho-autocomplete-minlength");
            if (!minLength || minLength < 1) {
                // Wenn nicht angegeben, dann den Standardwert zuweisen
                minLength = 1;
            }

            let hint: boolean = false;
            if ($elem.data("ho-autocomplete-hint") !== undefined) {
                // Wenn nicht angegeben, dann den Standardwert zuweisen
                hint = $elem.data("ho-autocomplete-hint");
            }

            let highlight: boolean = true;
            if ($elem.data("ho-autocomplete-highlight") !== undefined) {
                // Wenn nicht angegeben, dann den Standardwert zuweisen
                highlight = $elem.data("ho-autocomplete-highlight");
            }

            // Typeahead-Options vorbereiten
            typeaheadOptions = {
                hint,
                highlight,
                minLength,
            };

            if ($elem.data("ho-autocomplete-classmap") === "minwidth") {
                // Class-Map für Mindestbreite hinzufügen
                typeaheadOptions.classNames =
                    KonstantenAutocomplete.ClassMapMinWidth;
            }

            let displayKey = $elem.data("ho-autocomplete-display-key");
            if (displayKey) {
                typeaheadDataset.displayKey = displayKey;
            }

            // Typeahead für das Element initialisieren
            let $typeaheadInstanz = $elem.typeahead(
                typeaheadOptions,
                typeaheadDataset
            );

            if (
                autocompleteTyp === "remotejson" &&
                $elem.data("ho-autocomplete-template-suggestion") !==
                    "powersuche"
            ) {
                // Bei JSON Daten alle Datenfelder nach Auswahl befüllen, die befüllt werden sollen
                $typeaheadInstanz
                    .off("typeahead:selected.holteronline")
                    .on("typeahead:selected.holteronline", (event, data) => {
                        // Alle Elemente, die angegeben haben, dass sie Autocomplete-Ziel sind, befüllen
                        $j(
                            "[data-ho-autocomplete-display-key]",
                            $layoutRoot
                        ).each((indexEach, elemEach) => {
                            let $elemEach = $j(elemEach);
                            let elemEachDisplayKey = $elemEach.data(
                                "ho-autocomplete-display-key"
                            );

                            // Nur befüllen wenn der gesuchte Wert gefunden wird
                            if (data[elemEachDisplayKey]) {
                                // Wert zuweisen, danach jQuery Change auslösen
                                $elemEach
                                    .typeahead("val", data[elemEachDisplayKey])
                                    .trigger("change");
                            }
                        });

                        event.preventDefault();
                        event.stopPropagation();
                    });
            }

            // Am Ende der Autocomplete-Initialisierung wird das Autocomplete-Attribut vom Element entfernt
            // um es nicht irrtümlich doppelt zu initialisieren
            $elem.removeAttr("data-ho-autocomplete");
        });
    }
}
