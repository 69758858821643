import * as $j from "jquery";
import { HoItemSearchApi } from "../api/interfaces/hoItemSearchApi";
import { KonstantenArtikelsucheVersion2 } from "../konstanten/konstantenArtikelsucheVersion2";

export class ArtikelsucheVersion2FilterBuilder {
    private $filterObject: JQuery;

    constructor() {
        this.initFilterObject();
    }
    public addFilter(
        filter: HoItemSearchApi.DistinctFilter | HoItemSearchApi.RangeFilter
    ) {
        let $filterHeader = $j(`<h4>`).html(filter.displayName);

        if (filter.unit) {
            $filterHeader.append(
                $j(`<span class="small"> - in ${filter.unit}</span>`)
            );
        }

        let $filterArrow = $j(
            `<img src="../ho/images/icons/next.png" alt="Schließen">`
        );

        $filterHeader.append($filterArrow);

        let $filterContent = $j(
            `<div class="collapsing-container" style="height: 0">`
        );

        let anySelected: boolean = false;

        if (filter.isRangeType) {
            let min = filter.min ?? filter.selectedMin;
            let max = filter.max ?? filter.selectedMax;
            let selectedMin = filter.selectedMin ?? filter.min;
            let selectedMax = filter.selectedMax ?? filter.max;

            if (!min || !max) {
                console.debug(
                    "Range filter has no min or max value, skipping",
                    filter
                );
                return;
            }

            $filterContent.attr("data-ho-filter-type", "range");

            if (filter.selectedMin || filter.selectedMax) {
                anySelected = true;
            }

            let sliderStep = 1;
            let sliderDecimals = 0;
            let testMinCommas = min.toString(10).split(".");
            if (
                testMinCommas.length > 1 &&
                testMinCommas[1].length > sliderDecimals
            ) {
                sliderDecimals = testMinCommas[1].length;
            }
            let testMaxCommas = max.toString(10).split(".");
            if (
                testMaxCommas.length > 1 &&
                testMaxCommas[1].length > sliderDecimals
            ) {
                sliderDecimals = testMaxCommas[1].length;
            }
            if (sliderDecimals > 0) {
                let tempStringForSliderDecimals = "0.";
                for (let i = 0; i < sliderDecimals - 1; i++) {
                    tempStringForSliderDecimals += "0";
                }
                tempStringForSliderDecimals += "1";
                sliderStep = parseFloat(tempStringForSliderDecimals);
            }

            $filterContent.append(
                $j(`<div
                    class="price-slider"
                >`)
                    .append(
                        $j(`<input
                                type="text"
                                class="span2"
                                data-ho-toggle="slider"
                                data-ho-slider-name="${filter.attributeName}"
                                data-ho-slider-min-textbox="#${filter.attributeName}_min"
                                data-ho-slider-max-textbox="#${filter.attributeName}_max"
                                data-slider-min="${min}"
                                data-slider-max="${max}"
                                data-slider-tooltip="hide"
                                data-slider-value="[${selectedMin},${selectedMax}]"
                                data-slider-step="${sliderStep}"
                        >`)
                    )
                    .append(
                        $j(`<div class="price-inputs">`)
                            .append(
                                $j(
                                    `<input
                                        type="text"
                                        class="start-rounded"
                                        value="${selectedMin}"
                                        id="${filter.attributeName}_min"
                                        data-ho-validate="regex input"
                                        data-ho-validate-regex-typ="number"
                                        data-placement="bottom"
                                >`
                                )
                            )
                            .append(
                                $j(`<span class="price-seperator">`).html("-")
                            )
                            .append(
                                $j(
                                    `<input
                                        type="text"
                                        class="end-rounded"
                                        value="${selectedMax}"
                                        id="${filter.attributeName}_max"
                                        data-ho-validate="regex input"
                                        data-ho-validate-regex-typ="number"
                                        data-placement="bottom"
                                >`
                                )
                            )
                    )
            );
        } else {
            if (!filter.items?.length) {
                console.debug("Distinct filter has no items, skipping", filter);
                return;
            }

            $filterContent.attr("data-ho-filter-type", "distinct");

            $filterContent.append(
                $j(`<div class="filter-input-box">`)
                    .append(
                        $j(
                            `<input 
                                type="text" 
                                placeholder="Filter" 
                                class="full-width filter-input"
                            >`
                        ).attr(
                            "data-ho-suche-filter-textbox-for",
                            filter.attributeName
                        )
                    )
                    .append(
                        $j(
                            `<input 
                                type="button" 
                                class="search-button"
                            >`
                        )
                    )
            );

            let $filterItems = $j(`<ul>`).attr(
                "data-ho-suche-filter-values-list",
                filter.attributeName
            );
            let $filterItemBox = $j(`<div>`).append($filterItems);

            let itemNumber = 0;

            if (filter.items) {
                filter.items.forEach((item) => {
                    itemNumber++;

                    if (item.selected) {
                        anySelected = true;
                    }

                    let itemText: string = "(leer)";

                    if (item.item === true) {
                        itemText = "Ja";
                    } else if (item.item === false) {
                        itemText = "Nein";
                    } else if (item.item) {
                        itemText = item.item.toString();
                    }

                    $filterItems.append(
                        $j(`<li>`)
                            .append(
                                $j(`<div class="checkbox">`)
                                    .append(
                                        $j(`<input 
                                                type="checkbox"
                                                value="${item.item}"
                                                name="${filter.attributeName}"
                                                id="O4380_checkbox_${filter.attributeName}_${itemNumber}"
                                        >`).attr(
                                            "checked",
                                            item.selected ? "checked" : null
                                        )
                                    )
                                    .append($j(`<label>`))
                            )
                            .append(
                                $j(`<label
                                    for="O4380_checkbox_${filter.attributeName}_${itemNumber}"
                                >`).html(itemText)
                            )
                            .append(
                                $j(`<span class="small">`).html(
                                    (item.totalCount ?? 0).toString()
                                )
                            )
                    );
                });
            }

            $filterContent.append($filterItemBox);
        }

        $filterContent.append(
            $j(`<div class="text-center">`)
                .append(
                    $j(
                        `<input 
                            type="button"
                            class="button-grey"
                            value="anwenden"
                        >`
                    ).attr(
                        KonstantenArtikelsucheVersion2.SUCHE_STARTEN_DATA_ATTRIBUT,
                        "true"
                    )
                )
                .append(
                    $j(
                        `<input 
                            type="button"
                            class="button-grey"
                            value="zurücksetzen"
                        >`
                    ).attr(
                        KonstantenArtikelsucheVersion2.FILTER_CONTENT_RESET_DATA_ATTRIBUT,
                        filter.attributeName
                    )
                )
        );

        $filterHeader.attr(
            KonstantenArtikelsucheVersion2.FILTER_CONTENT_VISIBILITY_TOGGLER_DATA_ATTRIBUT,
            filter.attributeName
        );
        $filterContent.attr(
            KonstantenArtikelsucheVersion2.FILTER_CONTENT_DATA_ATTRIBUT,
            filter.attributeName
        );
        $filterArrow.attr(
            KonstantenArtikelsucheVersion2.FILTER_ARROW_DATA_ATTRIBUT,
            filter.attributeName
        );

        if (anySelected) {
            $filterContent
                .css("height", "auto")
                .addClass(KonstantenArtikelsucheVersion2.FILTER_IS_OPEN_CLASS);
            $filterArrow.removeClass(
                KonstantenArtikelsucheVersion2.FILTER_ARROW_IS_CLOSED_CLASS
            );
        } else {
            $filterContent.css("height", "0");
            $filterArrow.addClass(
                KonstantenArtikelsucheVersion2.FILTER_ARROW_IS_CLOSED_CLASS
            );
        }

        this.$filterObject.append(
            $j(`<div class="filter-container">`)
                .append($filterHeader)
                .append($filterContent)
        );
    }

    public getResult(): JQuery {
        this.$filterObject.append(
            $j(
                `<div class="button-container">
                    <input type="button" value="Filter anwenden" ${KonstantenArtikelsucheVersion2.SUCHE_STARTEN_DATA_ATTRIBUT}>
                    <input type="button" value="Filter zurücksetzen" data-ho-artikelsuche-alle-filter-ausblenden></div>`
            )
        );

        return this.$filterObject;
    }

    private initFilterObject() {
        this.$filterObject = $j("<div>");
    }
}
