/**
 * Stellt Prüfungen für das Auswerten von KeyEvents bereit.
 */
export class KeyEvent {
    /**
     * Prüft, ob es sich bei einem Tasten-Event um einen Enter-Tasten-Anschlag handelt.
     */
    public static isEnter(event: JQuery.TriggeredEvent): boolean {
        return event.key === "Enter";
    }
}
