import { AS400Layer } from "./as400Layer";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { StatischeMethoden } from "./statischeMethoden";

/**
 * Stellt Funktionen zum Verarbeiten der Browser-Historie bereit.
 */
export class Historie {
    public static readonly INSTANCE = new Historie();

    private constructor() {}

    /**
     * Hängt alle notwendigen Events an
     */
    public InitEvents() {
        // Bei PopStateChanged müssen wir selbst dafür sorgen, dass Seiteninhalt neu geladen wird
        // Es werden nur W-Pgm in die Historie eingefügt, somit können Formulare nicht doppelt übermittelt werden
        window.addEventListener("popstate", this.VerarbeitePopState);
    }

    /**
     * Erstellt einen neuen Browser-Historie-Eintrag.
     * @param ProgrammName Name des Programms, das aufgerufen worden ist
     * @param Parameter Parameter, mit denen das Programm aufgerufen worden ist
     * @param FensterTitel Name des Fenster, das bei Aufruf dieses Historieeintrags angezeigt wird
     * @param ProgrammLink Vollständiger Aufrufpfad für den Historieeintrag
     */
    public NeuerEintrag(
        ProgrammInfo: HoProgrammInfo,
        FensterTitel: string
    ): void {
        // Kopie der ProgrammInfo-Klasse machen
        let ProgrammInfoCpy = ProgrammInfo;
        // In der Historie darf call=JS nicht drin sein
        ProgrammInfoCpy.CallJsAnhaengen = false;

        if (ProgrammInfoCpy.Parameter) {
            ProgrammInfoCpy.Parameter = ProgrammInfoCpy.Parameter.trim();
            if (ProgrammInfoCpy.Parameter.startsWith("&")) {
                ProgrammInfoCpy.Parameter = ProgrammInfoCpy.Parameter.slice(1);
            }
        }

        // Nur zur Historie hinzufügen wenn ein anderes Programm als zuvor geladen worden ist
        // Sonst wird beim Zurücknavigieren immer die Vorwärts-Historie überschrieben
        if (
            !history.state ||
            history.state.Programm !== ProgrammInfoCpy.Programm ||
            history.state.Parameter !== ProgrammInfoCpy.Parameter
        ) {
            // Verarbeitete Seite zur Historie hinzufügen (um zurücknavigieren zu können)

            // Der zweite Parameter von pushState und replaceState wird zwar noch nicht offiziell verwendet,
            //  ist aber für spätere Verwendung in Browsern vorgesehen -> wird deshalb mit Fenstertitel befüllt
            if (history.state) {
                // Eintrag zur Historie hinzufügen
                history.pushState(
                    ProgrammInfoCpy,
                    FensterTitel,
                    ProgrammInfoCpy.ProgrammAufruf
                );
            } else {
                // Bestehenden Historie-Eintrag "mit PushState-Daten füttern" (damit auch zu diesem zurücknavigiert werden kann)
                history.replaceState(
                    ProgrammInfoCpy,
                    FensterTitel,
                    ProgrammInfoCpy.ProgrammAufruf
                );
            }
        }
    }

    /**
     * Kopiert den aktuellen Popstate in einen neuen Popstate-Eintrag.
     */
    public DuplizierePopState() {
        if (history.state) {
            let programmInfo: HoProgrammInfo = new HoProgrammInfo(
                history.state as HoProgrammInfo
            );
            history.pushState(programmInfo, "", programmInfo.ProgrammAufruf);
        }
    }

    /**
     * Ermittelt, welche Seite aktuell in HolterOnline geöffnet ist, auf Basis des letzten Historie-Eintrags.
     */
    public ErmittleAktuelleSeite() {
        if (history.state) {
            return history.state.Programm || "";
        }
        return "";
    }

    /**
     * Navigiert in der Browserhistorie um einen Eintrag zurück und sorgt dafür, dass nicht zu diesem Eintrag navigiert wird.
     */
    public EinenEintragZuruecknavigieren() {
        if (history.state) {
            // Flag setzen, dass Popstate einmal ignoriert wird
            window["ho-ignorepopstate"] = true;
            history.go(-1);
        }
    }

    /**
     * Verarbeitet einen geänderten PopState
     * @param EreignisDaten PopState-Objekt des HistoryAPI-Events übergeben
     */
    private VerarbeitePopState(EreignisDaten: PopStateEvent) {
        // zuerst überprüfen, ob ein Oxomi Overlay angezeigt wird
        if (typeof oxomi !== "undefined" && oxomi.isOverlayVisible()) {
            // Oxomi-Overlay schließen
            window["ho-oxomi-nonavigate"] = true;
            oxomi.hideOverlay();
        } else if (window["ho-ignorepopstate"] === true) {
            // Popstate ignorieren
            window["ho-ignorepopstate"] = false;
        }
        // Prüfen ob Modal geöffnet
        else if (StatischeMethoden.PruefeObModalGeoffnet()) {
            // Wenn Modal geöffnet, dann dieses schließen
            StatischeMethoden.SchliesseModal();
        }
        // Nur neu laden wenn die aktuelle Seite sich geändert hat
        else if (EreignisDaten.state) {
            // Kopie des State-Objekts machen
            let ProgrammInfo: HoProgrammInfo = new HoProgrammInfo(
                EreignisDaten.state as HoProgrammInfo
            );
            // Daten müssen mit call=JS neu geladen werden
            ProgrammInfo.CallJsAnhaengen = true;

            // Seite mit Parametern erneut laden
            AS400Layer.INSTANCE.LadeSeite(ProgrammInfo, {
                TableStateWiederherstellen: true,
            });
        }
    }
}
