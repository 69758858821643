import * as $j from "jquery";

/**
 * Gibt an, wie HolterOnline sich im Bezug auf den gegebenen Browser benehmen soll.
 */
export class Browsertyp {
    /**
     * Gibt an, ob HolterOnline sich so benehmen soll, als wäre das Gerät ein Touchscreen-Gerät (bspw. iPad).
     */
    public static get WieTouchscreen(): boolean {
        return (
            Modernizr.touchevents ||
            !$j(".collapse").is(":visible") ||
            window.innerWidth < 992
        );
    }

    /**
     * Gibt an, ob HolterOnline sich so benehmen soll, als wäre das Gerät ein Desktop-Gerät (bspw. Computer oder Mac).
     */
    public static get WieDesktop(): boolean {
        return $j(".collapse").is(":visible") && window.innerWidth >= 992;
    }

    /**
     * Gibt an, ob der Benutzer momentan ein mobiles Gerät verwendet.
     */
    public static get WieMobilesGeraet(): boolean {
        if (navigator.userAgent.toString().search(/mobi/i) > 0) {
            const mql = window.matchMedia("(orientation: portrait)");
            if (
                navigator.userAgent.toString().search(/iphone/i) >= 0 ||
                (mql.matches && screen.height / screen.width > 1.5) ||
                (!mql.matches && screen.width / screen.height > 1.5)
            ) {
                return true;
            }
        }
        return false;
    }
}
