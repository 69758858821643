// tslint:disable:comment-format
///<amd-dependency path="datatables.net" />
///<amd-dependency path="dataTables.bootstrap5" />
///<amd-dependency path="dataTables.fixedHeader" />
///<amd-dependency path="dataTables.responsive" />
///<amd-dependency path="popper" />
///<amd-dependency path="bootstrap.bundle" />
///<amd-dependency path="bootstrap.datepicker" />
///<amd-dependency path="jquery.validate" />
///<amd-dependency path="bloodhound" />
///<amd-dependency path="typeahead.js" />
///<amd-dependency path="clipboard" />
///<amd-dependency path="jquery.floatingscroll" />
///<amd-dependency path="lazyload.script.1.0.5" />
// tslint:enable:comment-format

import * as Clipboard from "clipboard";
import * as $j from "jquery";
import * as _ from "underscore";
import { HoMobileApi } from "./api/hoMobileApi";
import { AS400Layer } from "./as400Layer";
import { Autocomplete } from "./autocomplete";
import { Browsertyp } from "./browsertyp";
import { DataTableFunktionen } from "./dataTableFunktionen";
import { Debug } from "./debug";
import { KeyEvent } from "./events/keyEvent";
import { FokusToolbox } from "./fokusToolbox";
import { Historie } from "./historie";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { IAS400LayerSettings } from "./interfaces/IAS400LayerSettings";
import { IAS400LayerDataObject } from "./interfaces/IAs400LayerDataObject";
import { IHoProgrammInfo } from "./interfaces/IHoProgrammInfo";
import { Katalog } from "./katalog";
import { Konstanten } from "./konstanten/konstanten";
import { KonstantenBenutzerdefinierteEvents } from "./konstanten/konstantenBenutzerdefinierteEvents";
import { KonstantenWarenkorb } from "./konstanten/konstantenWarenkorb";
import { Message } from "./message";
import { Powersuche } from "./powersuche";
import { ScrollToolbox } from "./scrollToolbox";
import { SeitenspezifischeFunktionen } from "./seitenspezifischeFunktionen";
import { StatischeMethoden } from "./statischeMethoden";
import { StickyHeaderFooter } from "./stickyHeaderFooter";
import { Validierung } from "./validierung";

// Diese Definition ist nur notwendig, damit keine Syntaxprüfung durch die TypeScript-Prüfungen durchgeführt wird.
// Das definiert keine Variable - Anweisung ist nicht im kompilierten JS enthalten.

/**
 * Definiert den Typ der globalen Variable Typekit.
 * Ist notwendig, damit im TypeScript keine Datentyp-Prüfung für Typekit stattfindet.
 */
declare var Typekit: any;

/**
 * Methoden, die für die Layoutlogik in Holter Online verantwortlich sind
 */
export class Layout {
    public static readonly INSTANCE = new Layout();

    private constructor() {}

    /**
     * Initialisiert die Layoutlogik und
     * hängt alle für das Layout notwendigen Events an
     */
    public InitEvents($layoutRoot: JQuery) {
        // Typekit laden
        require("lazyload.script.1.0.5")("https://use.typekit.net/gau3qeh.js")
            .then(() => {
                if (typeof Typekit !== "undefined") {
                    try {
                        Typekit.load({ async: true });
                    } catch (e) {
                        console.log(e);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });

        // Notwendige Initialisierungen vornehmen
        // Tooltips nur initialisieren wenn kein Touch-Gerät
        if (Browsertyp.WieDesktop) {
            // Tooltips für Headerbereich initialisieren
            $j('header [data-ho-toggle="tooltip"]').tooltip();
            $j('header [data-ho-toggle="tooltip_hover"]').each(
                (index, element) => {
                    $j(element).tooltip({
                        trigger: "hover",
                        placement: "top",
                    });
                }
            );
        }

        let $navbar = $j(".navbar", $layoutRoot);
        $navbar.off("show.bs.collapse").on("show.bs.collapse", () => {
            $j("body").addClass("no-scroll");
        });
        $navbar.off("hide.bs.collapse").on("hide.bs.collapse", () => {
            $j("body").removeClass("no-scroll");
        });

        // Anonyme Methode zum Schließen der Navbar
        let NavbarSchliessen: () => void = () => {
            // Alle Dropdown-Menüs schließen
            $j("#main-menu .dropdown-toggle").dropdown("hide");

            // Burger-Menü schließen
            $j("#main-menu").collapse("hide");
        };

        // Navbar schließen bei Klick außerhalb
        $j(document)
            .off(".holteronline-closemenu-document")
            .on(
                "click.holteronline-closemenu-document touchstart.holteronline-closemenu-document",
                (event) => {
                    // Wenn nicht das Menü selbst geklickt worden ist, dann Menü schließen
                    if ($j(event.target).closest(".navbar").length === 0) {
                        NavbarSchliessen();
                    }
                }
            );

        // Navbar schließen bei Klick auf Navigations-Item
        $j(".dropdown-item", $navbar)
            .off(".holteronline-closemenu-navbar-a")
            .on("click.holteronline-closemenu-navbar-a", (evt) => {
                NavbarSchliessen();
            });

        // Event für Benutzerprofilanzeige anhängen
        $j(Konstanten.ProfilButtonSelector)
            .off("click.holteronline-profilbutton")
            .on("click.holteronline-profilbutton", () => {
                $j(Konstanten.ProfilButtonSelector).toggleClass("active");
                $j(Konstanten.ProfilContainerSelector).toggleClass("open");
            });

        // Klick-Event Barecodescanner - Api Authentifizierung (http Header)
        $j("[data-ho-scanner-button]")
            .off("click.holteronline")
            .on("click.holteronline", (ereignis: JQuery.TriggeredEvent) => {
                ereignis.preventDefault();
                ereignis.stopPropagation();

                // Element, auf das geklickt worden ist, ermitteln
                let linkelem = $j(ereignis.currentTarget);

                // Element für weitere Klicks sperren
                linkelem.attr("disabled", "disabled");

                // Klick auf Element ist nach 1 Sekunde wieder zulässig
                setTimeout(() => {
                    $j(linkelem).removeAttr("disabled");
                }, 1000);

                HoMobileApi.SilentLogin()
                    .then(() => {
                        window.location.href = "/scanner?mobile=false";
                    })
                    .catch(() => {
                        AS400Layer.INSTANCE.LadeSeite(
                            new HoProgrammInfo({
                                Programm: Konstanten.FehlerProgramm,
                                Parameter: "&fnrht=14256",
                            }),
                            {
                                DialogAnzeige: true,
                            }
                        );
                    });
            });

        // Headerbereich initialisieren
        this.ResetContentPadding($layoutRoot);
        this.SetzeMenueHover();
        this.HolterMobileLinkSichtbarMachenWennZulaessig();

        // Beim Ändern der Fenstergröße Header neu rechnen
        $j(window)
            .off("resize.holteronline")
            .on("resize.holteronline", () => {
                this.ResetContentPadding($layoutRoot);
                this.SetzeMenueHover();
                this.ResetFixedHeader($layoutRoot);
            });

        // Beim öffnen des Browsertabs den aktuellen Kunden-Kontext mit der Session vergleichen.
        // Wenn nicht mehr aktuell, dann die Seite neu Laden
        // = Session/Kunden-Kontext wurde in anderem Tab gewechselt
        $j(window)
            .off("focus")
            .on("focus", () => {
                this.CheckKundenKontext();
            });

        // Fügt das "x" zum leeren der Inputs hinzu
        this.ClearButtonHinzufuegen();

        $j("#logout")
            .off("click.holteronline-logout")
            .on("click.holteronline-logout", () => {
                HoMobileApi.Logout();
            });
    }

    /**
     * Schließt den Profilbereich, falls dieser geöffnet ist
     */
    public SchliesseProfil() {
        $j(Konstanten.ProfilButtonSelector).removeClass("active");
        $j(Konstanten.ProfilContainerSelector).removeClass("open");
    }

    /**
     * Diese Methode aktualisiert den Seiteninhalt mit den übergebenen Daten.
     * @param options Eigenschaften für das Aktualisieren des Layouts
     */
    public Aktualisieren($layoutRoot: JQuery, options: IAS400LayerDataObject) {
        if ($j(Konstanten.ProgrammDatenRahmenSelector, $layoutRoot).length) {
            ScrollToolbox.INSTANCE.ScrollPositionZuruecksetzen($layoutRoot);

            const $box = $j(
                Konstanten.ProgrammDatenRahmenSelector,
                $layoutRoot
            );

            this.Zuruecksetzen($box);

            $box.children().off();

            $box.off()
                .empty()
                .append(options.Data ?? "");

            const settings: IAS400LayerSettings = options.Settings || {
                TableStateWiederherstellen: false,
            };

            this.InitLayout($layoutRoot, {
                ProgrammName: options.ProgrammInfo.Programm,
                ModalAnzeige: false,
                TableStateWiederherstellen: settings.TableStateWiederherstellen,
            });

            Powersuche.INSTANCE.Aktualisieren($layoutRoot);

            const fensterTitel: string = this.ErmittleFenstertitel();

            Historie.INSTANCE.NeuerEintrag(options.ProgrammInfo, fensterTitel);
            document.title = fensterTitel;

            if (!options.ProgrammInfo.Programm.startsWith("O4008")) {
                Powersuche.INSTANCE.SetzeKategorie(
                    $j(
                        Konstanten.PowersucheKategorieHiddenFieldId
                    ).val() as string
                );
            }

            FokusToolbox.INSTANCE.SetzeFokus($layoutRoot);
        } else {
            AS400Layer.INSTANCE.LadeSeite(
                new HoProgrammInfo({
                    Programm: Konstanten.FehlerProgramm,
                    Parameter: Konstanten.FehlerNummer_Standardfehler,
                }),
                {
                    DialogAnzeige: true,
                }
            );
        }
    }

    /**
     * Alle notwendigen Layout initialisierungen durchführen.
     * @param options Parameter für Durchführung der Methode
     */
    public InitLayout(
        $layoutRoot: JQuery,
        options: {
            /**
             * Gibt den Namen des Programms an, für welches das Layout initialisiert wird
             */
            ProgrammName: string;
            /**
             * Gibt an, ob das aktuelle Programm als Modal dargestellt wird
             */
            ModalAnzeige?: boolean;
            /**
             * Definiert, ob das aktuelle Programm als Toast angezeigt wird.
             */
            ToastAnzeige?: boolean;
            /**
             * Gibt an, ob beim Initialisieren des Layouts der Zustand einer Tabelle aus einem vorherigen Aufruf wiederhergestellt wird.
             */
            TableStateWiederherstellen?: boolean;
            /**
             * Ziel für das Ausführen von layoutrelevanten Events, wenn dieses von [[$layoutRoot]] abweicht
             */
            $eventTargetRoot?: JQuery;
        }
    ): void {
        // Achtung: Reihenfolge der Init-Events spielt eine Rolle! Ist bewusst so gewählt
        this.InitLoadContainer($layoutRoot);
        this.InitPopover($layoutRoot);
        this.InitSlider($layoutRoot);
        this.InitTooltips($layoutRoot);
        this.InitButtons($layoutRoot, options.$eventTargetRoot); // siehe [1]
        this.InitDatePicker($layoutRoot);
        this.InitFloatingScroll($layoutRoot);
        this.InitDataTables(
            $layoutRoot,
            options.TableStateWiederherstellen || false
        );

        StickyHeaderFooter.InitStickyness($layoutRoot);

        this.InitButtons($layoutRoot, options.$eventTargetRoot); // siehe [1]
        if (options.ModalAnzeige) {
            // Infobox im Modal initialisieren
            this.InitInfo(
                $layoutRoot,
                Konstanten.InfoButtonModalSelector,
                Konstanten.InfoBoxModalSelector
            );
        } else if (!options.ToastAnzeige) {
            this.InitInfo($layoutRoot);
        }
        this.InitInputToggles($layoutRoot);

        Autocomplete.INSTANCE.InitAutocomplete($layoutRoot);
        Validierung.INSTANCE.InitValidierungen($layoutRoot);
        FokusToolbox.INSTANCE.InitFokusFunktionen($layoutRoot);

        if (options.ToastAnzeige) {
            FokusToolbox.INSTANCE.SetzeFokus($layoutRoot);
        }

        SeitenspezifischeFunktionen.INSTANCE.PruefeLayout(
            options.ProgrammName,
            $layoutRoot
        );

        // Wenn bei Initialisierung auf einen bestimmten Punkt gescrollt werden soll, dann dorthin scrollen
        if ($j("[data-ho-scrollto-onload]", $layoutRoot).length) {
            const $scrollTarget = $j(
                $j("[data-ho-scrollto-onload]", $layoutRoot)
                    .first()
                    .attr("data-ho-scrollto-onload") ?? ""
            );

            if ($scrollTarget?.length) {
                ScrollToolbox.INSTANCE.SetzeScrollPosition(
                    $layoutRoot,
                    $scrollTarget
                );
            }
        }

        this.ResetContentPadding($layoutRoot);
        this.HolterMobileLinkSichtbarMachenWennZulaessig();

        // [1]
        //  Für Buttons in der DataTable selbst muss die Initialisierung
        //  vor dem Anwenden der Pagination ausgeführt werden
        //  weil sonst nur die Buttons der ersten Seite initialisiert werden.
        //  Aber es gibt in einzelnen Seiten Buttons, die nach dem Initialisieren
        //  der DataTable (initComplete) manuell in den DataTable-Bereich
        //  hinzugefügt werden - Diese können erst nach InitDataTables initialisiert werden.
    }

    /**
     * Initialisiert das Layout für einen Teil-Container der Seite, wenn keine vollständige Initialisierung erwünscht ist.
     */
    public InitBereichLayout(
        $layoutRoot: JQuery,
        settings?: {
            TabellenInitialisieren?: boolean;
            FokusNeuSetzen?: boolean;
        }
    ): void {
        this.InitLoadContainer($layoutRoot);
        this.InitEvents($layoutRoot);
        this.InitSlider($layoutRoot);
        this.InitTooltips($layoutRoot);
        this.InitButtons($layoutRoot);

        if (settings?.TabellenInitialisieren) {
            this.InitDataTables($layoutRoot, false);
            this.InitButtons($layoutRoot);
        }

        Validierung.INSTANCE.InitValidierungen($layoutRoot);

        if (settings?.FokusNeuSetzen) {
            FokusToolbox.INSTANCE.SetzeFokus($layoutRoot);
        }
    }

    /**
     * Leert den gesamten Seiteninhalt. Es werden alle notwendigen Bereinigungsaufgaben ausgeführt.
     */
    public Zuruecksetzen($layoutRoot: JQuery): void {
        // Tooltips ausblenden
        StatischeMethoden.VersteckeTooltips($layoutRoot);
        StatischeMethoden.EntferneAlleToasts();

        // Alle bestehenden DataTables vernichten
        try {
            // Parameter api:true gibt an, dass API-Instanzen zurückgegeben werden sollen
            let tables: DataTables.Api = ($j.fn as any).dataTable.tables({
                api: true,
            });

            // Durch alle gefundenen Instanzen suchen
            _.forEach((tables as any).context, (item: any) => {
                if (item) {
                    // Prüfen ob gefundenes Element in den zu entfernenden Layoutcontainer gehört
                    if ($layoutRoot.find(item.nTable).length > 0) {
                        // Element ist Child des zu verwerfenden Layoutcontainers -> entfernen
                        // item ist hier ein API-Settings-Objekt
                        item._fixedHeader.enable(false);
                        item.oInstance.fnDestroy(true);
                    }
                }
            });
        } catch (err) {
            console.log(
                "Fehler bei layout.ts : Zuruecksetzen - DataTable kann nicht destroyed werden"
            );
            console.error(err);
        }
    }

    /**
     * Ermittelt den Fenstertitel, der angezeigt wird.
     */
    public ErmittleFenstertitel(): string {
        // Fenstertitel ermitteln
        let FensterTitel: string = Konstanten.FensterNameStandardTitel;

        // Variablen für zusätzlichen Fensternamen
        let Bereich: string | undefined = $j(
            Konstanten.FensterNameBereichId
        ).html();
        let Titel: string | undefined = $j(
            Konstanten.FensterNameTitelId
        ).html();

        // Wenn gefunden zusätzliche Fensternamen hinzufügen
        if (Bereich && Bereich.trim().length > 0) {
            FensterTitel += " - " + Bereich.trim();
        }
        if (Titel && Titel.trim().length > 0) {
            FensterTitel += " - " + Titel.trim();
        }

        return FensterTitel;
    }

    /**
     * Ermittelt den serialisierbaren Inhalt der UI
     */
    public GetSerializableUiComponents($serializeRoot?: JQuery): JQuery {
        let $serializableItems = $j(
            Konstanten.ProgrammDatenRahmenSelector,
            $serializeRoot
        )
            .find(Konstanten.ProgrammDatenSerialisierungTypen)
            .not((idx, elem) => {
                // Zum Ermitteln der aktuellen Dateninhalte einer DataTable sind API Calls erforderlich.
                // Deshalb werden Elemente aus DataTables hier weggefiltert,
                // und später durch API Call Code hinzugefügt.
                return $j(elem).parents(".dataTable").length !== 0;
            });

        $j(".dataTable", $serializeRoot).each((idx, dt) => {
            new $.fn.dataTable.Api($j(dt))
                .rows()
                .nodes()
                .each((row) => {
                    $serializableItems = $serializableItems.add(
                        $j(row).find(
                            Konstanten.ProgrammDatenSerialisierungTypen
                        )
                    );
                });
        });

        return $serializableItems;
    }

    /**
     * Berechnet den Header-Offset und gibt ihn zurück.
     */
    public HeaderOffsetBerechnen($layoutRoot: JQuery): number {
        return this.GetHeaderOffset($layoutRoot);
    }

    /**
     * Initialisiert alle Varianten von Tooltips
     * @description
     * Mittels data-ho-toggle="tooltip" (oder "tooltip_hover") wird angegeben,
     * dass auf dem entsprechenden Element ein Tooltip angehängt werden soll.
     * @tutorial C:\Projekte\HolterOnline\htmlsrc\muster_tooltip.html
     */
    private InitTooltips($layoutRoot: JQuery): void {
        // Tooltips nur initialisieren wenn kein Touch-Gerät
        if (Browsertyp.WieDesktop) {
            // Tooltips für Contentbereich initialisieren
            $j('[data-ho-toggle*="tooltip"]', $layoutRoot).each((idx, elem) => {
                $j(elem).tooltip({
                    trigger: "hover",
                    boundary: "viewport" as any,
                });
            });
            const wrapperspan = $j("#share-icon-wrapper-span", $layoutRoot);
            const iconspan = $j("#share-icon-wrapper");
            if (wrapperspan.length && iconspan.length) {
                wrapperspan.first()[0].addEventListener("mouseover", () => {
                    iconspan.first().tooltip("show");
                });
                wrapperspan.first()[0].addEventListener("mouseout", () => {
                    iconspan.first().tooltip("hide");
                });
            }
        }

        // Es gibt bestimmte Tooltips, die nur für Touch-Geräte onclick aktiviert werden
        if (Browsertyp.WieTouchscreen) {
            $j('[data-ho-toggle*="tooltip_touch"]', $layoutRoot).each(
                (idx, elem) => {
                    let $elem = $j(elem);

                    // Tooltip mit Konfiguration initialisieren
                    $elem.tooltip({
                        trigger: "click",
                    });
                }
            );
        }
    }

    /**
     * Ermittelt die Höhe des Headers
     */
    private GetHeaderOffset($layoutRoot: JQuery): number {
        return $j("header", $layoutRoot)?.outerHeight() ?? 0;
    }

    /**
     * Lädt den Inhalt eines Container aus einer fremden Quelle.
     */
    private InitLoadContainer($layoutRoot: JQuery) {
        $j("[data-ho-load-content='']", $layoutRoot).each((idx, elem) => {
            $j(elem)
                .empty()
                .attr("data-ho-load-content", "inProgress")
                .load($j(elem).data("ho-load-content-path"))
                .attr("data-ho-load-content", "loaded");
        });
    }

    /**
     * Initialisiert Schaltflächen in der Seite
     * @param $layoutRoot Quelle, in der nach zu initialisierenden Elementen gesucht wird
     * @param $eventTargetRoot Ziel für das Ausführen von layoutrelevanten Events
     */
    private InitButtons($layoutRoot: JQuery, $eventTargetRoot?: JQuery): void {
        /**
         * Bereitet die Settings für den Network Request bzw. für die Verarbeitung der Antwort vor
         * @param linkelem Element, aus dem die Konfiguration ausgelesen wird
         */
        let prepareSettings = (
            linkelem: JQuery<EventTarget>
        ): IAS400LayerSettings => {
            let modalCallback: IHoProgrammInfo | undefined;

            if (linkelem.data("ho-dialog-callback")) {
                modalCallback = {
                    Programm: linkelem.data("ho-dialog-callback"),
                    Parameter: linkelem.data("ho-dialog-callback-params"),
                };
            }

            return {
                DialogAnzeige: linkelem.data("ho-dialog"),
                modalCallback,
                modalCallbackAlsDialog: linkelem.data(
                    "ho-dialog-callback-dialog"
                ),
                BlockContainerSelector: linkelem.data("ho-block"),
                ModalSchliessen: linkelem.data("ho-modal-close"),
                ForDownload: linkelem.data("ho-fordownload"),
                ToastAnzeige: linkelem.data("ho-toast"),
            };
        };

        // Funktion für LadeSeite-Events
        let LadeSeiteDurchfuehren = (ereignis: JQuery.TriggeredEvent) => {
            // Element, auf das geklickt worden ist, ermitteln
            let linkelem = $j(ereignis.currentTarget);

            // Aufrufparameter auslesen
            let ProgrammInfo = new HoProgrammInfo({
                Programm: linkelem.data("ho-pgmn"),
                Parameter: linkelem.data("ho-params"),
            });

            let Settings: IAS400LayerSettings = prepareSettings(linkelem);

            if (
                (!ProgrammInfo.Programm || ProgrammInfo.Programm === "") &&
                linkelem.prop("href")
            ) {
                // Alternativmethode: Versuche über href das Programm zu finden
                ProgrammInfo.ProgrammAufruf = linkelem.prop("href");
            }

            if (
                ProgrammInfo.Programm === "O4828W" &&
                StatischeMethoden.PruefeObModalGeoffnet() &&
                StatischeMethoden.aktivesModal
            ) {
                // Sonderfall:
                // Wenn ein Artikelbild angezeigt wird, und bereits ein Modal geöffnet ist,
                // dann soll nach dem Schließen des Artikelbildes das vorherige Modal wieder angezeigt werden
                Settings.modalCallback =
                    StatischeMethoden.aktivesModal.modalSettings.programmInfo;
                Settings.modalCallbackAlsDialog = true;
            }

            ereignis.preventDefault();

            if (linkelem.data("ho-stoppropagination") !== false) {
                ereignis.stopPropagation();
            }

            const $dNaechsteSeite = $j.Deferred();

            $dNaechsteSeite.done(() =>
                AS400Layer.INSTANCE.LadeSeite(ProgrammInfo, Settings)
            );

            if (
                Historie.INSTANCE.ErmittleAktuelleSeite() ===
                    KonstantenWarenkorb.ProgrammWarenkorb &&
                !KonstantenWarenkorb.ErlaubteWechselProgramme.includes(
                    ProgrammInfo.Programm
                )
            ) {
                DataTableFunktionen.INSTANCE.DragDropOpenChangesHandler(
                    $eventTargetRoot ?? $layoutRoot,
                    new HoProgrammInfo({
                        Programm:
                            KonstantenWarenkorb.ProgrammWarenkorbSpeichern,
                        AjaxDaten: `butt=speichern&${this.GetSerializableUiComponents(
                            $eventTargetRoot ?? $layoutRoot
                        ).serialize()}`,
                    })
                )
                    .done(() => $dNaechsteSeite.resolve())
                    .catch($dNaechsteSeite.reject);
            } else {
                $dNaechsteSeite.resolve();
            }
        };

        // Globales Klick-Event definieren für eigene Links
        $j("[data-ho-funktion='ladeseite_click']", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis: JQuery.TriggeredEvent) => {
                // Element, auf das geklickt worden ist, ermitteln
                let linkelem = $j(ereignis.currentTarget);

                // Element für weitere Klicks sperren
                linkelem.attr("disabled", "disabled");

                // Klick auf Element ist nach 1 Sekunde wieder zulässig
                setTimeout(() => {
                    $j(linkelem).removeAttr("disabled");
                }, 1000);

                // Eigentliche Logik ausführen
                LadeSeiteDurchfuehren(ereignis);
            });

        // jQuery-Change-Event für LadeSeite
        $j("[data-ho-funktion='ladeseite_change']", $layoutRoot)
            .off("change.holteronline")
            .on("change.holteronline", LadeSeiteDurchfuehren);

        // Funktion für Submit-Events
        let SubmitDurchfuehren = (ereignis: JQuery.TriggeredEvent) => {
            let linkelem = $j(ereignis.currentTarget);

            let pgmn: string = linkelem.data("ho-pgmn");
            let butt: string = linkelem.data("ho-butt");
            let ajaxDaten: string | undefined;

            let $serializeRoot = $eventTargetRoot ?? $layoutRoot;

            let serializeBlockSelector: string | undefined =
                linkelem.data("ho-serialize-block");

            if (serializeBlockSelector?.length) {
                $serializeRoot = $j(serializeBlockSelector);
            }

            const $serializerResult =
                this.GetSerializableUiComponents($serializeRoot);

            ajaxDaten = $serializerResult.serialize();

            const linkDataParams = linkelem.data("ho-params");
            if (linkDataParams) {
                ajaxDaten += `&${linkDataParams}`;
            }

            let settings: IAS400LayerSettings = prepareSettings(linkelem);

            AS400Layer.INSTANCE.InhaltSubmit(
                new HoProgrammInfo({
                    Programm: pgmn,
                    ButtonName: butt,
                    AjaxDaten: ajaxDaten,
                }),
                settings
            ).done((statusInformation) => {
                if (statusInformation.FrontendTextboxenLeeren) {
                    $serializerResult.filter("input[type=text]").val("");
                }
            });

            ereignis.preventDefault();
            ereignis.stopPropagation();
        };

        let SubmitWKPruefungDurchfuehren = (
            ereignis: JQuery.TriggeredEvent
        ) => {
            // Element, auf das geklickt worden ist, ermitteln
            let linkelem = $j(ereignis.currentTarget);
            let ajaxDaten: string | undefined;

            ajaxDaten = linkelem.data("ho-o4089-params");

            AS400Layer.INSTANCE.SendePostRequest(
                new HoProgrammInfo({
                    Programm: "O4089R",
                    AjaxDaten: ajaxDaten,
                }),
                {}
            ).done((statusInformation) => {
                console.log("INFO: ", statusInformation);
                if (statusInformation === "TRUE") {
                    // Wenn O4089R "TRUE" liefert, heißt es, dass der WK passt!
                    // Artikel können sofort in den WK hinzugefügt werden
                    SubmitDurchfuehren(ereignis);
                } else {
                    AS400Layer.INSTANCE.LadeSeite(
                        new HoProgrammInfo({
                            Programm: "O4093W",
                            Parameter: ajaxDaten,
                        }),
                        {
                            DialogAnzeige: true,
                        }
                    );
                }
            });
        };

        // Globales Klick-Event definieren für Button-Submits
        $j("[data-ho-funktion='submit_click']", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis: JQuery.TriggeredEvent) => {
                // Element, auf das geklickt worden ist, ermitteln
                let linkelem = $j(ereignis.currentTarget);

                // Element für weitere Klicks sperren
                linkelem.attr("disabled", "disabled");

                // Klick auf Element ist nach 1 Sekunde wieder zulässig
                setTimeout(() => {
                    $j(linkelem).removeAttr("disabled");
                }, 1000);

                // Eigentliche Logik ausführen
                SubmitDurchfuehren(ereignis);
            });

        // jQuery-Change-Event für Submits
        $j("[data-ho-funktion='submit_change']", $layoutRoot)
            .off("change.holteronline")
            .on("change.holteronline", SubmitDurchfuehren);

        // Event für Enter-Tasten-Klick für Controls in Seite (stellt sicher das der aktive Warenkorb für den Beleg gültig ist)
        $j(
            '[data-ho-funktion="submit_to_valid_cart_on_keypress_enter"]',
            $layoutRoot
        )
            .off("keypress.holteronline")
            .on("keypress.holteronline", (ereignis) => {
                if (KeyEvent.isEnter(ereignis)) {
                    SubmitWKPruefungDurchfuehren(ereignis);
                }
            });

        // Event für Enter-Tasten-Klick für Controls in Seite
        $j('[data-ho-funktion="submit_keypress_enter"]', $layoutRoot)
            .off("keypress.holteronline")
            .on("keypress.holteronline", (ereignis) => {
                if (KeyEvent.isEnter(ereignis)) {
                    SubmitDurchfuehren(ereignis);
                }
            });

        // Funktion für Submit-Events / Fileupload
        let FileSubmitDurchfuehren = (ereignis) => {
            // Element, auf das geklickt worden ist, ermitteln
            let linkelem = $j(ereignis.currentTarget);

            // Aufrufparameter auslesen
            let ProgrammInfo: HoProgrammInfo = new HoProgrammInfo({
                Programm: linkelem.data("ho-pgmn"),
                CallJsAnhaengen: true,
            });

            let formData: FormData = new FormData();
            let fileInput = $j(
                "input[type='file']",
                $layoutRoot
            )[0] as HTMLInputElement;

            if (!fileInput?.files?.length) {
                console.warn("Keine Datei gefunden!");
                return;
            }

            let file: File = fileInput.files[0];
            formData.append("fileht", file, file.name);

            $.ajax({
                url: ProgrammInfo.ProgrammAufruf,
                data: formData,
                type: "POST",
                cache: false,
                contentType: false,
                processData: false,
            })
                .done((data) => {
                    AS400Layer.INSTANCE.VerarbeiteAjaxDone({
                        Data: data,
                        ProgrammInfo: new HoProgrammInfo({
                            Programm: "O4035W",
                        }),
                    });
                })
                .fail(() => {
                    AS400Layer.INSTANCE.VerarbeiteAjaxDone({
                        ProgrammInfo: new HoProgrammInfo({
                            Programm: Konstanten.FehlerProgramm,
                        }),
                    });
                });

            // Standardverhalten des Link-Click verhindern
            ereignis.preventDefault();
            ereignis.stopPropagation();
        };

        // Globales Klick-Event definieren für eigene Links und stellt sicher, dass der aktive Warenkorb für den Beleg gültig ist
        $j("[data-ho-funktion='submit_to_valid_cart_on_click']", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis: JQuery.TriggeredEvent) => {
                SubmitWKPruefungDurchfuehren(ereignis);
            });

        // Globales Klick-Event definieren für Button-Submits
        $j("[data-ho-funktion='filesubmit_click']", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", FileSubmitDurchfuehren);

        // filedialog initialisieren
        $j("input[type='file']", $layoutRoot)
            .off("change.holteronline")
            .on("change.holteronline", (ereignis) => {
                let file = ereignis.target;

                if (file !== null && file !== undefined) {
                    let fileName = ($j(file).val() as string).split("\\").pop();

                    if (fileName !== undefined && fileName !== null) {
                        $j(file)
                            .siblings("label")
                            .children("span")
                            .first()
                            .html(fileName);
                        $j(file)
                            .siblings('input[type="hidden"]')
                            .val($j(file).val() as string);
                    }
                }
            });

        // Seite drucken
        $j("[data-ho-funktion='printpage_click']", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis) => {
                window.print();

                // Standardverhalten des Click verhindern
                ereignis.preventDefault();
                ereignis.stopPropagation();
            });

        // Bestimmten Layoutcontainer drucken
        $j("[data-ho-funktion='printthis_click']", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis) => {
                let printContainerId: string = $j(ereignis.target).data(
                    "ho-printthis-containerid"
                );

                if (printContainerId?.length) {
                    // Falls in printContainerId kein # angegeben ist hinzufügen
                    if (!printContainerId.startsWith("#")) {
                        printContainerId = `#${printContainerId.trim()}`;
                    }

                    // Angegebenen Container drucken
                    (
                        $j(ereignis.target).parents(printContainerId) as any
                    ).printThis({
                        debug: Debug.PrintThis_Debugmodus,
                        importCSS: true,
                        importStyle: false,
                        printContainer: false,
                        removeInline: true,
                    });
                } else {
                    // An Fehlerseite weiterleiten
                    // "Ausdrucken des Dialogfensters nicht möglich"
                    AS400Layer.INSTANCE.LadeSeite(
                        new HoProgrammInfo({
                            Programm: Konstanten.FehlerProgramm,
                            Parameter: "FNRHT=13158",
                        }),
                        {
                            DialogAnzeige: true,
                        }
                    );
                }

                // Standardverhalten des Click verhindern
                ereignis.preventDefault();
                ereignis.stopPropagation();
            });

        $j("[data-ho-funktion='browser_back_click']", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis) => {
                // im Browser zurücknaivigieren
                history.back();

                // Standardverhalten des Click verhindern
                ereignis.preventDefault();
                ereignis.stopPropagation();
            });

        // Buttons für aktuelle URL ins Clipboard stellen
        $j("[data-ho-funktion='clipboard_click']", $layoutRoot).each(
            (idx, elem) => {
                new Clipboard(elem, {
                    container: $layoutRoot ? $layoutRoot[0] : undefined,
                    text: (trigger) => {
                        let clipboardTyp = $j(trigger).data("ho-clipboard-typ");

                        new Message(
                            '<div id="O4BSTHead"><h3 id="ho_titel">Clipboard</h3></div><p>In Zwischenablage kopiert</p>'
                        ).InitMarkup("clipboard");

                        if (clipboardTyp === "url") {
                            // URL der Seite in Clipboard stellen
                            return window.location.href;
                        } else {
                            // Standardverhalten des Clipboard-Buttons nachimplementieren
                            return $j(trigger).data("clipboard-text");
                        }
                    },
                });
            }
        );

        // Buttons für Versenden per E-Mail
        $j("[data-ho-funktion='mailto_click']", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis) => {
                let mailtoTyp = $j(ereignis.currentTarget).data(
                    "ho-mailto-typ"
                );

                if (mailtoTyp === "url") {
                    window.location.href =
                        "mailto:?body=" +
                        encodeURIComponent(window.location.href);
                }
            });

        // Buttons für das Öffnen eines Oxomi-Katalogs
        $j('[data-ho-funktion="opencatalog_click"]', $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis) => {
                // Katalog öffnen
                Katalog.INSTANCE.OeffneKatalog(
                    $j(ereignis.currentTarget).data("ho-catalog"),
                    $j(ereignis.currentTarget).data("ho-page")
                );
            });

        // Menge zu einem Input-Value hinzuzählen onclick
        $j("[data-ho-funktion='addmenge_click']", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis) => {
                let linkNummer = $j(ereignis.currentTarget);
                let txtTarget = $j(linkNummer.data("ho-target"));
                let justSetValue = !!linkNummer.data("ho-addmenge-just-set");

                if (txtTarget.length) {
                    // Inhalt des Target auslesen
                    let targetValue =
                        parseFloat(
                            (txtTarget.val() as string)
                                .replace(".", "")
                                .replace(",", ".")
                        ) * 1;

                    if (isNaN(targetValue)) {
                        // Wenn keine Zahl ermittelt werden konnte, dann 0 annehmen
                        targetValue = 0;
                    }

                    // Es gilt der Beistrich als Kommastelle, nicht der Punkt
                    // Als Kommastelle für die Berechnung muss aber ein Punkt anstelle des Beistrichs angegeben werden
                    let linkValue = parseFloat(
                        linkNummer.html().replace(".", "").replace(",", ".")
                    );

                    targetValue = justSetValue
                        ? linkValue
                        : targetValue + linkValue;

                    // Berechneten Wert als neuen Wert setzen
                    // Wert runden, damit das Ergebnis stimmt (JavaScript rechnet Nummern binär, was zu falschen Ergebnissen führt)
                    // Punkte durch Beistriche ersetzen, damit Businesslogik damit klarkommt
                    txtTarget.val(targetValue.toFixed(2).replace(".", ","));

                    // Change-Event am Target absetzen (TFS#2426)
                    txtTarget.trigger("change");
                } else {
                    console.error(
                        "Ziel zum Hinzufügen der Menge nicht gefunden"
                    );
                }

                // Standardverhalten des Click verhindern
                ereignis.preventDefault();
                ereignis.stopPropagation();
            });

        // Scroll-Buttons initialisieren
        $j("[data-ho-scrollto]", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis) => {
                const $scrollTarget = $j(
                    $j(ereignis.target).attr("data-ho-scrollto") ?? ""
                );

                if ($scrollTarget?.length) {
                    ScrollToolbox.INSTANCE.SetzeScrollPosition(
                        $layoutRoot,
                        $scrollTarget
                    );
                }

                // Standardverhalten des Click verhindern
                ereignis.preventDefault();
                ereignis.stopPropagation();
            });
    }

    /**
     * Initialisiert die Funktion, dass beim Click auf eine Box ein Radiobutton umgeschalten wird
     */
    private InitInputToggles($layoutRoot: JQuery): void {
        $j('[data-ho-toggle="radio"]', $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis) => {
                let radioId: string = $j(ereignis.target).data("ho-radio-id");

                if (radioId?.length) {
                    // ID zu einem jQuery ID Selector machen
                    radioId = "#" + radioId;
                    // Checked des Radiobuttons umschalten
                    $j(radioId, $layoutRoot).prop("checked", true);
                }
            });

        $j('[data-ho-toggle="checkbox"]', $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis) => {
                let checkboxId: string = $j(ereignis.target).data(
                    "ho-checkbox-id"
                );

                if (checkboxId?.length) {
                    // ID zu einem jQuery ID Selector machen
                    let checkBox = $j("#" + checkboxId, $layoutRoot);
                    // Checked der Checkbox umschalten
                    checkBox.prop("checked", !checkBox.is(":checked"));
                }
            });
    }

    /**
     * Initialisiert alle Datepicker-Steuerelemente
     */
    private InitDatePicker($layoutRoot: JQuery): void {
        if ($j('[data-ho-toggle="datepicker"]', $layoutRoot).length) {
            // Datepicker initialisieren
            $j('[data-ho-toggle="datepicker"]', $layoutRoot).datepicker({
                language: "de",
                format: "yyyy.mm.dd",
                autoclose: true,
                todayHighlight: true,
                orientation: "left bottom",
            });
        }
    }

    /**
     * Initialisiert floatingScroll für Container, wo es angegeben ist
     */
    private InitFloatingScroll($layoutRoot: JQuery): void {
        $j("[data-ho-floatingscroll]", $layoutRoot).each((index, elem) => {
            $j(elem).floatingScroll();
        });
    }

    /**
     * Initialisiert alle Popovers
     * @see https://getbootstrap.com/docs/5.0/components/popovers/#events bezüglich Events am Popover
     */
    private InitPopover($layoutRoot: JQuery): void {
        // Alle Popovers initialisieren
        $j('[data-ho-toggle~="popover"]', $layoutRoot).each((index, elem) => {
            // Holter-Data-Attribute separat zulassen in popover
            let hoCustomWhiteList: any = ($.fn.tooltip as any).Constructor
                .Default.allowList;
            hoCustomWhiteList.table = [];
            let regexHolterDataAttributes = /^data-ho-[\w-]+/;
            hoCustomWhiteList["*"].push(regexHolterDataAttributes);

            let popover = $j(elem);
            // popover.tooltip("dispose"); // Remove tooltip if there is a popover needed
            popover.popover({
                placement: "bottom",
                title: $j(elem).data("ho-popover-title"),
                content: $j(elem).data("ho-popover-content"),
                html: true,
                allowList: hoCustomWhiteList,
            });

            popover.on("shown.bs.popover", (event) => {
                // Buttons initialisieren im Popover
                Layout.INSTANCE.InitButtons(
                    $j(`#${$j(elem).attr("aria-describedby")}`)
                );

                let htmlTarget = document.getElementById(
                    $j(event.target).attr("aria-describedby") as string
                ) as HTMLElement;
                if (!htmlTarget) {
                    console.warn("Popover target not found");
                    return;
                }
                let $popoverTarget = $j(htmlTarget);
                $j(document).one(
                    KonstantenBenutzerdefinierteEvents.AjaxVorbereitungenGestartet,
                    (evt) => {
                        $popoverTarget.popover("hide");
                    }
                );
                // Select the Popover and find all popover-rows (Links/Buttons inside)
                // Close the Popover if one of them is clicked
                $j("#" + $j(elem).attr("aria-describedby"))
                    .find(".popover-row")
                    .on("click", (evt) => {
                        $popoverTarget.popover("hide");
                    });
            });
        });
    }

    /**
     * Initialisiert alle Slider
     */
    private InitSlider($layoutRoot: JQuery): void {
        $j('[data-ho-toggle="slider"]', $layoutRoot).each((index, elem) => {
            let $slider = $j(elem).slider();

            let $minTextbox: JQuery | undefined;
            let $maxTextbox: JQuery | undefined;

            let minTextboxSelector = $slider.attr("data-ho-slider-min-textbox");
            if (minTextboxSelector?.length) {
                $minTextbox = $j(minTextboxSelector, $layoutRoot);
            }

            let maxTextboxSelector = $slider.attr("data-ho-slider-max-textbox");
            if (maxTextboxSelector?.length) {
                $maxTextbox = $j(maxTextboxSelector, $layoutRoot);
            }

            /**
             * Befüllt anhand der in den Textboxen vorhandenen Werte die Slider-Werte
             */
            let setzeSliderGrenzenAusTextbox = () => {
                let minWert: number = 0;
                let maxWert: number = 0;

                let sliderWert = $slider.slider("getValue");

                let decimalPlaces: number = 0;

                let decimalPlacesHelper = ($slider.data("slider-step") || "")
                    .toString()
                    .split(".");
                if (decimalPlacesHelper.length === 2) {
                    decimalPlaces = decimalPlacesHelper[1].length;
                }

                let sliderMinGrenze = parseFloat(
                    $slider.attr("data-slider-min") ?? ""
                );
                let sliderMaxGrenze = parseFloat(
                    $slider.attr("data-slider-max") ?? ""
                );

                if ($minTextbox?.length) {
                    minWert = parseFloat(($minTextbox.val() || "").toString());
                } else {
                    // Min-Wert aus Slider ermitteln
                    minWert = parseFloat(sliderWert[0] + "");
                }

                if (minWert < sliderMinGrenze) {
                    // Kleiner als Min-Wert ist ungültig
                    minWert = sliderMinGrenze;
                }

                if ($maxTextbox?.length) {
                    maxWert = parseFloat(($maxTextbox.val() || "").toString());
                } else {
                    // Max-Wert aus Slider ermitteln
                    maxWert = parseFloat(sliderWert[1] + "");
                }

                if (maxWert > sliderMaxGrenze) {
                    // Größer als Max-Wert ist ungültig
                    maxWert = sliderMaxGrenze;
                }

                if (minWert > maxWert) {
                    // Values swappen
                    let dummyWert = maxWert;
                    maxWert = minWert;
                    minWert = dummyWert;
                }

                minWert = parseFloat(minWert.toFixed(decimalPlaces));
                maxWert = parseFloat(maxWert.toFixed(decimalPlaces));

                // Ergebnis in Slider übertragen
                $slider.slider("setValue", [minWert, maxWert]);

                // Textbox-Eingaben auch zurückschreiben, falls Min und Max jetzt getauscht sind
                if ($minTextbox?.length) {
                    $minTextbox.val(minWert);
                }

                if ($maxTextbox?.length) {
                    $maxTextbox.val(maxWert);
                }
            };

            /**
             * Befüllt anhand der im Slider vorhandenen Werte die Textbox-Werte
             */
            let setzeSliderGrenzenAusSlider = () => {
                let sliderValue = $slider.slider("getValue");

                if ($minTextbox?.length) {
                    // Wert an Stelle 0 = ausgewählter Min-Wert
                    $minTextbox.val(sliderValue[0] + "");
                }

                if ($maxTextbox?.length) {
                    // Wert an Stelle 1 = ausgewählter Max-Wert
                    $maxTextbox.val(sliderValue[1] + "");
                }
            };

            // Beim Ändern der Slider-Grenzen ausgewählte Werte in Textboxen übernehmen
            $slider
                .off("change.holteronline-slider")
                .on("change.holteronline-slider", setzeSliderGrenzenAusSlider);

            if ($minTextbox?.length) {
                // Events für Slider-Grenzen-Aktualisierung anhängen
                $minTextbox
                    .off("change.holteronline-slider")
                    .on(
                        "change.holteronline-slider",
                        setzeSliderGrenzenAusTextbox
                    );
                $minTextbox
                    .off("blur.holteronline-slider")
                    .on(
                        "blur.holteronline-slider",
                        setzeSliderGrenzenAusTextbox
                    );
                $minTextbox
                    .off("keypress.holteronline-slider")
                    .on("keypress.holteronline-slider", (ereignis) => {
                        if (KeyEvent.isEnter(ereignis)) {
                            setzeSliderGrenzenAusTextbox();
                        }
                    });
            }

            if ($maxTextbox?.length) {
                // Events für Slider-Grenzen-Aktualisierung anhängen
                $maxTextbox
                    .off("change.holteronline-slider")
                    .on(".holteronline-slider", setzeSliderGrenzenAusTextbox);
                $maxTextbox
                    .off("blur.holteronline-slider")
                    .on(
                        "blur.holteronline-slider",
                        setzeSliderGrenzenAusTextbox
                    );
                $maxTextbox
                    .off("keypress.holteronline-slider")
                    .on("keypress.holteronline-slider", (ereignis) => {
                        if (KeyEvent.isEnter(ereignis)) {
                            setzeSliderGrenzenAusTextbox();
                        }
                    });
            }
        });
    }

    /**
     * Initialisiert den Infobereich
     */
    private InitInfo(
        $layoutRoot: JQuery,
        InfoButtonSelector?: string,
        InfoBoxSelector?: string
    ): void {
        // Variablen für mehrfach verwendete Strings in Konstanten

        if (!InfoButtonSelector) {
            InfoButtonSelector = Konstanten.InfoButtonSelector;
        }

        if (!InfoBoxSelector) {
            InfoBoxSelector = Konstanten.InfoBoxSelector;
        }

        let $infoButton = $j(InfoButtonSelector, $layoutRoot);
        let $infoBox = $j(InfoBoxSelector, $layoutRoot);

        let boxOeffnen = () => {
            let curHeight = $infoBox.height() as number;
            let autoHeight = $infoBox.css("height", "auto").height();

            $infoBox
                .height(curHeight)
                .stop()
                .addClass("open")
                .animate(
                    {
                        height: autoHeight,
                    },
                    {
                        duration: 500,
                        step: () => {
                            $infoButton.tooltip("update");
                        },
                        complete: this.ResetFixedHeader.bind(this),
                    }
                );
        };
        let boxSchliessen = () => {
            $infoBox
                .stop()
                .removeClass("open")
                .animate(
                    { height: "0" },
                    {
                        duration: 500,
                        step: () => {
                            $infoButton.tooltip("update");
                        },
                        complete: this.ResetFixedHeader.bind(this),
                    }
                );
        };

        if ($infoButton.length) {
            $infoButton
                .off("click.holteronline")
                .on("click.holteronline", (ereignis) => {
                    if ($infoBox.height() === 0) {
                        boxOeffnen();
                    } else {
                        boxSchliessen();
                    }

                    // Standardverhalten des Click verhindern
                    ereignis.preventDefault();
                    ereignis.stopPropagation();
                });

            // Prüfen ob Info oninit geöffnet sein soll
            if (
                $j(InfoButtonSelector + '[data-ho-offen="true"]', $layoutRoot)
                    .length
            ) {
                // Wenn noch nicht offen, dann öffnen
                if ($infoBox.height() === 0) {
                    boxOeffnen();
                }
            }
        }
    }

    /**
     * Initialisiert Tabellen im $layoutRoot dieser Klasseninstanz.
     * @param TableStateWiederherstellen Gibt an, ob die Tabelle zuvor automatisch gespeicherte Zustände wiederherstellen darf.
     */
    private InitDataTables(
        $layoutRoot: JQuery,
        TableStateWiederherstellen: boolean
    ): void {
        DataTableFunktionen.INSTANCE.InitDataTable(
            $layoutRoot,
            TableStateWiederherstellen
        );
    }

    /**
     * Berechnet die Position des FixedHeaders neu
     */
    private ResetFixedHeader($layoutRoot: JQuery): void {
        _.forEach(
            window[Konstanten.DataTablesWindowArrayName],
            (dt: DataTables.Api) => {
                if (dt.hasOwnProperty("fixedHeader")) {
                    try {
                        let header = (dt as any).fixedHeader;
                        let offset = this.GetHeaderOffset($layoutRoot);
                        header.headerOffset(offset);
                        header.footerOffset(0);
                        header.adjust();
                    } catch (err) {
                        console.info(
                            "Layout::ResetFixedHeader raised an error",
                            err
                        );
                    }
                }
            }
        );
    }

    /**
     * Initialisiert die Headerlogik
     */
    private ResetContentPadding($layoutRoot: JQuery): void {
        // Header-Höhe berechnen
        let offset = this.GetHeaderOffset($layoutRoot);
        if (offset > 0) {
            $j(".content").css("padding-top", offset);
        }
    }

    /**
     * Führt die notwendige Logik für das Menü onhover aus.
     * Bei Nicht-Touch-fähigen Geräten wird onhover ein click simuliert.
     * Bei Touch-Geräten werden die entsprechenden Events entfernt.
     */
    private SetzeMenueHover(): void {
        const $navbar = $j(".navbar");
        if (Browsertyp.WieDesktop) {
            // Wenn kein Touch-Device dann die click-Action deaktivieren
            $j("nav .dropdown-toggle", $navbar)
                .off("click.holteronline-navbar-dropdown-toggle")
                .on("click.holteronline-navbar-dropdown-toggle", (ereignis) => {
                    ereignis.stopPropagation();
                });

            // Suche die Dropdown-Items und füge hover Effekt an innerhalb der Navbar:
            const topnavitems = $j(".nav-item", $navbar);
            topnavitems.each((index, menuepunkt) => {
                $j(".dropdown-toggle", menuepunkt).each((indexDdt, elem) => {
                    $j(menuepunkt).on("mouseenter", (evt) =>
                        $j(elem).dropdown("show")
                    );
                    $j(menuepunkt).on("mouseleave", (evt) =>
                        $j(elem).dropdown("hide")
                    );
                });
            });
        } else {
            // Bei Touch Devices die Click-Events wieder entfernen
            $j("nav .dropdown-toggle", $navbar).off(
                "click.holteronline-navbar-dropdown-toggle"
            );

            // Auch die simulierten Click-Events entfernen
            $j(".nav-item.dropdown", $navbar).off("mouseenter mouseleave");
        }
    }

    /**
     * Fügt die Clearbuttons in die Mengenfelder ein
     */
    private ClearButtonHinzufuegen() {
        $j(".text-with-clear")
            .off("input.input-with-clear change.input-with-clear")
            .on("input.input-with-clear change.input-with-clear", (e) => {
                if ($j(e.currentTarget).val()) {
                    $j(e.currentTarget).parent().addClass("btnclear-visible");
                } else {
                    $j(e.currentTarget)
                        .parent()
                        .removeClass("btnclear-visible");
                }
            });
    }

    /**
     * Prüft, ob der Link auf HOLTER Mobile anzuzeigen ist, und macht ihn sichtbar, wenn das der Fall ist.
     */
    private HolterMobileLinkSichtbarMachenWennZulaessig() {
        if (Browsertyp.WieMobilesGeraet) {
            $j("[data-ho-mobile-link]").removeClass("d-none");
        }
    }

    /**
     * Prüft der aktuelle Kundenkontext mit der Session übereinstimmt.
     * Wenn nicht, wird HolterOnline neu geladen.
     */
    private CheckKundenKontext() {
        let ajaxDaten: string | undefined;

        ajaxDaten =
            "teilht=" +
            $j("#tn").val() +
            "&kdnrht=" +
            $j("#kdnr").val() +
            "&oidaht=" +
            $j("#oida").val();

        AS400Layer.INSTANCE.SendePostRequest(
            new HoProgrammInfo({
                Programm: "O4998R",
                AjaxDaten: ajaxDaten,
            }),
            {
                LadeanimationDeaktivieren: true,
            }
        ).done((statusInformation) => {
            // Wenn O4998R "TRUE" liefert, heißt es, dass der aktuelle
            // Kundenkontext zur Session passt!
            // Bei False gibt es eine Abweichung und die
            // Seite muss akutalisiert werden.
            if (statusInformation === "FALSE") {
                AS400Layer.INSTANCE.LadeSeite(
                    new HoProgrammInfo({
                        Programm: "O4001W",
                    }),
                    {}
                );
            }
        });
    }
}
