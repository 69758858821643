/**
 * Beinhaltet konstante Werte für Klasse [[DataTableFunktionen]]
 */
export class KonstantenDataTableFunktionen {
    /**
     * Standard-Dom
     * @see https://datatables.net/examples/basic_init/dom.html
     */
    public static readonly DomDefault =
        "<'row dataTable_content'<'col-md-12'tr>><'row dataTable_buttons justify-content-start'><'row dataTable_footer'<'col-md-4'i><'col-md-4 text-center'l><'col-md-4'p>>";

    /**
     * Dom mit sichtbarer Filtertextbox
     * @see https://datatables.net/examples/basic_init/dom.html
     */
    public static readonly DomFilter =
        "<'row dataTable_header content-filter text-end'f><'row dataTable_content'<'col-md-12'tr>><'row dataTable_buttons dataTable_variantenFooter'><'row dataTable_footer'<'col-md-4'i><'col-md-4 text-center'l><'col-md-4'p>>";

    /**
     * Dom mit manueller Pagination
     * @see https://datatables.net/examples/basic_init/dom.html
     */
    public static readonly DomPagination = "";

    /**
     * Deutschsprachige Texte
     */
    public static readonly LanguageGerman = {
        decimal: ",",
        emptyTable: "Keine Daten vorhanden.",
        info: "Zeige _START_ bis _END_ von _TOTAL_ Eintr&auml;gen",
        infoEmpty: "Zeige 0 bis 0 von 0 Eintr&auml;gen",
        infoFiltered: "(gefiltert von _MAX_ Eintr&auml;gen)",
        infoPostFix: "",
        thousands: ".",
        lengthMenu: "Zeige _MENU_ Eintr&auml;ge",
        loadingRecords: "Laden...",
        processing: "Daten werden geladen...",
        search: "Tabelle filtern",
        zeroRecords: "Keine Daten vorhanden.",
        paginate: {
            first: "<<",
            last: ">>",
            next: ">",
            previous: "<",
        },
        aria: {
            sortAscending: ": aufsteigend sortieren",
            sortDescending: ": absteigend sortieren",
        },
    };

    /**
     * Gibt die deutschsprachigen Menü-Definitionen an
     */
    public static readonly LengthMenuGerman = [
        [25, 50, 100, -1],
        [25, 50, 100, "Alle"],
    ];

    /**
     * Gibt ein Length-Menu ohne Auswahl für alle Tupel an
     */
    public static readonly LengthMenuOhneAlle = [
        [25, 50, 100],
        [25, 50, 100],
    ];

    /**
     * Gibt ein Length-Menu mit mehr Optionen aber ohne "alle"!
     */
    public static readonly LengthMenuMoreOptions = [
        [25, 50, 100, 500, 1000],
        [25, 50, 100, 500, 1000],
    ];

    /**
     * Gibt das LengthMenu an, wenn nur der Modus "Alle" verfügbar sein soll.
     */
    public static readonly LengthMenuNurAlleGerman = [[-1], ["Alle"]];

    /**
     * Gibt an, wieviele Einträge standardmäßig auf einer Pagination-Seite angezeigt werden.
     */
    public static readonly DefaultPageLength: number = 50;

    /**
     * Gibt den Namen des Flags an, das bei Verwendung von DragDrop-Funktionalität
     * zur Statusüberwachung, ob es offene Änderungen in der Tabelle gibt,
     * verwendet wird.
     */
    public static readonly DragDropOpenChangesFlagName: string =
        "data-ho-table-dragdrop-openchanges";

    /**
     * Programm für die Anzeige eines Rückfrage-Modals,
     * ob ungespeicherte Änderungen übernommen werden sollen
     */
    public static readonly ProgrammUngespeicherteAenderungen = "O4017W";
}
