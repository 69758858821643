// tslint:disable:comment-format
///<amd-dependency path="dataTables.fixedColumns" />
///<amd-dependency path="dataTables.rowReorder" />
// tslint:enable:comment-format

import * as $j from "jquery";
import { filter } from "underscore";
import { Artikelsuche } from "./artikelsuche";
import { AS400Layer } from "./as400Layer";
import { Browsertyp } from "./browsertyp";
import { DataTableAnwendungsTyp } from "./enums/DataTableAnwendungsTyp";
import { DataTableFunktionenDomTyp } from "./enums/DataTableFunktionenDomTyp";
import { KeyEvent } from "./events/keyEvent";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { IDataTableFunktionenSettings } from "./interfaces/IDataTableFunktionenSettings";
import { Konstanten } from "./konstanten/konstanten";
import { KonstantenDataTableFunktionen } from "./konstanten/konstantenDataTableFunktionen";
import { Layout } from "./layout";
import { ScrollToolbox } from "./scrollToolbox";
import { StatischeMethoden } from "./statischeMethoden";

/**
 * Stellt alle Funktionen bereit, die im Zusammenhang mit DataTables stehen.
 */
export class DataTableFunktionen {
    public static readonly INSTANCE = new DataTableFunktionen();

    private constructor() {}

    /**
     * Handler zum Überprüfen, ob es ungespeicherte Änderungen in einer Drag-and-Drop-Tabelle gibt.
     * @param submitProgammZumSpeichern HoProgrammInfo-Objekt mit den Programmdaten für das Speichern der Daten
     * @returns JQuery.Deferred, welches auflöst, sobald die übrige Programmlogik fortgesetzt werden kann
     */
    public DragDropOpenChangesHandler(
        $layoutRoot: JQuery,
        submitProgammZumSpeichern: HoProgrammInfo
    ): JQuery.Deferred<void> {
        const $dArtikelsuche = $j.Deferred();

        if (
            !$j("[data-ho-table-dragdrop]", $layoutRoot).attr(
                KonstantenDataTableFunktionen.DragDropOpenChangesFlagName
            )
        ) {
            $dArtikelsuche.resolve();
            return $dArtikelsuche;
        }

        AS400Layer.INSTANCE.LadeSeite(
            new HoProgrammInfo({
                Programm:
                    KonstantenDataTableFunktionen.ProgrammUngespeicherteAenderungen,
            }),
            {
                DialogAnzeige: true,
            }
        )
            .done((SpeichernAbfrageModal) => {
                SpeichernAbfrageModal.onModalClose
                    ?.done((Speichern) => {
                        if (Speichern) {
                            AS400Layer.INSTANCE.InhaltSubmit(
                                submitProgammZumSpeichern,
                                { Silent: true }
                            ).always(() => $dArtikelsuche.resolve());
                        } else {
                            $dArtikelsuche.resolve();
                        }
                    })
                    .catch(() => $dArtikelsuche.reject());
            })
            .catch(() => $dArtikelsuche.reject());

        return $dArtikelsuche;
    }

    /**
     * Initialisiert alle Events für die Datatable.
     */
    public InitEvents(
        $layoutRoot: JQuery,
        table: DataTables.Api,
        tableId?: string
    ) {
        // Globale Filter-Textbox, falls vorhanden, initialisieren
        let $filter = $j("#filter", $layoutRoot);
        if ($filter.length && filter.length > 0) {
            $filter.on("keyup search input paste cut", () => {
                table.search($filter.val() as string).draw();
            });
        } else {
            // erstes filter objekt vor der Tabelle:
            $j(`#${tableId}`)
                .parents(".content-table")
                .siblings(".content-filter")
                .find(".filter-input")
                .on("keyup search input paste cut", (event) => {
                    table.search($j(event.target).val() as string).draw();
                });
        }

        // Alle Mengen alle in Input-Values übernhemen
        $j("[data-ho-funktion='addmenge_all_click']", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis) => {
                let tableid =
                    ".table" +
                    (ereignis.currentTarget.id
                        ? ereignis.currentTarget.id
                        : "");

                let $table = $j(
                    $j(tableid, $layoutRoot)
                        .DataTable()
                        .cells({ search: "applied" })
                        .nodes()
                );

                if (
                    $j(ereignis.currentTarget).attr("data-ho-alle-modus") ===
                    "befuellen"
                ) {
                    $j(ereignis.currentTarget)
                        .attr("data-original-title", "alle leeren")
                        .attr("data-ho-alle-modus", "leeren")
                        .addClass("inverted");

                    // alle Felder befüllen
                    $j.each(
                        $table.find("[data-ho-funktion='addmenge_click']"),
                        (index, elem) => {
                            $table
                                .find($j(elem).data("ho-target"))
                                .val(
                                    parseFloat(
                                        $j(elem)
                                            .html()
                                            .replace(".", "")
                                            .replace(",", ".")
                                    )
                                        .toFixed(2)
                                        .replace(".", ",")
                                )
                                .trigger("change");
                        }
                    );
                } else {
                    $j(ereignis.currentTarget)
                        .attr("data-original-title", "alle übernehmen")
                        .attr("data-ho-alle-modus", "befuellen")
                        .removeClass("inverted");

                    // alle Felder leeren
                    $j.each(
                        $table.find("[data-ho-funktion='addmenge_click']"),
                        (index, elem) => {
                            $table
                                .find($j(elem).data("ho-target"))
                                .val("")
                                .trigger("change");
                        }
                    );
                }
            });

        // Clear Button onclick target leeren lassen
        $j("[data-ho-suchtextbox-leeren]", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", (ereignis) => {
                $j($j(ereignis.currentTarget).data("ho-target"))
                    .val("")
                    .trigger("change");
            });

        // In Tabellen gibt es bei Checkboxspalten eine Header-Checkbox, die alle Checkboxen umschaltet
        $j("table .check-all", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", function () {
                $j(
                    $j("table", $layoutRoot)
                        .DataTable()
                        .cells({ search: "applied" })
                        .nodes()
                )
                    .find("input:checkbox")
                    .not(this)
                    .prop("checked", (this as HTMLInputElement).checked);
            });

        // In manchen Tabellen gibt es mehrere Header-Checkboxen, die alle Checkboxen der jeweiligen Spalte umschaltet
        $j("table .check-column", $layoutRoot)
            .off("click.holteronline")
            .on("click.holteronline", function () {
                $j(
                    $j("table", $layoutRoot)
                        .DataTable()
                        .cells({ search: "applied" })
                        .nodes()
                )
                    .find(
                        "input:checkbox[data-ho-table-check=" +
                            $j(this).attr("data-ho-table-check") +
                            "]"
                    )
                    .not(this)
                    .prop("checked", (this as HTMLInputElement).checked);
            });

        // Beim Paging der Tabelle die Scroll-Position der Seite zum Tabellen-Header stellen
        table.off("page.dt").on("page.dt", () => {
            // Pagination der Tabelle wurde geändert -> Scroll to top

            // Aktuell fokussiertes Element "ent-fokussieren"
            // aktuell ist die Pagination-Zeile fokussiert
            // -> wenn der Fokus nicht bereinigt wird, dann scrollt die Ansicht nach dem Table-Load automatisch wieder zur Pagination
            $j(document.activeElement!).trigger("blur");

            ScrollToolbox.INSTANCE.SetzeScrollPosition(
                $layoutRoot,
                table.tables().nodes().to$()
            );
        });
    }

    /**
     * Initialisiert alle DataTables auf der Seite
     * @param stateWiederherstellen true, wenn der vorherige Status der Tabelle wiederhergestellt werden soll
     */
    public InitDataTable($layoutRoot: JQuery, stateWiederherstellen: boolean) {
        $j(".table", $layoutRoot).each((index, tableObject) => {
            let headerOuterHeight =
                Layout.INSTANCE.HeaderOffsetBerechnen($layoutRoot);

            let paging: boolean =
                $j(tableObject).data("ho-table-paging") !== false;

            let fixedheader: boolean =
                Browsertyp.WieDesktop &&
                !($j(tableObject).data("ho-table-fixedheader") === false);

            let fixedfooter: boolean =
                Browsertyp.WieDesktop &&
                $j(tableObject).data("ho-table-fixedfooter") === true;

            let fixedColumns: boolean | DataTables.FixedColumnsSettings =
                $j(tableObject).data("ho-table-fixedcolumns") === true;

            if (fixedColumns) {
                fixedColumns = true;

                // Prüfen ob eine Anzahl von linken oder rechten Spalten angegeben ist
                let leftColumns: number | undefined = $j(tableObject).data(
                    "ho-table-fixedcolumns-left"
                );
                let rightColumns: number | undefined = $j(tableObject).data(
                    "ho-table-fixedcolumns-right"
                );

                if (leftColumns || rightColumns) {
                    // Links oder rechts angegeben -> fixedColumns überschreiben
                    fixedColumns = {
                        leftColumns,
                        rightColumns,
                        heightMatch: "auto",
                    };
                }
            }

            let scrollX: boolean =
                $j(tableObject).data("ho-table-scroll-x") === true;

            // seitenspezifisch festgelegte Anzahl der Tabelleneinträge pro Seite ermitteln
            let pageLength: number | undefined = $j(tableObject).data(
                "ho-table-page-length"
            );

            let lengthMenu: Array<Array<string | number>> | undefined;

            if ($j(tableObject).data("ho-table-lengthmenu-type") === "alle") {
                lengthMenu =
                    KonstantenDataTableFunktionen.LengthMenuNurAlleGerman;
            }

            if ($j(tableObject).attr("data-ho-table-SSP") === "O4830R.PGM") {
                lengthMenu =
                    KonstantenDataTableFunktionen.LengthMenuMoreOptions;
            }

            // Wenn die Anzahl der Tabelleneinträge pro Seite nicht seitenspezifisch festgelegt wurde
            // -> Persönliche Einstellung übernehmen
            if (pageLength === undefined) {
                pageLength = Number($j("#datatable-pagelength").val());
            }

            let stateSave: boolean =
                $j(tableObject).data("ho-table-statesave") === true;

            // DOM-Typ für Filterung herausfinden
            let domTyp: DataTableFunktionenDomTyp;

            switch ($j(tableObject).data("ho-table-domtyp")) {
                case "filterundfooteralike":
                    domTyp = DataTableFunktionenDomTyp.FilterUndFooterAlike;
                    break;
                case "withoutpagination":
                    domTyp = DataTableFunktionenDomTyp.WithoutPagination;
                    break;
                default:
                    domTyp = DataTableFunktionenDomTyp.Default;
                    break;
            }

            let anwendungsTyp: DataTableAnwendungsTyp;

            if (
                $j(tableObject).attr("data-ho-table-anwendung") ===
                "artikelsuche"
            ) {
                anwendungsTyp = DataTableAnwendungsTyp.Artikelsuche;
            } else if (
                $j(tableObject).attr("data-ho-table-anwendung") === "warenkorb"
            ) {
                anwendungsTyp = DataTableAnwendungsTyp.Warenkorb;
            } else if ($j(tableObject).attr("data-ho-table-SSP-verw")?.length) {
                anwendungsTyp = DataTableAnwendungsTyp.ServerSideProcessing;
            } else {
                anwendungsTyp = DataTableAnwendungsTyp.Default;
            }

            const baseProps: IDataTableFunktionenSettings = {
                tableObject,
                headerheight: headerOuterHeight,
                paging,
                fixedheader,
                fixedfooter,
                fixedColumns,
                scrollX,
                domTyp,
                pageLength,
                lengthMenu,
                anwendungsTyp,
            };

            const DtApi =
                this.GetServerSideProcessing($layoutRoot, baseProps) ??
                this.InitTable(
                    tableObject,
                    this.GetInitSettings($layoutRoot, {
                        ...baseProps,
                        stateSave,
                        stateWiederherstellen,
                    })
                );

            const tableId = $j(tableObject).attr("id");

            if (DtApi) {
                this.InitEvents($layoutRoot, DtApi, tableId);
                this.InitMoreInfoItems($layoutRoot);

                if ($j(tableObject).attr("data-ho-table-dragdrop") === "true") {
                    this.InitDragDropEvents(DtApi, tableId);
                }

                Layout.INSTANCE.InitBereichLayout($layoutRoot);
            }
        });
    }

    /**
     * Initialisiert DataTable Server Side Processing, wenn für die angegebene Tabelle relevant
     * @returns
     * [[DataTables.Api]] Instanz, wenn Server Side Processing aktiviert worden ist
     * [[undefined]], wenn reguläre Instanziierung notwendig ist
     */
    private GetServerSideProcessing(
        $layoutRoot: JQuery,
        settings: IDataTableFunktionenSettings
    ): DataTables.Api | undefined {
        if (
            settings.anwendungsTyp ===
            DataTableAnwendungsTyp.ServerSideProcessing
        ) {
            settings.SSPpgm = $j(settings.tableObject).attr(
                "data-ho-table-SSP"
            ) as string;

            let SSPpgmverw: string | undefined = $j(settings.tableObject).attr(
                "data-ho-table-SSP-verw"
            );

            settings.processing = true;
            settings.serverSide = true;
            settings.fetchcol = true;
            settings.reinit = true;

            let stdsettings = this.GetInitSettings($layoutRoot, settings);

            // Verwendung Artikelsuche (erfordert spezielle Parameter)
            let dt = $j(settings.tableObject).DataTable(stdsettings);

            dt.on("draw.dt", () => {
                if (SSPpgmverw !== undefined) {
                    this.InitDraw($layoutRoot, SSPpgmverw, dt);
                }
            });

            // Button zum neu Zeichnen der Datatable
            $j("#refreshSSP", $layoutRoot)
                .off("click.holteronline")
                .on("click.holteronline", () => {
                    dt.columns.adjust().draw();
                });

            return dt;
        } else if (
            settings.anwendungsTyp === DataTableAnwendungsTyp.Artikelsuche
        ) {
            settings.processing = true;
            settings.serverSide = true;
            settings.fetchcol = true;
            settings.reinit = true;

            let stdsettings = this.GetInitSettings($layoutRoot, settings);

            // Verwendung Artikelsuche (erfordert spezielle Parameter)
            let dt = $j(settings.tableObject).DataTable(stdsettings);

            // Event anhängen, das beim DataTable-Draw ausgeführt wird
            let artikelSuche: Artikelsuche = new Artikelsuche($layoutRoot);
            artikelSuche.InitialisiereDataTable(dt);

            return dt;
        }

        return undefined;
    }

    /**
     * Hängt die Draw Events für die Datatable an
     * @param verwendung frei wählbarer String der Angibt ob zusätliche initialisierungen notendig sind
     * @param dt Api Instanz der Datatable auf der die Events hängen sollen
     */
    private InitDraw(
        $layoutRoot: JQuery,
        verwendung: string,
        dt: DataTables.Api
    ) {
        // Button der Draw event auslösen soll
        $j("[data-ho-table-SSP-refresh]")
            .off("click.holteronline")
            .on("click.holteronline", () => {
                dt.draw();
            });

        // Alle Parameter können auch mit "Keypress enter" ausgelöst werden
        $j("[data-ho-serverside-parm]")
            .off("keypress.holteronline")
            .on("keypress.holteronline", (ereignis) => {
                if (KeyEvent.isEnter(ereignis)) {
                    dt.draw();
                }
            });

        // Zusätzliche initialisierungen
        switch (verwendung) {
            // Kommissionen
            case "KOMMED":
            case "KOMMJS":
                break;
        }

        // Buttons neu initialisieren
        Layout.INSTANCE.InitBereichLayout($layoutRoot);
    }

    /**
     * Ajax Settings für Datatable mit Serverside = true definieren
     * @param programm Programmname
     * @param calltype POST oder GET
     */
    private InitAjaxSettings(
        programm: string | undefined,
        calltype: string
    ): JQueryAjaxSettings {
        let ajaxSettings: JQueryAjaxSettings;
        if (programm !== undefined) {
            ajaxSettings = {
                url: programm,
                type: calltype,
                data: (d: any) => {
                    d.suche = $j("#artikelsuche").val();
                    d.filter = $j("#filtercret").val();
                    let object: object = {};
                    $j('[data-ho-serverside-param!=""]').each(function () {
                        switch ($j(this).data("ho-serverside-param")) {
                            case "check":
                                if ($j(this).prop("checked")) {
                                    object[
                                        $j(this).attr(
                                            "data-ho-serverside-param-name"
                                        ) ?? ""
                                    ] = $j(this).val();
                                }
                                break;
                            case "string":
                                if ($j(this).val() !== undefined) {
                                    object[
                                        $j(this).attr(
                                            "data-ho-serverside-param-name"
                                        ) ?? ""
                                    ] = $j(this).val();
                                }
                                break;
                        }
                    });
                    $j.extend(d, object);
                },
            };
        } else {
            ajaxSettings = {};
        }
        return ajaxSettings;
    }

    /**
     * Initialisiert Sub-Elemente der Tabelle, die erweitert werden können.
     */
    private InitMoreInfoItems($layoutRoot: JQuery) {
        const CHILD_TAG = "data-ho-table-moreinfo-child";

        $j("[data-ho-table-moreinfo-click]", $layoutRoot)
            .off("click.holteronline-dataTable")
            .on("click.holteronline-dataTable", (event) => {
                const $row = $j(event.currentTarget).closest("tr");
                const apiRow = $j(event.currentTarget)
                    .parents("table")
                    .DataTable()
                    .row($row);

                $j(".dataTable")
                    .DataTable()
                    .row($j(event.currentTarget).closest("tr"));

                if (apiRow.child.isShown()) {
                    $row.removeClass("shown");

                    $j(`[${CHILD_TAG}]`, apiRow.child()).slideUp(() => {
                        apiRow.child.hide();
                    });
                } else {
                    const $content = $j("[data-ho-table-moreinfo-click]", $row)
                        .children()
                        .clone()
                        .attr(CHILD_TAG, "true")
                        .addClass("dataTable-detailTable");

                    apiRow.child($content).show();

                    $row.addClass("shown");

                    $j(`[${CHILD_TAG}]`, apiRow.child()).slideDown();

                    Layout.INSTANCE.InitBereichLayout($layoutRoot);
                }
            });
    }

    /**
     * Initialisiert Drag- und Drop-Events für die Tabelle
     * @warn Darf nur ausgeführt werden, wenn die Tabelle Drag- und Drop-Funktionalität enthält
     */
    private InitDragDropEvents(tableApi: DataTables.Api, tableId?: string) {
        const setDragDropOpenChangedFlag = () =>
            $j(`#${tableId}`).attr(
                KonstantenDataTableFunktionen.DragDropOpenChangesFlagName,
                "true"
            );

        const reorderRows = () => {
            const $table = $j(`#${tableId}`);
            const savedInputValues = new Map<string, any>();

            $j("input[name=mge]", $table).each((i, e) => {
                if (e.dataset.hoDragdropAmountInput) {
                    savedInputValues.set(
                        e.dataset.hoDragdropAmountInput,
                        $j(e).val()
                    );
                }
            });

            StatischeMethoden.VersteckeTooltips($table);

            let sortNumber = 0;
            tableApi.rows().every((i) => {
                const rowData = tableApi.row(i).data();
                ++sortNumber;
                rowData[0] = sortNumber;
                tableApi.row(i).data(rowData);
            });

            savedInputValues.forEach((v, k) => {
                if (k) {
                    $j(`[data-ho-dragdrop-amount-input="${k}"]`).val(v);

                    // Es wird die Liste von Keys zum Abgleichen der Sortierreihenfolgen benötigt.
                    $j(`[data-ho-dragdrop-sort-input="${k}"]`).val(
                        $j(`[data-ho-dragdrop-sort-cell="${k}"]`).html()
                    );
                }
            });
        };

        tableApi.on("order.dt", () => {
            reorderRows();
            setDragDropOpenChangedFlag();
        });

        tableApi.on("row-reordered.dt", () => {
            setDragDropOpenChangedFlag();
            tableApi.order([0, "asc"]);
        });

        $j("input", $j(`#${tableId}`))
            .off("input.validate.holteronline-cart")
            .on("input.validate.holteronline-cart", () =>
                setDragDropOpenChangedFlag()
            );
    }

    /**
     * Ajax Settings für Datatable mit Serverside = true definieren
     * @param programm Programmname
     * @param calltype POST oder GET
     */
    private InitTable(
        tableObject: HTMLElement,
        settings: DataTables.Settings
    ): DataTables.Api | undefined {
        // Standardinitialisierung der Datatable (Clientseitige Verarbeitung)
        let table = $j(tableObject).DataTable(settings);

        window[Konstanten.DataTablesWindowArrayName] =
            window[Konstanten.DataTablesWindowArrayName] || [];
        window[Konstanten.DataTablesWindowArrayName].push(table);

        return table;
    }

    /**
     * Standardeinstellungen der Datatable definieren
     * @param settings Gibt die Einstellungen für die Initialisierung an
     * @param headerheight höhe des Headers
     * @param paging Paging einschalten
     * @param fixedheader Definiert ob header fixed
     * @param fixedfooter Definiert ob Footer fixed
     * @param fixedColumns Definiert ein Objekt vom Typ [[DataTables.FixedColumnsSettings]] das die Konfiguration für [[datatables.net-fixedcolumns]] angibt
     * @param processing Wenn true wird bei jeder aktion der Datatable ein event ausgelöst
     * @param serverSide Definiert ob die Datatable über AJAX daten von einem Server lädt
     * @param fetchcol definiert ob die Spaltennamen aus Array kommen oder default sind
     * @param SSPpgm Programm dass als schnittstelle für Serverside dient
     * @param reinit Wenn datatable schon vorhanden, dannn wird diese Gelöscht und die neue Erstellt
     */
    private GetInitSettings(
        $layoutRoot: JQuery,
        settings: IDataTableFunktionenSettings
    ): DataTables.Settings {
        // Zu verwendenden DOM bestimmen
        let domVerwenden: string = KonstantenDataTableFunktionen.DomDefault;
        // Vorübergehend sloppy gelöst über Prufung auf FixedColumns
        if (
            settings.domTyp === DataTableFunktionenDomTyp.FilterUndFooterAlike
        ) {
            domVerwenden = KonstantenDataTableFunktionen.DomFilter;
        } else if (
            settings.domTyp === DataTableFunktionenDomTyp.WithoutPagination
        ) {
            domVerwenden = KonstantenDataTableFunktionen.DomPagination;
        }

        settings.rowReorder = undefined;

        if (settings.anwendungsTyp === DataTableAnwendungsTyp.Warenkorb) {
            settings.rowReorder = { selector: ".reorder" };
        }

        let set: DataTables.Settings = {
            rowReorder: settings.rowReorder,
            processing: settings.processing === true,
            serverSide: settings.serverSide === true,
            destroy: settings.reinit === true,
            renderer: "bootstrap",
            paging: settings.paging,
            stateSave: settings.stateSave,
            stateSaveCallback: (
                stateSaveSettings: DataTables.SettingsLegacy,
                dtData
            ) => {
                sessionStorage.setItem(
                    stateSaveSettings.sTableId,
                    JSON.stringify(dtData)
                );
            },
            stateLoadCallback: (stateLoadSettings) => {
                try {
                    if (settings.stateWiederherstellen) {
                        let filter = JSON.parse(
                            sessionStorage.getItem(
                                stateLoadSettings.sTableId
                            ) ?? ""
                        );
                        $j("#filter").val(filter.search.search || "");
                        return filter;
                    } else {
                        return null;
                    }
                } catch (e) {}
            },
            info: settings.paging,
            pageLength:
                settings.pageLength ??
                KonstantenDataTableFunktionen.DefaultPageLength,
            lengthMenu:
                settings.lengthMenu ??
                KonstantenDataTableFunktionen.LengthMenuGerman,
            fixedHeader: {
                header: settings.fixedheader,
                footer: settings.fixedfooter,
                headerOffset: settings.headerheight,
                footerOffset: 0,
            },
            fixedColumns: settings.fixedColumns,
            scrollX: settings.scrollX || false,
            deferRender: true,
            columnDefs: [
                {
                    targets: ["no-sort"],
                    orderable: false,
                },
            ],
            order: [],
            language: KonstantenDataTableFunktionen.LanguageGerman,
            autoWidth: true,
            dom: domVerwenden,
            initComplete: (initCompleteSettings) => {
                $j(".dataTables_length")
                    .children()
                    .children("select")
                    .wrap('<div class="select-box"></div>');

                const $dataTableZusatz = $j(
                    `[data-ho-table-zusatz-id="#${initCompleteSettings.sTableId}"]`
                );

                if ($dataTableZusatz.length) {
                    const zusatzContent: string = $dataTableZusatz.html();

                    const extraClasses = `${
                        $dataTableZusatz.data("ho-shadow") === false
                            ? "no-shadow"
                            : "shadow"
                    } ${$dataTableZusatz.data("ho-extra-classes")}`.trim();

                    const makeStickyFooter =
                        Browsertyp.WieDesktop &&
                        $dataTableZusatz.data("ho-stickyfooter") === true;

                    $dataTableZusatz.remove();

                    $j(
                        `#${initCompleteSettings.sTableId}_wrapper div.dataTable_buttons`
                    )
                        .html(zusatzContent)
                        .addClass(extraClasses);

                    if (makeStickyFooter) {
                        $j(`#${initCompleteSettings.sTableId}_wrapper`)
                            .attr("data-ho-toggle", "sticky")
                            .attr(
                                "data-ho-sticky-body-selector",
                                "div.dataTable_content"
                            )
                            .attr("data-ho-sticky-header-selector", "")
                            .attr(
                                "data-ho-sticky-footer-selector",
                                "div.dataTable_buttons"
                            );
                    }

                    Layout.INSTANCE.InitBereichLayout(
                        $j(`#${initCompleteSettings.sTableId}_wrapper`)
                    );
                }
            },
            drawCallback: (drawCallbackSettings) => {
                if ($j("[data-ho-table-rowgroup]", $layoutRoot)) {
                    if (
                        drawCallbackSettings.nTFoot !== null &&
                        drawCallbackSettings.nTFoot !== undefined
                    ) {
                        let tableRows = drawCallbackSettings.nTFoot.childNodes;

                        $j(tableRows)
                            .filter("[data-ho-table-rowgroup]")
                            .each((rowIndex, row) => {
                                let anzahlSpalten = $j(row).children().length;
                                let zeilenInhalt = $j(row).children(
                                    "[data-ho-table-rowgroup-content]"
                                );
                                if (
                                    zeilenInhalt.attr("colspan") &&
                                    zeilenInhalt.attr("colspan") !== "1"
                                ) {
                                    anzahlSpalten = parseInt(
                                        zeilenInhalt.attr("colspan") ?? "",
                                        10
                                    );
                                }

                                $j(row).children().off();

                                $j(row)
                                    .off()
                                    .empty()
                                    .append(
                                        '<td data-ho-table-rowgroup-content="" colspan="' +
                                            anzahlSpalten +
                                            '">' +
                                            zeilenInhalt.html() +
                                            "</td>"
                                    );
                            });
                    }
                }

                Layout.INSTANCE.InitBereichLayout($layoutRoot);
                this.InitMoreInfoItems($layoutRoot);
            },
        };

        if (settings.anwendungsTyp === DataTableAnwendungsTyp.Artikelsuche) {
            // Spezial-Logik für die Artikelsuche ausführen
            let artikelSuche: Artikelsuche = new Artikelsuche($layoutRoot);
            set = artikelSuche.HoleDataTableSettings(set);

            // Bei der Artikelsuche darf es keine Pagination-Option "alle" geben
            set.lengthMenu = KonstantenDataTableFunktionen.LengthMenuOhneAlle;
        } else if (settings.SSPpgm) {
            set.ajax = this.InitAjaxSettings(
                settings.SSPpgm,
                "POST"
            ) as DataTables.AjaxSettings;
        }

        return set;
    }
}
