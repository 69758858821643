import * as $j from "jquery";

export const ArtikelsucheVersion2Templates = {
    get Lagerware(): string {
        return $j("#O4380_muster_lagerware").html();
    },

    StockIcons: {
        get InStock(): string {
            return $j("#O4380_stockicon_instock").html();
        },

        get OutOfStock(): string {
            return $j("#O4380_stockicon_outofstock").html();
        },

        get AvailableWithinTwoDays(): string {
            return $j("#O4380_stockicon_availablewithintwodays").html();
        },

        get InMainStock(): string {
            return $j("#O4380_stockicon_inmainstock").html();
        },

        get ProcurementItem(): string {
            return $j("#O4380_stockicon_procurementitem").html();
        },
    },

    get MeintenSie(): string {
        return $j("#O4380_muster_meintenSie").html();
    },

    get MeintenSieButton(): string {
        return $j("#O4380_muster_meintenSie_button").html();
    },

    get Aehnlich(): string {
        return $j("#O4380_aehnlich").html();
    },

    get AehnlichButton(): string {
        return $j("#O4380_aehnlich_button").html();
    },

    get WorkInProgressLayout(): JQuery {
        return $j(
            `<div class="artikelsuche">
                <div class="attribute-loader"></div>
            </div>`
        );
    },

    get EmptySearchTermLayout(): JQuery {
        return $j(
            `<div class="error-box">
                <span class="warning">
                    Bitte geben Sie einen Suchbegriff ein. 
                </span>
            </div>`
        );
    },

    get BaseLayoutForResults(): JQuery {
        return $j(
            `<div class="expanded-content artikelsuche" data-ho-artikelsuche-wrapper>
                <div class="start-side no-print" data-ho-artikelsuche-filter-wrapper>
                    <div id="O4380_artikelfilter">
                        <div class="text-end">
                            <div class="hide-filter" data-ho-artikelsuche-filter-ausblenden>
                                Filter
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="end-side" data-ho-artikelsuche-table-wrapper>
                    <div class="show-filter invisible" data-ho-artikelsuche-filter-anzeigen>
                        Filter
                    </div>
                    <div class="content-table" id="json">
                    </div>
                </div>
            </div>`
        );
    },

    get MakesNoSenseHint(): JQuery {
        return $j(
            `<div class="artikelsuche-info">
                <p>
                    <strong>Suchbegriff nicht eindeutig.</strong>
                    <br>
                    Ihr Suchbegriff konnte nicht eindeutig erkannt werden. 
                    Die hier angezeigten Artikel sind womöglich nicht das, wonach Sie gesucht haben.
                </p>
            </div>`
        );
    },

    get EmptyResultsHint(): JQuery {
        return $j(
            `<div class="artikelsuche-info">
                <p><strong>Zu Ihrem Suchbegriff konnten wir keine Treffer finden.</strong></p>
            </div>`
        );
    },

    get NotEnoughResultsHint(): JQuery {
        return $j(
            `<div class="artikelsuche-info">
            <p><strong>Die angegebene Seite ist nicht vorhanden.</strong></p>
          </div>`
        );
    },

    get Pagination(): string {
        return `
        <div class="row dataTable_footer">
            <div class="col-md-6">
                    <div class="dataTables_info" role="status" aria-live="polite">
                        Zeige %%start%% bis %%end%% von %%total%% Eintr&auml;gen
                    </div>
            </div>
            <div class="col-md-6">
                <div class="dataTables_paginate paging_simple_numbers" id="O4380_ergebnisliste_paginate">
                    <ul class="pagination">
                        <li class="paginate_button page-item previous %%enableprev%%" id="O4380_ergebnisliste_previous">
                            <a aria-controls="O4380_ergebnisliste" data-dt-idx="0" tabindex="0" class="page-link"  data-ho-load-prev-results>&lt;</a>
                        </li>
                        <li class="paginate_button page-item firstpage">
                            <a aria-controls="O4380_ergebnisliste" data-dt-idx="1" tabindex="0" class="page-link" data-ho-load-first-page>1</a>
                        </li>
                        <li class="paginate_button page-item disabled spacerprev">
                            <a aria-controls="O4380_ergebnisliste" data-dt-idx="3" tabindex="0" class="page-link">...</a>
                        </li>
                        <li class="paginate_button page-item prevpage">
                            <a aria-controls="O4380_ergebnisliste" data-dt-idx="4" tabindex="0" class="page-link" data-ho-load-prev-results> %%prevpage%%</a>
                        </li>
                        <li class="paginate_button page-item active">
                            <a aria-controls="O4380_ergebnisliste" data-dt-idx="5" tabindex="0" class="page-link">%%currpage%%</a>
                        </li>
                        <li class="paginate_button page-item nextpage">
                            <a aria-controls="O4380_ergebnisliste" data-dt-idx="6" tabindex="0" class="page-link" data-ho-load-next-results>%%nextpage%%</a>
                        </li>
                        <li class="paginate_button page-item disabled spacernext">
                            <a aria-controls="O4380_ergebnisliste" data-dt-idx="7" tabindex="0" class="page-link">...</a>
                        </li>
                        <li class="paginate_button page-item lastpage">
                            <a aria-controls="O4380_ergebnisliste" data-dt-idx="8" tabindex="0" class="page-link" data-ho-load-last-page>%%lastpage%%</a>
                        </li>
                        <li class="paginate_button page-item next %%enablenext%%" id="O4380_ergebnisliste_next">
                            <a aria-controls="O4380_ergebnisliste" data-dt-idx="9" tabindex="0" class="page-link" data-ho-load-next-results>&gt;</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>`;
    },
};
