import * as $j from "jquery";
import { AS400Layer } from "./as400Layer";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { Konstanten } from "./konstanten/konstanten";
import { Layout } from "./layout";

/**
 * Stellt Funktionen für das Befüllen und Aktualisieren des Warenkorb-Bereichs im Rahmenlayout bereit.
 */
export class Warenkorb {
    public static readonly INSTANCE = new Warenkorb();

    private constructor() {}

    /**
     * Aktualisiert den Warenkorb-Bereich im Rahmenlayout.
     * @param ListeOeffnen wenn true, dann wird die Liste der Warenkorbanzeige sofort nach dem Laden geöffnet
     * @description
     * Es wird mittels [[Konstanten.WarenkorbRahmenSelector]] der zu aktualisierende Bereich ermittelt.
     * Zur Ermittlung des Warenkorb-Markup wird O4501W aufgerufen.
     */
    public Aktualisieren(ListeOeffnen?: boolean) {
        const $warenkorbLayoutRoot: JQuery = $j(
            Konstanten.WarenkorbRahmenSelector
        );

        // Request per AJAX POST senden
        let ProgrammInfo = new HoProgrammInfo({
            Programm: "O4501W",
            CallJsAnhaengen: true,
        });

        AS400Layer.INSTANCE.SendeGetRequest(ProgrammInfo, {
            LadeanimationDeaktivieren: false,
        }).done((AntwortDaten) => {
            // Zuerst Events vom Warenkorb-Bereich entfernen, die eventuell noch vorhanden sind
            $warenkorbLayoutRoot.off("show.bs.dropdown");

            // Inhalt von Container ersetzen
            $warenkorbLayoutRoot.html(AntwortDaten);

            // Feature-Initialisierung für Links im Warenkorbbereich
            Layout.INSTANCE.InitLayout($warenkorbLayoutRoot, {
                ProgrammName: "O4501",
                $eventTargetRoot: $j("body"),
            });

            // Wenn die Liste sofort angezeigt werden soll, dann einblenden
            if (ListeOeffnen === true) {
                // Liste sofort nach dem Laden anzeigen
                $j("[data-bs-toggle='dropdown']", $warenkorbLayoutRoot).trigger(
                    "click"
                );
            }

            // Für nachfolgendes Listen-Anzeigen Ereignis definieren
            $warenkorbLayoutRoot
                .off("show.bs.dropdown")
                .on("show.bs.dropdown", (ereignis) => {
                    // Standard-Ereignisbehandlung abbrechen
                    ereignis.preventDefault();
                    ereignis.stopPropagation();
                    // Warenkorbliste laden, sofort nach dem Laden öffnen
                    this.Aktualisieren(true);
                });
        });
    }
}
