import * as $j from "jquery";
import { Konstanten } from "./konstanten/konstanten";
import { Modal } from "./modal";

/**
 * Stellt statische Methoden bereit, die unabhängig von anderen Klassen funktionieren.
 */
export class StatischeMethoden {
    /**
     * Aktive Modal-Instanz holen
     */
    public static get aktivesModal() {
        return this._aktivesModal;
    }

    /**
     * Aktive Modal-Instanz festlegen
     */
    public static set aktivesModal(aktivesModal) {
        this._aktivesModal = aktivesModal;
    }

    /**
     * Prüft, ob ein Bootstrap Modal in der Seite geöffnet ist
     */
    public static PruefeObModalGeoffnet(): boolean {
        return this._aktivesModal !== undefined;
    }

    /**
     * Schließt ein Bootstrap Modal, wenn eines geöffnet ist
     */
    public static SchliesseModal(ModalDismissVerhindern?: boolean): void {
        if (this._aktivesModal) {
            this._aktivesModal.verstecken({
                ModalDismissVerhindern: ModalDismissVerhindern || false,
            });
        }
    }

    /**
     * Blendet die Ladeanimation ein, wenn diese nicht angezeigt wird
     */
    public static LadeAnimationAnzeigen() {
        $j(Konstanten.LoadingOverlay).addClass("loader active");
    }

    /**
     * Blendet die Ladeanimation aus, wenn diese angezeigt wird
     */
    public static LadeAnimationAusblenden() {
        $j(Konstanten.LoadingOverlay).removeClass("loader active");
    }

    /**
     * Blendet alle Tooltips aus
     * @param $container Container, innerhalb dessen Tooltips ausgeblendet werden
     */
    public static VersteckeTooltips($container: JQuery): void {
        $j(
            "[data-ho-toggle^='tooltip'],[data-ho-validate]",
            $container
        ).tooltip("hide");
    }

    /**
     * Entfernt alle Toasts (Surprise!)
     */
    public static EntferneAlleToasts(): void {
        $j(".toast").toast("hide");
    }

    /**
     * Speicherzelle für aktive Modal-Instanz
     */
    private static _aktivesModal?: Modal;
}
