export class KonstantenArtikelsucheVersion2 {
    public static readonly SUCHE_STARTEN_DATA_ATTRIBUT =
        "data-ho-suche-starten";
    public static readonly SUCHBEGRIFF_AENDERN_DATA_ATTRIBUT =
        "data-ho-suchbegriff-aendern";
    public static readonly FILTER_CONTENT_VISIBILITY_TOGGLER_DATA_ATTRIBUT =
        "data-ho-filter-content-visibility-toggler";
    public static readonly FILTER_CONTENT_RESET_DATA_ATTRIBUT =
        "data-ho-filter-content-reset";
    public static readonly FILTER_CONTENT_DATA_ATTRIBUT =
        "data-ho-filter-content";

    public static readonly FILTER_ARROW_DATA_ATTRIBUT = "data-ho-filter-arrow";

    public static readonly FILTER_IS_OPEN_CLASS = "filterIsOpen";

    public static readonly FILTER_ARROW_IS_CLOSED_CLASS = "closed";

    public static readonly ZusatzInfoButtonDataAttribut =
        "data-ho-artikelsuche-moreinfo";

    public static readonly LAYOUT_ROOT_SELECTOR =
        "[data-ho-artikelsuche-result-container]";

    public static readonly EventNameClick = "click.holteronline-artikelsuche";

    public static readonly ProgrammZusatzInfo = "O4821R";

    public static readonly ZusatzInfoZeileSelector = ".dataTable-detailTable";

    public static readonly NAECHSTE_SEITE_LADEN = "data-ho-load-next-results";

    public static readonly VORHERIGE_SEITE_LADEN = "data-ho-load-prev-results";

    public static readonly ERSTE_SEITE_LADEN = "data-ho-load-first-page";

    public static readonly LETZTE_SEITE_LADEN = "data-ho-load-last-page";
}
