// tslint:disable:comment-format
///<amd-dependency path="bloodhound" />
///<amd-dependency path="typeahead.js" />
///<amd-dependency path="popper" />
///<amd-dependency path="bootstrap.bundle" />
// tslint:enable:comment-format

import * as $j from "jquery";
import { ArtikelsucheVersion2 } from "./artikelsucheVersion2";
import { ArtikelsucheZustand } from "./artikelsucheZustand";
import { AS400Layer } from "./as400Layer";
import { Autocomplete } from "./autocomplete";
import { DataTableFunktionen } from "./dataTableFunktionen";
import { KeyEvent } from "./events/keyEvent";
import { Historie } from "./historie";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { Konstanten } from "./konstanten/konstanten";
import { KonstantenWarenkorb } from "./konstanten/konstantenWarenkorb";
import { Layout } from "./layout";

import UParams = require("UParams");

/**
 * Stellt Funktionen rund um die Powersuche bereit
 */
export class Powersuche {
    public static readonly INSTANCE = new Powersuche();

    private constructor() {}

    /**
     * Sorgt dafür, dass alle Maßnahmen ausgeführt werden,
     * die für einen sauberen Anwendungs-Start notwendig sind.
     * Diese Methode darf nur einmal in der Anwendungslaufzeit ausgeführt werden.
     * Für das Initialisieren von Seiten-Membern [[Aktualisieren]] verwenden.
     */
    public InitEvents(): void {
        // Autocomplete hinzufügen für die Powersuche-Textbox
        this.AutocompleteHinzufuegen();

        // X-Button wird bei Suchbegriffseingabe hinzugefügt
        this.ClearButtonhinzufuegen();

        // Leeren der Suchtextbox bei Klick auf "x"
        this.LeereSuchTextboxWennXClick();

        // Beim initialen Seiten-Laden auch den Powersuche-Bereich initial festlegen, damit dieser befüllt ist (wird evtl. durch spätere Logik neu gesetzt)
        this.SetzeKategorie();
    }

    /**
     * Initialisiert Events für Controls in Seiten, die eine Powersuche auslösen
     */
    public Aktualisieren($layoutRoot: JQuery): void {
        // Click- und Changed-Event für Controls in Seite
        $j(
            '[data-ho-funktion="powersuche_click"],[data-ho-funktion="powersuche_change"]'
        )
            .off("click.holteronline")
            .on("click.holteronline", () =>
                this.SucheDurchfuehren($layoutRoot)
            );

        // Event für Enter-Tasten-Klick für Controls in Seite
        $j('[data-ho-funktion="powersuche_keypress_enter"]')
            .off("keypress.holteronline")
            .on("keypress.holteronline", (ereignis) => {
                if (KeyEvent.isEnter(ereignis)) {
                    this.SucheDurchfuehren($layoutRoot);
                }
            });

        // Prüfen ob die aktuelle Seite eine Powersuche-Seite ist
        if (this.PruefeObPowersucheSeite()) {
            if (this.PruefeObPowersucheArtikel()) {
                // Die aktuelle Seite ist die Artikelsuche -> Artikelsuche-Suchbegriff ermitteln
                this.HoleSuchbegriffAusArtikelsuche();
            } else {
                // Suchbegriff setzen mit Parameter aus URL
                this.HoleSuchbegriffAusHref();
            }
        }

        // Manuelles Wechseln der Suchkategorie
        this.Suchkategoriewechseln();
    }

    /**
     * Setzt die angegebene Kategorie in die Powersuche-Kategorieauswahl ein
     * @param Kategorie Kategorie, die als aktive Kategorie gesetzt wird
     */
    public SetzeKategorie(Kategorie?: string): void {
        // Wenn Kategorie-Steuerelement vorhanden ist, die Kategorie auswählen
        if ($j(Konstanten.PowersucheKategorieId).length) {
            // Wenn keine Kategorie angegeben ist, dann Artikelsuche vorauswählen (TFS#2255)
            if (!Kategorie || Kategorie === "") {
                Kategorie = "ART";
            }

            if (Kategorie && Kategorie !== "") {
                $j(Konstanten.PowersucheKategorieId).val(Kategorie);
                let suche = $j(
                    '[data-ho-suchkategorie="' + Kategorie + '"]'
                ).text();
                $j(Konstanten.PowersucheKategorieId).text(suche);
                if (this.PruefeObPowersucheArtikel()) {
                    // Die aktuelle Seite ist die Artikelsuche -> Artikelsuche-Suchbegriff ermitteln
                    this.HoleSuchbegriffAusArtikelsuche();
                } else {
                    // Suchbegriff setzen mit Parameter aus URL
                    this.HoleSuchbegriffAusHref();
                }
            }

            // Autocomplete für Artikelsuche aktivieren
            this.ArtikelAutocomplete();
        }
    }

    /**
     * Ermittelt den Suchbegriff und entfernt alle ungültigen Zeichen
     */
    public ErmittleSuchbergriffAusTextbox(encode = true): string {
        let GepruefterWert: string = "";

        // Array aus gültigen Zeichen erstellen
        let PruefArray = ($j(Konstanten.PowersucheTextboxId).val() as string)
            .trim()
            .match(Konstanten.RegexStandardInput);

        // Array-Elemente in Variable stellen
        if (PruefArray) {
            GepruefterWert = PruefArray.join("");
        }

        // auf die übrigen Zeichen URI-Encodierung anwenden
        if (encode) {
            GepruefterWert = encodeURIComponent(GepruefterWert);
        }

        return GepruefterWert;
    }

    /**
     * Setzt den angegebenen Suchbegriff in die Such-Textbox ein
     * @param Suchbegriff Suchbegriff, nach dem gesucht worden ist
     */
    public SetzeSuchbegriff(Suchbegriff: string): void {
        // Wenn Suchbegriff-Textbox vorhanden ist, den Suchbegriff einsetzen
        if ($j(Konstanten.PowersucheTextboxId).length) {
            // jQuery-Change-Event manuell auslösen (damit Validierungen richtig durchgeführt werden)
            $j(Konstanten.PowersucheTextboxId)
                .typeahead("val", Suchbegriff)
                .trigger("change");
        }
    }

    /**
     * Fügt das Autocomplete für die Powersuche-Textbox hinzu.
     * Kann mit [[AutocompleteEntfernen]] wieder entfernt werden.
     */
    private AutocompleteHinzufuegen(): void {
        Autocomplete.INSTANCE.InitAutocomplete($j("#PowersucheTextbox"));

        // Am jQuery Change Event auf der Powersuche Textbox die Suche ausführen
        $j(Konstanten.PowersucheTextboxId)
            .off("typeahead:selected.holteronline")
            .on(
                "typeahead:selected.holteronline",
                this.SucheDurchfuehren.bind(this)
            );
    }

    /**
     * Führt die Powersuche aus
     */
    private SucheDurchfuehren($layoutRoot: JQuery): void {
        let kategorie: string = (
            $j(Konstanten.PowersucheKategorieId).val() as string
        ).trim();

        const $dArtikelsuche = $j.Deferred();

        $dArtikelsuche.done(() => {
            const ProgrammInfo: HoProgrammInfo = new HoProgrammInfo({
                Programm: Konstanten.PowersucheProgrammName,
            });

            if (
                kategorie === "ART" &&
                ArtikelsucheVersion2.INSTANCE.istAktuellGeoeffnet()
            ) {
                ArtikelsucheZustand.processHtmlFields({
                    suchbegriff: this.ErmittleSuchbergriffAusTextbox(
                        false
                    ).slice(0, 98),
                }).done((zustand) => {
                    ArtikelsucheVersion2.INSTANCE.suchen(zustand);
                });
            } else {
                let extraParameter = "";

                if (
                    $j(Konstanten.PowersucheKategorieHiddenFieldId).val() ===
                    kategorie
                ) {
                    if ($j(Konstanten.PowersucheFilterzusatzId).length) {
                        extraParameter = $j(Konstanten.PowersucheFilterzusatzId)
                            .find(Konstanten.ProgrammDatenSerialisierungTypen)
                            .serialize();
                    }
                }

                ProgrammInfo.AjaxDaten = `${extraParameter}&suche=${this.ErmittleSuchbergriffAusTextbox()}&belegart=${kategorie}`;

                AS400Layer.INSTANCE.SendePostRequest(ProgrammInfo, {}).done(
                    (Data) => {
                        AS400Layer.INSTANCE.VerarbeiteAjaxDone({
                            Data,
                            ProgrammInfo,
                            Settings: {},
                        });
                    }
                );
            }
        });

        if (
            Historie.INSTANCE.ErmittleAktuelleSeite() ===
            KonstantenWarenkorb.ProgrammWarenkorb
        ) {
            DataTableFunktionen.INSTANCE.DragDropOpenChangesHandler(
                $layoutRoot,
                new HoProgrammInfo({
                    Programm: KonstantenWarenkorb.ProgrammWarenkorbSpeichern,
                    AjaxDaten: `butt=speichern&${Layout.INSTANCE.GetSerializableUiComponents().serialize()}`,
                })
            )
                .done(() => $dArtikelsuche.resolve())
                .catch($dArtikelsuche.reject);
        } else {
            $dArtikelsuche.resolve();
        }
    }

    /**
     * Ermittelt den Suchbegriff aus window.location.href und setzt ihn.
     */
    private HoleSuchbegriffAusHref(): void {
        let suchparam: string = "";
        // Aktuelle Location des Fensters in Parameter splitten
        let parameter = new UParams(window.location.href);
        // Suchbegriff in Such-Textbox ausgeben
        // Wenn Suchbegriff fehlt, dann wird die Textbox geleert
        // tslint:disable:no-string-literal
        if (parameter["suche"]) {
            suchparam = parameter["suche"];
        }
        // tslint:enable:no-string-literal

        // Suchbegriff setzen
        this.SetzeSuchbegriff(suchparam);
    }

    /**
     * Ermittelt den Suchbegriff einer Artikelsuche
     */
    private HoleSuchbegriffAusArtikelsuche(): void {
        let suchbegriff: string;

        if (ArtikelsucheVersion2.INSTANCE.istAktuellGeoeffnet()) {
            suchbegriff = ArtikelsucheVersion2.INSTANCE.LetzterSuchbegriff;
        } else {
            suchbegriff = decodeURIComponent(
                ($j("#O4370_suchbegriff").val() || "").toString()
            );
        }

        this.SetzeSuchbegriff(suchbegriff);
    }

    /**
     * Fokussiere Textbox
     */
    private FokussiereTextbox(): void {
        $j(Konstanten.PowersucheTextboxId).trigger("focus");
    }

    /**
     * Prüft, ob die aktuell angezeigte Seite eine Powersuche-Seite ist
     */
    private PruefeObPowersucheSeite(): boolean {
        // Ziel ist es, nach "F5" oder Browser-Back den gesuchten Suchbegriff in die Powersuche hineinzustellen
        // Dafür muss aber bekannt sein, ob die angezeigte eine Powersuch-Seite ist,
        //  und welcher Suchbegriff gesucht worden ist.

        let IstPowersuchSeite: boolean = false;

        if ($j("#ho_powersuche").length) {
            // Powersuch-Element ist vorhanden -> ist eine Powersuche-Seite
            IstPowersuchSeite = true;
        }

        return IstPowersuchSeite;
    }

    /**
     * Prüft, ob die aktuelle Seite die Artikelsuche ist
     */
    private PruefeObPowersucheArtikel(): boolean {
        let istArtikelsuche: boolean = false;

        let $powersucheInfo = $j("#ho_powersuche");

        if ($powersucheInfo.length && $powersucheInfo.val() === "AS") {
            // Powersuche-Info ist die Artikelsuche-Seite
            istArtikelsuche = true;
        }

        if (ArtikelsucheVersion2.INSTANCE.istAktuellGeoeffnet()) {
            istArtikelsuche = true;
        }

        return istArtikelsuche;
    }

    /**
     * Event für manuelles Wechseln der Suchkategorie
     */
    private Suchkategoriewechseln() {
        $j("[data-ho-suchkategorie]")
            .off("click.holteronline-powersuche")
            .on("click.holteronline-powersuche", (e) => {
                let $kategoriebtn = $j(Konstanten.PowersucheKategorieId);
                let $selectedkategorie = $j(e.currentTarget);
                $kategoriebtn.val($selectedkategorie.data("ho-suchkategorie"));
                $kategoriebtn.text($selectedkategorie.text());

                // Fokusiere PowersucheTextbox nach Ändern der Suchkategorie
                this.FokussiereTextbox();

                // Autocomplete für Artikelsuche aktivieren
                this.ArtikelAutocomplete();
            });
    }

    /**
     * Autocomplete für Artikelsuche aktivieren
     */
    private ArtikelAutocomplete() {
        if ($j(Konstanten.PowersucheKategorieId).val() === "ART") {
            // Autocomplete initialisieren

            $j(Konstanten.PowersucheTextboxId).typeahead("enable");
            $j(Konstanten.PowersucheTextboxId).typeahead("activate");
        } else {
            // DestroyAutocomplete

            $j(Konstanten.PowersucheTextboxId).typeahead("disable");
            $j(Konstanten.PowersucheTextboxId).typeahead("deactivate");
        }
    }

    /**
     * Leert die Powersuche-Textbox bei Klick auf "x"
     */
    private LeereSuchTextboxWennXClick(): void {
        $j("[data-ho-suchtextbox-leeren]")
            .off("click.holteronline-powersuche")
            .on("click.holteronline-powersuche", () => {
                this.SetzeSuchbegriff("");
                this.FokussiereTextbox();
            });
    }

    /**
     * Event für das Hinzufügen des ClearButtons (X)
     * zum Leeren der Textbox
     */
    private ClearButtonhinzufuegen() {
        $j(Konstanten.PowersucheTextboxId)
            .off("input.holteronline-powersuche change.holteronline-powersuche")
            .on(
                "input.holteronline-powersuche change.holteronline-powersuche",
                (e) => {
                    if ($j(Konstanten.PowersucheTextboxId).val()) {
                        $j(".toolbar-search").addClass("btnclear-visible");
                    } else {
                        $j(".toolbar-search").removeClass("btnclear-visible");
                    }
                }
            );
    }
}
