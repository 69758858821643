import * as $j from "jquery";
import { Browsertyp } from "./browsertyp";
import { Konstanten } from "./konstanten/konstanten";

/**
 * Stellt Funktionen zum Fokussieren von Steuerelementen bereit.
 */
export class FokusToolbox {
    public static readonly INSTANCE = new FokusToolbox();

    private constructor() {}

    /**
     * Sorgt dafür, dass alle Maßnahmen ausgeführt werden,
     * die für einen sauberen Anwendungs-Start notwendig sind.
     * Diese Methode darf nur einmal in der Anwendungslaufzeit ausgeführt werden.
     * Für das Initialisieren von Seiten-Membern [[Aktualisieren]] verwenden.
     */

    public InitEvents() {
        // Fokus nach Kategorieauswahl wieder in die Textbox setzen
        $j(Konstanten.PowersucheKategorieId)
            .off("change.holteronline-fokus")
            .on("change.holteronline-fokus", () => {
                this.FokussiereElement(
                    $j(Konstanten.PowersucheTextboxId),
                    undefined,
                    true
                );
            });
    }

    /**
     * Initialisiert automatisierte Fokus-Funktionen innerhalb der Seite
     */
    public InitFokusFunktionen($layoutRoot: JQuery): void {
        $j("[data-ho-funktion='fokuswechsel_input']", $layoutRoot)
            .off("input.holteronline-fokustoolbox")
            .on(
                "input.holteronline-fokustoolbox",
                (ereignis: JQuery.TriggeredEvent) => {
                    let $elem = $j(ereignis.currentTarget);

                    let laengeInputVorWechsel: number = $elem.data(
                        "ho-fokuswechsel-length"
                    );
                    let laengeInputAktuell: number = (
                        $elem.val() || ""
                    ).toString().length;

                    if (laengeInputAktuell >= laengeInputVorWechsel) {
                        // Genug Zeichen eingegeben -> wechseln
                        let $naechstesElement = $j(
                            $elem.data("ho-fokuswechsel-next-selector"),
                            $layoutRoot
                        );

                        if ($naechstesElement) {
                            $naechstesElement
                                .trigger("focus")
                                .trigger("select");
                        }
                    }
                }
            );
    }

    /**
     * Diese Methode sucht das zu fokussierende Element in der Oberfläche und fokussiert dieses.
     * Wenn kein spezielles Element zum Fokussieren angegeben ist, wird die Powersuche-Textbox fokussiert.
     */
    public SetzeFokus($layoutRoot: JQuery): void {
        // Hier muss absichtlich mit undefined initialisiert werden,
        // damit es zu keinem TypeScript-Fehler kommt (Bezug auf ein nicht initialisiertes Objekt)
        // tslint:disable:no-unnecessary-initializer
        let $elem: JQuery | undefined = undefined;
        let Finalisierung: (() => JQuery.Deferred<any>) | undefined = undefined;
        // tslint:enable:no-unnecessary-initializer

        // Zuerst herausfinden, welches Element fokussiert gehört
        if ($j("[data-ho-focus-onload]", $layoutRoot).length) {
            // Nur das erste Element nehmen, falls irrtümlich mehr als eines angegeben worden ist
            $elem = $j("[data-ho-focus-onload]", $layoutRoot).first();
        }

        // Wenn kein Element für Fokussierung angegben ist, wird die Powersuche-Textbox fokussiert
        if (
            $elem === undefined ||
            $j("[data-ho-focus-override='powersuche']", $layoutRoot).length
        ) {
            $elem = $j(Konstanten.PowersucheTextboxId);
            // Wenn die Powersuche-Textbox fokussiert wird, ist im Anschluss das Schließen des Autocomplete nötig
            Finalisierung = (): JQuery.Deferred<any> => {
                let $promise: JQuery.Deferred<any> = $j.Deferred();

                // Hack für den Internet Explorer:
                // Mit einem Delay von 10ms dafür sorgen, dass vorherige Tasks fertig ausgeführt worden sind
                setTimeout(() => {
                    $j(Konstanten.PowersucheTextboxId).typeahead("close");
                    $promise.resolve();
                }, 10);

                return $promise;
            };
        }

        if ($elem?.length) {
            // Element fokussieren
            this.FokussiereElement($elem, Finalisierung);
        }
    }

    /**
     * Setzt den Fokus auf ein angegebenes Element.
     * @param $elem Auf dieses Element wird der Fokus gesetzt
     * @param Finalisierung Methode, die als "Abschlussarbeit" ausgeführt wird (bspw. Schließen eines Autocomplete)
     */
    private FokussiereElement(
        $elem: JQuery,
        Finalisierung?: () => JQuery.Deferred<any>,
        forceFokus: boolean = false
    ): void {
        // Hack für den Internet Explorer:
        // Mit einem Delay von 10ms dafür sorgen, dass vorherige Tasks fertig ausgeführt worden sind
        setTimeout(() => {
            let fokusWurdeGesetzt = false;

            // Textfeld anschließend sofort fokussieren (TFS#2104)
            // Nur fokussieren, wenn es sich nicht um ein Touch-Gerät handelt (TFS#2288)
            if (forceFokus || Browsertyp.WieDesktop) {
                $elem.trigger("focus").trigger("select");
                fokusWurdeGesetzt = true;
            }

            // Methode zum Prüfen, ob der Element-Fokus entfernt werden soll
            let FokusEntfernen = () => {
                // Bei Touch-Geräten im Anschluss die Textbox wieder verlassen, damit das Touchscreen-Keyboard geschlossen wird
                if (
                    Browsertyp.WieTouchscreen &&
                    !forceFokus &&
                    !fokusWurdeGesetzt
                ) {
                    $elem.trigger("blur");
                }
            };

            // Abschlussarbeit ausführen, wenn angegeben
            if (Finalisierung !== undefined) {
                // Erst nach Abschluss der Finalize-Methode prüfen, ob der Fokus entfernt werden soll
                Finalisierung().done(FokusEntfernen.bind(this));
            } else {
                // Fokus direkt entfernen, es gibt nichts zu finalisieren
                FokusEntfernen();
            }
        }, 10);
    }
}
