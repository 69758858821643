import { HoItemSearchApi } from "../api/interfaces/hoItemSearchApi";

/**
 * @tutorial
 * let requestBuilder = new ItemSearchRequestBuilder(...);
 * ItemSearchApi.search(requestBuilder.getResult());
 */
export class ItemSearchRequestBuilder {
    private searchRequest: {
        data: HoItemSearchApi.SearchRequest;
    };

    /**
     * Erstellt ein Objekt zum Erzeugen einer Such-Anfrage.
     * @param query Suchbegriff, nach dem gesucht wird
     */
    constructor(query?: string) {
        this.searchRequest = {
            data: {
                query: query ?? "",
                resultsOptions: {
                    filter: undefined,
                    skip: 0,
                    sortBy: undefined,
                    take: 50,
                },
            },
        };
    }

    /**
     * Legt den Suchbegriff für die Anfrage fest.
     * @param query Suchbegriff
     */
    public setQuery(query: string) {
        this.searchRequest.data.query = query;
    }

    /**
     * Mit dieser Funktion kann angegeben werden, wieviele Einträge
     * bei einer Suchanfrage "übersprungen" werden sollen
     * (für Pagination).
     * @default 0
     * @param skip Anzahl der zu überspringenden Einträge
     */
    public setSkip(skip: number) {
        if (!this.searchRequest.data.resultsOptions) {
            this.searchRequest.data.resultsOptions = {};
        }
        this.searchRequest.data.resultsOptions.skip = skip;
    }

    /**
     * Mit dieser Funktion kann angegeben werden, wieviele Einträge
     * bei einer Suchanfrage auf einer "Seite" angezeigt werden sollen
     * (für Pagination).
     * @default 50
     * @param skip Anzahl der zu überspringenden Einträge
     */
    public setTake(take: number) {
        if (!this.searchRequest.data.resultsOptions) {
            this.searchRequest.data.resultsOptions = {};
        }
        this.searchRequest.data.resultsOptions.take = take;
    }

    /**
     * Legt fest, ob Nettopreise ermittelt werden sollen
     * bei der Durchführung der Suchanfrage.
     * @default false
     */
    public setCalculateNetPrices(calculateNetPrices: boolean) {
        this.searchRequest.data.calculateNetPrices = calculateNetPrices;
    }

    /**
     * Gibt an, wie die Ergebnisse sortiert werden sollen.
     * [WARN]: Überschreibt vorherige Angaben.
     * @param sortBy Sortierreihenfolge(n)
     */
    public setSortBy(sortBy: HoItemSearchApi.EntitySortingParameter[]) {
        if (!this.searchRequest.data.resultsOptions) {
            this.searchRequest.data.resultsOptions = {};
        }

        this.searchRequest.data.resultsOptions.sortBy = sortBy;
    }

    /**
     * Fügt ein Kriterium, nach dem sortiert werden soll, zur Anfrage hinzu.
     * @param sortBy Sortierkriterium
     */
    public addSortBy(sortBy: HoItemSearchApi.EntitySortingParameter) {
        if (!this.searchRequest.data.resultsOptions) {
            this.searchRequest.data.resultsOptions = {};
        }
        if (!this.searchRequest.data.resultsOptions.sortBy) {
            this.searchRequest.data.resultsOptions.sortBy = [];
        }
        this.searchRequest.data.resultsOptions.sortBy.push(sortBy);
    }

    /**
     * Gibt an, mit welchen Filtern die Anfrage durchgeführt werden soll.
     * [WARN]: Überschreibt vorherige Angaben.
     * @param filter Filtekriterien
     */
    public setFilter(
        filter: Array<
            | HoItemSearchApi.DistinctFilterParameter
            | HoItemSearchApi.RangeFilterParameter
        >
    ) {
        if (!this.searchRequest.data.resultsOptions) {
            this.searchRequest.data.resultsOptions = {};
        }

        this.searchRequest.data.resultsOptions.filter = filter;
    }

    /**
     * Fügt ein Filterkriterium zur Anfrage hinzu.
     * @param filter Filterkriterium
     */
    public addFilter(
        filter:
            | HoItemSearchApi.DistinctFilterParameter
            | HoItemSearchApi.RangeFilterParameter
    ) {
        if (!this.searchRequest.data.resultsOptions) {
            this.searchRequest.data.resultsOptions = {};
        }
        if (!this.searchRequest.data.resultsOptions.filter) {
            this.searchRequest.data.resultsOptions.filter = [];
        }
        this.searchRequest.data.resultsOptions.filter.push(filter);
    }

    /**
     * Gibt an, ob nur Artikel, die als Lagerware markiert sind, gesucht werden sollen.
     * @param inStock true, wenn nur Artikel, die als Lagerware markiert sind, gesucht werden sollen
     */
    public setInStock(inStock: boolean) {
        if (!this.searchRequest.data.resultsOptions) {
            this.searchRequest.data.resultsOptions = {};
        }
        if (!this.searchRequest.data.resultsOptions.productSearchOptions) {
            this.searchRequest.data.resultsOptions.productSearchOptions = {};
        }
        this.searchRequest.data.resultsOptions.productSearchOptions.inStock =
            inStock;
    }

    /**
     * Gibt an, in welchem Lager ein Artikel lagernd sein muss, um in der Ergebnisliste aufscheinen zu dürfen.
     * [WARN]: Überschreibt vorherige Angaben.
     * @param inStockIn Lagernummern
     */
    public setInStockIn(inStockIn: number[]) {
        if (!this.searchRequest.data.resultsOptions) {
            this.searchRequest.data.resultsOptions = {};
        }
        if (!this.searchRequest.data.resultsOptions.productSearchOptions) {
            this.searchRequest.data.resultsOptions.productSearchOptions = {};
        }
        this.searchRequest.data.resultsOptions.productSearchOptions.inStockIn =
            inStockIn;
    }

    /**
     * Gibt an, in welchem Lager ein Artikel lagernd sein muss, um in der Ergebnisliste aufscheinen zu dürfen.
     * @param inStockIn Lagernummer
     */
    public addInStockIn(inStockIn: number) {
        if (!this.searchRequest.data.resultsOptions) {
            this.searchRequest.data.resultsOptions = {};
        }
        if (!this.searchRequest.data.resultsOptions.productSearchOptions) {
            this.searchRequest.data.resultsOptions.productSearchOptions = {};
        }
        if (
            !this.searchRequest.data.resultsOptions.productSearchOptions
                .inStockIn
        ) {
            this.searchRequest.data.resultsOptions.productSearchOptions.inStockIn =
                [];
        }
        this.searchRequest.data.resultsOptions.productSearchOptions.inStockIn.push(
            inStockIn
        );
    }

    /**
     * Gibt an, ob nur Artikel, die die bereits gekauft wurden, gesucht werden sollen.
     * @param inStock true, wenn nur Artikel, die bereits gekauft wurden, gesucht werden sollen
     */
    public setOnlyUserPurchased(onlyUserPurchased: boolean) {
        if (!this.searchRequest.data.resultsOptions) {
            this.searchRequest.data.resultsOptions = {};
        }
        if (!this.searchRequest.data.resultsOptions.productSearchOptions) {
            this.searchRequest.data.resultsOptions.productSearchOptions = {};
        }
        this.searchRequest.data.resultsOptions.productSearchOptions.onlyUserPurchased =
            onlyUserPurchased;
    }

    /**
     * Gibt die Parameter für die Such-Anfrage zurück.
     * @throws Fehler wenn keine `query` (Suchbegriff) angegeben worden ist
     */
    public getResult() {
        if (!this.searchRequest.data.query) {
            throw new Error("Insufficient request data");
        }

        if (
            this.searchRequest.data.resultsOptions &&
            !this.searchRequest.data.resultsOptions.filter
        ) {
            delete this.searchRequest.data.resultsOptions.filter;
        }

        if (
            this.searchRequest.data.resultsOptions &&
            !this.searchRequest.data.resultsOptions.sortBy
        ) {
            delete this.searchRequest.data.resultsOptions.sortBy;
        }

        return this.searchRequest;
    }
}
