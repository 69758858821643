/**
 * Definiert die möglichen Typen von Artikelinformationen, die geladen werden können
 */
export enum ArtikelInformationTyp {
    /**
     * Artikel-Masterinformationen des Lieferanten
     */
    Masterdata,
    /**
     * Artikel-Langtext
     */
    Langtext,
    /**
     * Artikel-Bilder
     */
    Bilder,
    /**
     * Artikel-Videos
     */
    Videos,
    /**
     * Artikel-Exposees
     */
    Exposees,
    /**
     * Artikel-Dokumente
     */
    Dokumente,
}
