/**
 * Beinhaltet alle konstanten Werte für die Artikelsuche
 */
export class KonstantenArtikelsuche {
    /**
     * Programmname der Trefferlisten-Ermittlung
     */
    public static readonly ProgrammTrefferliste: string = "O4371R";

    /**
     * Programmname für Filterbereich-Ermittlung
     */
    public static readonly ProgrammFilterbereich: string = "O4372W";

    /**
     * Programm, über das Filter-Values ermittelt werden
     */
    public static readonly ProgrammFilterValues: string = "O4373W";

    /**
     * Programm, an das Eingaben im Filterbereich submittet werden
     */
    public static readonly ProgrammFilterbereichSubmit: string = "O4374R";

    /**
     * Programm, das die erweiterten Artikelinformationen lädt
     */
    public static readonly ProgrammZusatzInfo: string = "O4821R";

    /**
     * Selector für das Input-Element, in dem sich in [[value]] die Honsu befindet
     */
    public static readonly UkHonsuSelector: string = "#O4370_ukhonsu";

    /**
     * Selector für das Lager-Input-Element
     */
    public static readonly LagerSelector: string = "#O4370_lage";

    /**
     * Gibt den Selector für den Wrapper der Artikelsuche an
     */
    public static readonly ContainerWrapperSelector: string =
        "[data-ho-artikelsuche-wrapper]";

    /**
     * Gibt den Wrapper für den Selector des Filterbereichs an
     */
    public static readonly FilterWrapperSelector: string =
        "[data-ho-artikelsuche-filter-wrapper]";

    /**
     * Gibt den Selector für den Wrapper des Tabellenbereichs an
     */
    public static readonly TableWrapperSelector: string =
        "[data-ho-artikelsuche-table-wrapper]";

    /**
     * Selector für den Marker, der aussagt, dass ein Filter noch nicht befüllt worden ist
     */
    public static readonly FilterLeerSelector: string =
        "[data-ho-O4372-filter-empty]";

    /**
     * Gibt den Selector für den Button an, der den Filterbereich anzeigt
     */
    public static readonly FilterAnzeigenButtonSelector: string =
        "[data-ho-artikelsuche-filter-anzeigen]";

    /**
     * Gibt den Selector für den Button an, der den Filterbereich ausblendet
     */
    public static readonly FilterAusblendenButtonSelector: string =
        "[data-ho-artikelsuche-filter-ausblenden]";

    /**
     * Gibt den Selector für die Tabelle an
     */
    public static readonly TabelleSelector: string = "#O4370_ergebnisliste";

    /**
     * Gibt den Selector für den Container an, der die Tabelle umgibt.
     */
    public static readonly TabelleWrapperSelector: string =
        "#O4370_ergebnisliste_wrapper";

    /**
     * Selector für den Bereich, in dem alle Artikelfilter platziert sind
     */
    public static readonly FilterBereichSelector: string =
        "#O4370_artikelfilter";

    /**
     * Gibt den Selector für den Button, der alle Filter zurücksetzt, an
     */
    public static readonly FilterResetAlleSelector: string =
        "[data-ho-O4372-filter-reset-alle]";

    /**
     * Gibt den Selector für den Button, der alle Filter zurücksetzt, an
     */
    public static readonly FilterAnwendenSelector: string =
        "[data-ho-O4372-filter-anwenden]";

    /**
     * Gibt den Selector für eine Zeile zur Anzeige von erweiterten Artikelinformationen an
     */
    public static readonly ZusatzInfoZeileSelector: string =
        ".dataTable-detailTable";

    /**
     * data-Attribut für einen Button zum Anzeigen von erweiterten Artikelinformationen in der Artikelsuche-Tabelle
     */
    public static readonly ZusatzInfoButtonDataAttribut: string =
        "data-ho-artikelsuche-moreinfo";

    /**
     * Gibt das data-Attribut für das Auswählen von Tabellen-Spaltennamen an
     */
    public static readonly TabelleSpaltenNameDataAttribut: string =
        "data-ho-O4370-column-name";

    /**
     * Gibt das data-Attribut an, das bestimmt, dass ein Filter sofort nach dem Laden geöffnet werden soll
     */
    public static readonly FilterSofortZuOeffnenDataAttribut: string =
        "data-ho-O4372-filter-sofort-oeffnen";

    /**
     * Selector für den Header eines Filters
     */
    public static readonly FilterHeaderDataAttribut: string =
        "data-ho-O4372-filter-header";

    /**
     * Name des data-Attributs am Container, in dem sich der Filter-Inhalt befindet
     */
    public static readonly FilterContainerDataAttribut: string =
        "data-ho-O4372-filter-container";

    /**
     * Selector für den Container bei Alpha-Attributen, in dem sich die Checkboxen befinden
     */
    public static readonly FilterValuesListDataAttribut: string =
        "data-ho-O4372-filter-values-list";

    /**
     * Gibt das data-Attribut für einen Button zum Laden von weiteren Filter-Values an.
     */
    public static readonly FilterValuesWeitereDataAttribut: string =
        "data-ho-O4372-filter-values-weitere";

    /**
     * Gibt das data-Attribut für den Suchbegriff einer Filter-Value-Pagination an
     */
    public static readonly FilterValuesSuchbegriffDataAttribut: string =
        "data-ho-O4372-filter-values-suchbegriff";

    /**
     * Gibt das data-Attribut an, in dem definiert ist, welche Seite der Value-Pagination nachgeladen wird.
     */
    public static readonly FilterValuesWeitereSeiteDataAttribut: string =
        "data-ho-O4372-filter-values-weitere-seite";

    /**
     * Gibt das data-Attribut des Containers an, in den weitere Einträge der Filter-Value-Pagination geladen werden
     */
    public static readonly FilterValuesWeitereContainerDataAttribut: string =
        "data-ho-O4372-filter-values-weitere-container";

    /**
     * Selector für die Filtertextbox eines Alpha-Attributs
     */
    public static readonly FilterTextboxDataAttribut: string =
        "data-ho-O4372-filter-textbox";

    /**
     * Gibt an, wie das data-Attribut für den Filtertext-Button heißt
     */
    public static readonly FilterTextboxButtonDataAttribut: string =
        "data-ho-O4372-filter-textbox-button";

    /**
     * Gibt den Selector zum Auffinden der ausgewählten Values eines Filters an
     */
    public static readonly FilterAusgewaehlteValuesDataAttribut: string =
        "data-ho-O4372-filter-auswahl";

    /**
     * Gibt den Selector für einen Slider-Container an
     */
    public static readonly FilterSliderDataAttribut: string =
        "data-ho-O4372-filter-slider";

    /**
     * data-Attribut des Zurücksetzen-Buttons eines Filters
     */
    public static readonly FilterResetDataAttribut: string =
        "data-ho-O4372-filter-reset";

    /**
     * Name des click-Events für die Artikelsuche
     */
    public static readonly EventNameClick: string =
        "click.holteronline-artikelsuche";

    /**
     * Name des keypress-Events für die Artikelsuche
     */
    public static readonly EventNameKeypress: string =
        "keypress.holteronline-artikelsuche";

    /**
     * Gibt den Namen des Events für ein DataTable-Draw an
     */
    public static readonly EventNameDataTableDraw: string = "draw.dt";

    /**
     * Gibt den Namen des Events für ein DataTable-Page an
     */
    public static readonly EventNameDataTablePage: string = "page.dt";

    /**
     * Gibt den Namen der Klasse an, die dafür sorgt, dass erweiterte Artikelinformationen angezeigt werden
     */
    public static readonly KlasseZusatzInfoAnzeigen: string = "shown";
}
