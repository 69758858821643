/**
 * Stellt Variablen bereit, um bestimmte Funktionen gezielt testen zu können.
 */
export class Debug {
    // Zur Info: Alle Variablen hier sollen so definiert sein, dass true bedeutet, dass das Feature deaktiviert ist.

    /**
     * Gibt an, ob das Plugin PrintThis im Modus Debug ausgeführt werden soll
     */
    // tslint:disable-next-line:variable-name
    public static readonly PrintThis_Debugmodus: boolean = true;

    /**
     * Gibt an, ob der Browsersupport ausgehebelt wird.
     * true: Browser wird als nicht unterstützter Browser gesehen
     * false: Übliche Prüflogik
     */
    public static readonly BrowserSupportDeaktivieren: boolean = false;

    /**
     * Gibt an, ob Oxomi im Debug-Modus initialisiert werden soll, oder nicht
     */
    public static readonly OxomiDebugModus: boolean = true;
}
