import * as $j from "jquery";
import { ArtikelsucheSpaltenNamen } from "./enums/ArtikelsucheSpaltenNamen";
import { ArtikelsucheVorlagen } from "./enums/ArtikelsucheVorlagen";
import { KonstantenArtikelsuche } from "./konstanten/konstantenArtikelsuche";

/**
 * Hilfsfunktionen für [[Artikelsuche]] um die Komplexität der Logik in der Klasse zu reduzieren
 */
export class ArtikelsucheHilfsfunktionen {
    private $layoutRoot: JQuery;

    constructor($layoutRoot: JQuery) {
        this.$layoutRoot = $layoutRoot;
    }

    /**
     * Gibt an, welche Such-ID in der aktuellen Seite ausgeführt worden ist
     */
    public get UkHonsu(): string {
        return (
            $j(
                KonstantenArtikelsuche.UkHonsuSelector,
                this.$layoutRoot
            ).val() || ""
        )
            .toString()
            .trim();
    }

    /**
     * Gibt an, nach welcher spalte die aktuelle Seite sortiert worden ist
     */
    public get Sortierung(): number {
        return (
            parseInt(
                ($j("#O4370_sort", this.$layoutRoot).val() || "").toString(),
                10
            ) - 1
        );
    }

    /**
     * Gibt die aktuelle Sortierrichtung zurück
     */
    public get SortierRichtung(): string {
        return this.SortierRichtungAbsteigend ? "desc" : "acs";
    }

    /**
     * Gibt an, in welche Richtung die aktuelle Seite sortiert worden ist
     */
    public get SortierRichtungAbsteigend(): boolean {
        let sortierung = ($j("#O4370_sortdesc", this.$layoutRoot).val() || "")
            .toString()
            .trim();
        return sortierung === "1";
    }

    /**
     * Gibt an, auf welcher Seite die aktuelle Anzeige steht
     */
    public get Seite(): number {
        let seite = parseInt(
            ($j("#O4370_seite", this.$layoutRoot).val() || "").toString(),
            10
        );

        if (seite > 0) {
            // Von der Seite 1 abziehen, damit der Index passt
            seite -= 1;
        }

        return seite;
    }

    /**
     * Gibt zurück, wieviele Items pro Seite angezeigt werden sollen
     */
    public get Limit(): number {
        let limit = parseInt(
            ($j("#O4370_limit", this.$layoutRoot).val() || "").toString(),
            10
        );

        if (limit <= 0) {
            // Limit holen, das global definiert worden ist
            limit = parseInt(
                ($j("#datatable-pagelength").val() || "").toString(),
                10
            );
        }

        if (limit <= 0 || limit > 100) {
            // Standard-Limit ist 50
            limit = 50;
        }

        return limit;
    }

    /**
     * Gibt den berechneten Start der Anzeige zurück
     */
    public get DisplayStart(): number {
        return this.Seite * this.Limit;
    }

    /**
     * Ermittelt das Markup der angegebenen Vorlage
     * @param Vorlage Vorlage, die ermittelt wird
     */
    public HoleVorlage(Vorlage: ArtikelsucheVorlagen): string {
        return $j(Vorlage, this.$layoutRoot).html() || "";
    }

    /**
     * Ermittelt für eine angegebene Spalte das Zeilen-Markup
     * @param Spaltenname Funktionsname der Spalte, für die das Markup generiert wird
     * @param JsonDaten Json-Daten, mit denen die Spalte generiert wird
     */
    public HoleTabelleSpalteRendered(
        Spaltenname: string,
        JsonDaten: ArtikelSucheInterfaces.IArtikelSucheJsonDaten
    ): string {
        let spaltenInhalt: string = "";

        switch (Spaltenname) {
            case ArtikelsucheSpaltenNamen.Toggle:
                spaltenInhalt = this.HoleVorlage(ArtikelsucheVorlagen.Toggle);
                break;
            case ArtikelsucheSpaltenNamen.Bild:
                spaltenInhalt = this.HoleVorlage(ArtikelsucheVorlagen.Bild);
                break;
            case ArtikelsucheSpaltenNamen.Artikel:
                spaltenInhalt = this.HoleVorlage(ArtikelsucheVorlagen.Artikel);
                break;
            case ArtikelsucheSpaltenNamen.Lagerware:
                if (JsonDaten.cdlw === "L") {
                    spaltenInhalt = this.HoleVorlage(
                        ArtikelsucheVorlagen.Lagerware
                    );
                }
                break;
            case ArtikelsucheSpaltenNamen.Menge:
                spaltenInhalt = this.HoleVorlage(ArtikelsucheVorlagen.Menge);
                if (JsonDaten.ehbe && JsonDaten.ehbe !== "") {
                    spaltenInhalt =
                        spaltenInhalt +
                        this.HoleVorlage(
                            ArtikelsucheVorlagen.MengeEinheitsbezeichnung
                        );
                }
                break;
            case ArtikelsucheSpaltenNamen.ArtikelnummerLieferant:
                spaltenInhalt = JsonDaten.artl;
                break;
            case ArtikelsucheSpaltenNamen.Preis:
                spaltenInhalt = JsonDaten.preis;
                break;
            default:
                break;
        }

        // Platzhalter in gefundenem Markup ersetzen
        spaltenInhalt = spaltenInhalt.replace(/(\/%pos%\/)+/g, JsonDaten.index);
        spaltenInhalt = spaltenInhalt.replace(/(\/%artn%\/)+/g, JsonDaten.artn);
        spaltenInhalt = spaltenInhalt.replace(
            /(\/%artnurl%\/)+/g,
            encodeURIComponent(JsonDaten.artn)
        );
        spaltenInhalt = spaltenInhalt.replace(
            /(\/%bezeichnung%\/)+/g,
            JsonDaten.bez
        );
        spaltenInhalt = spaltenInhalt.replace(/(\/%ehbe%\/)+/g, JsonDaten.ehbe);
        spaltenInhalt = spaltenInhalt.replace(
            /(\/%bildg%\/)+/g,
            JsonDaten.bild.gross
        );
        spaltenInhalt = spaltenInhalt.replace(
            /(\/%bildk%\/)+/g,
            JsonDaten.bild.klein
        );

        return spaltenInhalt;
    }

    /**
     * Ermittelt für eine Spalte das Set von Klassen, das zusätzlich angewendet werden soll
     * @param Spaltenname Funktionsname der Spalte, für die die Klasse ermittelt wird
     */
    public HoleTabelleSpalteKlasse(Spaltenname: string): string {
        let spaltenKlasse: string = "";

        switch (Spaltenname) {
            case ArtikelsucheSpaltenNamen.Toggle:
                spaltenKlasse = "no-print";
                break;
            case ArtikelsucheSpaltenNamen.Preis:
                spaltenKlasse = "dt-right";
                break;
            default:
                break;
        }

        return spaltenKlasse;
    }

    /**
     * Öffnet einen angegebenen Filter in der Oberfläche
     * @param FilterName Name des Filters, der geöffnet werden soll
     */
    public ArtikelFilterOeffnen(FilterName: string): void {
        // Header und Content finden zum Umschalten der Sichtbarkeit
        let $filterHeader = $j(
            "[" +
                KonstantenArtikelsuche.FilterHeaderDataAttribut +
                "='" +
                FilterName +
                "']",
            this.$layoutRoot
        );
        let $filterContent = $j(
            "[" +
                KonstantenArtikelsuche.FilterContainerDataAttribut +
                "='" +
                FilterName +
                "']",
            this.$layoutRoot
        );

        $filterContent.css("height", "auto");
        // Symbol im Header-Bereich umschalten
        $filterHeader.children("img").removeClass("closed");
    }

    /**
     * Schließt einen angegebenen Filter in der Oberfläche
     * @param FilterName Name des Filters, der geöffnet werden soll
     */
    public ArtikelFilterSchliessen(FilterName: string): void {
        // Header und Content finden zum Umschalten der Sichtbarkeit
        let $filterHeader = $j(
            "[" +
                KonstantenArtikelsuche.FilterHeaderDataAttribut +
                "='" +
                FilterName +
                "']",
            this.$layoutRoot
        );
        let $filterContent = $j(
            "[" +
                KonstantenArtikelsuche.FilterContainerDataAttribut +
                "='" +
                FilterName +
                "']",
            this.$layoutRoot
        );

        $filterContent.css("height", "0");
        // Symbol im Header-Bereich umschalten
        $filterHeader.children("img").addClass("closed");
    }

    /**
     * Gleicht die Auswahlen in einem Filter mit dem Hidden Field ab und gibt den gefundenen Filter-String zurück
     * @returns Wert OHNE Encoding. Vor dem Übermitteln an die Schnittstelle URL-encodieren.
     */
    public ArtikelFilterInHiddenFieldAktualisieren(FilterName: string): string {
        // Filterstring-Element finden
        let $hiddenFilterStringElement = $j(
            "[" +
                KonstantenArtikelsuche.FilterAusgewaehlteValuesDataAttribut +
                "='" +
                FilterName +
                "']",
            this.$layoutRoot
        );
        let filterString: string = "";

        if ($hiddenFilterStringElement?.length) {
            // Aktuellen Filter-Wert ermitteln
            filterString = decodeURIComponent(
                ($hiddenFilterStringElement.val() || "").toString()
            );

            if (
                $j(
                    "[" +
                        KonstantenArtikelsuche.FilterSliderDataAttribut +
                        "='" +
                        FilterName +
                        "']",
                    this.$layoutRoot
                ).length
            ) {
                // Attribut ist numerisch -> Slider-Grenzen lesen und in Filterstring ausgeben
                let $sliderContainer = $j(
                    "[" +
                        KonstantenArtikelsuche.FilterSliderDataAttribut +
                        "='" +
                        FilterName +
                        "']",
                    this.$layoutRoot
                );
                let $slider = $j("[data-ho-toggle='slider']", $sliderContainer);

                let sliderMinGewaehlt: number = 0;
                let sliderMaxGewaehlt: number = 0;
                let sliderMinGrenze: number = parseInt(
                    $slider.attr("data-slider-min") ?? "",
                    10
                );
                let sliderMaxGrenze: number = parseInt(
                    $slider.attr("data-slider-max") ?? "",
                    10
                );

                let minTextboxSelector = $slider.attr(
                    "data-ho-slider-min-textbox"
                );
                if (minTextboxSelector?.length) {
                    let $minTextbox = $j(minTextboxSelector, this.$layoutRoot);
                    sliderMinGewaehlt = parseInt(
                        ($minTextbox.val() || "").toString(),
                        10
                    );
                }

                let maxTextboxSelector = $slider.attr(
                    "data-ho-slider-max-textbox"
                );
                if (maxTextboxSelector?.length) {
                    let $maxTextbox = $j(maxTextboxSelector, this.$layoutRoot);
                    sliderMaxGewaehlt = parseInt(
                        ($maxTextbox.val() || "").toString(),
                        10
                    );
                }

                // Prüfen ob im Slider was ausgewählt worden ist
                if (
                    sliderMinGewaehlt !== sliderMinGrenze ||
                    sliderMaxGewaehlt !== sliderMaxGrenze
                ) {
                    // Filterstring setzt sich zusammen aus Min+Max
                    filterString =
                        sliderMinGewaehlt.toString(10) +
                        "-" +
                        sliderMaxGewaehlt.toString(10);
                } else {
                    // Filterstring leeren -> Slider wurde zurückgesetzt
                    filterString = "";
                }
            } else if (
                $j(
                    "[" +
                        KonstantenArtikelsuche.FilterValuesListDataAttribut +
                        "='" +
                        FilterName +
                        "']",
                    this.$layoutRoot
                ).length
            ) {
                // Attribut ist Alpha -> alle Checkboxen durchgehen
                let filterStringArray: string[] = filterString.split(";");

                let $filterListe = $j(
                    "[" +
                        KonstantenArtikelsuche.FilterValuesListDataAttribut +
                        "='" +
                        FilterName +
                        "']",
                    this.$layoutRoot
                );

                // Liste aller ausgewählten Checkboxen im Container zusammenstellen
                $filterListe
                    .find("input[type=checkbox]")
                    .each((itemIndex: number, itemElem: HTMLInputElement) => {
                        let itemWert: string = decodeURIComponent(
                            ($j(itemElem).val() || "").toString().trim()
                        );
                        let arrayIndex: number =
                            filterStringArray.indexOf(itemWert);

                        if (itemElem.checked) {
                            // Value des Items der Liste hinzufügen, wenn diese noch nicht vorhanden ist
                            if (arrayIndex < 0) {
                                // Element nicht vorhanden -> hinzufügen
                                filterStringArray.push(itemWert);
                            }
                        } else if (arrayIndex > -1) {
                            filterStringArray.splice(arrayIndex, 1);
                        }
                    });
                // Elemente zusammenführen in Array
                filterString = filterStringArray.join(";");
                // Führende Semikolons aus String entfernen
                filterString = filterString.replace(/^[;]+|[;]+$/g, "");
            }

            // Filter-String encoden
            filterString = encodeURIComponent(filterString);

            // Filter-String in Oberfläche zurückschreiben
            $hiddenFilterStringElement.val(filterString);
        }

        return filterString;
    }

    /**
     * Sorgt dafür, dass die Anzeige eines Filters im Frontend an die im versteckten Filter-String enthaltenen Werte angeglichen wird.
     * @param FilterName Name des Filters, dessen Frontend aktualisiert wird
     */
    public ArtikelFilterFrontendMitHiddenFieldAktualisieren(
        FilterName: string
    ): void {
        // Filterstring-Element finden
        let $hiddenFilterStringElement = $j(
            "[" +
                KonstantenArtikelsuche.FilterAusgewaehlteValuesDataAttribut +
                "='" +
                FilterName +
                "']",
            this.$layoutRoot
        );
        let filterString: string = "";

        if ($hiddenFilterStringElement?.length) {
            // Aktuellen Filter-Wert ermitteln
            filterString = decodeURIComponent(
                ($hiddenFilterStringElement.val() || "").toString()
            );

            if (
                $j(
                    "[" +
                        KonstantenArtikelsuche.FilterSliderDataAttribut +
                        "='" +
                        FilterName +
                        "']",
                    this.$layoutRoot
                ).length
            ) {
                // Attribut ist numerisch -> Slider-Grenzen berechnen und setzen
                let $sliderContainer = $j(
                    "[" +
                        KonstantenArtikelsuche.FilterSliderDataAttribut +
                        "='" +
                        FilterName +
                        "']",
                    this.$layoutRoot
                );
                let $slider = $j("[data-ho-toggle='slider']", $sliderContainer);

                let sliderMinWert: number = 0;
                let sliderMaxWert: number = 0;
                let sliderMinGrenze: number = parseInt(
                    $slider.attr("data-slider-min") ?? "",
                    10
                );
                let sliderMaxGrenze: number = parseInt(
                    $slider.attr("data-slider-max") ?? "",
                    10
                );

                // Werte aus Filter-String auslesen
                if (filterString.length > 0) {
                    let filterSplit = filterString.split("-");
                    sliderMinWert = parseInt(filterSplit[0], 10);
                    sliderMaxWert = parseInt(filterSplit[1], 10);
                }

                if (sliderMaxWert < sliderMinWert) {
                    // Wenn Werte vertauscht sind swappen
                    let dummy = sliderMaxWert;
                    sliderMaxWert = sliderMinWert;
                    sliderMinWert = dummy;
                }

                // Prüfen ob Grenzen eingehalten werden
                if (sliderMinWert < sliderMinGrenze) {
                    sliderMinWert = sliderMinGrenze;
                }
                if (sliderMaxWert > sliderMaxGrenze || sliderMaxWert === 0) {
                    sliderMaxWert = sliderMaxGrenze;
                }

                // Slider mit Auswahl befüllen und Change-Event auslösen (damit zB Textboxen aktualisiert werden)
                $slider
                    .slider("setValue", [sliderMinWert, sliderMaxWert])
                    .trigger("change");
            } else if (
                $j(
                    "[" +
                        KonstantenArtikelsuche.FilterValuesListDataAttribut +
                        "='" +
                        FilterName +
                        "']",
                    this.$layoutRoot
                ).length
            ) {
                // Attribut ist Alpha -> alle Checkboxen überprüfen
                let filterStringArray: string[] = filterString.split(";");

                let $filterListe = $j(
                    "[" +
                        KonstantenArtikelsuche.FilterValuesListDataAttribut +
                        "='" +
                        FilterName +
                        "']",
                    this.$layoutRoot
                );

                // Liste aller Checkboxen im Container durchgehen
                $filterListe
                    .find("input[type=checkbox]")
                    .each((itemIndex: number, itemElem: HTMLInputElement) => {
                        let itemWert: string = decodeURIComponent(
                            ($j(itemElem).val() || "").toString().trim()
                        );
                        let arrayIndex: number =
                            filterStringArray.indexOf(itemWert);

                        if (arrayIndex > -1) {
                            // Element ist enthalten -> checked setzen
                            itemElem.checked = true;
                        } else {
                            // Element ist nicht enthalten -> checked false setzen
                            itemElem.checked = false;
                        }
                    });
            }
        }
    }
}
