// tslint:disable:comment-format
///<amd-dependency path="bootstrap.slider" />
///<amd-dependency path="bloodhound" />
///<amd-dependency path="dataTables.fixedColumns" />
///<amd-dependency path="jquery.floatingscroll" />
///<amd-dependency path="slick" />
// tslint:enable:comment-format

import * as $j from "jquery";
import { Aktionen } from "./aktionen";
import { Artikelsuche } from "./artikelsuche";
import { ArtikelsucheVersion2 } from "./artikelsucheVersion2";
import { ArtikelsucheZustand } from "./artikelsucheZustand";
import { AS400Layer } from "./as400Layer";
import { Browsertyp } from "./browsertyp";
import { ArtikelInformationTyp } from "./enums/ArtikelInformationsTyp";
import { KatalogSeitenTyp } from "./enums/KatalogSeitenTyp";
import { KeyEvent } from "./events/keyEvent";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { Katalog } from "./katalog";
import { Konstanten } from "./konstanten/konstanten";
import { KonstantenArtikeldetails } from "./konstanten/konstantenArtikeldetails";
import { Powersuche } from "./powersuche";
import { Warenkorb } from "./warenkorb";

/**
 * Stellt Funktionen bereit, die nur für einzelne Seiten relevant sind
 */
export class SeitenspezifischeFunktionen {
    public static readonly INSTANCE = new SeitenspezifischeFunktionen();

    private constructor() {}

    /**
     * Überprüft, ob für die aktuelle Seite spezifische Layout-Initialisierungen stattfinden müssen.
     */
    public PruefeLayout(ProgrammName: string, $layoutRoot: JQuery) {
        // Am Anfang jeder Layoutprüfung eventuell liegengebliebene Events bereinigen
        this.BereinigeLiegengebliebeneEvents();

        // Unterscheiden, welche Seite aufgerufen worden ist
        switch (ProgrammName.substring(0, 5)) {
            case "O4000":
                this.InitLogin($layoutRoot);
                break;
            case "O4004":
                this.InitStartseite($layoutRoot);
                this.PruefeVollbildAktion($layoutRoot);
                break;
            case "O4008":
                // Artikeldetails
                this.InitArtikelDetails($layoutRoot);
                break;
            case "O4018":
                this.InitSuchbaum($layoutRoot);
                break;
            case "O4039":
                this.InitLieferinformationen($layoutRoot);
                break;
            case "O4045":
                this.InitBestellbestaetigung($layoutRoot);
                break;
            case "O4055":
                this.InitPreisanfrageBestaetigung($layoutRoot);
                break;
            case "O4065":
                this.InitSuchbaum($layoutRoot);
                break;
            case "O4068":
                this.InitMassfreigabeBestaetigen($layoutRoot);
                break;
            case "O4071":
                this.InitOffertDetails($layoutRoot);
                break;
            case "O4094":
                this.InitUrgenzAnfordern($layoutRoot);
                break;
            case "O4181":
                this.InitKojenSuche($layoutRoot);
                break;
            case "O4190":
                // Offertdetails
                this.InitDatanormButton($layoutRoot);
                break;
            case "O4091":
                this.InitKommissionsauftragsDetails($layoutRoot);
                break;
            case "O4226":
                this.InitOnlinePlusEinstellungen($layoutRoot);
                break;
            case "O4266":
                this.InitKontakte($layoutRoot);
                break;
            case "O4280":
                this.InitBenutzereinstellungen($layoutRoot);
                break;
            case "O4370":
                // Artikelsuche Filterbereich initialisieren
                // Initialisierung der DataTable erfolgt in [[dataTableFunktionen]]
                let artikelSuche: Artikelsuche = new Artikelsuche($layoutRoot);
                artikelSuche.InitFilterBereich();
                this.PruefeVollbildAktion($layoutRoot);
                break;
            case "O4380":
                // Für den Fall, dass die Artikelsuche mit einem Suchbegriff gekommen ist,
                //  wird vor dem Durchführen der Suche der Suchbegriff in die Rahmenlayout-Textbox
                //  übernommen, weil eine spätere Prüfung dazu führen würde,
                //  dass ein eingegebener Suchbegriff nicht mehr entfernt werden kann.
                let uk: string = ($j("#O4380_ukhonsf").val() || "").toString();
                let sort: string = ($j("#O4380_sort").val() || "").toString();
                let page: string = ($j("#O4380_page").val() || "").toString();
                let warehouse: string = (
                    $j("#O4380_wh").val() || "0"
                ).toString();
                if (warehouse.length === 1) {
                    warehouse = "0" + warehouse;
                }
                $j("#O4380_lage").val(warehouse).trigger("change");
                let suchbegriff: string = (
                    $j("#O4380_suchbegriff").val() || ""
                ).toString();

                ArtikelsucheVersion2.INSTANCE.initEvents();
                ArtikelsucheZustand.processHtmlFields({
                    uk,
                    suchbegriff,
                    sort,
                    page,
                    warehouse,
                }).done((zustand) => {
                    ArtikelsucheVersion2.INSTANCE.suchen(zustand);
                    Powersuche.INSTANCE.SetzeSuchbegriff(zustand.suchbegriff);
                });

                this.PruefeVollbildAktion($layoutRoot);
                break;
            case "O4600":
                this.InitSchnittstellenTestseite($layoutRoot);
                break;
            case "O4615":
                this.InitKataloge($layoutRoot);
                break;
            case "O4620":
                let aktionen: Aktionen = new Aktionen($layoutRoot);
                aktionen.InitAktionsUebersicht();
                break;
            case "O4890":
                this.InitPraemienweltDatenschutzerklaerung($layoutRoot);
                break;
            case "O4910":
                this.InitBenutzeradministrationHauptansicht($layoutRoot);
                break;
            case "O4911":
                this.InitBenutzeradministrationDetails($layoutRoot);
                break;
            case "O4920":
                this.InitNeuesPasswortFestlegen($layoutRoot);
                break;
            case "TEST0":
                this.ApiAuthentifizierung($layoutRoot);
                break;
            default:
                break;
        }
    }

    /**
     * Entfernt Events, die unter Umständen nicht bereinigt worden sind
     */
    private BereinigeLiegengebliebeneEvents() {
        // Window-Events vom Suchbaum entfernen
        $j(window).off(".holteronline-suchbaum");
    }

    /**
     * Initialisiert spezielle Logik für die Artikeldetails-Seite
     */
    private InitArtikelDetails($layoutRoot: JQuery) {
        // Funktionen zum Ein- und Ausblenden von preisrelevanten Elementen
        const $buttonAnzeigenArea = $j(
            "[data-ho-O4008-preisbutton-einblenden-container]",
            $layoutRoot
        );

        if ($buttonAnzeigenArea.length) {
            const $preisElemente = $j(
                "[data-ho-O4008-preisbutton-sichtbarkeit]",
                $layoutRoot
            );

            const $buttonAnzeigen = $j(
                "#O4008_preisbutton_einblenden",
                $layoutRoot
            );
            const $buttonAusblenden = $j(
                "#O4008_preisbutton_ausblenden",
                $layoutRoot
            );

            const anzeigen = () => {
                $preisElemente.show();
                $buttonAusblenden.show();
                $buttonAnzeigen.hide();
                $buttonAnzeigenArea.hide();
            };

            const ausblenden = () => {
                $preisElemente.hide();
                $buttonAusblenden.hide();
                $buttonAnzeigen.show();
                $buttonAnzeigenArea.show();
            };

            $buttonAnzeigen
                .off("click.holteronline-artikeldetails-preisanzeige")
                .on("click.holteronline-artikeldetails-preisanzeige", anzeigen);
            $buttonAusblenden
                .off("click.holteronline-artikeldetails-preisanzeige")
                .on(
                    "click.holteronline-artikeldetails-preisanzeige",
                    ausblenden
                );

            ausblenden();
        }

        // Button für Online Plus
        if ($j("#O4008_onlineplus", $layoutRoot).length) {
            $j("#O4008_onlineplus", $layoutRoot)
                .off("click.holteronline")
                .on("click.holteronline", (ereignis) => {
                    let $button = $j(ereignis.currentTarget);

                    AS400Layer.INSTANCE.InhaltSubmit(
                        new HoProgrammInfo({
                            Programm: $button.data("ho-pgmn"),
                            ButtonName: $button.data("ho-butt"),
                            AjaxDaten:
                                "&ARTNHT=" +
                                $j("#O4008_artn", $layoutRoot).val() +
                                "&BMGEHT=" +
                                $j("#O4008_bmge", $layoutRoot).val(),
                        }),
                        {}
                    );
                });
        }

        // Katalog-Integration
        // Benötigte Variablen einlesen
        let katalogArtikelnummer: string = "";
        let katalogLieferant: string = "";
        let katalogLieferantArtikelnummer: string = "";

        if (
            $j(KonstantenArtikeldetails.ArtikelnummerHolterSelector).length > 0
        ) {
            katalogArtikelnummer = $j(
                KonstantenArtikeldetails.ArtikelnummerHolterSelector
            ).val() as string;
        }
        if (
            $j(KonstantenArtikeldetails.ArtikelnummerLieferantSelector).length >
            0
        ) {
            katalogLieferantArtikelnummer = $j(
                KonstantenArtikeldetails.ArtikelnummerLieferantSelector
            ).val() as string;
        }
        if ($j(KonstantenArtikeldetails.LieferantNummerSelector).length > 0) {
            katalogLieferant = $j(
                KonstantenArtikeldetails.LieferantNummerSelector
            ).val() as string;
        }

        // Toggle, der aussagt, ob das Laden der Katalogseiten bereits einmal ausgeführt worden ist
        let initTab02Ausgefuehrt = false;
        // Methode zum Ausführen des Katalogseiten-Ladevorgangs
        let initTab02 = () => {
            // Methode nur ausführen wenn Tab noch nicht initialisiert worden ist
            if (initTab02Ausgefuehrt) {
                return;
            } else {
                initTab02Ausgefuehrt = true;
            }

            // Eigene Katalogseiten
            $j(KonstantenArtikeldetails.KatalogHolterContainerSelector).html(
                KonstantenArtikeldetails.KatalogHolterStatusTextVorher
            );

            Katalog.INSTANCE.HoleKatalogSeiten(
                KatalogSeitenTyp.Holter,
                KonstantenArtikeldetails.KatalogHolterContainerSelector,
                katalogArtikelnummer,
                katalogLieferantArtikelnummer,
                katalogLieferant
            ).fail(() => {
                $j(
                    KonstantenArtikeldetails.KatalogHolterContainerSelector
                ).html(KonstantenArtikeldetails.KatalogHolterStatusTextLeer);
            });

            // Fremd-Katalogseiten
            $j(
                KonstantenArtikeldetails.KatalogHerstellerContainerSelector
            ).html(KonstantenArtikeldetails.KatalogHerstellerStatusTextVorher);

            Katalog.INSTANCE.HoleKatalogSeiten(
                KatalogSeitenTyp.Extern,
                KonstantenArtikeldetails.KatalogHerstellerContainerSelector,
                katalogArtikelnummer,
                katalogLieferantArtikelnummer,
                katalogLieferant
            ).fail(() => {
                $j(
                    KonstantenArtikeldetails.KatalogHerstellerContainerSelector
                ).html(
                    KonstantenArtikeldetails.KatalogHerstellerStatusTextLeer
                );
            });
        };

        // Laden der Katalogseiten nur ausführen, wenn der Tab mit Katalogen der aktive ist
        if (
            $j(KonstantenArtikeldetails.Tab02Selector, $layoutRoot).hasClass(
                "active"
            )
        ) {
            initTab02();
        }

        let entferneTab04 = () => {
            // Nur wenn alle fehlschlagen den Tab entfernen
            $j(
                KonstantenArtikeldetails.Tab04Selector +
                    ", a[href='" +
                    KonstantenArtikeldetails.Tab04Selector +
                    "']"
            ).remove();
            pruefeObTabAktiviert();
        };

        let pruefeObTab05ZuEntfernenIstUndEntferneIhnGegebenenfalls = () => {
            // Prüfen ob es eigene Dokumente zum Download gibt
            if (
                $j(
                    KonstantenArtikeldetails.DownloadsEigeneDokumenteContainerSelector
                ).length === 0
            ) {
                // Es sind keine downloadbaren Dokumente vorhanden -> Tab entfernen
                $j(
                    KonstantenArtikeldetails.Tab05Selector +
                        ", a[href='" +
                        KonstantenArtikeldetails.Tab05Selector +
                        "']"
                ).remove();
                pruefeObTabAktiviert();
            }
        };

        let entferneTab06 = () => {
            // Nur wenn alle fehlschlagen den Tab entfernen
            $j(
                KonstantenArtikeldetails.Tab06Selector +
                    ", a[href='" +
                    KonstantenArtikeldetails.Tab06Selector +
                    "']"
            ).remove();
            pruefeObTabAktiviert();
        };

        // Stellt sicher, dass ein Tab aktiv ist
        let pruefeObTabAktiviert = () => {
            // Überprüfen, ob noch Tabs vorhanden sind
            if (
                !$j(KonstantenArtikeldetails.TabsHeadersSelector + " a").length
            ) {
                // Keine Tabs mehr vorhanden -> gesamten Tab-Bereich entfernen
                $j(KonstantenArtikeldetails.TabsContainerSelector).remove();
                return;
            }

            // Prüfen ob ein Tab aktiv
            if (
                !$j(KonstantenArtikeldetails.TabsHeadersSelector + " a.active")
                    .length
            ) {
                // Kein Tab aktiv -> ersten Tab aktiv machen
                $j(
                    KonstantenArtikeldetails.TabsHeadersSelector + " a:first"
                ).tab("show");
            }
        };

        // Verfügbarkeitsabhängige Logik-Abschnitte durchführen
        Katalog.INSTANCE.HoleArtikelInformationsVerfuegbarkeit(
            katalogArtikelnummer,
            katalogLieferantArtikelnummer,
            katalogLieferant
        )
            .done((jsonDaten) => {
                // Prüfen welche Tabs nicht mehr benötigt werden
                if (!jsonDaten.text && !jsonDaten.masterdata) {
                    entferneTab04();
                }

                if (!jsonDaten.attachment) {
                    pruefeObTab05ZuEntfernenIstUndEntferneIhnGegebenenfalls();
                }

                if (
                    !jsonDaten.image &&
                    !jsonDaten.video &&
                    !jsonDaten.gallery
                ) {
                    entferneTab06();
                }
            })
            .fail(() => {
                // Keine Oxomi-Daten vorhanden -> alle Tabs entfernen, die nicht mehr benötigt werden
                entferneTab04();
                pruefeObTab05ZuEntfernenIstUndEntferneIhnGegebenenfalls();
                entferneTab06();
            });

        // Variable, die anzeigt, ob der Tab bereits initialisiert worden ist
        let initTab04Ausgefuehrt = false;
        // Methode zum Initialisieren von Tab04
        let initTab04 = () => {
            // Methode nur ausführen wenn Tab noch nicht initialisiert worden ist
            if (initTab04Ausgefuehrt) {
                return;
            } else {
                initTab04Ausgefuehrt = true;
            }

            // Artikel-Langtext
            $j(
                KonstantenArtikeldetails.LangtextContainerSelector,
                $layoutRoot
            ).html(KonstantenArtikeldetails.LangtextStatusTextVorher);

            let $dLangtext = Katalog.INSTANCE.HoleArtikelInformationen(
                ArtikelInformationTyp.Langtext,
                KonstantenArtikeldetails.LangtextContainerSelector,
                katalogArtikelnummer,
                katalogLieferantArtikelnummer,
                katalogLieferant
            ).fail(() => {
                // Wenn Datenermittlung nichts ergeben hat, dann in den Container einen Blanko-Text schreiben
                $j(
                    KonstantenArtikeldetails.LangtextContainerSelector,
                    $layoutRoot
                ).html(KonstantenArtikeldetails.LangtextStatusTextLeer);
            });

            // Artikel-Masterdaten
            $j(
                KonstantenArtikeldetails.MasterdataContainerSelector,
                $layoutRoot
            ).html(KonstantenArtikeldetails.MasterdataStatusTextVorher);

            let $dMasterdata = Katalog.INSTANCE.HoleArtikelInformationen(
                ArtikelInformationTyp.Masterdata,
                KonstantenArtikeldetails.MasterdataContainerSelector,
                katalogArtikelnummer,
                katalogLieferantArtikelnummer,
                katalogLieferant
            ).fail(() => {
                // Wenn Datenermittlung nichts ergeben hat, dann in den Container einen Blanko-Text schreiben
                $j(
                    KonstantenArtikeldetails.MasterdataContainerSelector,
                    $layoutRoot
                ).html(KonstantenArtikeldetails.MasterdataStatusTextLeer);
            });
        };

        // Variable, die anzeigt, ob der Tab bereits initialisiert worden ist
        let initTab05Ausgefuehrt = false;
        // Methode zum Initialisieren von Tab05
        let initTab05 = () => {
            // Methode nur ausführen wenn Tab noch nicht initialisiert worden ist
            if (initTab05Ausgefuehrt) {
                return;
            } else {
                initTab05Ausgefuehrt = true;
            }

            // Downloadbare Artikel-Dokumente
            $j(
                KonstantenArtikeldetails.DownloadsContainerSelector,
                $layoutRoot
            ).html(KonstantenArtikeldetails.DownloadsStatusTextVorher);

            let $dDownloads = Katalog.INSTANCE.HoleArtikelInformationen(
                ArtikelInformationTyp.Dokumente,
                KonstantenArtikeldetails.DownloadsContainerSelector,
                katalogArtikelnummer,
                katalogLieferantArtikelnummer,
                katalogLieferant
            ).fail(() => {
                // Wenn Datenermittlung nichts ergeben hat, dann in den Container einen Blanko-Text schreiben
                $j(
                    KonstantenArtikeldetails.DownloadsContainerSelector,
                    $layoutRoot
                ).html(KonstantenArtikeldetails.DownloadsStatusTextLeer);
            });
        };

        // Variable, die anzeigt, ob der Tab bereits initialisiert worden ist
        let initTab06Ausgefuehrt = false;
        // Methode zum Initialisieren von Tab06
        let initTab06 = () => {
            // Methode nur ausführen wenn Tab noch nicht initialisiert worden ist
            if (initTab06Ausgefuehrt) {
                return;
            } else {
                initTab06Ausgefuehrt = true;
            }

            // Artikel-Bilder
            $j(
                KonstantenArtikeldetails.BilderContainerSelector,
                $layoutRoot
            ).html(KonstantenArtikeldetails.BilderStatusTextVorher);

            let $dBilder = Katalog.INSTANCE.HoleArtikelInformationen(
                ArtikelInformationTyp.Bilder,
                KonstantenArtikeldetails.BilderContainerSelector,
                katalogArtikelnummer,
                katalogLieferantArtikelnummer,
                katalogLieferant
            ).fail(() => {
                // Wenn Datenermittlung nichts ergeben hat, dann in den Container einen Blanko-Text schreiben
                $j(
                    KonstantenArtikeldetails.BilderContainerSelector,
                    $layoutRoot
                ).html(KonstantenArtikeldetails.BilderStatusTextLeer);
            });

            // Artikel-Videos
            $j(
                KonstantenArtikeldetails.VideosContainerSelector,
                $layoutRoot
            ).html(KonstantenArtikeldetails.VideosStatusTextVorher);

            let $dVideos = Katalog.INSTANCE.HoleArtikelInformationen(
                ArtikelInformationTyp.Videos,
                KonstantenArtikeldetails.VideosContainerSelector,
                katalogArtikelnummer,
                katalogLieferantArtikelnummer,
                katalogLieferant
            ).fail(() => {
                // Wenn Datenermittlung nichts ergeben hat, dann in den Container einen Blanko-Text schreiben
                $j(
                    KonstantenArtikeldetails.VideosContainerSelector,
                    $layoutRoot
                ).html(KonstantenArtikeldetails.VideosStatusTextLeer);
            });

            // Artikel-Exposees
            $j(
                KonstantenArtikeldetails.ExposeesContainerSelector,
                $layoutRoot
            ).html(KonstantenArtikeldetails.ExposeesStatusTextVorher);

            let $dExposees = Katalog.INSTANCE.HoleArtikelInformationen(
                ArtikelInformationTyp.Exposees,
                KonstantenArtikeldetails.ExposeesContainerSelector,
                katalogArtikelnummer,
                katalogLieferantArtikelnummer,
                katalogLieferant
            ).fail(() => {
                // Wenn Datenermittlung nichts ergeben hat, dann in den Container einen Blanko-Text schreiben
                $j(
                    KonstantenArtikeldetails.ExposeesContainerSelector,
                    $layoutRoot
                ).html(KonstantenArtikeldetails.ExposeesStatusTextLeer);
            });
        };

        // Methode zum Initialisiseren von Tab07
        let initTab07 = () => {
            let $item = $j(KonstantenArtikeldetails.Tab07NichtBefuelltSelector);

            if ($item.length) {
                // Daten wurden noch nicht geladen -> müssen jetzt geladen werden
                // Parameter für Aufruf sind am Item definiert, das bestimmt, ob der Container bereits befüllt worden ist
                AS400Layer.INSTANCE.LadeSeite(
                    new HoProgrammInfo({
                        Programm: $item.data("ho-pgmn"),
                        Parameter: $item.data("ho-params"),
                    }),
                    {
                        BlockContainerSelector: $item.data("ho-block"),
                        LadeanimationDeaktivieren: true,
                    }
                ).done(updateTab07);
            } else {
                updateTab07();
            }
        };

        // Laden der Katalogseiten nur ausführen, wenn der Tab mit Katalogen der aktive ist
        if (
            $j(KonstantenArtikeldetails.Tab07Selector, $layoutRoot).hasClass(
                "active"
            )
        ) {
            initTab07();
        }

        // Methode zum Berechnen von Positionen im tab07
        let updateTab07 = () => {
            // Window Resize auslösen für DataTable-FixedColumns
            // Nur ausführen wenn es fixierte Columns gibt
            if (
                $j(KonstantenArtikeldetails.Tab07TabelleSelector).data(
                    "ho-table-fixedcolumns"
                ) === true
            ) {
                $j(KonstantenArtikeldetails.Tab07TabelleSelector)
                    .dataTable()
                    .api()
                    .draw()
                    .fixedColumns();
            }

            // Hack um Rundungsfehler im Plugin zu lösen
            $j(".DTFC_LeftBodyLiner").css("overflow-y", "d-none");

            if (
                !$j(".fl-scrolls", $j(KonstantenArtikeldetails.Tab07Selector))
                    .length
            ) {
                // Floating-Scroll für Varianten-Tabelle aktivieren
                $j(
                    ".dataTables_scrollBody",
                    $j(KonstantenArtikeldetails.Tab07Selector)
                ).floatingScroll();
            }
        };

        // Beim Wechsel auf den Tab Artikelvarianten die Tabelle neu zeichnen
        $j('a[data-bs-toggle="tab"]').on("shown.bs.tab", (evt) => {
            let eventTarget = $j(evt.target).attr("href");
            switch (eventTarget) {
                case KonstantenArtikeldetails.Tab02Selector:
                    initTab02();
                    break;
                case KonstantenArtikeldetails.Tab04Selector:
                    initTab04();
                    break;
                case KonstantenArtikeldetails.Tab05Selector:
                    initTab05();
                    break;
                case KonstantenArtikeldetails.Tab06Selector:
                    initTab06();
                    break;
                case KonstantenArtikeldetails.Tab07Selector:
                    initTab07();
                    break;
                default:
                    break;
            }
        });

        // Am Ende dieser Logik überprüfen, ob es einen aktiven Tab gibt
        pruefeObTabAktiviert();
    }

    /**
     * Startseite Initialisieren
     */
    private InitStartseite($layoutRoot: JQuery) {
        let requests: Array<JQuery.Deferred<any>> = new Array<
            JQuery.Deferred<any>
        >();

        $j("[data-ho-startseite-loadwidget-active='true']", $layoutRoot).each(
            (idx, elem) => {
                let pgm = $j(elem).data("ho-startseite-loadwidget-programm");
                let container = "#" + $j(elem).data("ho-startseite-loadwidget");

                let ProgrammInfo = new HoProgrammInfo({
                    Programm: pgm,
                    Parameter: "",
                });

                let promise = AS400Layer.INSTANCE.LadeSeite(ProgrammInfo, {
                    BlockContainerSelector: container,
                    LadeanimationDeaktivieren: true,
                });

                requests.push(promise);
            }
        );

        // Wenn alle Requests erfolgreich zurückgekommen sind, dann die Startseite fertig initialisieren
        $j.when.apply(this, requests).then(() => {
            // Aktionsbereich: Slick-Plugin initialisieren
            $j("#O4004_aktionen", $layoutRoot).slick({
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                draggable: false,
                autoplay: true,
                autoplaySpeed: 6000,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 4,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                ],
            });
        });
    }

    private InitKommissionsauftragsDetails($layoutRoot: JQuery) {
        const applyDeliveryModeToUi = () => {
            const identifier = $j("[data-ho-O4091-deliveryMode]", $layoutRoot)
                .children("option:selected")
                .attr("data-ho-O4091-delivery-flag");

            $j("[data-ho-O4091-delivery-group]").addClass("d-none");
            $j(`[data-ho-O4091-delivery-group="${identifier}"]`).removeClass(
                "d-none"
            );
        };

        const switchDateMode = (newMode) => {
            $j(`[data-ho-O4091-dateMode]`).addClass("d-none");
            $j(`[data-ho-O4091-dateMode=${newMode}]`).removeClass("d-none");
            $j("[data-ho-O4091-dateMode-active]").val(newMode ?? "");
        };

        $j("[data-ho-O4091-deliveryMode]", $layoutRoot)
            .off("change.holteronline-O4091")
            .on("change.holteronline-O4091", applyDeliveryModeToUi.bind(this));

        $j("[data-ho-O4091-dateMode-switch]")
            .off("click.holteronline-O4091")
            .on("click.holteronline-O4091", (e) => {
                switchDateMode(
                    $j(e.currentTarget).attr("data-ho-O4091-dateMode-show")
                );
            });

        applyDeliveryModeToUi();
        switchDateMode($j("[data-ho-O4091-dateMode-active]").val());
    }
    /**
     * Initialisiert spezielle Logik für die Lieferinfomationen
     */
    private InitLieferinformationen($layoutRoot: JQuery) {
        // Methodendeklarationen für die Layout-Reorganisations-Events
        let lieferadresseChanged = () => {
            let abweichendeAdresse =
                $j(".switch", $layoutRoot).children("input").is(":checked") &&
                !$j("#O4039_lieferadresse", $layoutRoot).hasClass("d-none");

            if (abweichendeAdresse) {
                $j("#lieferadresse", $layoutRoot).removeClass("d-none");

                $j(
                    "[data-ho-O4039-disable-on-divergent-delivery]",
                    $layoutRoot
                ).prop("disabled", true);

                $j(
                    "[data-ho-O4039-add-disabled-class-on-divergent-delivery]",
                    $layoutRoot
                ).addClass("select-box-disabled");

                $j(
                    "[data-ho-O4039-show-on-divergent-delivery]",
                    $layoutRoot
                ).removeClass("d-none");
            } else {
                $j("#lieferadresse", $layoutRoot).addClass("d-none");

                $j(
                    "[data-ho-O4039-disable-on-divergent-delivery]",
                    $layoutRoot
                ).prop("disabled", false);

                $j(
                    "[data-ho-O4039-add-disabled-class-on-divergent-delivery]",
                    $layoutRoot
                ).removeClass("select-box-disabled");

                $j(
                    "[data-ho-O4039-show-on-divergent-delivery]",
                    $layoutRoot
                ).addClass("d-none");
            }
        };

        let updateVisibility = () => {
            let visibleElem = $j("#lmodht", $layoutRoot)
                .children("option:selected")
                .data("ho-show-options");

            $j(".delivery-info-options", $layoutRoot).addClass("d-none");
            $j(visibleElem)
                .not("[data-ho-show-options-always-hide='true']")
                .removeClass("d-none");

            if (
                $j(
                    `[data-ho-divergent-delivery-address-allowed-in-mode="${$j(
                        visibleElem
                    ).data("ho-show-options-target-for")}"]`,
                    $layoutRoot
                ).length
            ) {
                $j("#O4039_lieferadresse", $layoutRoot).removeClass("d-none");
            } else {
                $j("#O4039_lieferadresse", $layoutRoot).addClass("d-none");
            }
            lieferadresseChanged();
        };

        let HinweisAutoKommission = () => {
            let $autoKommission = $j("#autokom", $layoutRoot);

            if (($j("#textht").val()?.toString()?.trim() || "") === "") {
                $autoKommission.addClass("invisible");
            } else {
                $autoKommission.removeClass("invisible");
            }
        };

        let BaustellZustellungsKostenErmitteln = () => {
            // Es müssen die Baustellzustellungskosten neu ermittelt werden
            let SuchDaten = $layoutRoot
                .find(Konstanten.ProgrammDatenSerialisierungTypen)
                .serialize();

            let ProgrammInfo = new HoProgrammInfo({
                Programm: "O4060W",
                Parameter: SuchDaten,
            });

            AS400Layer.INSTANCE.LadeSeite(ProgrammInfo, {
                BlockContainerSelector: "#O4039_Baustellzustellungskosten",
                LadeanimationDeaktivieren: true,
            });
        };

        // Initialisierungen einmal ausführen
        HinweisAutoKommission();
        updateVisibility();
        BaustellZustellungsKostenErmitteln();

        // Zusätzliche Events anhängen, bei denen die Init-Logik neu ausgeführt werden muss
        $j("#textht", $layoutRoot)
            .off(".holteronline-lieferinfo")
            .on("change.holteronline-lieferinfo", (e) => {
                HinweisAutoKommission();
            })
            .on("blur.holteronline-lieferinfo", (e) => {
                HinweisAutoKommission();
            })
            .on("focus.holteronline-lieferinfo", (e) => {
                $j("#autokom", $layoutRoot).removeClass("invisible");
            });

        $j("#lmodht", $layoutRoot)
            .off("change.holteronline-lieferinfo")
            .on("change.holteronline-lieferinfo", updateVisibility);

        $j("#lieferadresse_switch", $layoutRoot)
            .off("change.holteronline-lieferinfo")
            .on("change.holteronline-lieferinfo", lieferadresseChanged);

        // Beim Ändern der Versand-Plz die Baustellzustellungskosten neu ermitteln
        $j("[data-ho-O4039-baustellenzustellungskosten]", $layoutRoot)
            .off(".holteronline-lieferinfo")
            .on(
                "input.holteronline-lieferinfo",
                BaustellZustellungsKostenErmitteln
            )
            .on(
                "change.holteronline-lieferinfo",
                BaustellZustellungsKostenErmitteln
            );

        $j("[data-ho-show-options-switch-always-hidden]", $layoutRoot)
            .off("click.holteronline-lieferinfo")
            .on("click.holteronline-lieferinfo", (e) => {
                let $elementsToHide = $j(e.currentTarget).parents(
                    "[data-ho-show-options-target-for]"
                );

                let targetIdentifier = $elementsToHide.data(
                    "ho-show-options-target-for"
                );

                let $elementsToShow = $j(
                    `[data-ho-show-options-target-for="${targetIdentifier}"]`
                ).filter("[data-ho-show-options-always-hide='true']");

                $elementsToShow.attr(
                    "data-ho-show-options-always-hide",
                    "false"
                );
                $elementsToHide.attr(
                    "data-ho-show-options-always-hide",
                    "true"
                );

                $j(
                    `[data-ho-show-options-modus-identifier="${targetIdentifier}"]`
                ).val($elementsToShow.data("ho-show-options-modus"));

                updateVisibility();
            });
    }

    /**
     * Führt spezielle Logik zum Anzeigen der Bestellbestätigung aus.
     */
    private InitBestellbestaetigung($layoutRoot: JQuery) {
        Warenkorb.INSTANCE.Aktualisieren();
    }

    /**
     * Initialisiert spezielle Logik für die Preisanfrage-Bestätigung
     */
    private InitPreisanfrageBestaetigung($layoutRoot: JQuery) {
        Warenkorb.INSTANCE.Aktualisieren();
    }

    /**
     * Initialisiert spezielle Logik für die Suchbaum-Seiten (Herstellersuchbaum, Artikelsuchbaum)
     */
    private InitSuchbaum($layoutRoot: JQuery) {
        // Funktion, die die Position der fixierten Buchstabenleiste berechnet
        let berechneSuchbaumPos = () => {
            if (
                $j(".fixed-letters", $layoutRoot).length > 0 &&
                $j(".letter-navigation", $layoutRoot).is(":visible")
            ) {
                // Suchbaum-Scrolling aktivieren
                $j(window)
                    .off("scroll.holteronline-suchbaum")
                    .on("scroll.holteronline-suchbaum", () => {
                        // Scroll-Logik
                        // Soll-Offset berechnen
                        let scrollTop =
                            parseInt(
                                $j(".content-header", $layoutRoot).css(
                                    "margin-top"
                                ),
                                10
                            ) +
                            ($j(
                                ".content-header",
                                $layoutRoot
                            ).outerHeight() as number) +
                            parseInt(
                                $j(".content-box", $layoutRoot).css(
                                    "padding-top"
                                ),
                                10
                            ) +
                            ($j(".hierarchy", $layoutRoot).height() as number);

                        if (($j(window).scrollTop() as number) > scrollTop) {
                            // Fixierung des Headers hinzufügen
                            let top =
                                ($j("header").height() as number) -
                                parseInt(
                                    $j(".content-box", $layoutRoot).css(
                                        "padding-top"
                                    ),
                                    10
                                ) -
                                ($j(
                                    ".hierarchy",
                                    $layoutRoot
                                ).height() as number);

                            $j(".content-box", $layoutRoot).addClass("fixed");
                            $j(".content-box", $layoutRoot).css("top", top);
                            $j(".content-filter", $layoutRoot).css(
                                "padding-top",
                                ($j(
                                    ".content-box",
                                    $layoutRoot
                                ).outerHeight() as number) +
                                    parseInt(
                                        $j(".content-box", $layoutRoot).css(
                                            "margin-bottom"
                                        ),
                                        10
                                    )
                            );
                        } else {
                            // Fixierung des Headers wieder entfernen
                            $j(".content-box", $layoutRoot).removeClass(
                                "fixed"
                            );
                            $j(".content-filter", $layoutRoot).css(
                                "padding-top",
                                10
                            );
                        }
                    });
            } else {
                // Suchbaum-Scrolling deaktivieren
                $j(window).off("scroll.holteronline-suchbaum");
            }
        };

        // Funktion ausführen, damit auch ohne Window-Resize die fixierte Leiste initialisiert ist
        berechneSuchbaumPos();

        // Beim Verändern der Fenstergröße die Suchbaumleiste neu berechnen
        $j(window)
            .off("resize.holteronline-suchbaum")
            .on("resize.holteronline-suchbaum", () => {
                berechneSuchbaumPos();
            });
    }

    /**
     * Initialisiert die Seite der Kojensuche
     */
    private InitKojenSuche($layoutRoot: JQuery) {
        let $buttonKojenuebersicht = $j(
            "#O4181_button_kojenuebersicht",
            $layoutRoot
        );
        let $selectLagernummer = $j("#O4181_lageht", $layoutRoot);

        // Wenn Button geklickt wird die Kojenübersicht öffnen
        $buttonKojenuebersicht
            .off("click.holteronline-kojensuche")
            .on("click.holteronline-kojensuche", () => {
                // Seite für Kojensuche laden
                AS400Layer.INSTANCE.LadeSeite(
                    new HoProgrammInfo({
                        Programm: "O4180W",
                        Parameter: "lage=" + $selectLagernummer.val(),
                    }),
                    {}
                );
            });
    }

    /**
     * Verarbeitet den Donwload link für die Datanorm Seite
     */
    private InitDatanormButton($layoutRoot: JQuery) {
        // Download link aufrufen
        if (
            $j("#O4190_clickedlink", $layoutRoot).attr("href") !== null &&
            $j("#O4190_clickedlink", $layoutRoot).attr("href") !== undefined
        ) {
            if (
                $j("#O4190_clickedlink", $layoutRoot).attr("target") ===
                "_blank"
            ) {
                window.open(
                    $j("#O4190_clickedlink", $layoutRoot).attr("href"),
                    "_blank"
                );
            } else {
                window.location.href = $j(
                    "#O4190_clickedlink",
                    $layoutRoot
                ).attr("href") as string;
            }
        }
    }

    /**
     * Initialisiert spezielle Logik für die Kataloge-Seite
     */
    private InitKataloge($layoutRoot: JQuery) {
        // Katalog-Ansicht initialisieren
        Katalog.INSTANCE.LadePortalSuche(
            "#O4615_oxomi_kataloge_content",
            "#O4615_oxomi_kataloge_input",
            "#O4615_oxomi_kataloge_filter",
            ($j("#O4615_oxomi_kataloge_filterstring").val() || "").toString()
        );
    }

    /**
     * Initialisiert spezielle Logik für die Online Plus Einstellungsseite
     */
    private InitOnlinePlusEinstellungen($layoutRoot: JQuery) {
        // Prüft, welche Auswahl getroffen worden ist, und aktualisiert die Oberfläche entsprechend
        let pruefeAuswahl = () => {
            if ($j("#O4226_brutto", $layoutRoot).prop("checked") === true) {
                // Radiobutton Brutto aktiv -> Textbox für Mengeneingabe inaktivieren
                $j("#O4226_aufschlag", $layoutRoot).prop("disabled", true);
            } else {
                // Radiobutton Netto aktiv -> Textbox für Mengeneingabe freischalten
                $j("#O4226_aufschlag", $layoutRoot).prop("disabled", false);
            }
        };

        // Wenn Toggle umgeschaltet wird prüfen, welcher Modus aktiv ist
        $j("#O4226_brutto, #O4226_netto", $layoutRoot)
            .off("change.holteronline-onlineplus")
            .on("change.holteronline-onlineplus", () => {
                pruefeAuswahl();
            });

        // Bei der Initialisierung direkt prüfen, welche Oberflächenauswahl aktiv ist
        pruefeAuswahl();
    }

    /**
     * Initialisiert spezielle Logik für die Offertdetails
     */
    private InitOffertDetails($layoutRoot: JQuery) {
        $j("#O4071_bewertungselect", $layoutRoot)
            .off("change.holteronline-offertdetails")
            .on("change.holteronline-offertdetails", () => {
                $j("#O4071_bewertungkommentar", $layoutRoot).css(
                    "display",
                    "block"
                );
            });
    }

    /**
     * Initialisiert automatische Änderung der E-Mail Adresse bei Änderung des Anforderers
     */
    private InitKontakte($layoutRoot: JQuery) {
        let KontakteAnfordererEmail = () => {
            // das "selected" der vorher ausgewählen E-Mail Adresse entfernen
            $j("#O4266_mail", $layoutRoot)
                .children("option:selected")
                .removeAttr("selected");

            // E-Mail Adresse selecten, die zum Anforderer gehört
            let anfordererPerson = $j("#O4266_anforderer", $layoutRoot)
                .children("option:selected")
                .data("ho-o4266-person");
            $j("#O4266_mail", $layoutRoot)
                .children("[data-ho-O4266-mail='" + anfordererPerson + "']")
                .attr("selected", "selected");
        };

        // sicherstellen, dass beim Aufruf die richtige E-Mail Adresse ausgewählt ist
        KontakteAnfordererEmail();

        $j("#O4266_anforderer", $layoutRoot)
            .off("change.holteronline-kontakte-anforderer")
            .on(
                "change.holteronline-kontakte-anforderer",
                KontakteAnfordererEmail
            );
    }

    /**
     * Initialisiert die Benutzereinstellungen-Seite.
     */
    private InitBenutzereinstellungen($layoutRoot: JQuery) {
        const updateUiForKdivVisibility = () => {
            if (
                Number.parseFloat(
                    ($j("#kdmuht", $layoutRoot).val() ?? 0).toString()
                ) > 0
            ) {
                $j(
                    "#O4280_adpri_container, #O4280_onpai_container, #O4280_prani_container",
                    $layoutRoot
                ).show();
            } else {
                if ($j("#adpri", $layoutRoot).prop("checked")) {
                    $j("#adpri", $layoutRoot).prop("checked", false);
                    $j("#adprb", $layoutRoot).prop("checked", true);
                }
                if ($j("#onpai", $layoutRoot).prop("checked")) {
                    $j("#onpai", $layoutRoot).prop("checked", false);
                    $j("#onpal", $layoutRoot).prop("checked", true);
                }
                if ($j("#prani", $layoutRoot).prop("checked")) {
                    $j("#prani", $layoutRoot).prop("checked", false);
                    $j("#pranl", $layoutRoot).prop("checked", true);
                }

                $j(
                    "#O4280_adpri_container, #O4280_onpai_container, #O4280_prani_container",
                    $layoutRoot
                ).hide();
            }
        };

        updateUiForKdivVisibility();

        $j("#kdmuht", $layoutRoot)
            .off("input.holteronline-benutzereinstellungen")
            .on(
                "input.holteronline-benutzereinstellungen",
                updateUiForKdivVisibility
            );
    }

    /**
     * Initialisiert automatische Änderung der E-Mail Adresse bei Änderung des Anforderers
     */
    private InitUrgenzAnfordern($layoutRoot: JQuery) {
        let UrgenzAnfordernEmail = () => {
            // das "selected" der vorher ausgewählten E-Mail Adresse entfernen
            $j("#O4094_mail", $layoutRoot)
                .children("option:selected")
                .removeAttr("selected");

            // E-Mail Adresse selected, die zum Anforderer gehört
            let anfordererUrgenz = $j("#O4094_name", $layoutRoot)
                .children("option:selected")
                .data("ho-o4094-person");
            $j("#O4094_mail", $layoutRoot)
                .children("[data-ho-O4094-mail='" + anfordererUrgenz + "']")
                .attr("selected", "selected");
        };

        // sicherstellen, dass beim Aufruf die richtige E-Mail Adresse ausgewählt ist
        UrgenzAnfordernEmail();

        $j("#O4094_name", $layoutRoot)
            .off("change.holteronline-urgenzAnfordern-anforderer")
            .on(
                "change.holteronline-urgenzAnfordern-anforderer",
                UrgenzAnfordernEmail
            );
    }

    /**
     * Initialisiert die Prämienwelt-Datenschutzerklärung
     */
    private InitPraemienweltDatenschutzerklaerung($layoutRoot: JQuery) {
        // Beim Togglen der Checkbox den Button aktivieren/deaktivieren
        $j("#O4890_cdsvht", $layoutRoot)
            .off("change.holteronline-praemienwelt")
            .on("change.holteronline-praemienwelt", () => {
                // Wenn die Checkbox checked ist, dann kann auch der Button geklickt werden - sonst nicht
                $j("#O4890_akzeptieren", $layoutRoot).prop(
                    "disabled",
                    !$j("#O4890_cdsvht", $layoutRoot).prop("checked")
                );
            });
    }

    /**
     * Initialisiert bei der Benutzeradministration die Detailseite
     */
    private InitBenutzeradministrationDetails($layoutRoot: JQuery) {
        const updateUiForNettoVisibility = () => {
            if ($j("#O4911_nettoerlauben", $layoutRoot).prop("checked")) {
                $j(
                    "#O4911_adprn_container, #O4911_onpan_container, #O4911_prann_container",
                    $layoutRoot
                ).show();
            } else {
                if ($j("#O4911_adprn", $layoutRoot).prop("checked")) {
                    $j("#O4911_adprn", $layoutRoot).prop("checked", false);
                    $j("#O4911_adprb", $layoutRoot).prop("checked", true);
                }
                if ($j("#O4911_onpan", $layoutRoot).prop("checked")) {
                    $j("#O4911_onpan", $layoutRoot).prop("checked", false);
                    $j("#O4911_onpal", $layoutRoot).prop("checked", true);
                }
                if ($j("#O4911_prann", $layoutRoot).prop("checked")) {
                    $j("#O4911_prann", $layoutRoot).prop("checked", false);
                    $j("#O4911_pranl", $layoutRoot).prop("checked", true);
                }

                $j(
                    "#O4911_adprn_container, #O4911_onpan_container, #O4911_prann_container",
                    $layoutRoot
                ).hide();
            }
        };

        const updateUiForKdivVisibility = () => {
            if (
                Number.parseFloat(
                    ($j("#kdmuht", $layoutRoot).val() ?? 0).toString()
                ) > 0
            ) {
                $j(
                    "#O4911_adpri_container, #O4911_onpai_container, #O4911_prani_container",
                    $layoutRoot
                ).show();
            } else {
                if ($j("#O4911_adpri", $layoutRoot).prop("checked")) {
                    $j("#O4911_adpri", $layoutRoot).prop("checked", false);
                    $j("#O4911_adprb", $layoutRoot).prop("checked", true);
                }
                if ($j("#O4911_onpai", $layoutRoot).prop("checked")) {
                    $j("#O4911_onpai", $layoutRoot).prop("checked", false);
                    $j("#O4911_onpal", $layoutRoot).prop("checked", true);
                }
                if ($j("#O4911_prani", $layoutRoot).prop("checked")) {
                    $j("#O4911_prani", $layoutRoot).prop("checked", false);
                    $j("#O4911_pranl", $layoutRoot).prop("checked", true);
                }

                $j(
                    "#O4911_adpri_container, #O4911_onpai_container, #O4911_prani_container",
                    $layoutRoot
                ).hide();
            }
        };

        updateUiForNettoVisibility();
        updateUiForKdivVisibility();

        $j("#O4911_nettoerlauben", $layoutRoot)
            .off("change.holteronline-benutzeradministration-details")
            .on(
                "change.holteronline-benutzeradministration-details",
                updateUiForNettoVisibility
            );

        $j("#kdmuht", $layoutRoot)
            .off("input.holteronline-benutzeradministration-details")
            .on(
                "input.holteronline-benutzeradministration-details",
                updateUiForKdivVisibility
            );
    }

    /**
     * Initialisiert die Seite zum Festlegen eines neuen Passworts
     */
    private InitNeuesPasswortFestlegen($layoutRoot: JQuery) {
        let $cookieItem = $j("#O4000_cookies", $layoutRoot);
        let $agbItem = $j("#O4920_cdsvht", $layoutRoot);

        let pruefeButtonSubmitVerfuegbar = () => {
            // Wenn die Checkbox checked ist, dann kann auch der Button geklickt werden - sonst nicht
            if ($cookieItem.length) {
                $j("#O4920_submit", $layoutRoot).prop(
                    "disabled",
                    !($cookieItem.prop("checked") && $agbItem.prop("checked"))
                );
            } else {
                $j("#O4920_submit", $layoutRoot).prop(
                    "disabled",
                    !$agbItem.prop("checked")
                );
            }
        };

        // prüfen ob Button Submit aktiv
        pruefeButtonSubmitVerfuegbar();

        // Beim Togglen der Checkbox den Button aktivieren/deaktivieren
        $j("#O4920_cdsvht, #O4000_cookies", $layoutRoot)
            .off("change.holteronline-neuespasswort")
            .on(
                "change.holteronline-neuespasswort",
                pruefeButtonSubmitVerfuegbar
            );
    }

    /**
     * Initialisiert die Maßfreigabe-Bestätigung
     */
    private InitMassfreigabeBestaetigen($layoutRoot: JQuery) {
        // Beim Togglen der Checkbox den Button aktivieren/deaktivieren
        $j("#O4068_best", $layoutRoot)
            .off("change.holteronline-massfreigabe")
            .on("change.holteronline-massfreigabe", () => {
                // Wenn die Checkbox checked ist, dann kann auch der Button geklickt werden - sonst nicht
                $j("#O4068_akzeptieren", $layoutRoot).prop(
                    "disabled",
                    !$j("#O4068_best", $layoutRoot).prop("checked")
                );
            });
    }

    /**
     * initialisiert alle Funktionen rund um die Schnittstellen-Test-Seite
     */
    private InitSchnittstellenTestseite($layoutRoot: JQuery) {
        const verarbeiteBenutzerEingaben = () => {
            // Benutzername und Passwort werden aus Oberflächen-Textboxen eingelesen
            const Benutzer: string = (
                $j("#O4600_Benutzer", $layoutRoot).val() || ""
            ).toString();
            const Passwort: string = (
                $j("#O4600_Passwort", $layoutRoot).val() || ""
            ).toString();

            // Bei Klick auf a-Tags wird der Aufruf-Link für die Schnittstellen aktualisiert / bereitgestellt
            $j("#O4600_Links_mit_Passwort a", $layoutRoot).each(
                (index, item) => {
                    item.setAttribute(
                        "href",
                        item
                            .getAttribute("data-ho-href")
                            ?.replace("%%benutzer%%", Benutzer)
                            .replace("%%passwort%%", Passwort) ?? ""
                    );
                }
            );

            // Damit es irgendein optisches Feedback gibt, dass der Vorgang durchgeführt worden ist, ein Alert ausgeben
            alert("Passwort gesetzt");
        };

        // Bei Klick auf den "Passwort setzen" Button die Eingabe verarbeiten
        $j("#O4600_PwSetzen", $layoutRoot)
            .off("click.holteronline-schnittstellen")
            .on(
                "click.holteronline-schnittstellen",
                verarbeiteBenutzerEingaben
            );

        // Auch bei Enter-Taste beim Passwort dasselbe auslösen
        $j("#O4600_Benutzer, #O4600_Passwort", $layoutRoot)
            .off("keypress.holteronline-schnittstellen")
            .on("keypress.holteronline-schnittstellen", (ereignis) => {
                if (KeyEvent.isEnter(ereignis)) {
                    verarbeiteBenutzerEingaben();
                }
            });
    }

    /**
     * Prüft, ob es eine Vollbildaktion anzuzeigen gibt
     */
    private PruefeVollbildAktion($layoutRoot: JQuery) {
        AS400Layer.INSTANCE.InhaltSubmit(
            new HoProgrammInfo({
                Programm: "O4019R",
                AjaxDaten: "butt=check",
            }),
            {}
        );
    }

    /**
     * Weiterleitung an Benutzerverwaltung durch neuen Button
     */
    private InitBenutzeradministrationHauptansicht($layoutRoot: JQuery) {
        const weiterleitung = () => {
            const oida = ($j("#O4910_oida_textbox", $layoutRoot).val() || "")
                .toString()
                .toUpperCase();

            AS400Layer.INSTANCE.LadeSeite(
                new HoProgrammInfo({
                    Programm: "O4911W",
                    Parameter: `&oida=${oida}&butt=bearbeiten`,
                }),
                {}
            );
        };

        $j("#O4910_oida_button", $layoutRoot)
            .off("click.quickstartoida")
            .on("click.quickstartoida", weiterleitung);

        // Auch bei Enter-Taste beim Passwort dasselbe auslösen
        $j("#O4910_oida_textbox", $layoutRoot)
            .off("keypress.quickstartoida")
            .on("keypress.quickstartoida", (ereignis) => {
                if (KeyEvent.isEnter(ereignis)) {
                    weiterleitung();
                }
            });
    }

    /**
     * Prüft, ob Cookies akzeptiert wurden
     */
    private InitLogin($layoutRoot: JQuery) {
        let $cookieItem = $j("#O4000_cookies", $layoutRoot);

        let pruefeButtonSubmitVerfuegbar = () => {
            if ($cookieItem.length) {
                // Wenn die Cookie-Checkbox checked ist, dann kann auch der Button geklickt werden
                $j("#O4000_submit", $layoutRoot).prop(
                    "disabled",
                    !$cookieItem.prop("checked")
                );
                $j("#O4000_homa", $layoutRoot).prop(
                    "disabled",
                    !$cookieItem.prop("checked")
                );
            }
        };

        // prüfen ob Button Submit aktiv
        pruefeButtonSubmitVerfuegbar();

        // Beim Togglen der Checkbox den Button aktivieren/deaktivieren
        $j("#O4000_cookies", $layoutRoot)
            .off("change.holteronline-login")
            .on("change.holteronline-login", pruefeButtonSubmitVerfuegbar);

        $j("#O4000_password", $layoutRoot)
            .off("keypress.holteronline")
            .on("keypress.holteronline", (ereignis) => {
                if (
                    KeyEvent.isEnter(ereignis) &&
                    (!$cookieItem.length ||
                        ($cookieItem.length && $cookieItem.prop("checked")))
                ) {
                    $j("#O4000_submit", $layoutRoot).trigger("click");
                }
            });

        // mobilelink anzeigen wenn mobile device
        $j(".mobile-link", $layoutRoot).hide();
        if (Browsertyp.WieMobilesGeraet) {
            $j(".mobile-link", $layoutRoot).show();
        }
    }

    private ApiAuthentifizierung($layoutRoot: JQuery) {
        $j("#httpheader", $layoutRoot)
            .off("click.holteronline-test0w")
            .on("click.holteronline-test0w", (ereignis) => {
                // Element, auf das geklickt worden ist, ermitteln
                let linkelem = $j(ereignis.currentTarget);

                // Element für weitere Klicks sperren
                linkelem.attr("disabled", "disabled");

                // Klick auf Element ist nach 1 Sekunde wieder zulässig
                setTimeout(() => {
                    $j(linkelem).removeAttr("disabled");
                }, 1000);

                $j.ajax({
                    type: "GET",
                    url: "/api/mobile/v1/users/current",
                    headers: {
                        "ho-app-auth":
                            "HolterOnline=51fb74e5-d12e-4943-8470-481577f74b7c",
                    },
                }).done((responseJSON) => {
                    alert(JSON.stringify(responseJSON));
                });
            });
    }
}
