import * as $j from "jquery";
import { HoItemSearchApi } from "./interfaces/hoItemSearchApi";

export class ItemSearchApi {
    public static readonly AUTOCOMPLETE_URL: string =
        "/api/item-search/v1/autoComplete";

    public static search(params: {
        data: HoItemSearchApi.SearchRequest;
    }): JQuery.Deferred<
        HoItemSearchApi.SearchResponse,
        {
            XHR: JQuery.jqXHR<any>;
            settings: JQuery.UrlAjaxSettings;
        }
    > {
        let $d = $j.Deferred<
            HoItemSearchApi.SearchResponse,
            {
                XHR: JQuery.jqXHR<any>;
                settings: JQuery.UrlAjaxSettings;
            }
        >();

        let settings: JQuery.UrlAjaxSettings = {
            url: "/api/item-search/v1/search",
            global: false,
            ...params,
        };

        $j.post(settings)
            .done((result) => $d.resolve(result))
            .fail((XHR) =>
                $d.reject({
                    XHR,
                    settings,
                })
            );

        return $d;
    }

    public static getAutocompleteAjaxSettings(
        query: string,
        limit: number,
        settings: JQueryAjaxSettings
    ): JQueryAjaxSettings {
        settings.url = ItemSearchApi.AUTOCOMPLETE_URL;
        settings.global = false;
        settings.method = "POST";
        settings.data = {
            query,
            queriesOptions: {
                take: limit,
            },
        } as HoItemSearchApi.AutoCompleteRequest;

        return settings;
    }
}
