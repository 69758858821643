/**
 * Definiert die möglichen Anwendungstypen für DataTables.
 */
export enum DataTableAnwendungsTyp {
    /**
     * Standard-Anwendung: Client-Pagination, alle Daten im Dom
     */
    Default,
    /**
     * DataTable, deren Daten von einer Server-Anwendung ermittelt werden
     */
    ServerSideProcessing,
    /**
     * Anwendung ist die Artikelsuche
     */
    Artikelsuche,
    /**
     * Anwendung ist für den Warenkorb
     */
    Warenkorb,
}
