import * as $j from "jquery";
import * as _ from "underscore";

/**
 * Stellt Funktionen zum Fokussieren von Steuerelementen bereit.
 */
export class ScrollToolbox {
    public static readonly INSTANCE = new ScrollToolbox();

    private constructor() {}

    /**
     * Setzt die Scroll-Position des Fensters.
     * @param $scrollTarget Definiert den Container, der für die Berechnung der Scroll-Position als Referenz genommen wird.
     */
    public SetzeScrollPosition(
        $layoutRoot: JQuery,
        $scrollTarget: JQuery
    ): void {
        if ($scrollTarget?.length) {
            const $scrollContainer =
                $layoutRoot.prop("tagName").toLowerCase() === "body"
                    ? $j("html, body")
                    : this.FindeScrollbaresElement($layoutRoot);

            let scrollToPosition: number = $scrollTarget.offset()!.top;

            if ($scrollContainer.prop("tagName").toLowerCase() === "html") {
                scrollToPosition =
                    scrollToPosition -
                    parseInt(
                        $j("section.content", $layoutRoot).css("padding-top"),
                        10
                    );
            }

            const $offsetItem = $j(
                "[data-ho-scrollto-offset]",
                $layoutRoot
            ).first();

            if ($offsetItem.length) {
                // true damit Margins auch berücksichtigt werden
                scrollToPosition -= $offsetItem.outerHeight(true) ?? 0;
            }

            // Zum Ziel-Element scrollen
            $scrollContainer.css("scroll-behavior", "auto").animate(
                {
                    scrollTop: scrollToPosition,
                },
                {
                    duration: 800,
                }
            );
        } else {
            console.warn(
                `ACHTUNG: Scrollen zu dieser Position nicht möglich. $scrollTarget: ${JSON.stringify(
                    $scrollTarget
                )}`
            );
        }
    }

    /**
     * Setzt die Scroll-Position im Container zurück
     */
    public ScrollPositionZuruecksetzen(
        $layoutRoot: JQuery,
        istModalesFenster: boolean = false
    ): void {
        let $container: JQuery<HTMLElement | Document> = $layoutRoot;

        if ($layoutRoot[0] === $j("body")[0]) {
            $container = $j("html, body");
        }
        if (istModalesFenster) {
            $container = $j(".modal-content", $layoutRoot);
        }

        $container.css("scroll-behavior", "auto").animate(
            {
                scrollTop: 0,
                screenLeft: 0,
            },
            {
                duration: 50,
            }
        );
    }

    /**
     * Findet das nächste Element, zu dem gescrolled werden kann
     */
    private FindeScrollbaresElement(
        $selector: JQuery,
        container: string = "div"
    ): JQuery {
        if ($selector[0].clientHeight !== $selector[0].scrollHeight) {
            return $selector;
        }
        let elem = _.find(
            $selector.find(container).toArray(),
            (item) => item && item.clientHeight !== item.scrollHeight
        );

        if (elem) {
            return $j(elem);
        } else {
            return $selector;
        }
    }
}
