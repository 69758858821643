/**
 * Definiert die möglichen Typen von Validierungen, die überprüft werden können
 */
export enum ValidierungsTyp {
    /**
     * Prüfung auf regulären Ausdruck
     */
    Regex,
    /**
     * Prüfung auf eine erforderliche Eingabe
     */
    Required,
    /**
     * Prüfung auf die Gleichheit von zwei Datenfeldern
     */
    Equal,
}
