import * as $j from "jquery";
import { HoItemSearchApi } from "./api/interfaces/hoItemSearchApi";
import { AS400Layer } from "./as400Layer";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { IO4381Output } from "./interfaces/IO4381Output";

export class ArtikelsucheZustand {
    public static processHtmlFields(params: {
        uk?: string;
        suchbegriff?: string;
        sort?: string;
        page?: string;
        warehouse?: string;
    }): JQuery.Deferred<ArtikelsucheZustand> {
        let $d = $j.Deferred();
        let zustand: ArtikelsucheZustand = new ArtikelsucheZustand();

        if (parseInt(params.uk ?? "0", 10) >= 0) {
            zustand._uk = parseInt(params.uk ?? "0", 10);
        }

        if (params.suchbegriff) {
            zustand._suchbegriff = params.suchbegriff;
        }

        if (params.sort && params.sort !== "") {
            let order: HoItemSearchApi.EntitySortingParameterOrderEnum;
            if (params.sort.split("/")[1] === "desc") {
                order = HoItemSearchApi.EntitySortingParameterOrderEnum.Desc;
            } else {
                order = HoItemSearchApi.EntitySortingParameterOrderEnum.Asc;
            }
            let input: string = params.sort.split("/")[0];

            if (
                input === "itemId" ||
                input === "itemIdBrand" ||
                input === "grossPrice"
            ) {
                zustand._sort = {
                    attributeName: input,
                    order,
                    type: HoItemSearchApi.EntitySortingParameterTypeEnum
                        .Attribute,
                };
            }
        }

        if (params.page && params.page !== "") {
            if (parseInt(params.page, 10) > 0) {
                zustand._page = parseInt(params.page, 10);
            } else {
                zustand._page = 1;
            }
        }

        if (params.warehouse && params.warehouse !== "") {
            let wh: string = "";
            if (
                parseInt(params.warehouse, 10) >= 0 &&
                parseInt(params.warehouse, 10) < 100
            ) {
                wh = params.warehouse;
            } else {
                wh = "00";
            }

            $("#O4380_lage").val(wh).trigger("change");

            zustand._warehouse = parseInt(wh, 10);
        }

        zustand
            .sync()
            .done(() => {
                $d.resolve(zustand);
            })
            .fail($d.reject);

        return $d;
    }

    private static getUrlParam(url: string, name: string): string {
        let result: string = "";
        let urlparamarr = url.substring(url.search(/\?/) + 1).split("&");
        urlparamarr.forEach((e) => {
            let key = e.split("=")[0];
            if (key === name) {
                result = e.split("=")[1];
            }
        });
        return result;
    }

    private _uk: number;
    private _suchbegriff: string;
    private _filter: Array<
        | HoItemSearchApi.DistinctFilterParameter
        | HoItemSearchApi.RangeFilterParameter
    >;
    private _sort: HoItemSearchApi.EntitySortingParameter;
    private _page: number;
    private _warehouse: number;

    public get suchbegriff(): string {
        return this._suchbegriff;
    }
    public set suchbegriff(value: string) {
        this._suchbegriff = value;
        this._uk = 0;
        this._filter = [];
        this._sort = {};
        this._page = 1;
        this.sync();
    }

    public get filter(): Array<
        | HoItemSearchApi.DistinctFilterParameter
        | HoItemSearchApi.RangeFilterParameter
    > {
        return this._filter;
    }
    public set filter(
        value: Array<
            | HoItemSearchApi.DistinctFilterParameter
            | HoItemSearchApi.RangeFilterParameter
        >
    ) {
        this._filter = value;
        this._sort = {};
        this._page = 1;
        this._uk = 0;
        this.sync();
    }

    public get sort(): HoItemSearchApi.EntitySortingParameter {
        return this._sort;
    }
    public set sort(value: HoItemSearchApi.EntitySortingParameter) {
        this._sort = value;
        this._page = 1;
    }

    public get page(): number {
        return this._page;
    }
    public set page(value: number) {
        this._page = value;
    }

    public get warehouse(): number {
        return this._warehouse;
    }
    public set warehouse(value: number) {
        this._warehouse = value;
    }

    public get uk(): number {
        return this._uk;
    }

    public set uk(value: number) {
        this._uk = value;
        this._suchbegriff = "";
        this._filter = [];
        this._sort = {};
        this._page = 1;
        this.sync();
    }

    constructor() {
        this._uk = 0;
        this._suchbegriff = "";
        this._page = 1;
        this._sort = {};
        this._filter = [];
        this._warehouse = 0;
    }

    public getHoProgrammInfo(): HoProgrammInfo {
        let result = new HoProgrammInfo({
            Programm: "O4380W",
            Parameter: "",
        });
        result.ParameterHinzufuegen("UKHONSFHT", this.uk.toString());

        if (this._sort?.attributeName && this._sort?.order) {
            result.ParameterHinzufuegen(
                "SORTHT",
                this._sort.attributeName + "/" + this._sort.order.toString()
            );
        }

        if (this._page) {
            result.ParameterHinzufuegen("PAGEHT", this._page.toString());
        }

        if (this._warehouse) {
            result.ParameterHinzufuegen("WHHT", this._warehouse.toString());
        }

        return result;
    }

    private sync(): JQueryXHR {
        if (this._uk !== 0) {
            return AS400Layer.INSTANCE.SendePostRequest(
                new HoProgrammInfo({
                    Programm: "O4381R",
                    AjaxDaten: "&ukhonsfht=" + JSON.stringify(this._uk),
                }),
                {
                    LadeanimationDeaktivieren: false,
                }
            ).done((resData) => {
                let O4381result: IO4381Output = JSON.parse(
                    this.htmlDecode(resData)
                );

                this._suchbegriff = O4381result.suchbegriff ?? "";

                if (O4381result.filter) {
                    this._filter = O4381result.filter;
                }
            });
        } else {
            return AS400Layer.INSTANCE.SendePostRequest(
                new HoProgrammInfo({
                    Programm: "O4381R",
                    AjaxDaten:
                        "&sbgrht=" +
                        JSON.stringify(encodeURIComponent(this.suchbegriff)) +
                        "&filterht=" +
                        JSON.stringify(this.filter),
                }),
                {
                    LadeanimationDeaktivieren: true,
                }
            ).done((resData) => {
                let O4381result: IO4381Output = JSON.parse(resData);

                if (O4381result.ukhonsf) {
                    this._uk = parseInt(O4381result.ukhonsf, 10);
                }
            });
        }
    }

    private htmlDecode(input: string): string {
        let doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent ?? "";
    }
}
