import * as $j from "jquery";
import { HoItemSearchApi } from "./api/interfaces/hoItemSearchApi";
import { ItemSearchApi } from "./api/itemSearchApi";
import { ArtikelsucheZustand } from "./artikelsucheZustand";
import { AS400Layer } from "./as400Layer";
import { Benutzerprofil } from "./benutzerprofil";
import { ArtikelsucheVersion2FilterBuilder as FilterBuilder } from "./creationals/artikelsucheVersion2FilterBuilder";
import { ArtikelsucheVersion2TableBuilder as TableBuilder } from "./creationals/artikelsucheVersion2TableBuilder";
import { ItemSearchRequestBuilder } from "./creationals/itemSearchRequestBuilder";
import { ArtikelsuchePreisanzeigeModus } from "./enums/ArtikelsuchePreisanzeigeModus";
import { Historie } from "./historie";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { Konstanten } from "./konstanten/konstanten";
import { KonstantenArtikelsucheVersion2 as Konstanten2 } from "./konstanten/konstantenArtikelsucheVersion2";
import { Layout } from "./layout";
import { Powersuche } from "./powersuche";
import { ScrollToolbox } from "./scrollToolbox";
import { StatischeMethoden } from "./statischeMethoden";
import { ArtikelsucheVersion2Templates as Templates } from "./templates/artikelsucheVersion2Templates";

export class ArtikelsucheVersion2 {
    private static _INSTANCE: ArtikelsucheVersion2;

    private apiCallOngoing: boolean = false;

    private results = 0;
    private zustand: ArtikelsucheZustand;

    public static get INSTANCE(): ArtikelsucheVersion2 {
        if (!ArtikelsucheVersion2._INSTANCE) {
            ArtikelsucheVersion2._INSTANCE = new ArtikelsucheVersion2();
        }
        return ArtikelsucheVersion2._INSTANCE;
    }

    public get LetzterSuchbegriff() {
        return decodeURIComponent(
            ($j("#O4380_suchbegriff").val() || "").toString()
        );
    }

    private readonly benutzerprofil: Benutzerprofil;

    private constructor() {
        this.benutzerprofil = new Benutzerprofil();
        this.zustand = new ArtikelsucheZustand();
    }

    public SetzeSuchbegriff(val: string) {
        $j("#O4380_suchbegriff").val(val);
    }

    public suchen(params: ArtikelsucheZustand) {
        if (this.apiCallOngoing) {
            return;
        }

        this.zustand = params;

        const $layoutRoot = $j(Konstanten2.LAYOUT_ROOT_SELECTOR);

        if (this.zustand.suchbegriff !== "") {
            let requestBuilder = this.prepareRequest();

            Layout.INSTANCE.Zuruecksetzen($layoutRoot);
            $layoutRoot.off().empty().append(Templates.WorkInProgressLayout);

            StatischeMethoden.LadeAnimationAnzeigen();

            this.apiCallOngoing = true;
            ItemSearchApi.search(requestBuilder.getResult())
                .always(() => {
                    this.apiCallOngoing = false;
                    StatischeMethoden.LadeAnimationAusblenden();
                })
                .done((response) => {
                    this.dealWithApiResponse(response, {
                        sortBy: this.zustand.sort,
                    });
                })
                .fail((err) => {
                    AS400Layer.INSTANCE.LadeSeite(
                        new HoProgrammInfo({
                            Programm: Konstanten.FehlerProgramm,
                            Parameter:
                                "HTTPSTATUS=" +
                                err.XHR.status +
                                "&TEXTHT=" +
                                encodeURIComponent(err.settings.url || ""),
                            CallJsAnhaengen: true,
                        }),
                        {
                            DialogAnzeige: true,
                        }
                    );

                    $layoutRoot.off().empty();
                });
        } else {
            Layout.INSTANCE.Zuruecksetzen($layoutRoot);
            $layoutRoot.off().empty().append(Templates.EmptySearchTermLayout);
        }
    }

    /**
     * Prüft, ob die aktuelle Seite die Artikelsuche ist.
     */
    public istAktuellGeoeffnet() {
        return Historie.INSTANCE.ErmittleAktuelleSeite() === "O4380W";
    }

    public initEvents() {
        $j(`[${Konstanten2.SUCHE_STARTEN_DATA_ATTRIBUT}]`)
            .off("click.holteronline-artikelsuche-version-2")
            .on("click.holteronline-artikelsuche-version-2", (event) => {
                this.takeFiltersFromUI();
                this.suchen(this.zustand);
            });

        $j(`[${Konstanten2.SUCHBEGRIFF_AENDERN_DATA_ATTRIBUT}]`)
            .off("click.holteronline-artikelsuche-version-2")
            .on("click.holteronline-artikelsuche-version-2", (event) => {
                let $clickedItem = $j(event.currentTarget);

                let suchbegriff =
                    $clickedItem.attr(
                        Konstanten2.SUCHBEGRIFF_AENDERN_DATA_ATTRIBUT
                    ) ?? "";

                Powersuche.INSTANCE.SetzeSuchbegriff(suchbegriff);
                this.zustand.suchbegriff = suchbegriff;
                this.suchen(this.zustand);
            });

        $j("[data-ho-suche-filter-textbox-for]")
            .off("keyup.holteronline-aktionen")
            .on("keyup.holteronline-aktionen", (ereignis) => {
                let $textbox = $j(ereignis.currentTarget);
                let filterName = $textbox.attr(
                    "data-ho-suche-filter-textbox-for"
                );
                let $listOfChildren = $j(
                    `[data-ho-suche-filter-values-list="${filterName}"]`
                ).children("li");

                let $matchingChildren = $listOfChildren.filter(
                    (index, elem) =>
                        $j(elem)
                            .text()
                            .toUpperCase()
                            .indexOf(
                                ($textbox.val() || "").toString().toUpperCase()
                            ) >= 0
                );

                $listOfChildren.hide();
                $matchingChildren.show();
            });

        $j(`[${Konstanten2.FILTER_CONTENT_VISIBILITY_TOGGLER_DATA_ATTRIBUT}]`)
            .off("click.holteronline-artikelsuche-version-2")
            .on("click.holteronline-artikelsuche-version-2", (event) => {
                let $header = $j(event.currentTarget);
                let $content = $j(
                    `[${
                        Konstanten2.FILTER_CONTENT_DATA_ATTRIBUT
                    }="${$header.attr(
                        Konstanten2.FILTER_CONTENT_VISIBILITY_TOGGLER_DATA_ATTRIBUT
                    )}"]`
                );
                let $arrow = $j(
                    `[${Konstanten2.FILTER_ARROW_DATA_ATTRIBUT}="${$header.attr(
                        Konstanten2.FILTER_CONTENT_VISIBILITY_TOGGLER_DATA_ATTRIBUT
                    )}"]`
                );

                if ($content.height() === 0) {
                    $content
                        .css("height", "auto")
                        .addClass(Konstanten2.FILTER_IS_OPEN_CLASS);
                    $arrow.removeClass(
                        Konstanten2.FILTER_ARROW_IS_CLOSED_CLASS
                    );
                } else {
                    $content
                        .css("height", "0")
                        .removeClass(Konstanten2.FILTER_IS_OPEN_CLASS);
                    $arrow.addClass(Konstanten2.FILTER_ARROW_IS_CLOSED_CLASS);
                    this.takeFiltersFromUI();
                }
            });

        $j(`[${Konstanten2.FILTER_CONTENT_RESET_DATA_ATTRIBUT}]`)
            .off("click.holteronline-artikelsuche-version-2")
            .on("click.holteronline-artikelsuche-version-2", (event) => {
                let $header = $j(event.currentTarget);
                let $content = $j(
                    `[${
                        Konstanten2.FILTER_CONTENT_DATA_ATTRIBUT
                    }="${$header.attr(
                        Konstanten2.FILTER_CONTENT_RESET_DATA_ATTRIBUT
                    )}"]`
                );
                let $arrow = $j(
                    `[${Konstanten2.FILTER_ARROW_DATA_ATTRIBUT}="${$header.attr(
                        Konstanten2.FILTER_CONTENT_VISIBILITY_TOGGLER_DATA_ATTRIBUT
                    )}"]`
                );

                if ($content.height() === 0) {
                    $content
                        .css("height", "auto")
                        .addClass(Konstanten2.FILTER_IS_OPEN_CLASS);
                    $arrow.removeClass(
                        Konstanten2.FILTER_ARROW_IS_CLOSED_CLASS
                    );
                } else {
                    $content
                        .css("height", "0")
                        .removeClass(Konstanten2.FILTER_IS_OPEN_CLASS);
                    $arrow.addClass(Konstanten2.FILTER_ARROW_IS_CLOSED_CLASS);
                    this.takeFiltersFromUI();
                    this.suchen(this.zustand);
                }
            });

        $j("#O4380_cdlw")
            .off("change.holteronline-artikelsuche-version-2")
            .on("change.holteronline-artikelsuche-version-2", () => {
                this.benutzerprofil.Suche_NurLagerware =
                    $j("#O4380_cdlw").prop("checked") === true;
            });

        $j("[data-ho-artikelsuche-filter-anzeigen]")
            .off("click.holteronline-artikelsuche-version-2")
            .on("click.holteronline-artikelsuche-version-2", () => {
                this.benutzerprofil.Suche_FilterAnzeigen = true;
                this.showFilterArea();
            });

        $j("[data-ho-artikelsuche-filter-ausblenden]")
            .off("click.holteronline-artikelsuche-version-2")
            .on("click.holteronline-artikelsuche-version-2", () => {
                this.benutzerprofil.Suche_FilterAnzeigen = false;
                this.hideFilterArea();
            });

        $j("[data-ho-artikelsuche-alle-filter-ausblenden]")
            .off("click.holteronline-artikelsuche-version-2")
            .on("click.holteronline-artikelsuche-version-2", () => {
                $j(
                    `[${Konstanten2.FILTER_CONTENT_DATA_ATTRIBUT}].${Konstanten2.FILTER_IS_OPEN_CLASS}`
                ).each((idx, container) => {
                    $j(container)
                        .css("height", "0")
                        .removeClass(Konstanten2.FILTER_IS_OPEN_CLASS);

                    $j(
                        `[${Konstanten2.FILTER_ARROW_DATA_ATTRIBUT}=${$j(
                            container
                        ).attr(Konstanten2.FILTER_CONTENT_DATA_ATTRIBUT)}]`
                    ).addClass(Konstanten2.FILTER_ARROW_IS_CLOSED_CLASS);
                });
                this.takeFiltersFromUI();
                this.suchen(this.zustand);
            });

        $j(`[${Konstanten2.NAECHSTE_SEITE_LADEN}]`)
            .off("click.holteronline-artikelsuche-version-2")
            .on("click.holteronline-artikelsuche-version-2", (event) => {
                if (this.results > this.zustand.page * 50) {
                    this.zustand.page += 1;
                    this.suchen(this.zustand);
                }
            });

        $j(`[${Konstanten2.VORHERIGE_SEITE_LADEN}]`)
            .off("click.holteronline-artikelsuche-version-2")
            .on("click.holteronline-artikelsuche-version-2", (event) => {
                if (this.zustand.page > 1) {
                    this.zustand.page -= 1;
                    this.suchen(this.zustand);
                }
            });

        $j(`[${Konstanten2.ERSTE_SEITE_LADEN}]`)
            .off("click.holteronline-artikelsuche-version-2")
            .on("click.holteronline-artikelsuche-version-2", (event) => {
                this.zustand.page = 1;
                this.suchen(this.zustand);
            });

        $j(`[${Konstanten2.LETZTE_SEITE_LADEN}]`)
            .off("click.holteronline-artikelsuche-version-2")
            .on("click.holteronline-artikelsuche-version-2", (event) => {
                this.zustand.page = Math.ceil(this.results / 50);
                this.suchen(this.zustand);
            });

        $j("#O4380_lage")
            .off("click.holteronline-artikelsuche-version-2")
            .on("click.holteronline-artikelsuche-version-2", (event) => {
                this.zustand.warehouse = parseInt(
                    ($j("#O4380_lage").val() || "0").toString(),
                    10
                );
            });

        $j(`[data-ho-artikelsuchev2-tabelle]`).on(
            "order.dt",
            (event, setting, ordArr) => {
                this.zustand.page = 1;
                this.zustand.sort = {};
                let sortBy: string;
                switch (ordArr[0].src) {
                    case 2: {
                        this.zustand.sort.attributeName = "itemId";
                        break;
                    }
                    case 4: {
                        this.zustand.sort.attributeName = "itemIdBrand";
                        break;
                    }
                    default: {
                        this.zustand.sort.attributeName = "grossPrice";
                        break;
                    }
                }
                this.zustand.sort.order = ordArr[0].dir;
                this.zustand.sort.type =
                    HoItemSearchApi.EntitySortingParameterTypeEnum.Attribute;
                this.suchen(this.zustand);
            }
        );

        $j("[" + Konstanten2.ZusatzInfoButtonDataAttribut + "]")
            .off(Konstanten2.EventNameClick)
            .on(Konstanten2.EventNameClick, (ereignis) => {
                // Nächste Zeile ermitteln
                let dt = $j("[data-ho-artikelsuchev2-tabelle]").DataTable();
                let $row = $j(ereignis.currentTarget).closest("tr");
                let apiRow = dt.row($row);

                if (apiRow.child.isShown()) {
                    // Zeile wird angezeigt -> ausblenden
                    $row.removeClass("shown");

                    $j(
                        Konstanten2.ZusatzInfoZeileSelector,
                        apiRow.child()
                    ).slideUp(() => {
                        apiRow.child.hide();
                    });
                } else {
                    // Zeile ist im Moment ausgeblendet -> Daten ermitteln und anzeigen

                    let ProgrammInfoArtikelInfo = new HoProgrammInfo({
                        Programm: Konstanten2.ProgrammZusatzInfo,
                    });

                    ProgrammInfoArtikelInfo.AjaxDatenHinzufuegen(
                        "artnr",
                        $j(ereignis.currentTarget).attr(
                            Konstanten2.ZusatzInfoButtonDataAttribut
                        ) ?? ""
                    );
                    ProgrammInfoArtikelInfo.AjaxDatenHinzufuegen(
                        "lage",
                        ($j("#O4380_lage").val() ?? "").toString()
                    );

                    AS400Layer.INSTANCE.SendePostRequest(
                        ProgrammInfoArtikelInfo,
                        {}
                    ).done((ereignisO4821) => {
                        // Daten ausgeben und Bereich anzeigen
                        apiRow.child(ereignisO4821.toString()).show();
                        $row.addClass("shown");
                        $j(
                            Konstanten2.ZusatzInfoZeileSelector,
                            apiRow.child()
                        ).slideDown();

                        Layout.INSTANCE.InitBereichLayout(
                            $j(Konstanten2.LAYOUT_ROOT_SELECTOR)
                        );
                    });
                }
            });
    }

    public takeFiltersFromUI() {
        let aktuellerSuchbegriff =
            Powersuche.INSTANCE.ErmittleSuchbergriffAusTextbox(false);
        if (aktuellerSuchbegriff !== this.zustand.suchbegriff) {
            this.zustand.suchbegriff = aktuellerSuchbegriff;
        } else {
            let filterarr: Array<
                | HoItemSearchApi.DistinctFilterParameter
                | HoItemSearchApi.RangeFilterParameter
            > = [];
            $j(
                `[${Konstanten2.FILTER_CONTENT_DATA_ATTRIBUT}].${Konstanten2.FILTER_IS_OPEN_CLASS}`
            ).each((idx, container) => {
                let $container = $j(container);

                if ($container.attr("data-ho-filter-type") === "range") {
                    let attributeName =
                        $container.attr(
                            Konstanten2.FILTER_CONTENT_DATA_ATTRIBUT
                        ) ?? "";

                    filterarr.push({
                        attributeName,
                        isRangeType: true,
                        selected: {
                            min: parseFloat(
                                (
                                    $j(`#${attributeName}_min`).val() || 0
                                ).toString()
                            ),
                            max: parseFloat(
                                (
                                    $j(`#${attributeName}_max`).val() || 0
                                ).toString()
                            ),
                        },
                    });
                } else {
                    let distinctFilter: HoItemSearchApi.DistinctFilterParameter =
                        {
                            attributeName:
                                $container.attr(
                                    Konstanten2.FILTER_CONTENT_DATA_ATTRIBUT
                                ) ?? "",
                            isRangeType: false,
                        };

                    $container
                        .find("input[type=checkbox]:checked")
                        .each((idy, item) => {
                            if (!distinctFilter.selected) {
                                distinctFilter.selected = [];
                            }
                            distinctFilter.selected.push(
                                ($j(item).val() || "").toString()
                            );
                        });

                    if (
                        distinctFilter.selected &&
                        distinctFilter.selected.length > 0
                    ) {
                        filterarr.push(distinctFilter);
                    }
                }
            });
            this.zustand.filter = filterarr;
        }
    }

    private showFilterArea() {
        $j("[data-ho-artikelsuche-wrapper]").removeClass("filter-hidden");

        $j("[data-ho-artikelsuche-filter-anzeigen]").addClass("invisible");

        this.DataTableFixedHeaderAktualisieren();
    }

    private hideFilterArea(makeAvailable = true) {
        $j("[data-ho-artikelsuche-wrapper]").addClass("filter-hidden");

        if (makeAvailable) {
            $j("[data-ho-artikelsuche-filter-anzeigen]").removeClass(
                "invisible"
            );
        } else {
            $j("[data-ho-artikelsuche-filter-anzeigen]").remove();
        }

        this.DataTableFixedHeaderAktualisieren();
    }

    /**
     * Sorgt dafür, dass am Ende aller Animationen FixedHeader und FixedFooter der Tabelle angepasst werden
     */
    private DataTableFixedHeaderAktualisieren() {
        $j("[data-ho-artikelsuche-wrapper]").one(
            "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
            () => {
                $j("[data-ho-artikelsuche-wrapper] table")
                    .dataTable()
                    .api()
                    .fixedHeader.adjust();
            }
        );
    }

    private prepareRequest(): ItemSearchRequestBuilder {
        let requestBuilder = new ItemSearchRequestBuilder(
            this.zustand.suchbegriff
        );

        if ($j("#O4380_cdlw").prop("checked") === true) {
            requestBuilder.setInStock(true);
        }

        let selectedLager = parseInt(
            ($j("#O4380_lage").val() || 0).toString(),
            10
        );
        if (selectedLager > 0) {
            requestBuilder.addInStockIn(selectedLager);
        }

        if ($j("#O4380_nur_gekaufte").prop("checked") === true) {
            requestBuilder.setOnlyUserPurchased(true);
        }

        if (this.zustand.filter.length) {
            requestBuilder.setFilter(this.zustand.filter);
        }

        if (this.zustand.sort) {
            requestBuilder.addSortBy(this.zustand.sort);
        }

        if (this.zustand.page > 1) {
            requestBuilder.setSkip((this.zustand.page - 1) * 50);
        }

        if (
            this.benutzerprofil.Suche_PreisanzeigeModus ===
            ArtikelsuchePreisanzeigeModus.Netto
        ) {
            requestBuilder.setCalculateNetPrices(true);
        }

        return requestBuilder;
    }

    private dealWithApiResponse(
        response: HoItemSearchApi.SearchResponse,
        params?: {
            sortBy?: HoItemSearchApi.EntitySortingParameter;
        }
    ) {
        if (
            response.results?.items?.length === 1 &&
            response.results.items[0] &&
            (response.results.items[0] as HoItemSearchApi.Product).itemId
        ) {
            AS400Layer.INSTANCE.LadeSeite(
                new HoProgrammInfo({
                    Programm: "O4008W",
                    Parameter:
                        "&artnht=" +
                        (response.results.items[0] as HoItemSearchApi.Product)
                            .itemId,
                    CallJsAnhaengen: true,
                }),
                {
                    DialogAnzeige: false,
                }
            );
            return;
        }

        const $layoutRoot = $j(Konstanten2.LAYOUT_ROOT_SELECTOR);

        $layoutRoot.off().empty().append(Templates.BaseLayoutForResults);

        let $leftBox = $j("#O4380_artikelfilter");
        let $rightBox = $j("#json");

        if (response.relatedQueries?.items?.[0]) {
            let $bubbles = $j(Templates.Aehnlich);
            response.relatedQueries.items.forEach((elem) => {
                $bubbles
                    .find("[data-ho-artikelsuche-spellingSuggestions]")
                    .append(
                        Templates.AehnlichButton.replace(
                            "%%relquery%%",
                            elem.query
                        ).replace("%%relquery%%", elem.query)
                    );
            });
            $rightBox.append($bubbles);
        }
        if (response.spellingSuggestions?.items?.[0]) {
            let $bubbles = $j(Templates.MeintenSie);
            response.spellingSuggestions.items.forEach((elem) => {
                $bubbles
                    .find("[data-ho-artikelsuche-spellingSuggestions]")
                    .append(
                        Templates.MeintenSieButton.replace(
                            "%%relquery%%",
                            elem.query
                        ).replace("%%relquery%%", elem.query)
                    );
            });
            $rightBox.append($bubbles);
        }

        // Ergebnisse gefunden
        if (response.results?.totalCount && response.results?.items?.length) {
            this.writeHistory();
            this.results = response.results.totalCount;
            if (!response.makesSense) {
                $rightBox.append(Templates.MakesNoSenseHint);
            }

            let tableBuilder = new TableBuilder({
                ArtikelBilderAnzeigen: this.benutzerprofil.Suche_BilderAnzeigen,
                PreisanzeigeModus: this.benutzerprofil.Suche_PreisanzeigeModus,
            });
            if (params?.sortBy) {
                tableBuilder.setOrder(params.sortBy);
            }
            response.results.items.forEach((elem: HoItemSearchApi.Product) => {
                tableBuilder.addRow(elem);
            });
            $rightBox.append(tableBuilder.getResult());

            let pagination = Templates.Pagination;

            pagination = pagination.replace(
                "%%start%%",
                ((this.zustand.page - 1) * 50 + 1).toString()
            );
            pagination = pagination.replace(
                "%%end%%",
                (this.results < this.zustand.page * 50
                    ? this.results
                    : this.zustand.page * 50
                ).toString()
            );
            pagination = pagination.replace(
                "%%total%%",
                this.results.toString()
            );

            if (this.results < this.zustand.page * 50) {
                pagination = pagination.replace("%%enablenext%%", "disabled");
            } else {
                pagination = pagination.replace("%%enablenext%%", "");
            }
            if (this.zustand.page === 1) {
                pagination = pagination.replace("%%enableprev%%", "disabled");
            } else {
                pagination = pagination.replace("%%enableprev%%", "");
            }

            let pages = 1;
            let currentpage = 1;
            pages = Math.ceil(this.results / 50);
            currentpage = this.zustand.page;

            // fixed page
            pagination = pagination.replace(
                "%%currpage%%",
                currentpage.toString()
            );

            // previous pages
            pagination = pagination.replace(
                "%%prevpage%%",
                (currentpage - 1).toString()
            );

            // followingpages
            pagination = pagination.replace(
                "%%nextpage%%",
                (currentpage + 1).toString()
            );
            pagination = pagination.replace("%%lastpage%%", pages.toString());

            let $pagination = $j(pagination);

            // elemente ausblenden wenn nicht sinnvoll anzuzeigen
            if (currentpage === 1) {
                $pagination.find(".firstpage").hide();
                $pagination.find(".spacerprev").hide();
                $pagination.find(".prevpage").hide();
            } else if (currentpage === 2) {
                $pagination.find(".spacerprev").hide();
                $pagination.find(".prevpage").hide();
            } else if (currentpage === 3) {
                $pagination.find(".spacerprev").hide();
            }

            if (currentpage === pages) {
                $pagination.find(".lastpage").hide();
                $pagination.find(".spacernext").hide();
                $pagination.find(".nextpage").hide();
            } else if (currentpage + 1 === pages) {
                $pagination.find(".lastpage").hide();
                $pagination.find(".spacernext").hide();
            } else if (currentpage + 2 === pages) {
                $pagination.find(".spacernext").hide();
            }

            $rightBox.append($pagination);
        }
        // Ergebnisse, aber aktuelle Seite leer
        else if (
            response.results?.totalCount &&
            !response.results?.items?.length
        ) {
            $rightBox.append(Templates.NotEnoughResultsHint);
        }
        // keine Ergebnisse
        else {
            $rightBox.append(Templates.EmptyResultsHint);
        }

        if (response.results?.filters?.length) {
            let filterBuilder = new FilterBuilder();
            response.results.filters.slice(0, 20).forEach((filter) => {
                filterBuilder.addFilter(filter);
            });
            $leftBox.append(filterBuilder.getResult());

            if (!this.benutzerprofil.Suche_FilterAnzeigen) {
                this.hideFilterArea();
            }
        } else {
            this.hideFilterArea(false);
        }

        Layout.INSTANCE.InitBereichLayout($layoutRoot, {
            FokusNeuSetzen: true,
            TabellenInitialisieren: true,
        });
        this.initEvents();

        ScrollToolbox.INSTANCE.SetzeScrollPosition($j("body"), $layoutRoot);
    }

    private writeHistory() {
        Historie.INSTANCE.NeuerEintrag(
            this.zustand.getHoProgrammInfo(),
            `HolterOnline - Artikel - Suche`
        );
    }
}
