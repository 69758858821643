import * as $j from "jquery";
import { AS400Layer } from "./as400Layer";
import { Browsersupport } from "./browsersupport";
import { FokusToolbox } from "./fokusToolbox";
import { Historie } from "./historie";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { IAS400LayerSettings } from "./interfaces/IAS400LayerSettings";
import { Katalog } from "./katalog";
import { Konstanten } from "./konstanten/konstanten";
import { Layout } from "./layout";
import { Powersuche } from "./powersuche";
import { StatischeMethoden } from "./statischeMethoden";
import { Warenkorb } from "./warenkorb";

/**
 * Stellt den zentralen Anwendungseinstiegspunkt bereit.
 * Sorgt dafür, dass alles, was initialisiert werden muss, initialisiert wird.
 * @default
 */
export default function main() {
    Browsersupport.INSTANCE.ValidiereBrowser();

    Layout.INSTANCE.InitEvents($j("body"));
    AS400Layer.INSTANCE.InitEvents();
    Historie.INSTANCE.InitEvents();
    Powersuche.INSTANCE.InitEvents();
    Katalog.INSTANCE.InitEvents();
    FokusToolbox.INSTANCE.InitEvents();

    // Warenkorb aktualisieren
    Warenkorb.INSTANCE.Aktualisieren();

    // Wenn es clientseitig zu einem beliebigen Fehler kommt, wird dieser als Fehlerdialog angezeigt
    if (window.onerror == null) {
        window.onerror = (
            message: string,
            filename: string,
            lineno: number,
            colno: number,
            error: Error
        ) => {
            // Bereinigungsvorgang für abgebrochene AJAX Requests
            StatischeMethoden.LadeAnimationAusblenden();
            // nur double equals damit null und undefined gleich geprüft werden
            // tslint:disable-next-line:triple-equals
            if (error != undefined) {
                // Zusätzlich die Fehlernachricht ausgeben
                AS400Layer.INSTANCE.LadeSeite(
                    new HoProgrammInfo({
                        Programm: Konstanten.FehlerProgramm,
                        Parameter:
                            Konstanten.FehlerNummer_Standardfehler +
                            "&TEXTHT=" +
                            encodeURIComponent(error.message),
                        CallJsAnhaengen: true,
                    }),
                    {
                        DialogAnzeige: true,
                    }
                );
            }
        };
    }
    // Wenn ein Programm zum Starten vorhanden ist, dieses laden
    if ($j("#programm").length) {
        // Programmstart-Variable auslesen
        let ProgrammName: string = ($j("#programm").val() as string).replace(
            /&amp;/g,
            "&"
        );

        // Parameter (falls vorhanden) auslesen
        let ProgrammParameter: string | undefined;
        if ($j("#programmparam").length) {
            ProgrammParameter = ($j("#programmparam").val() as string).replace(
                /&amp;/g,
                "&"
            );
            $j("#programmparam").remove();
        }

        let ProgrammInfo: HoProgrammInfo = new HoProgrammInfo({
            Programm: ProgrammName,
            Parameter: ProgrammParameter,
        });

        let ProgrammSettings: IAS400LayerSettings = {};

        if ($j("#programmdialog").val()) {
            ProgrammSettings.DialogAnzeige = true;
            ProgrammSettings.modalCallback = new HoProgrammInfo({
                Programm: "O4004W",
            });
        }

        // Angefordertes Programm laden
        AS400Layer.INSTANCE.LadeSeite(ProgrammInfo, ProgrammSettings);
    }
}
