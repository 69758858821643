/**
 * Definiert die möglichen Typen von Katalogseiten, nach denen gesucht werden kann
 */
export enum KatalogSeitenTyp {
    /**
     * Holter-eigene Katalogseiten
     */
    Holter,
    /**
     * Katalogseiten von Herstellern oder anderen Quellen
     */
    Extern,
}
