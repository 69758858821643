import { ArtikelsuchePreisanzeigeModus } from "../enums/ArtikelsuchePreisanzeigeModus";

export const ArtikelsuchePreisanzeigeModusTools = {
    /**
     * Ermittelt den entsprechenden Preisanzeige-Modus zu einem Preisanzeige-Code.
     * @param code Feldwert aus Datenbank-Feld `ADPRK1`
     */
    parseCode(code?: "K" | "B" | "N" | "I"): ArtikelsuchePreisanzeigeModus {
        switch (code) {
            case "B": {
                return ArtikelsuchePreisanzeigeModus.UVP;
            }
            case "N": {
                return ArtikelsuchePreisanzeigeModus.Netto;
            }
            case "I": {
                return ArtikelsuchePreisanzeigeModus.Kundenindividuell;
            }
            default: {
                return ArtikelsuchePreisanzeigeModus.Keine;
            }
        }
    },
};
