/**
 * Beinhaltet alle konstanten Werte für benutzerdefinierte Events
 */
export class KonstantenBenutzerdefinierteEvents {
    /**
     * Definiert den Namen des Events, das ausgelöst wird, wenn Ajax-Vorbereitungen gestartet worden sind.
     * Wird auf $j(document) ausgelöst.
     */
    public static readonly AjaxVorbereitungenGestartet: string =
        "holteronline:ajaxvorbereitungengestartet";
}
