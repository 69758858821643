// tslint:disable:comment-format
///<amd-dependency path="popper" />
///<amd-dependency path="bootstrap.bundle" />
// tslint:enable:comment-format

import * as $j from "jquery";
import { ValidierungsTyp } from "./enums/ValidierungsTyp";
import { Konstanten } from "./konstanten/konstanten";

/**
 * Stellt Funktionen zur Validierung (bzw. Fehleranzeige) in der Oberfläche bereit
 */
export class Validierung {
    public static readonly INSTANCE = new Validierung();

    private constructor() {}

    /**
     * Hängt Funktionen zur clientseitigen Validierung an
     * @param jQuery-Element, das das Layout-Wurzelelement darstellt
     */
    public InitValidierungen($layoutRoot: JQuery) {
        // Die Validierungsmethode wird auf die entsprechenden Events angehängt
        $j('[data-ho-validate*="input"]', $layoutRoot)
            .off("input.validate.holteronline")
            .on(
                "input.validate.holteronline",
                this.ValidierungenPruefen.bind(this)
            );

        $j('[data-ho-validate*="change"]', $layoutRoot)
            .off("change.validate.holteronline")
            .on(
                "change.validate.holteronline",
                this.ValidierungenPruefen.bind(this)
            );

        $j('[data-ho-validate*="blur"]', $layoutRoot)
            .off("blur.validate.holteronline")
            .on(
                "blur.validate.holteronline",
                this.ValidierungenPruefen.bind(this)
            );
    }

    /**
     * In dieser Methode werden alle Validierungen durchgeführt
     * @param ereignis
     */
    private ValidierungenPruefen(ereignis: JQuery.TriggeredEvent) {
        // Objekt, das geprüft wird, zu einem jQuery-Objekt machen
        let $pruefObjekt: JQuery = $j(
            ereignis.currentTarget
        ) as JQuery<HTMLElement>;

        // Beim Equal-Check gibt es zwei Prüf-Objekte -> das zweite hier berücksichtigen
        let $vergleichsObjekt: JQuery | undefined;

        let vergleichsObjektSelector = $pruefObjekt.data(
            "ho-validate-equal-selector"
        );
        if (vergleichsObjektSelector?.length) {
            $vergleichsObjekt = $j(vergleichsObjektSelector);
        }

        // Alle vorher durchgeführten Validierungen mit Fehlertexten entfernen
        this.FehlerAusblenden($pruefObjekt);
        if ($vergleichsObjekt?.length) {
            this.FehlerAusblenden($vergleichsObjekt);
        }

        // Wenn eine Validierung fehlgeschlagen ist, den entsprechenden Fehler ausgeben
        // Es wird nur der erste gefundene Fehler berücksichtigt
        if (!this.ValidiereRequired($pruefObjekt)) {
            // Fehlerausgabe Required
            // Fehler-Tooltip einblenden
            this.FehlerAnzeigen(
                $pruefObjekt,
                this.FehlertextErmitteln($pruefObjekt, ValidierungsTyp.Required)
            );
        } else if (!this.ValidiereRegex($pruefObjekt)) {
            // Fehlerausgabe Regex
            // Fehler-Tooltip einblenden
            this.FehlerAnzeigen(
                $pruefObjekt,
                this.FehlertextErmitteln($pruefObjekt, ValidierungsTyp.Regex)
            );
        } else if (!this.ValidiereEqual($pruefObjekt, $vergleichsObjekt)) {
            // Fehlerausgabe Equal
            // Fehlertext auslesen
            let fehlertext = this.FehlertextErmitteln(
                $pruefObjekt,
                ValidierungsTyp.Equal
            );

            // Fehler-Tooltip einblenden (auf beiden Elementen)
            this.FehlerAnzeigen($pruefObjekt, fehlertext);
            if ($vergleichsObjekt?.length) {
                this.FehlerAnzeigen($vergleichsObjekt, fehlertext);
            }
        }
    }

    /**
     * Prüft, ob für das angegebene Element eine Prüfung auf den angegebenen Typ angegeben ist.
     * @param PruefWert
     * @param PruefTyp
     */
    private PruefeValidierungsTyp(
        PruefObjekt: JQuery,
        PruefTyp: ValidierungsTyp
    ): boolean {
        // Abhängig vom angegebenen Prüftyp die entsprechende Prüfung durchführen
        switch (PruefTyp) {
            case ValidierungsTyp.Regex:
                return (
                    (PruefObjekt.attr("data-ho-validate") as string).indexOf(
                        "regex"
                    ) >= 0
                );
            case ValidierungsTyp.Required:
                return (
                    (PruefObjekt.attr("data-ho-validate") as string).indexOf(
                        "required"
                    ) >= 0
                );
            case ValidierungsTyp.Equal:
                return (
                    (PruefObjekt.attr("data-ho-validate") as string).indexOf(
                        "equal"
                    ) >= 0
                );
            default:
                // Fallback-Zweig
                return false;
        }
    }

    /**
     * Prüft für das angegebene Element, ob der eingegebene Text Regex-gültig ist (wenn angegeben).
     * @param PruefObjekt Objekt, dessen Inhalt validiert wird
     * @returns
     * true, wenn kein Regex-Check angegeben ist, oder der Regex-Check erfolgreich durchgeführt werden konnte.
     * false, wenn die Eingabe nicht dem angegebenen Regex entspricht.
     */
    private ValidiereRegex(PruefObjekt: JQuery): boolean {
        if (this.PruefeValidierungsTyp(PruefObjekt, ValidierungsTyp.Regex)) {
            // Regex-Check durchführen
            let regex: RegExp | undefined;

            // Prüfe ob Regex separat angegeben worden ist
            let regexExpression: string =
                PruefObjekt.data("ho-validate-regex-expression") || "";
            let regexFlags: string = PruefObjekt.data(
                "ho-validate-regex-flags"
            );

            // Wenn Regex-Parameter gefunden worden sind, einen Regex daraus erstellen
            if (regexExpression?.length) {
                regex = new RegExp(regexExpression, regexFlags);
            }

            // Prüfen ob Regex vorhanden
            if (!regex || regex == null || regex === undefined) {
                // Suche Regex-Typ (falls angegeben)
                let regexTyp: string =
                    PruefObjekt.data("ho-validate-regex-typ") || "";

                switch (regexTyp.toLowerCase()) {
                    case "number":
                        regex = Konstanten.RegexNumber;
                        break;
                    case "email":
                        regex = Konstanten.RegexEmail;
                        break;
                    default:
                        // Wenn kein Regex gefunden wurde, den Fallback-Regex nehmen
                        regex = Konstanten.RegexStandardInput;
                        break;
                }
            }

            // Regex-Check durchführen
            return this.PruefeRegex(PruefObjekt.val() as string, regex);
        }

        // Kein Regex-Check für dieses Element angegeben
        return true;
    }

    /**
     * Prüft für das angegebene Element, ob ein Text eingegeben worden ist (wenn angegeben).
     * @param PruefObjekt Objekt, dessen Inhalt validiert wird
     * @returns
     * true, wenn kein Required-Check angegeben ist, oder das Element einen Inhalt besitzt.
     * false, wenn das Element leer ist.
     */
    private ValidiereRequired(PruefObjekt: JQuery): boolean {
        if (this.PruefeValidierungsTyp(PruefObjekt, ValidierungsTyp.Required)) {
            // Required-Check durchführen
            return ((PruefObjekt.val() as string) || "").trim().length > 0;
        }

        // Kein Required-Check für dieses Element angegeben
        return true;
    }

    /**
     * Prüft für die angegebenen Elemente, ob die Inhalte ident sind (wenn angegeben).
     * @param PruefObjekt Objekt, dessen Inhalt validiert wird
     * @returns
     * true, wenn kein Required-Check angegeben ist, oder der Inhalt der beiden Elemente übereinstimmt.
     * false, wenn die Inhalte der beiden Elemente sich unterscheiden (ohne Berücksichtigung von Leerzeichen).
     */
    private ValidiereEqual(
        PruefObjekt: JQuery,
        VergleichsObjekt: JQuery | undefined
    ): boolean {
        if (this.PruefeValidierungsTyp(PruefObjekt, ValidierungsTyp.Equal)) {
            // Equal-Check durchführen
            if (VergleichsObjekt?.length) {
                // Prüfung erfolgreich wenn Texte in beiden Elementen übereinstimmen
                return (
                    (VergleichsObjekt.val() as string).trim() ===
                    (PruefObjekt.val() as string).trim()
                );
            } else {
                // Hier schlägt Prüfung immer fehl, damit dem Entwickler sein Fehler auffällt
                return false;
            }
        }

        // Kein Equal-Check für dieses Element angegeben
        return true;
    }

    /**
     * Prüft einen übergebenen String mit einem übergebenen Regex.
     * @returns true, wenn PruefText nur Zeichen enthält, die durch PruefRegex abgedeckt sind
     * @param PruefText Text, der auf den Regex geprüft wird
     * @param PruefRegex Regex, mit dem der Text geprüft wird
     */
    private PruefeRegex(PruefText: string, PruefRegex: RegExp): boolean {
        // Neues Regexp-Objekt aus dem Regex-Objekt erzeugen
        let regex = new RegExp(PruefRegex);

        if (PruefText && PruefText.length > 0) {
            // Regex-Prüfung durchführen
            let regexErgebnis: RegExpExecArray | null = regex.exec(PruefText);

            // Wenn das Ergebnis null ist, dann wurde kein übereinstimmender Teil-String gefunden
            // Wenn Input <> erstes Element vom Output, dann kommt ein ungültiges Zeichen vor
            if (
                regexErgebnis?.length &&
                regexErgebnis?.input === regexErgebnis[0]
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            // Leere Strings gelten als Regex-gültig
            return true;
        }
    }

    /**
     * Ermittelt den Fehlertext für eine bestimmte Gültigkeitsprüfung und ein bestimmtes Objekt.
     * @param ObjektInstanz Objekt, für das der Fehlertext ermittelt wird
     * @param PruefTyp Typ, für den der korrekte Fehlertext ermittelt wird
     * @returns Gibt einen Fehlertext zurück.
     * Es gibt für jeden Fehlertyp einen definierten Standard-Text.
     */
    private FehlertextErmitteln(
        ObjektInstanz: JQuery,
        PruefTyp: ValidierungsTyp
    ): string {
        let fehlertext: string;

        // Abhängig vom angegebenen Prüftyp die entsprechende Prüfung durchführen
        switch (PruefTyp) {
            case ValidierungsTyp.Regex:
                fehlertext = ObjektInstanz.data("ho-validate-regex-fehlertext");

                // Prüfen ob Fehlertext vorhanden
                if (!fehlertext?.length) {
                    // Standard-Fehlertext, wenn kein anderer angegeben
                    fehlertext = "Ungültiges Zeichen entdeckt";
                }
                break;

            case ValidierungsTyp.Required:
                fehlertext = ObjektInstanz.data(
                    "ho-validate-required-fehlertext"
                );

                // Prüfen ob Fehlertext vorhanden
                if (!fehlertext?.length) {
                    // Standard-Fehlertext, wenn kein anderer angegeben
                    fehlertext = "Erforderlich";
                }
                break;

            case ValidierungsTyp.Equal:
                fehlertext = ObjektInstanz.data("ho-validate-equal-fehlertext");

                // Prüfen ob Fehlertext vorhanden
                if (!fehlertext?.length) {
                    // Standard-Fehlertext, wenn kein anderer angegeben
                    fehlertext = "Eingaben sind nicht ident";
                }
                break;

            default:
                // Fallback-Zweig
                fehlertext = "Fehler beim Durchführen einer Gültigkeitsprüfung";
        }

        return fehlertext;
    }

    /**
     * Gibt ein Fehler-Tooltip auf einer bestimmten Objektinstanz aus
     * @param ObjektInstanz Objekt, auf dem ein Fehler-Tooltip angezeigt wird
     */
    private FehlerAnzeigen(ObjektInstanz: JQuery, FehlerText: string) {
        // Fehler-Tooltip einblenden
        if (!$j(ObjektInstanz).hasClass("error")) {
            // Tooltip-Ausrichtung ermitteln - wenn diese separat angegeben ist, diese verwenden
            // Fallback: rechtsbündig
            let tooltipPlacement =
                $j(ObjektInstanz).data("placement") || "right";

            $j(ObjektInstanz)
                .addClass("error")
                .attr("data-bs-original-title", FehlerText)
                .tooltip({
                    animation: false,
                    placement: tooltipPlacement,
                    trigger: "manual",
                    customClass: "tooltip-red",
                });
            $j(ObjektInstanz).tooltip("show");
        }
    }

    /**
     * Entfernt ein Fehler-Tooltip von einer bestimmten Objektinstanz
     * @param ObjektInstanz Objekt, von dem das Tooltip entfernt weird
     */
    private FehlerAusblenden(ObjektInstanz: JQuery) {
        // falls ein Tooltip vorhanden ist ausblenden
        if ($j(ObjektInstanz).hasClass("error")) {
            $j(ObjektInstanz).removeClass("error").tooltip("hide");
        }
    }
}
