import * as $j from "jquery";
import { AS400Layer } from "./as400Layer";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { Konstanten } from "./konstanten/konstanten";
import { Layout } from "./layout";
import { StatischeMethoden } from "./statischeMethoden";

/**
 * Stellt alle Funktionen bereit, die im Zusammenhang mit dem Toast notwendig sind.
 */
export class Message {
    /**
     * Inhalt des Toasts
     */
    private toastContent: string;
    /**
     * ID des Toasts
     */
    private toastId: string;
    /**
     * jQuery Selector für den Toast
     */
    private toastIdSelector: string;

    /**
     * Initialisiert die Klasse
     * @param toastContent - Inhalt des toasts (HTML Markup)
     */
    constructor(toastContent: string) {
        // Freie Toast-ID ermitteln, damit Toast eindeutig referenziert werden kann
        let toastId = "";
        for (let index = 0; index < 10; index++) {
            toastId = "O4812_toast_" + index.toString();
            if (!$j("#" + toastId).length) {
                break;
            }
        }
        this.toastId = toastId;
        this.toastIdSelector = "#" + this.toastId;

        this.toastContent = toastContent;
    }

    /**
     * Generiert das Markup und Initialisiert alle Events durch InitEvents
     */
    public InitMarkup(ProgrammName: string) {
        let params: string = "";

        let ProgrammInfo: HoProgrammInfo = new HoProgrammInfo({
            Programm: "O4812W",
            Parameter: params.trim(),
            CallJsAnhaengen: true,
        });

        AS400Layer.INSTANCE.SendeGetRequest(ProgrammInfo, {}).done(
            (AntwortDaten) => {
                // Entkopplung über anonyme Methode ist notwendig, da sonst "this" in VerarbeitePostAntwort undefined ist
                this.VerarbeiteGetAntwort(AntwortDaten, ProgrammName);
            }
        );
    }

    /**
     * Hängt alle notwendigen Events an
     */
    public InitEvents(ProgrammName: string) {
        $j(this.toastIdSelector).on("shown.bs.toast", (ereignis) => {
            const $layoutRoot = $j(Konstanten.ToastSelector);

            Layout.INSTANCE.InitLayout($layoutRoot, {
                ProgrammName,
                ToastAnzeige: true,
            });

            // Tooltip auch dann schließen, wenn aus btn mit tt ein Toast geöffnet wird
            StatischeMethoden.VersteckeTooltips($layoutRoot);
        });

        $j(this.toastIdSelector).on("hide.bs.toast", (ereignis) => {
            $j(ereignis.target).remove();
        });
    }

    /**
     * Setzt den Toasttitel
     * @param title - Titel des Toasts
     * @param pgmn Inhalt, der in den Programmnamen-Platzhalter im Toast gestellt wird
     * @param datum Inhalt, der in den Datum-Platzhalter im Toast gestellt wird
     */
    public SetzeHeader(title: string) {
        $j(this.toastIdSelector).find(".toast-title").html(title);
    }

    /**
     * Setzt den Toastcontent (Markup)
     * @param html - Content der im Toast angezeigt werden soll
     */
    public SetzeInhalt(html: string) {
        $j(this.toastIdSelector).find(".toast-content").html(html);
        $j(this.toastIdSelector).find("#O4BSTHead").remove();
    }

    /**
     * Anzeige des Toasts
     */
    public ZeigeToast() {
        $j(this.toastIdSelector).toast({ autohide: false });
        $j(this.toastIdSelector).toast("show");
    }

    /**
     * Verarbeitet die Antwort eines AJAX POST.
     * @param AntwortDaten Daten, die als Antwort auf den AJAX POST gekommen sind.
     */
    private VerarbeiteGetAntwort(AntwortDaten: string, ProgrammName: string) {
        // Response verarbeiten -> Response an Markup anhängen
        let tempAntwortDaten = AntwortDaten.replace(
            "%%toastId%%",
            this.toastId
        );

        $j(Konstanten.ToastSelector).append(tempAntwortDaten);
        this.SetzeHeader(
            $j(this.toastContent).find(Konstanten.FensterNameTitelId).html()
        );

        this.SetzeInhalt(this.toastContent);
        this.ZeigeToast();
        this.InitEvents(ProgrammName);
    }
}
