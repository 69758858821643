import { IHoProgrammInfo } from "./interfaces/IHoProgrammInfo";
import { Konstanten } from "./konstanten/konstanten";

/**
 * Klasse für Programminformationen (Programmname + Parameterliste)
 */
export class HoProgrammInfo implements IHoProgrammInfo {
    public Programm: string;
    public Parameter: string | undefined;
    public AjaxDaten: string | undefined;
    public ButtonName: string | undefined;
    public CallJsAnhaengen: boolean | undefined;

    /**
     * Beim Ermitteln von [[ProgrammAufruf]] wird der vollständige Aufrufpfad für das entsprechende Programm zurückgegeben.
     * Wenn [[CallJsAnhaengen]] "true" ist, dann wird "call=JS" angehängt.
     */
    public get ProgrammAufruf(): string {
        // Programmlink zusammenstoppeln
        let ProgrammLink: string =
            Konstanten.ProgrammAusfuehrungsverzeichnis +
            this.Programm +
            Konstanten.ProgrammDateierweiterung;

        // Wenn call=JS angehängt werden soll, dieses auch anhängen
        if (this.CallJsAnhaengen === true) {
            // Wenn ProgrammLink mit & oder ? endet nichts anhängen
            // Wenn ProgrammLink mit anderem Zeichen endet und noch kein ? enthält mit ? arbeiten
            // Sonst mit & arbeiten
            if (ProgrammLink.endsWith("&") || ProgrammLink.endsWith("?")) {
                // nichts tun
            } else if (ProgrammLink.indexOf("?") < 0) {
                // Noch kein Parameter vorhanden -> ? anhängen
                ProgrammLink += "?";
            } else {
                // In diesem Fall ein & anhängen
                ProgrammLink += "&";
            }

            ProgrammLink += "call=JS";
        }

        // Wenn nötig Parameterergänzung noch hinzufügen
        if (this.Parameter !== undefined && this.Parameter.trim() !== "") {
            // Wenn ProgrammLink mit & oder ? endet nichts anhängen
            // Wenn ProgrammLink mit anderem Zeichen endet und noch kein ? enthält mit ? arbeiten
            // Sonst mit & arbeiten
            if (
                ProgrammLink.substring(ProgrammLink.length - 2) === "&" ||
                ProgrammLink.substring(ProgrammLink.length - 2) === "?"
            ) {
                // nichts tun
            } else if (ProgrammLink.indexOf("?") < 0) {
                // Noch kein Parameter vorhanden -> ? anhängen
                ProgrammLink += "?";
            } else {
                // In diesem Fall ein & anhängen
                ProgrammLink += "&";
            }

            // Bei Parameterliste dafür sorgen, dass diese nicht mit ? oder & beginnt
            if (
                this.Parameter?.length > 0 &&
                (this.Parameter.startsWith("?") ||
                    this.Parameter.startsWith("&"))
            ) {
                // Erstes Zeichen entfernen
                ProgrammLink += this.Parameter.substring(1).trim();
            } else {
                // Gesamten Parameterstring hinzufügen
                ProgrammLink += this.Parameter.trim();
            }
        }
        // Programmaufruf zurückgeben
        return ProgrammLink;
    }

    /**
     * Beim Setzen von [[ProgrammAufruf]] wird der angegebene Programmlink in die Parameter [[Programm]] und [[Parameter]] gesplittet.
     */
    public set ProgrammAufruf(ProgrammLink: string) {
        let ProgrammName: string;
        let Parameter: string;

        // Prüfen, ob es sich um einen Link handelt, den wir in HO verarbeiten können
        if (ProgrammLink.indexOf(Konstanten.ProgrammDateierweiterung) < 0) {
            // Fehler beim Trennen der Parameter
            console.warn(
                "hoProgrammInfo:set ProgrammAufruf - Fehler beim Trennen von Parametern"
            );
            // Klasse leeren - Parameter sind nicht gesetzt
            this.Programm = "";
            this.Parameter = undefined;
            // Fehler zurückgeben
            return;
        }

        // Zusammenstellen des Programmnamens
        // Alle Sub-Ordner vor .PGM entfernen
        ProgrammName = ProgrammLink.toUpperCase();
        while (
            ProgrammName.indexOf("/") > -1 &&
            ProgrammName.indexOf("/") <
                ProgrammName.indexOf(Konstanten.ProgrammDateierweiterung)
        ) {
            // Text bis zum ersten / wegschneiden
            ProgrammName = ProgrammName.substring(
                ProgrammName.indexOf("/") + 1
            );
        }
        // Alles ab .PGM wegschneiden
        ProgrammName = ProgrammName.substring(
            0,
            ProgrammName.indexOf(Konstanten.ProgrammDateierweiterung)
        );

        // Zusammenstellen der Parameterliste
        // Alles nach .PGM ist zugehörig zu Parameter
        Parameter = ProgrammLink;
        Parameter = Parameter.substring(
            Parameter.indexOf(Konstanten.ProgrammDateierweiterung) +
                Konstanten.ProgrammDateierweiterung.length
        );

        if (Parameter?.startsWith("?")) {
            Parameter = Parameter.substring(1);
        }

        // Zum Schluss die gefundenen Parameter in die Klasse übernehmen
        this.Programm = ProgrammName;
        this.Parameter = Parameter;
    }

    /**
     * Beim Ermitteln von [[AjaxParameter]] werden die angegebenen [[AjaxDaten]] für das Versenden per jQuery-Ajax-POST vorbereitet.
     * "call=JS" wird unabhängig von [[CallJsAnhaengen]] immer hinzugefügt.
     */
    public get AjaxParameter(): string {
        let AjaxDaten: string = this.AjaxDaten ?? "";

        if (AjaxDaten && AjaxDaten.length > 0) {
            // Erstes &-Zeichen hinzufügen wenn Daten in Form sind
            AjaxDaten += "&";
        }

        // Parameter anhängen, damit Businesslogik weiß, dass Programm über JS aufgerufen worden ist
        AjaxDaten += "call=JS";

        // Button nur hinzufügen wenn angegebene
        if (this.ButtonName && this.ButtonName.length > 0) {
            AjaxDaten += "&butt=" + this.ButtonName.trim();
        }

        return AjaxDaten;
    }

    /**
     * Gibt die einzelnen Parameter von [[AjaxParameter]] in Form eines Objekts zurück
     */
    public get AjaxParameterObjekt(): object {
        let ajaxObjekt: object = this.AjaxParameter.split("&").reduce(
            (obj, str, index) => {
                let strParts = str.split("=");
                if (strParts[0] && strParts[1]) {
                    obj[strParts[0].replace(/\s+/g, "")] = strParts[1].trim();
                }
                return obj;
            },
            {}
        );

        return ajaxObjekt;
    }

    /**
     * Erstellt eine neue Instanz von HoProgrammInfo.
     */
    constructor(Settings: IHoProgrammInfo) {
        this.Programm = Settings.Programm;
        this.Parameter = Settings.Parameter;
        this.AjaxDaten = Settings.AjaxDaten;
        this.ButtonName = Settings.ButtonName;

        // Wenn CallJsAnhaengen nicht explizit mit false angegeben ist, dann true befüllen
        this.CallJsAnhaengen =
            Settings.CallJsAnhaengen === false ? false : true;
    }

    /**
     * Fügt einen Parameter zu [[this.Parameter]] hinzu unter Berücksichtigung der endenden Zeichen ('&').
     * Es wird nicht geprüft, ob der Parameter bereits enthalten ist.
     * @param ParamName Name des Parameters, der ergänzt wird
     * @param ParamWert Wert des Parameters, der ergänzt wird
     */
    public ParameterHinzufuegen(ParamName: string, ParamWert: string) {
        this.Parameter += "&" + ParamName.trim() + "=" + ParamWert.trim();
    }

    /**
     * Fügt einen Parameter zu [[this.AjaxDaten]] hinzu unter Berücksichtigung der endenden Zeichen ('&').
     * Es wird nicht geprüft, ob der Parameter bereits enthalten ist.
     * @param ParamName Name des Parameters, der ergänzt wird
     * @param ParamWert Wert des Parameters, der ergänzt wird
     */
    public AjaxDatenHinzufuegen(ParamName: string, ParamWert: string) {
        this.AjaxDaten =
            (this.AjaxDaten ? this.AjaxDaten + "&" : "") +
            ParamName.trim() +
            "=" +
            ParamWert.trim();
    }

    /**
     * Vergleicht, ob ein [[HoProgrammInfo]] Objekt das gleiche Programm und die gleichen Parameter wie diese Klasseninstanz hat
     * @param that Zu vergleichendes Objekt
     * @returns true, wenn beide Objektinstanzen dasselbe Programm und dieselben Parameter enthält, andernfalls false
     */
    public VergleicheObProgrammUndParameterIdent(
        that: HoProgrammInfo
    ): boolean {
        // Als erstes Programm überprüfen
        if (!that || !that.Programm) {
            return false;
        }
        if (this.Programm.trim() !== that.Programm.trim()) {
            return false;
        }

        let thisParameterArray = (this.Parameter ?? "").split("&");
        let thatParameterArray = (that.Parameter ?? "").split("&");

        let ident: boolean = true;

        // Prüfen ob alle Items des einen Array im anderen sind
        thisParameterArray.forEach((thisWert) => {
            if (thatParameterArray.indexOf(thisWert) < 0) {
                // Wenn ein Item nicht im anderen Array gefunden wird -> nicht gleich
                ident = false;
            }
        });
        // dasselbe umgekehrt
        thatParameterArray.forEach((thatWert) => {
            if (thisParameterArray.indexOf(thatWert) < 0) {
                // Wenn ein Item nicht im anderen Array gefunden wird -> nicht gleich
                ident = false;
            }
        });

        return ident;
    }
}
