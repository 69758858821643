// tslint:disable:comment-format
///<amd-dependency path="bootstrap.slider" />
// tslint:enable:comment-format

import * as $j from "jquery";
import { ArtikelsucheHilfsfunktionen } from "./artikelsucheHilfsfunktionen";
import { AS400Layer } from "./as400Layer";
import { ArtikelsucheVorlagen } from "./enums/ArtikelsucheVorlagen";
import { KeyEvent } from "./events/keyEvent";
import { Historie } from "./historie";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { KonstantenArtikelsuche } from "./konstanten/konstantenArtikelsuche";
import { Layout } from "./layout";

/**
 * Klasse für Artikelsuche-Logik.
 * @description
 * Diese Klasse wird ausgeführt, wenn die Artikelsuche verwendet worden ist.
 * Sie kümmert sich darum, dass alles rund um die Artikelsuche funktioniert.
 */
export class Artikelsuche {
    /**
     * Definiert den Layout-Root-Bereich, in dem die Initialisierung der Artikelsuche durchgeführt wird.
     */
    private $layoutRoot: JQuery;

    /**
     * Hilfsfunktionen zur Artikelsuche, um die Komplexität der Logik in dieser Klasse zu reduzieren
     */
    private hifu: ArtikelsucheHilfsfunktionen;

    constructor($layoutRoot: JQuery) {
        this.$layoutRoot = $layoutRoot;
        this.hifu = new ArtikelsucheHilfsfunktionen(this.$layoutRoot);
    }

    /**
     * Führt die Initialisierung des Filterbereichs der Artikelsuche durch
     */
    public InitFilterBereich() {
        // Filterbereich neu laden
        this.AktualisiereFilterBereich();

        // Prüfen ob das Filter-Panel als Overlay angezeigt wird
        // Wenn der Tabellen-Wrapper display=block hat, dann überlagert das Filter-Panel den Bereich
        if (
            $j(KonstantenArtikelsuche.TableWrapperSelector).css("display") ===
            "block"
        ) {
            this.FilterBereichSchliessen();
        }
    }

    /**
     * Bereitet die Tabellen-Settings für die Artikelsuche-Tabelle vor
     * @param settings Vorbefüllte Settings
     */
    public HoleDataTableSettings(
        settings: DataTables.Settings
    ): DataTables.Settings {
        // Einstellungs-Optionen ermitteln
        settings.ajax = this.HoleTabelleAjaxSettings();
        settings.columns = this.HoleTabelleSpaltenDefinitionen();
        settings.order = this.HoleSortierDefinition();

        settings.displayStart = this.hifu.DisplayStart;
        settings.pageLength = this.hifu.Limit;

        return settings;
    }

    /**
     * Initialisiert für ein DataTable-Objekt die Artikelsuche-Tabelle
     */
    public InitialisiereDataTable(dt: DataTables.Api) {
        dt.off(KonstantenArtikelsuche.EventNameDataTableDraw).on(
            KonstantenArtikelsuche.EventNameDataTableDraw,
            () => {
                // Funktion ausführen, die dafür sorgt, dass in der Tabelle layoutmäßig alles initialisiert ist
                this.OnArtikelsucheTabelleDraw(dt);

                // Historie-Eintrag für den geänderten Tabellenzustand erstellen
                this.HistorieEintragHinzufuegen(dt);
            }
        );
    }

    /**
     * Ermittelt die jQuery AJAX Settings für die asynchrone Tabellen-Schnittstellenanfrage
     */
    private HoleTabelleAjaxSettings(): DataTables.AjaxSettings {
        let SucheProgrammInfo: HoProgrammInfo = new HoProgrammInfo({
            Programm: KonstantenArtikelsuche.ProgrammTrefferliste,
            AjaxDaten: "ukhonsu=" + this.hifu.UkHonsu,
        });

        let SucheProgrammInfoSettings: DataTables.AjaxSettings = {
            url: SucheProgrammInfo.ProgrammAufruf,
            type: "POST",
            data: (d: any) => {
                $j.extend(d, SucheProgrammInfo.AjaxParameterObjekt);
            },
        };

        return SucheProgrammInfoSettings;
    }

    /**
     * Spaltendefinition ermitteln
     */
    private HoleTabelleSpaltenDefinitionen(): DataTables.ColumnSettings[] {
        let spaltenArray: DataTables.ColumnSettings[] = [];

        // Auf den Spaltenheadern sind die zugehörigen Column-Names angegeben
        // Diese durchloopen und für jedes das richtige Spaltenformat finden
        $j(
            "th[" + KonstantenArtikelsuche.TabelleSpaltenNameDataAttribut + "]",
            this.$layoutRoot
        ).each((index, elem) => {
            let colName =
                $j(elem).attr(
                    KonstantenArtikelsuche.TabelleSpaltenNameDataAttribut
                ) ?? "";
            let spaltenElement: DataTables.ColumnSettings = {
                data: colName,
                render: (data, type, full, meta) => {
                    return this.hifu.HoleTabelleSpalteRendered(colName, full);
                },
                className: this.hifu.HoleTabelleSpalteKlasse(colName),
            };
            spaltenArray.push(spaltenElement);
        });

        return spaltenArray;
    }

    /**
     * Ermittelt die Sortier-Definition für die DataTable
     */
    private HoleSortierDefinition():
        | Array<number | string>
        | Array<Array<number | string>> {
        // Artikelsuche kann nur nach bestimmten Spalten sortiert werden
        if (
            this.hifu.Sortierung === 2 ||
            this.hifu.Sortierung === 4 ||
            this.hifu.Sortierung === 6
        ) {
            return [[this.hifu.Sortierung, this.hifu.SortierRichtung]];
        }

        return [];
    }

    /**
     * Methode, die die Logik enthält, die ausgeführt wird, wenn die Artikelsuche-Tabelle neu geladen wird
     */
    private OnArtikelsucheTabelleDraw(dt: DataTables.Api) {
        const $layoutRoot = $j(KonstantenArtikelsuche.TabelleWrapperSelector);
        Layout.INSTANCE.InitBereichLayout($layoutRoot);

        // Button für erweiterte Artikelinformationen
        $j(
            "[" + KonstantenArtikelsuche.ZusatzInfoButtonDataAttribut + "]",
            this.$layoutRoot
        )
            .off(KonstantenArtikelsuche.EventNameClick)
            .on(KonstantenArtikelsuche.EventNameClick, (ereignis) => {
                // Nächste Zeile ermitteln
                let $row = $j(ereignis.currentTarget).closest("tr");
                let apiRow = dt.row($row);

                if (apiRow.child.isShown()) {
                    // Zeile wird angezeigt -> ausblenden
                    $row.removeClass(
                        KonstantenArtikelsuche.KlasseZusatzInfoAnzeigen
                    );

                    $j(
                        KonstantenArtikelsuche.ZusatzInfoZeileSelector,
                        apiRow.child()
                    ).slideUp(() => {
                        apiRow.child.hide();
                    });
                } else {
                    // Zeile ist im Moment ausgeblendet -> Daten ermitteln und anzeigen

                    let ProgrammInfoArtikelInfo = new HoProgrammInfo({
                        Programm: KonstantenArtikelsuche.ProgrammZusatzInfo,
                    });

                    ProgrammInfoArtikelInfo.AjaxDatenHinzufuegen(
                        "artnr",
                        $j(ereignis.currentTarget).attr(
                            KonstantenArtikelsuche.ZusatzInfoButtonDataAttribut
                        ) ?? ""
                    );
                    ProgrammInfoArtikelInfo.AjaxDatenHinzufuegen(
                        "lage",
                        (
                            $j(
                                KonstantenArtikelsuche.LagerSelector,
                                this.$layoutRoot
                            ).val() ?? ""
                        ).toString()
                    );

                    AS400Layer.INSTANCE.SendePostRequest(
                        ProgrammInfoArtikelInfo,
                        {}
                    ).done((ereignisO4821) => {
                        // Daten ausgeben und Bereich anzeigen
                        apiRow.child(ereignisO4821.toString()).show();
                        $row.addClass(
                            KonstantenArtikelsuche.KlasseZusatzInfoAnzeigen
                        );
                        $j(
                            KonstantenArtikelsuche.ZusatzInfoZeileSelector,
                            apiRow.child()
                        ).slideDown();
                        Layout.INSTANCE.InitBereichLayout($layoutRoot);
                    });
                }
            });
    }

    /**
     * Fügt einen Historie-Eintrag hinzu
     */
    private HistorieEintragHinzufuegen(dt: DataTables.Api) {
        // benötigte Klassen vorbereiten
        let programmInfoHistorie: HoProgrammInfo = new HoProgrammInfo({
            Programm: "O4370W",
            Parameter:
                "ukhonsu=" +
                (
                    $j(
                        KonstantenArtikelsuche.UkHonsuSelector,
                        this.$layoutRoot
                    ).val() || ""
                ).toString(),
        });

        // Sortierung überprüfen
        let order = dt.order();
        if (order.length) {
            let orderSpalte = order[0][0];
            let orderRichtung = order[0][1];

            if (orderSpalte) {
                let orderSpalteNumerisch = parseInt(orderSpalte.toString(), 10);

                // Zur Spalte muss 1 hinzugezählt werden - Index ist 0-basiert
                orderSpalteNumerisch += 1;

                // Parameter hinzufügen
                programmInfoHistorie.ParameterHinzufuegen(
                    "sort",
                    orderSpalteNumerisch.toString(10)
                );

                // Sortier-Richtung wird nur hinzugefügt wenn eine Spalte zur Sorierung angegeben war
                if (orderRichtung && orderRichtung === "desc") {
                    programmInfoHistorie.ParameterHinzufuegen("sortdesc", "1");
                }
            }
        }

        // Pagination-Seite überprüfen
        let seite = dt.page();
        if (seite > 0) {
            // Zur Seite muss 1 hinzugezählt werden - Index ist 0-basiert
            seite += 1;

            // Parameter hinzufügen zur Anfrage
            programmInfoHistorie.ParameterHinzufuegen(
                "seite",
                seite.toString(10)
            );
        }

        // Limit überprüfen
        let limit = dt.page.len();
        if (limit > 0 && limit !== 50) {
            // Limit wird nur übernommen, wenn es ungleich dem Standard-Limit ist
            programmInfoHistorie.ParameterHinzufuegen(
                "limit",
                limit.toString(10)
            );
        }

        // Historie-Eintrag hinzufügen
        Historie.INSTANCE.NeuerEintrag(
            programmInfoHistorie,
            "Holter Online - Artikel - Powersuche"
        );
    }

    /**
     * Lädt den Filterbereich neu
     */
    private AktualisiereFilterBereich() {
        // Prüfen ob der Filterbereich der Artikelsuche vorhanden ist
        if (
            $j(KonstantenArtikelsuche.FilterBereichSelector, this.$layoutRoot)
                .length
        ) {
            // Programminfo für Filterermittlung zusammenstellen
            let FilterProgrammInfo: HoProgrammInfo = new HoProgrammInfo({
                Programm: KonstantenArtikelsuche.ProgrammFilterbereich,
                Parameter: "ukhonsu=" + this.hifu.UkHonsu,
            });

            // Filterbereich laden
            AS400Layer.INSTANCE.LadeSeite(FilterProgrammInfo, {
                BlockContainerSelector:
                    KonstantenArtikelsuche.FilterBereichSelector,
                LadeanimationDeaktivieren: true,
            }).done(this.InitialisiereFilterBereichLayout.bind(this));
        }
    }

    /**
     * Initialisiert das Layout im Filterbereich
     */
    private InitialisiereFilterBereichLayout() {
        // Beim Click auf einen Kategorie-Bereich wird der Filterbereich angezeigt
        $j(
            "[" + KonstantenArtikelsuche.FilterHeaderDataAttribut + "]",
            this.$layoutRoot
        )
            .off(KonstantenArtikelsuche.EventNameClick)
            .on(
                KonstantenArtikelsuche.EventNameClick,
                (ereignis: JQuery.ClickEvent) => {
                    // Aktuelles Target ist der Header, auf den geklickt worden ist
                    let $filterHeader = $j(ereignis.currentTarget);

                    // Attribut auslesen, das geladen werden soll
                    let attributName: string =
                        $filterHeader.attr(
                            KonstantenArtikelsuche.FilterHeaderDataAttribut
                        ) ?? "";

                    // Zum Filter-Header zugehörigen Content-Bereich finden
                    let $filterContent = $j(
                        "[" +
                            KonstantenArtikelsuche.FilterContainerDataAttribut +
                            "='" +
                            attributName +
                            "']",
                        this.$layoutRoot
                    );

                    // Filter-Sichtbarkeit umschalten
                    let filterGeoeffnet: boolean =
                        this.ArtikelFilterUmschalten(attributName);

                    if (filterGeoeffnet) {
                        // Filter ist jetzt offen -> prüfen, ob Filter bereits initialisiert/geladen ist
                        if (
                            $filterContent.children(
                                KonstantenArtikelsuche.FilterLeerSelector
                            ).length
                        ) {
                            // Wenn das der Fall ist, dann wurde der Filter-Bereich noch nicht befüllt
                            // Ladeanimation hinzufügen bis die Schnittstellenantwort da ist
                            $filterContent.children().off();
                            $filterContent
                                .off()
                                .empty()
                                .append(
                                    this.hifu.HoleVorlage(
                                        ArtikelsucheVorlagen.Ladeanimation
                                    )
                                );

                            // Filter laden
                            // Am Ende der Anfrage diese Funktion erneut ausführen, damit auch alles im Filter-Bereich initialisiert wird
                            this.LadeArtikelFilter(attributName).done(
                                this.InitialisiereFilterBereichLayout.bind(this)
                            );
                        }
                    }
                }
            );

        // Filter-Textboxen Event anhängen
        $j(
            "[" + KonstantenArtikelsuche.FilterTextboxDataAttribut + "]",
            this.$layoutRoot
        )
            .off(KonstantenArtikelsuche.EventNameKeypress)
            .on(KonstantenArtikelsuche.EventNameKeypress, (ereignis) => {
                if (KeyEvent.isEnter(ereignis)) {
                    // Schnittstellenanfrage mit Suchtext aus Textbox ausführen
                    let $filterTextbox = $j(ereignis.currentTarget);

                    // Attribut auslesen, das geladen werden soll
                    let attributName =
                        $filterTextbox.attr(
                            KonstantenArtikelsuche.FilterTextboxDataAttribut
                        ) ?? "";

                    // Logik zum Aktualisieren des Bereichs ausführen
                    this.ArtikelFilterAktualisierenMitTextbox(attributName);
                }
            });

        // Filter-Textbox-Buttons
        $j(
            "[" + KonstantenArtikelsuche.FilterTextboxButtonDataAttribut + "]",
            this.$layoutRoot
        )
            .off(KonstantenArtikelsuche.EventNameClick)
            .on(
                KonstantenArtikelsuche.EventNameClick,
                (ereignis: JQuery.ClickEvent) => {
                    // Schnittstellenanfrage mit Suchtext aus Textbox ausführen
                    let $filterTextboxButton = $j(ereignis.currentTarget);

                    // Attribut auslesen, das geladen werden soll
                    let attributName =
                        $filterTextboxButton.attr(
                            KonstantenArtikelsuche.FilterTextboxButtonDataAttribut
                        ) ?? "";

                    // Logik zum Aktualisieren des Bereichs ausführen
                    this.ArtikelFilterAktualisierenMitTextbox(attributName);
                }
            );

        // Links zum Laden von weiteren Ergebnissen Event anhängen
        $j(
            "[" + KonstantenArtikelsuche.FilterValuesWeitereDataAttribut + "]",
            this.$layoutRoot
        )
            .off(KonstantenArtikelsuche.EventNameClick)
            .on(
                KonstantenArtikelsuche.EventNameClick,
                (ereignis: JQuery.ClickEvent) => {
                    let $filterLink = $j(ereignis.currentTarget);

                    // Attributsnamen auslesen
                    let attributName =
                        $filterLink.attr(
                            KonstantenArtikelsuche.FilterValuesWeitereDataAttribut
                        ) ?? "";
                    // Seite auslesen, die geladen werden soll
                    let seite = $filterLink.attr(
                        KonstantenArtikelsuche.FilterValuesWeitereSeiteDataAttribut
                    );

                    let $filterLinkContainer = $j(
                        "[" +
                            KonstantenArtikelsuche.FilterValuesWeitereContainerDataAttribut +
                            "='" +
                            attributName +
                            "']",
                        this.$layoutRoot
                    );

                    let $filterListe = $j(
                        "[" +
                            KonstantenArtikelsuche.FilterValuesListDataAttribut +
                            "='" +
                            attributName +
                            "']",
                        this.$layoutRoot
                    );

                    if ($filterListe?.length) {
                        // Filterlink mit Ladeanimation befüllen
                        $filterLinkContainer.children().off();
                        $filterLinkContainer
                            .off()
                            .empty()
                            .append(
                                this.hifu.HoleVorlage(
                                    ArtikelsucheVorlagen.Ladeanimation
                                )
                            );

                        // Bestehenden Filterwert mitnehmen
                        let filterTextboxWert: string =
                            $filterLink.attr(
                                KonstantenArtikelsuche.FilterValuesSuchbegriffDataAttribut
                            ) ?? "";

                        // Gefilterte Liste laden
                        this.LadeArtikelFilter(
                            attributName,
                            false,
                            true,
                            filterTextboxWert,
                            parseInt(seite ?? "0", 10)
                        ).done(() => {
                            // Filter-Link-Container entfernen
                            $filterLinkContainer.remove();
                            // Initialisierungsroutine ausführen
                            this.InitialisiereFilterBereichLayout();
                        });
                    }
                }
            );

        // Buttons zum Zurücksetzen von Filtern mit Event behängen
        $j(
            "[" + KonstantenArtikelsuche.FilterResetDataAttribut + "]",
            this.$layoutRoot
        )
            .off(KonstantenArtikelsuche.EventNameClick)
            .on(
                KonstantenArtikelsuche.EventNameClick,
                (ereignis: JQuery.ClickEvent) => {
                    let $resetButton = $j(ereignis.currentTarget);

                    // herausfinden, welcher Filter zurückgesetzt werden soll
                    let attributName: string =
                        $resetButton.attr(
                            KonstantenArtikelsuche.FilterResetDataAttribut
                        ) ?? "";

                    // gefundenen Filter zurücksetzen
                    this.ArtikelFilterZuruecksetzen(attributName);
                }
            );

        // Button zum Zurücksetzen aller Filter initialisieren
        $j(KonstantenArtikelsuche.FilterResetAlleSelector, this.$layoutRoot)
            .off(KonstantenArtikelsuche.EventNameClick)
            .on(
                KonstantenArtikelsuche.EventNameClick,
                (ereignis: JQuery.Event) => {
                    // alle Filter zurücksetzen
                    $j(
                        "[" +
                            KonstantenArtikelsuche.FilterHeaderDataAttribut +
                            "]",
                        this.$layoutRoot
                    ).each((index, elem) => {
                        // Filter zurücksetzen
                        this.ArtikelFilterZuruecksetzen(
                            $j(elem).attr(
                                KonstantenArtikelsuche.FilterHeaderDataAttribut
                            ) ?? ""
                        );
                    });
                    // Am Ende des Zurücksetzens die Suche neu ausführen
                    this.ArtikelFilterSubmit();
                }
            );

        // Buttons zum Anwenden von Filtern mit Click-Event bestücken
        $j(KonstantenArtikelsuche.FilterAnwendenSelector, this.$layoutRoot)
            .off(KonstantenArtikelsuche.EventNameClick)
            .on(
                KonstantenArtikelsuche.EventNameClick,
                this.ArtikelFilterSubmit.bind(this)
            );

        // Button zum Öffnen des Filters mit Event ausstatten
        $j(KonstantenArtikelsuche.FilterAnzeigenButtonSelector)
            .off(KonstantenArtikelsuche.EventNameClick)
            .on(
                KonstantenArtikelsuche.EventNameClick,
                this.FilterBereichOeffnen.bind(this)
            );

        // Button zum Schließen des Filters mit Event ausstatten
        $j(KonstantenArtikelsuche.FilterAusblendenButtonSelector)
            .off(KonstantenArtikelsuche.EventNameClick)
            .on(
                KonstantenArtikelsuche.EventNameClick,
                this.FilterBereichSchliessen.bind(this)
            );

        // Prüfen ob es Filter gibt, die sofort geöffnet werden sollen
        $j(
            "[" +
                KonstantenArtikelsuche.FilterSofortZuOeffnenDataAttribut +
                "]",
            this.$layoutRoot
        ).each((idx, obj) => {
            let $filterbereich: JQuery = $j(obj);
            // data-Attribut vom Filter entfernen - wird jetzt geöffnet
            $filterbereich.removeAttr(
                KonstantenArtikelsuche.FilterSofortZuOeffnenDataAttribut
            );
            // Click-Event auf den Filterbereich löst die restliche notwendige Logik aus
            $filterbereich.trigger("click");
        });
    }

    /**
     * Öffnet den Filterbereich
     */
    private FilterBereichOeffnen() {
        // Artikelsuche-Bereich Klasse entfernen
        $j(KonstantenArtikelsuche.ContainerWrapperSelector).removeClass(
            "filter-hidden"
        );
        // Button zur Anzeige des Filterbereichs ausblenden
        $j(KonstantenArtikelsuche.FilterAnzeigenButtonSelector).addClass(
            "invisible"
        );

        // FixedHeader der Tabelle aktualisieren
        this.DataTableFixedHeaderAktualisieren();
    }

    /**
     * Schließt den Filterbereich
     */
    private FilterBereichSchliessen() {
        // Artikelsuche-Bereich Klasse hinzufügen
        $j(KonstantenArtikelsuche.ContainerWrapperSelector).addClass(
            "filter-hidden"
        );
        // Button zur Anzeige des Filterbereichs einblenden
        $j(KonstantenArtikelsuche.FilterAnzeigenButtonSelector).removeClass(
            "invisible"
        );

        // FixedHeader der Tabelle aktualisieren
        this.DataTableFixedHeaderAktualisieren();
    }

    /**
     * Sorgt dafür, dass am Ende aller Animationen FixedHeader und FixedFooter der Tabelle angepasst werden
     */
    private DataTableFixedHeaderAktualisieren() {
        $j(KonstantenArtikelsuche.ContainerWrapperSelector).one(
            "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
            () => {
                $j(KonstantenArtikelsuche.TabelleSelector)
                    .dataTable()
                    .api()
                    .fixedHeader.adjust();
            }
        );
    }

    /**
     * Sorgt dafür, dass ein Filterbereich mit dem Wert aus der Such-Textbox aktualisiert wird
     * @param attributName Name des Attributs, für das der Filterbereich aktualisiert wird
     */
    private ArtikelFilterAktualisierenMitTextbox(attributName: string) {
        let $filterListe = $j(
            "[" +
                KonstantenArtikelsuche.FilterValuesListDataAttribut +
                "='" +
                attributName +
                "']",
            this.$layoutRoot
        );
        let $filterTextbox = $j(
            "[" +
                KonstantenArtikelsuche.FilterTextboxDataAttribut +
                "='" +
                attributName +
                "']",
            this.$layoutRoot
        );

        if ($filterListe?.length) {
            // Bis Ergebnisliste geladen ist Ladeanimation anzeigen
            $filterListe.children().off();
            $filterListe
                .off()
                .empty()
                .append(
                    this.hifu.HoleVorlage(ArtikelsucheVorlagen.Ladeanimation)
                );
            // Gefilterte Liste laden
            // Am Ende der Anfrage diese Funktion erneut ausführen, damit auch alles im Filter-Bereich initialisiert wird
            this.LadeArtikelFilter(
                attributName,
                false,
                false,
                $filterTextbox.val() as string
            ).done(this.InitialisiereFilterBereichLayout.bind(this));
        }
    }

    /**
     * Schaltet die Sichtbarkeit des angegeben Containers abhängig davon, ob er aktuell sichtbar ist, um.
     * @param FilterName Name des Filters, der umgeschaltet wird
     * @returns true, wenn der Filter geöffnet worden ist - false, wenn der Filter geschlossen worden ist
     */
    private ArtikelFilterUmschalten(FilterName: string): boolean {
        // Content-Bereich finden, um Sichtbarkeit festzustellen
        let $filterContent = $j(
            "[" +
                KonstantenArtikelsuche.FilterContainerDataAttribut +
                "='" +
                FilterName +
                "']",
            this.$layoutRoot
        );

        if ($filterContent.height() === 0) {
            // Container wird noch nicht angezeigt -> anzeigen
            this.hifu.ArtikelFilterOeffnen(FilterName);
            return true;
        } else {
            // Filter ausblenden
            this.hifu.ArtikelFilterSchliessen(FilterName);
            return false;
        }
    }

    /**
     * Setzt den angegebenen Filter auf seinen Ursprungs-Zustand zurück
     * @param FilterName Name des Filters, der geöffnet werden soll
     */
    private ArtikelFilterZuruecksetzen(FilterName: string): void {
        // Zuerst den String mit ausgewählten Filtern für den Bereich leeren
        $j(
            "[" +
                KonstantenArtikelsuche.FilterAusgewaehlteValuesDataAttribut +
                "=" +
                FilterName +
                "]",
            this.$layoutRoot
        ).val("");

        // Dann Frontend aktualisieren - Filter an Wert in hidden field angleichen
        this.hifu.ArtikelFilterFrontendMitHiddenFieldAktualisieren(FilterName);

        // Filter schließen
        this.hifu.ArtikelFilterSchliessen(FilterName);
    }

    /**
     * Lädt einen bestimmten Artikelfilter
     * @param FilterName Gibt an, welcher filter geladen wird
     * @param Initialisierung wenn true, dann wird der gesamte Container ersetzt mit der Schnittstellenantwort
     * @param ListeErweitern wenn true, dann wird der Listenbereich erweitert. Macht nur mit [[Initialisierung]]=false Sinn.
     * @param Suchtext Such-String, um die Liste zu filtern
     */
    private LadeArtikelFilter(
        FilterName: string,
        Initialisierung: boolean = true,
        ListeErweitern: boolean = false,
        Suchtext: string = "",
        Seite: number = 0
    ): JQuery.Deferred<any> {
        // Schnittstellenanfrage vorbereiten
        let FilterValuesProgrammInfo: HoProgrammInfo = new HoProgrammInfo({
            Programm: KonstantenArtikelsuche.ProgrammFilterValues,
            Parameter:
                "ukhonsu=" + this.hifu.UkHonsu + "&filtname=" + FilterName,
        });

        let containerSelector: string = "";
        let containerLeeren: boolean = true;

        if (Initialisierung) {
            // Es soll der ganze Bereich neu geladen werden
            containerSelector =
                "[" +
                KonstantenArtikelsuche.FilterContainerDataAttribut +
                "='" +
                FilterName +
                "']";
        } else {
            // Es soll nur die Filter-Values-Liste geändert werden
            containerSelector =
                "[" +
                KonstantenArtikelsuche.FilterValuesListDataAttribut +
                "='" +
                FilterName +
                "']";

            // Der Schnittstelle mitteilen, dass nur der Listeninhalt benötigt wird
            FilterValuesProgrammInfo.ParameterHinzufuegen("nurwerte", "J");

            if (ListeErweitern) {
                // Ergebnisliste soll hinzugefügt werden zur bestehenden Liste
                containerLeeren = false;
            }

            if (Suchtext !== "") {
                // Suchtext zur Schnittstellenanfrage hinzufügen
                FilterValuesProgrammInfo.ParameterHinzufuegen(
                    "suche",
                    Suchtext
                );
            }

            if (Seite > 1) {
                // Schnittstelle mitteilen, dass eine weitere Seite benötigt wird
                FilterValuesProgrammInfo.ParameterHinzufuegen(
                    "seite",
                    Seite.toString()
                );
            }
        }

        // Promise aus BLL-Klasse zurückgeben
        return AS400Layer.INSTANCE.LadeSeite(FilterValuesProgrammInfo, {
            BlockContainerSelector: containerSelector,
            BlockContainerLeeren: containerLeeren,
            LadeanimationDeaktivieren: true,
        }).done(() => {
            this.hifu.ArtikelFilterFrontendMitHiddenFieldAktualisieren(
                FilterName
            );
        });
    }

    /**
     * Führt ein Submit an das Programm zur Ermittlung der Artikelfilter aus, um im Anschluss die Seite mit einem neuen Schlüssel neu zu laden
     */
    private ArtikelFilterSubmit() {
        let SubmitProgrammInfo: HoProgrammInfo = new HoProgrammInfo({
            Programm: KonstantenArtikelsuche.ProgrammFilterbereichSubmit,
            AjaxDaten: "ukhonsu=" + this.hifu.UkHonsu,
        });

        // String der ausgewählten Filter
        let filterString: string = "";

        // Durch alle Filter-Strings der Oberfläche iterieren
        $j(
            "[" +
                KonstantenArtikelsuche.FilterAusgewaehlteValuesDataAttribut +
                "]",
            this.$layoutRoot
        ).each((listeIndex, listeElem) => {
            let $item = $j(listeElem);

            // Filtername befindet sich im data-Attribut des Items
            let filterName =
                $item.attr(
                    KonstantenArtikelsuche.FilterAusgewaehlteValuesDataAttribut
                ) ?? "";

            if (filterName.length > 0) {
                // Filter aktualisieren
                let filterWert =
                    this.hifu.ArtikelFilterInHiddenFieldAktualisieren(
                        filterName
                    );

                if (filterWert.length > 0) {
                    // Filter zum Ausgabe-String hinzufügen
                    if (filterString.length > 0) {
                        filterString += "&";
                    }
                    filterString += filterName + "=" + filterWert;
                }
            }
        });

        SubmitProgrammInfo.AjaxDatenHinzufuegen(
            "filter",
            encodeURIComponent(filterString)
        );

        // Inhalts-Submit über reguläre Logik ausführen
        AS400Layer.INSTANCE.InhaltSubmit(SubmitProgrammInfo, {});
    }
}
