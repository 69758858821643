/**
 * Beinhaltet alle konstanten Werte für das Oxomi-Portal
 */
export class KonstantenOxomi {
    /**
     * Gibt den Namen der Window-Variable an, in die gespeichert wird, ob Oxomi bereits initialisiert worden ist.
     */
    public static readonly initIdentifizierer: string =
        "__holter.oxomi_initialisiert";

    /**
     * Gibt den Namen des Buttons im Oxomi-Portal an
     */
    public static readonly infoplayMenuTitle: string = "Artikeldetails";

    /**
     * Gibt an, ob die Oxomi-Logik für Historie ausgehebelt werden soll, oder nicht
     * @description
     * Befindet sich nicht in debug.ts weil es sich um einen Wert handelt,
     * der fix auf true eingestellt bleiben sollte,
     * damit die Seite nicht neu geladen wird nach Schließen des Popups
     */
    public static readonly disableOverlayHistory: boolean = true;

    /**
     * Gibt die Class-Map für oxomi.itemAttachments an
     */
    public static readonly itemAttachmentsClassMap = {
        ".oxomi-attachment": "col-lg-4 col-md-6 col-xs-12",
    };
}
