import { Debug } from "./debug";
import { Konstanten } from "./konstanten/konstanten";

/**
 * Stellt alle Funktionen bereit, die für die Überprüfung des Browsersupports relevant sind.
 */
export class Browsersupport {
    public static readonly INSTANCE = new Browsersupport();

    private constructor() {}

    /**
     * Prüft, ob HO mit dem aktuellen Browser funktionsfähig ist.
     * Leitet weiter zur Fehlerseite, falls dem nicht so ist.
     */
    public ValidiereBrowser() {
        if (!this.PruefeBrowserSupportErforderlich()) {
            // Zur "Nicht unterstützt" Seite weiterleiten
            window.location.href =
                Konstanten.ProgrammAusfuehrungsverzeichnis + "O4NOSW.PGM";
        }
    }

    /**
     * Prüft, ob der Browser alle Features unterstützt, ohne die HO nicht funktionsfähig ist.
     * @returns true, wenn der Browser alle erforderlichen Features anbietet
     */
    private PruefeBrowserSupportErforderlich(): boolean {
        let success: boolean = true;

        if (
            Debug.BrowserSupportDeaktivieren ||
            !Modernizr.cookies ||
            !Modernizr.eventlistener ||
            !Modernizr.strictmode ||
            !Modernizr.es5syntax ||
            !Modernizr.es5object ||
            !Modernizr.es5array ||
            !Modernizr.history
        ) {
            // Erforderliches Feature nicht gefunden
            success = false;
        }

        return success;
    }
}
