import * as $j from "jquery";
import { AppAuthenticationHeader } from "../auth/app-authentication-header";

export class HoMobileApi {
    /**
     * Führt einen unsichtbaren Login bei HoMobileApi durch.
     */
    public static SilentLogin(): JQuery.Deferred<void> {
        const $d = $j.Deferred();

        $j.ajax({
            type: "GET",
            url: "/api/mobile/v1/users/access/silentlogin",
            headers: {
                ...AppAuthenticationHeader,
            },
            global: false,
        })
            .done(() => $d.resolve())
            .catch((err) => $d.reject(err));

        return $d;
    }

    /**
     * Führt einen Logout bei HoMobileApi durch.
     */
    public static Logout(): JQuery.Deferred<void> {
        const $d = $j.Deferred();

        $j.ajax({
            type: "POST",
            url: "/api/mobile/v1/users/access/logout",
            headers: {
                ...AppAuthenticationHeader,
            },
            global: false,
        })
            .done(() => $d.resolve())
            .catch((err) => $d.reject(err));

        return $d;
    }
}
