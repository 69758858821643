/**
 * Definiert die Selektoren für die Spalten-Vorlagen für die Artikelsuche
 */
export enum ArtikelsucheVorlagen {
    Toggle = "#O4370_muster_toggle",
    Bild = "#O4370_muster_bild",
    Artikel = "#O4370_muster_artikel",
    Lagerware = "#O4370_muster_lagerware",
    Menge = "#O4370_muster_menge",
    MengeEinheitsbezeichnung = "#O4370_muster_menge_ehbe",
    Ladeanimation = "#O4372_muster_ladeanimation",
}
