import { AS400Layer } from "./as400Layer";
import { ArtikelsuchePreisanzeigeModus } from "./enums/ArtikelsuchePreisanzeigeModus";
import { HoProgrammInfo } from "./hoProgrammInfo";
import { IO4999ROutput } from "./interfaces/IO4999ROutput";
import { ArtikelsuchePreisanzeigeModusTools } from "./mappers/ArtikelsuchePreisanzeigeModusTools";

export class Benutzerprofil {
    private _Suche_FilterAnzeigen: boolean;
    public get Suche_FilterAnzeigen(): boolean {
        return this._Suche_FilterAnzeigen;
    }
    public set Suche_FilterAnzeigen(v: boolean) {
        this._Suche_FilterAnzeigen = v;
        this.sync();
    }

    private _Suche_NurLagerware: boolean;
    public get Suche_NurLagerware(): boolean {
        return this._Suche_NurLagerware;
    }
    public set Suche_NurLagerware(v: boolean) {
        this._Suche_NurLagerware = v;
        this.sync();
    }

    private _Suche_BilderAnzeigen: boolean;
    public get Suche_BilderAnzeigen(): boolean {
        return this._Suche_BilderAnzeigen;
    }
    public set Suche_BilderAnzeigen(v: boolean) {
        this._Suche_BilderAnzeigen = v;
        this.sync();
    }

    private _Suche_PreisanzeigeModus: ArtikelsuchePreisanzeigeModus;
    public get Suche_PreisanzeigeModus(): ArtikelsuchePreisanzeigeModus {
        return this._Suche_PreisanzeigeModus;
    }

    public constructor() {
        this.sync();
    }

    public sync() {
        let aufrufDaten = new HoProgrammInfo({
            Programm: "O4999R",
            CallJsAnhaengen: true,
        });

        if (this._Suche_FilterAnzeigen !== undefined) {
            aufrufDaten.AjaxDatenHinzufuegen(
                "filterAnzeigen",
                this._Suche_FilterAnzeigen.toString()
            );
        }

        if (this._Suche_NurLagerware !== undefined) {
            aufrufDaten.AjaxDatenHinzufuegen(
                "nurLagerware",
                this._Suche_NurLagerware.toString()
            );
        }

        if (this._Suche_BilderAnzeigen !== undefined) {
            aufrufDaten.AjaxDatenHinzufuegen(
                "BilderInSucheAnzeigen",
                this._Suche_BilderAnzeigen.toString()
            );
        }

        AS400Layer.INSTANCE.SendePostRequest(aufrufDaten, {
            LadeanimationDeaktivieren: true,
        }).done((resData) => {
            let data: IO4999ROutput = JSON.parse(resData);

            if (data.filterAnzeigen !== undefined) {
                this._Suche_FilterAnzeigen = data.filterAnzeigen;
            }

            if (data.nurLagerware !== undefined) {
                this._Suche_NurLagerware = data.nurLagerware;
            }

            if (data.BilderInSucheAnzeigen !== undefined) {
                this._Suche_BilderAnzeigen = data.BilderInSucheAnzeigen;
            }

            this._Suche_PreisanzeigeModus =
                ArtikelsuchePreisanzeigeModusTools.parseCode(
                    data.PreisanzeigeModus
                );
        });
    }
}
