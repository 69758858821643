/**
 * Beinhaltet alle konstanten Werte für das Typeahead.js Autocomplete
 */
export class KonstantenAutocomplete {
    /**
     * Gibt das Wildcard an, mit dem der Parameterwert in der URL vor dem Schnittstellenaufruf dargestellt wird.
     */
    public static readonly StandardUrlWildcard: string = "%QUERY";

    /**
     * Gibt die Klassendefinitionen für Mindestbreite der Autocomplete an
     */
    public static readonly ClassMapMinWidth = {
        menu: "tt-menu autocomplete-min-width",
    };

    /**
     * Gibt die URL-Erweiterung für einen Schnittstellenaufruf zurück (Parameter + Platzhalter).
     */
    public static get StandardUrlErweiterung(): string {
        return this.StandardUrlParameter + this.StandardUrlWildcard;
    }

    /**
     * Stellt den Namen des Standard-Parameters für den Suchbegriff in der URL bereit.
     */
    private static readonly StandardUrlParameter: string = "&SUCHBEGR=";
}
