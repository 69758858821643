import * as $j from "jquery";

/**
 * Stellt Funktionen für Sticky-Elemente zur Verfügung
 */
export class StickyHeaderFooter {
    /**
     * Initialisiert alle vorhandenen Sticky-Elemente in der Seite
     */
    public static InitStickyness($layoutRoot: JQuery): void {
        $j('[data-ho-toggle="sticky"]', $layoutRoot).each((index, elem) => {
            // Jedes Sticky-Element, das gefunden wird, initialisieren
            // Das Element will unten festkleben
            $j(elem).stickyHeaderFooter({
                bodySelector: $j(elem).data("ho-sticky-body-selector"),
                headerSelector: $j(elem).data("ho-sticky-header-selector"),
                footerSelector: $j(elem).data("ho-sticky-footer-selector"),
                bottom: $j(elem).data("ho-sticky-bottom-offset") || 0,
                top: $j(elem).data("ho-sticky-top-offset") || 0,
            });
        });
    }
}
