/**
 * Beinhaltet konstante Werte
 */
export class Konstanten {
    /**
     * Bestimmt das Basis-CGI-Verzeichnis der Businesslogik
     */
    public static readonly ProgrammAusfuehrungsverzeichnis: string = "/hon40/";
    /**
     * Bestimmt die Datentyperweiterung von Programmen
     */
    public static readonly ProgrammDateierweiterung: string = ".PGM";

    /**
     * Bestimmt den jQuery Selector für den Container, der als Rahmen für die zu serialisierenden Typen verwendet wird.
     */
    public static readonly ProgrammDatenRahmenSelector: string =
        "[data-ho-serialize]";

    /**
     * Bestimmt den jQuery Selector für den Container, der als Rahmen für den Warenkorb-Bereich verwendet wird.
     */
    public static readonly WarenkorbRahmenSelector: string =
        "[data-ho-warenkorb]";
    /**
     * Bestimmt die Typen von Steuerelementen, die beim Serialisieren
     * eines Bereichs für das Ermitteln der zu serialisierenden Steuerelemente verwendet wird.
     */
    public static readonly ProgrammDatenSerialisierungTypen: string =
        "select, textarea, input";

    /**
     * Bestimmt den Basis-Fenstertitel
     */
    public static readonly FensterNameStandardTitel: string = "Holter Online";

    /**
     * Bestimmt die ID des Markup-Elements, dessen Inhalt als Bereich-Beschriftung verwendet wird
     */
    public static readonly FensterNameBereichId: string = "#ho_bereich";

    /**
     * Bestimmt die ID des Markup-Elements, dessen Inhalt als Titel-Beschriftung verwendet wird
     */
    public static readonly FensterNameTitelId: string = "#ho_titel";

    /**
     * Bestimmt die ID des Markup-Elements, dessen Inhalt der Programmname ist
     */
    public static readonly FensterPgmnId: string = "#ho_pgmn";

    /**
     * Bestimmt die ID des Markup-Elements, dessen Inhalt das Datum mit Uhrzeit der Anzeige ist
     */
    public static readonly FensterDatumId: string = "#ho_datum";

    /**
     * Das ist das Notfall-Programm, das geladen wird, wenn sonst kein Programm ausgeführt werden würde.
     */
    public static readonly FallbackStrategieProgramm: string = "O4004";

    /**
     * Das ist das Programm, das für die Fehlerausgabe zuständig ist.
     */
    public static readonly FehlerProgramm: string = "O4351W";

    /**
     * Das ist die Standard-Fehlernummer, die bei generellen Fehlern
     * an Konstanten.FehlerProgramm als Parameter übergeben wird.
     */
    // tslint:disable-next-line:variable-name
    public static readonly FehlerNummer_Standardfehler = "&fnrht=13153";

    /**
     * Definiert den Namen des Window-Elements, das true ist, während eine AJAX Request ausgeführt wird
     */
    public static readonly AjaxPendingIdentifizierer: string =
        "___holter.ajaxpending";

    /**
     * Definiert den jQuery Selector, um alle Profil-Buttons in der Seite anzusprechen
     */
    public static readonly ProfilButtonSelector: string = ".toolbar-profile";

    /**
     * Definiert den jQuery-Selektor, um den Profilbereich in der Seite anzusprechen
     */
    public static readonly ProfilContainerSelector: string = "#profile";

    /**
     * Bestimmt die Klasse, die einen Info-Button identifiziert
     */
    public static readonly InfoButtonSelector: string = "#info-button";
    /**
     * Bestimmt die Klasse, die einen Info-Button im Modal identifiziert
     */
    public static readonly InfoButtonModalSelector: string =
        "#dialog-info-button";
    /**
     * Bestimmt die Klasse, die einen Info-Bereich identifiziert
     */
    public static readonly InfoBoxSelector: string = "#info-box";
    /**
     * Bestimmt die Klasse, die einen Info-Bereich im Modal identifiziert
     */
    public static readonly InfoBoxModalSelector: string = "#dialog-info-box";
    /**
     * Bestimmt die Klasse, die hinzugefügt oder entfernt wird, um den Infobereich in der Seite ein-/auszublenden
     */
    public static readonly InfoBoxOffenToggle: string = "open";

    /**
     * Bestimmt den Namen des generellen Powersuche-Programms
     */
    public static readonly PowersucheProgrammName: string = "O4810R";
    /**
     * Bestimmt die ID der Textbox, in die der Powersuche-Suchbegriff eingegeben wird
     */
    public static readonly PowersucheTextboxId: string = "#PowersucheTextbox";
    /**
     * Bestimmt die ID der Kategorie-Auswahl für die Powersuche
     */
    public static readonly PowersucheKategorieId: string =
        "#PowersucheKategorie";
    /**
     * Bestimmt die ID des Buttons zur Durchführung der Powersuche
     */
    public static readonly PowersucheButtonId: string = "#PowersucheButton";
    /**
     * ID des Steuerelements, das bestimmt, welcher Kategorie die aktuell Seite zugehörig ist
     */
    public static readonly PowersucheKategorieHiddenFieldId: string =
        "#kategorie";

    /**
     * ID des Containers im Seiteninhalt, der beim Ausführen einer Powersuche serialisiert wird.
     */
    public static readonly PowersucheFilterzusatzId: string =
        "#powersuchefilter";

    /**
     * ID des Dialogfensters im Seiteninhalt.
     */
    public static readonly DialogFensterId: string = "dialog";

    /**
     * Definiert den Selector, mit dem geöffnete Dialogfenster ermittelt werden können.
     */
    public static readonly ToastSelector: string = "#O4000_toastbox";

    /**
     * Name des Window-Objektes in dem die DataTables-Instanzen gehalten werden
     * @description
     * Nachdem Holter Online eine {@link https://de.wikipedia.org/wiki/Single-Page-Webanwendung | SPA} ist,
     * müssen beim Verlassen einer Seite spezielle Bereinigungsschritte vorgenommen werden.
     * Zum Beispiel müssen alle erstellten DataTables-Objektinstanzen manuell bereinigt werden.
     * Deshalb werden erstellte DataTables-Instanzen am Window-Objekt in Array-Form angehängt.
     * Diese Variable bestimmt den Namen des Window-Objekts, in dem sich die DataTables-Objekte befinden.
     */
    public static readonly DataTablesWindowArrayName: string =
        "__holter.datatables.list";

    /**
     * Selector für das Overlay, welches bei längeren Ladezeiten angzeigt wird
     */
    public static readonly LoadingOverlay: string = "[data-ho-ladeanimation]";

    /**
     * Stellt den generellen Regex für die Validierung von Eingaben dar
     * @description
     * Alles zwischen ASCII-Zeichen 32 (Leerzeichen) und 126 (~)
     * plus äöüß (in Unicode-Notation anzugeben)
     * case-insensitive alle Übereinstimmungen (/ig zum Schluss)
     * @see http://regexr.com/3gehj
     */
    public static readonly RegexStandardInput =
        /[ -~\u00df\u00e4\u00f6\u00fc]+/gi;

    /**
     * Stellt den generellen Regex für Number-Validierungen bereit
     * @description
     * Alles zwischen 0 und 9 plus . und ,
     * @see http://regexr.com/3gehg
     */
    public static readonly RegexNumber = /[0-9,.]+/g;

    /**
     * Stellt den Regex für eine E-Mail-Adress-Prüfung bereit
     * @description
     * Es wird geprüft, ob das Format der eingegebenen Adresse stimmt,
     * und ob nur gültige Zeichen vorkommen
     * @see http://regexr.com/3bcrb
     */
    public static readonly RegexEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/gm;
}
