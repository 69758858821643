/**
 * Beinhaltet konstante Werte für die Artikeldetails
 */
export class KonstantenArtikeldetails {
    /**
     * Selector für das Element, das die Holter-Artikelnummer enthält
     */
    public static readonly ArtikelnummerHolterSelector = "#O4008_oxomi_artn";

    /**
     * Selector für das Element, das die Lieferanten-Artikelnummer enthält
     */
    public static readonly ArtikelnummerLieferantSelector = "#O4008_oxomi_artl";

    /**
     * Selector für das Element, das die Lieferanten-Nummer enthält
     */
    public static readonly LieferantNummerSelector = "#O4008_oxomi_lief";

    // Selektoren für Tabs

    /**
     * Gibt den Selector für den Container an, in dem sich alle Tabs befinden
     */
    public static readonly TabsContainerSelector = "#O4008_tabs";

    /**
     * Gibt den Selector für den Parent-Container der Tab-Header an
     */
    public static readonly TabsHeadersSelector = "#O4008_tabs_headers";

    /**
     * Gibt den Selector für Tab02 an
     */
    public static readonly Tab02Selector = "#tab02";

    /**
     * Gibt den Selector für Tab04 an
     */
    public static readonly Tab04Selector = "#tab04";

    /**
     * Gibt den Selector für Tab05 an
     */
    public static readonly Tab05Selector = "#tab05";

    /**
     * Gibt den Selector für Tab06 an
     */
    public static readonly Tab06Selector = "#tab06";

    /**
     * Gibt den Selector für Tab07 an
     */
    public static readonly Tab07Selector = "#tab07";

    // Selektoren für separate Prüfungen

    /**
     * Gibt den Selector an, der aussagt, ob Tab07 schon befüllt worden ist, oder noch nicht
     */
    public static readonly Tab07NichtBefuelltSelector =
        "#tab07 [data-ho-O4008-varianten-empty]";

    /**
     * Gibt den Selector für die Tabelle in Tab07 an
     */
    public static readonly Tab07TabelleSelector = "#O4008_tab07_tabelle";

    // Selektoren für einzelne Container, die dynamisch befüllt werden

    /**
     * Gibt den Selector des Containers für Holter-Kataloge an
     */
    public static readonly KatalogHolterContainerSelector =
        "#O4008_oxomi_itempages_own";

    /**
     * Gibt den Selector des Containers für Hersteller-Kataloge an
     */
    public static readonly KatalogHerstellerContainerSelector =
        "#O4008_oxomi_itempages_others";

    /**
     * Gibt den Selector des Containers für den Langtext an
     */
    public static readonly LangtextContainerSelector = "#O4008_oxomi_itemtext";

    /**
     * Gibt den Selector des Containers für Download-Dokumente an
     */
    public static readonly DownloadsContainerSelector =
        "#O4008_oxomi_itemattachments";

    /**
     * Gibt den Selector des Containers für Holter-eigene Download-Dokumente an
     */
    public static readonly DownloadsEigeneDokumenteContainerSelector =
        "#O4008_own_itemattachments";

    /**
     * Gibt den Selector des Containers für Bilder an
     */
    public static readonly BilderContainerSelector = "#O4008_oxomi_itemimages";

    /**
     * Gibt den Selector des Containers für Videos an
     */
    public static readonly VideosContainerSelector = "#O4008_oxomi_itemvideos";

    /**
     * Gibt den Selector des Containers für Exposees an
     */
    public static readonly ExposeesContainerSelector =
        "#O4008_oxomi_itemgalleries";

    /**
     * Gibt den Selector des Containers für Masterdata an
     */
    public static readonly MasterdataContainerSelector =
        "#O4008_oxomi_itemmasterdata";

    // Ladestatus-Texte

    /**
     * Gibt den Text an, der während dem Ladevorgang im Platzhalter für Holter-Kataloge angezeigt wird
     */
    public static readonly KatalogHolterStatusTextVorher =
        "<div>HOLTER Kataloge werden geladen...</div>";

    /**
     * Gibt den Text an, der während dem Ladevorgang im Platzhalter für Hersteller-Kataloge angezeigt wird
     */
    public static readonly KatalogHerstellerStatusTextVorher =
        "<div>Hersteller-Kataloge werden geladen...</div>";

    /**
     * Gibt den Text an, der während dem Ladevorgang im Platzhalter für die Masterdaten angezeigt wird
     */
    public static readonly MasterdataStatusTextVorher =
        "Masterdaten werden geladen...";

    /**
     * Gibt den Text an, der während dem Ladevorgang im Platzhalter für den Langtext angezeigt wird
     */
    public static readonly LangtextStatusTextVorher =
        "Langtext wird geladen...";

    /**
     * Gibt den Text an, der während dem Ladevorgang im Platzhalter für Downloads angezeigt wird
     */
    public static readonly DownloadsStatusTextVorher =
        "<span class='col-xs-12'>Hersteller-Dokumente werden geladen...</span>";

    /**
     * Gibt den Text an, der während dem Ladevorgang im Platzhalter für Bilder angezeigt wird
     */
    public static readonly BilderStatusTextVorher = "Bilder werden geladen...";

    /**
     * Gibt den Text an, der während dem Ladevorgang im Platzhalter für Videos angezeigt wird
     */
    public static readonly VideosStatusTextVorher = "Videos werden geladen...";

    /**
     * Gibt den Text an, der während dem Ladevorgang im Platzhalter für Exposees angezeigt wird
     */
    public static readonly ExposeesStatusTextVorher =
        "Exposees werden geladen...";

    // Leer-Texte

    /**
     * Gibt den Text an, der in den Platzhalter für Holter-Kataloge eingefügt wird, wenn es keine Holter-Kataloge gibt
     */
    public static readonly KatalogHolterStatusTextLeer =
        "<div>Keine HOLTER Kataloge zu diesem Artikel vorhanden</div>";

    /**
     * Gibt den Text an, der in den Platzhalter für Hersteller-Kataloge eingefügt wird, wenn es keine Hersteller-Kataloge gibt
     */
    public static readonly KatalogHerstellerStatusTextLeer =
        "<div>Keine Hersteller-Kataloge zu diesem Artikel vorhanden</div>";

    /**
     * Gibt den Text an, der in den Platzhalter für die Masterdaten eingefügt wird, wenn es keine Masterdaten gibt
     */
    public static readonly MasterdataStatusTextLeer =
        "Keine Artikel-Masterdaten vorhanden";

    /**
     * Gibt den Text an, der in den Platzhalter für den Langtext eingefügt wird, wenn es keinen Langtext gibt
     */
    public static readonly LangtextStatusTextLeer = "Kein Langtext vorhanden";

    /**
     * Gibt den Text an, der in den Platzhalter für Downloads eingefügt wird, wenn es keine Downloads gibt
     */
    public static readonly DownloadsStatusTextLeer =
        "<span class='col-xs-12'>Keine Hersteller-Dokumente vorhanden</span>";

    /**
     * Gibt den Text an, der in den Platzhalter für Bilder eingefügt wird, wenn es keine Bilder gibt
     */
    public static readonly BilderStatusTextLeer = "Keine Bilder vorhanden";

    /**
     * Gibt den Text an, der in den Platzhalter für Videos eingefügt wird, wenn es keine Videos gibt
     */
    public static readonly VideosStatusTextLeer = "Keine Videos vorhanden";

    /**
     * Gibt den Text an, der in den Platzhalter für Exposees eingefügt wird, wenn es keine Exposees gibt
     */
    public static readonly ExposeesStatusTextLeer = "Keine Exposees vorhanden";
}
