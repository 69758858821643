import * as $j from "jquery";
import { AS400Layer } from "./as400Layer";
import { Browsersupport } from "./browsersupport";
import { Browsertyp } from "./browsertyp";
import { FokusToolbox } from "./fokusToolbox";
import { Historie } from "./historie";
import { Layout } from "./layout";

/**
 * Stellt den zentralen Anwendungseinstiegspunkt bereit.
 * Sorgt dafür, dass alles, was initialisiert werden muss, initialisiert wird.
 * @default
 */
export default function main() {
    const $layoutRoot = $j("body");

    let url = new URLSearchParams(document.location.search);
    if (Browsertyp.WieMobilesGeraet && url.toString().length === 0) {
        window.location.href = "/mobile";
    } else if ($j("#O4000_autoLogin_TokenHolder").length) {
        let navigationUrl = `/HON40/O4000W.PGM?butt=login&oida=${$j(
            "#O4000_autoLogin_TokenHolder"
        ).val()}`;

        const programm = $j("input[name=programm]").val();
        if (programm) {
            navigationUrl += `&programm=${programm}`;
        }

        const parameter = $j("input[name=params]").val();
        if (parameter) {
            navigationUrl += `&${parameter}`;
        }

        const ukkafsa = $j("input[name=ukkafsa]").val();
        if (ukkafsa) {
            navigationUrl += `&ukkafsa=${ukkafsa}`;
        }

        window.location.href = navigationUrl;
    }

    Browsersupport.INSTANCE.ValidiereBrowser();

    Layout.INSTANCE.InitEvents($layoutRoot);
    AS400Layer.INSTANCE.InitEvents();
    Historie.INSTANCE.InitEvents();

    // Herausfinden, ob Login-Seite oder Kennwort-Rücksetz-Seite
    let programmName: string = "";

    if ($j("[id^='O4920']").length) {
        programmName = "O4920";
    } else {
        programmName = "O4000";
    }

    // Layout hier manuell initialisieren
    Layout.INSTANCE.InitLayout($layoutRoot, { ProgrammName: programmName });

    // Prüfen ob es ein zu fokussierendes Item gibt
    FokusToolbox.INSTANCE.SetzeFokus($layoutRoot);
}
