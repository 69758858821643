import * as $j from "jquery";
import { HoItemSearchApi } from "../api/interfaces/hoItemSearchApi";
import { ArtikelsuchePreisanzeigeModus } from "../enums/ArtikelsuchePreisanzeigeModus";
import { ArtikelsucheVersion2Templates as Templates } from "../templates/artikelsucheVersion2Templates";

export class ArtikelsucheVersion2TableBuilder {
    private $tableObject: JQuery;
    private $tableBody: JQuery;
    private tableId: string;
    private rowIndex: number;

    private ArtikelBilderAnzeigen: boolean;
    private readonly PreisanzeigeModus: ArtikelsuchePreisanzeigeModus;

    constructor(props: {
        ArtikelBilderAnzeigen: boolean;
        PreisanzeigeModus: ArtikelsuchePreisanzeigeModus;
    }) {
        this.tableId = Math.random().toString(36).substring(7);

        this.rowIndex = 0;

        this.ArtikelBilderAnzeigen = props.ArtikelBilderAnzeigen;
        this.PreisanzeigeModus = props.PreisanzeigeModus;

        this.initTableObject();
    }

    public setOrder(sort: HoItemSearchApi.EntitySortingParameter) {
        let col = 0;
        switch (sort.attributeName) {
            case "itemId": {
                col = 2;
                break;
            }
            case "itemIdBrand": {
                col = 4;
                break;
            }
            case "grossPrice": {
                col = 6;
                break;
            }
        }
        if (col && sort.order) {
            this.$tableObject.attr("data-order", `[${col},"${sort.order}"]`);
        }
    }

    public addRow(rowData: HoItemSearchApi.Product) {
        this.rowIndex++;

        let $imageCell = $j(`<td>`);
        let $tdUvp = $j(`<td>`);

        if (this.ArtikelBilderAnzeigen && rowData.imageURL) {
            $imageCell.append(
                $j(
                    `<a data-ho-funktion="ladeseite_click" data-ho-pgmn="O4828W" data-ho-dialog="true" data-ho-params="ARTNHT=${rowData.itemId}" ><img src="${rowData.imageURL}" class="product-img"></a>`
                )
            );
        }

        switch (this.PreisanzeigeModus) {
            case ArtikelsuchePreisanzeigeModus.UVP:
                $tdUvp.addClass("dt-right");
                if (rowData.grossPrice && rowData.grossPrice > 0.01) {
                    $tdUvp.html(
                        rowData.grossPrice.toFixed(2).replace(".", ",")
                    );
                } else {
                    $tdUvp.html("auf Anfrage");
                }
                break;
            case ArtikelsuchePreisanzeigeModus.Netto:
                $tdUvp.addClass("dt-right");
                if (rowData.netPrice && rowData.netPrice > 0.01) {
                    $tdUvp.html(rowData.netPrice.toFixed(2).replace(".", ","));
                } else {
                    $tdUvp.html("auf Anfrage");
                }
                break;
            case ArtikelsuchePreisanzeigeModus.Kundenindividuell:
                $tdUvp.addClass("dt-right");
                if (rowData.individualNetPrice && rowData.individualNetPrice >= 0.01) {
                    $tdUvp.html(rowData.individualNetPrice.toFixed(2).replace(".", ","));
                } else {
                    $tdUvp.html("auf Anfrage");
                }
                break;
            default:
                break;
        }

        this.$tableBody.append(
            $j("<tr>")
                .append(
                    $j(`<td class=" no-print">`).append(
                        $j(`<div 
                            class="table-icon open-details" 
                            data-ho-artikelsuche-moreinfo=${rowData.itemId}>
                        </div>`)
                    )
                )
                .append($imageCell)
                .append(
                    $j(
                        `<td>
                        <a href="O4008W.PGM?ARTNHT=${rowData.itemId}" data-ho-funktion="ladeseite_click">
                            <strong>${rowData.itemId}</strong>
                        </a>
                        <br> ${rowData.description} </td>`
                    )
                )
                .append(
                    $j(`<td>`).append(
                        Templates.Lagerware.replace(
                            "%%icon_lagerware%%",
                            this.findAvailability(rowData)
                        )
                    )
                )
                .append($j(`<td>`).html(this.escapeHtml(rowData.itemIdBrand)))
                .append(
                    $j(
                        `<td>
                            <input type="hidden"
                                name="pos(${this.zeroPad(this.rowIndex, 3)})" 
                                value="${this.zeroPad(this.rowIndex, 3)}"
                            />
                            <input type="hidden"
                                name="art(${this.zeroPad(this.rowIndex, 3)})" 
                                value="${rowData.itemId}"
                            />
                            <input type="text" 
                                name="mge(${this.zeroPad(this.rowIndex, 3)})" 
                                id="O4380_${this.zeroPad(
                                    this.rowIndex,
                                    3
                                )}_menge" 
                                data-ho-validate="regex input change" 
                                data-ho-validate-regex-typ="number" 
                                data-ho-funktion="submit_keypress_enter" 
                                data-ho-pgmn="O4300R" 
                                tabindex="${this.rowIndex}" 
                            />
                            <br />
                            <a data-ho-funktion="addmenge_click" 
                                data-ho-target="#O4380_${this.zeroPad(
                                    this.rowIndex,
                                    3
                                )}_menge" 
                                class="no-user-select no-outline" 
                                tabindex="0"
                            >
                                ${(rowData.packageSize || 1)
                                    .toFixed(2)
                                    .replace(".", ",")} ${rowData.unit}
                            </a>
                        </td>`
                    )
                )
                .append($tdUvp)
        );
    }

    public escapeHtml(unsafe: string): string {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    public zeroPad(num, places) {
        const zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

    public getResult(): JQuery {
        return this.$tableObject;
    }

    private initTableObject() {
        this.$tableObject = $j(
            `<table
                class="table table-striped table-hover dataTable"
                data-ho-table-domtyp="withoutpagination" 
                data-ho-table-page-length="-1"
                data-ho-table-lengthmenu-type="alle"
                data-ho-table-fixedfooter="true"
                id="O4380_table_${this.tableId}"
                data-ho-artikelsuchev2-tabelle
            >`
        );

        let $thUvp = $j(`<th>`);

        switch (this.PreisanzeigeModus) {
            case ArtikelsuchePreisanzeigeModus.Keine:
                $thUvp.addClass("no-sort");
                break;
            case ArtikelsuchePreisanzeigeModus.UVP:
                $thUvp.addClass("dt-right").html("UVP");
                break;
            case ArtikelsuchePreisanzeigeModus.Netto:
                $thUvp.addClass("dt-right").addClass("no-sort").html("Netto");
                break;
            case ArtikelsuchePreisanzeigeModus.Kundenindividuell:
                $thUvp.addClass("dt-right").addClass("no-sort").html("Kundenindiv. VP");
                break;
            default:
                break;
        }

        this.$tableObject.append(
            $j("<thead>").append(
                $j("<tr>")
                    .append($j(`<th>`).addClass("no-sort"))
                    .append($j(`<th>`).addClass("no-sort"))
                    .append($j(`<th>`).html("Artikel"))
                    .append($j(`<th>`).addClass("no-sort"))
                    .append($j(`<th>`).html("Werksnummer"))
                    .append($j(`<th>`).addClass("no-sort").html("Menge"))
                    .append($thUvp)
            )
        );

        this.$tableObject.append(
            $j("<tfoot>").append(
                $j(`<tr data-ho-table-rowgroup>`)
                    .append($j(`<td>`))
                    .append($j(`<td>`))
                    .append(
                        $j(
                            `<td 
                                class="dt-right" 
                                data-ho-table-rowgroup-content
                            >`
                        ).append(
                            $j(`<input 
                                type="button"
                                class="button-shoppingcart"
                                value="In den Warenkorb"
                                data-ho-butt="Speichern"
                                data-ho-funktion="submit_click"
                                data-ho-pgmn="O4300R"
                                data-ho-block="O4380_table_${this.tableId}"
                            >`)
                        )
                    )
                    .append($j(`<td>`))
                    .append($j(`<td>`))
                    .append($j(`<td>`))
                    .append($j(`<td>`))
            )
        );

        this.$tableBody = $j("<tbody>");
        this.$tableObject.append(this.$tableBody);
    }

    /**
     * Ermittelt das Verfügbarkeits-Kennzeichen für die UI anhand der Artikeleigenschaften
     */
    private findAvailability(rowData: HoItemSearchApi.Product): string {
        let availabilityMarkup: string = "";

        switch (rowData.stock?.availability.indicator) {
            case HoItemSearchApi.StockAvailability.IndicatorEnum.InStock:
                availabilityMarkup = Templates.StockIcons.InStock;
                break;
            case HoItemSearchApi.StockAvailability.IndicatorEnum.InMainStock:
                availabilityMarkup = Templates.StockIcons.InMainStock;
                break;
            case HoItemSearchApi.StockAvailability.IndicatorEnum
                .AvailableWithinTwoDays:
                availabilityMarkup =
                    Templates.StockIcons.AvailableWithinTwoDays;
                break;
            default:
                if (rowData.stock?.isProcurementItem) {
                    availabilityMarkup = Templates.StockIcons.ProcurementItem;
                } else {
                    availabilityMarkup = Templates.StockIcons.OutOfStock;
                }
                break;
        }

        return availabilityMarkup.replace(
            "%%availability_text%%",
            rowData.stock?.availability.stockInformation ?? ""
        );
    }
}
